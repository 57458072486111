import {faList, faLongArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import pluralize from "pluralize";
import {useLocation} from "react-router";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

const TransmittalPaperItem = (props: {
  transmittal: any;
  onEdit: (transmittal) => void;
  projectId: number;
  [rest: string]: any;
}) => {
  const {transmittal, onEdit, projectId, ...rest} = props;
  const {pathname} = useLocation();
  return (
    <PaperItem>
      {/* <PaperItem.Left></PaperItem.Left> */}
      <PaperItem.Body>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            {/* <Link href={`/transmittals/${transmittal.id}/`}> */}
            <MuiNavLink href={`/v2/projects/${projectId}/transmittals/${transmittal.id}/`} underline="always">
              {transmittal.description}
            </MuiNavLink>
            {/* </Link> */}
          </Grid>

          <Grid item xs={7}>
            {transmittal.from_contact.full_name} <FontAwesomeIcon icon={faLongArrowRight} />{" "}
            {transmittal.to_contact.full_name}
          </Grid>

          <Grid item sm={2} xs={12}>
            <MuiNavLink href={`/v2/projects/${projectId}/transmittals/${transmittal.id}/#tab=items`}>
              <Typography2 type="metadata">
                <FontAwesomeIcon icon={faList} /> {transmittal.item_count} {pluralize("item", transmittal.item_count)}
              </Typography2>
            </MuiNavLink>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography2 type="metadata">Sent: {transmittal.date_sent}</Typography2>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography2 type="metadata">Required: {transmittal.date_required || "None"}</Typography2>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography2 type="metadata">Transmitted For: {transmittal.transmitted_for_display}</Typography2>
          </Grid>
        </Grid>
        {/* <pre>{JSON.stringify(transmittal, null, 2)}</pre> */}
      </PaperItem.Body>
      <PaperItem.Right>
        <Box textAlign="right">
          <Typography2 noWrap type="metadata">
            {transmittal.number_display}
          </Typography2>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.ItemNumber number={transmittal.number_display} />
        <PaperItem.RightHover.IconButton
          icon={PictureAsPdfIcon}
          title="Get PDF"
          component={Link}
          href={`/reports2/transmittals/${transmittal.id}/?display_from_signature=on`}
          target="_blank"
        />
        <PaperItem.RightHover.IconButton
          icon={EditIcon}
          title="Quick Edit"
          onClick={() => {
            onEdit(transmittal);
          }}
        />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete"
          href={`/v2/projects/${projectId}/delete/transmittals/transmittal/${transmittal.id}/?next=/v2/projects/${projectId}/transmittals/&cancel=${pathname}`}
          component={MuiNavLink}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export default TransmittalPaperItem;
