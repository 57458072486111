import {faTrafficCone} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet";
import {PageHeader} from "../../components/PageHeader";
import {PSRDashboard} from "./PSRDashboard";
import WorkPlans from "../WorkPlans/WorkPlans";
import {Breadcrumbs} from "../../components/Breadcrumbs";

import {Box, Link, Typography} from "@material-ui/core";

const SafetyDashboard = (props) => {
  const {...rest} = props;
  return (
    <>
      <Helmet title="Safety Dashboard" />

      <Breadcrumbs>
        <Link href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </Link>
        <Typography color="textPrimary">Safety Dashboard</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faTrafficCone} /> Safety Dashboard
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <WorkPlans />
      <Box mb={3} />
      <PSRDashboard />
    </>
  );
};

export default SafetyDashboard;
