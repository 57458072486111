import {Grid, Typography} from "@material-ui/core";

import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect, FilterSwitch} from "../../../components/Filters";
import workPlanStatusChoices from "../../../components/forms/choices/workPlanStatusChoices.json";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import useUser from "../../../hooks/useUser";
import {WorkPlanTable} from "../../WorkPlans/WorkPlanComponents";
import {WorkPlanDialogForm} from "../../WorkPlans/WorkPlanForms";

const ProjectWorkPlans = (props) => {
  const {project, ...rest} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [workPlanDialogIsOpen, setWorkPlanDialogIsOpen] = React.useState(false);

  const blockUI = useBlockUI();
  const user = useUser();

  const [workPlanFilterOptions, setWorkPlanFilterOption, clearWorkPlanFilterOption, clearAllWorkPlanFilterOptions] =
    useFilterOptions(["Status", "Search", "Start Date", "End Date", "Expedited"], {});

  const workPlanFilterParams = {
    q: workPlanFilterOptions.Search.value,
    status: workPlanFilterOptions.Status.value,
    date_min: workPlanFilterOptions["Start Date"].value,
    date_max: workPlanFilterOptions["End Date"].value,
    page: page,
    page_size: pageSize,
    is_expedited: workPlanFilterOptions["Expedited"].value,
  };

  const {query: workPlanQuery, create: createWorkPlan} = useSentinelListAPI(
    `projects/${project.id}/safety/work-plans/?${qs.stringify(workPlanFilterParams)}`,
    {
      initialData: {results: []},
    }
  );

  const workPlans = workPlanQuery.data.results;

  return (
    <>
      <Helmet title={`Work Plans`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textSecondary">Safety</Typography>

        <Typography color="textPrimary">Work Plans</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={workPlanQuery.isFetching}>
          <PaperPanel.Header.Title>Work Plans</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setWorkPlanDialogIsOpen(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => workPlanQuery.refetch()}
                isFetching={workPlanQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={workPlanFilterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setWorkPlanFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                label="Status"
                name="Status"
                options={workPlanStatusChoices}
                value={workPlanFilterOptions["Status"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setWorkPlanFilterOption("Status", value, label);
                }}
                showStatusIcon
                statusType="workPlan"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FilterDate
                name="Start Date"
                value={workPlanFilterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setPage(1);
                  setWorkPlanFilterOption("Start Date", date, date);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterDate
                name="End Date"
                value={workPlanFilterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setPage(1);
                  setWorkPlanFilterOption("End Date", date, date);
                }}
                disableFuture={false}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSwitch
                name="Expedited"
                label="Expedited"
                value={workPlanFilterOptions["Expedited"].value}
                onChange={(value) => {
                  setPage(1);
                  setWorkPlanFilterOption("Expedited", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterOptionChips
                filterOptions={workPlanFilterOptions}
                onDelete={clearWorkPlanFilterOption}
                onDeleteAll={() => clearAllWorkPlanFilterOptions()}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <WorkPlanTable workPlans={workPlans} />
        </PaperPanel.Body>
      </PaperPanel>
      <WorkPlanDialogForm
        isNew
        isOpen={workPlanDialogIsOpen}
        handleClose={() => setWorkPlanDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving Work Plan...");
          createWorkPlan.mutateAsync(values).then((result) => {
            window.location.href = result.url;
          });
        }}
        initialValues={{
          project: project,
          created_by: user,
        }}
      />
    </>
  );
};

export default ProjectWorkPlans;
