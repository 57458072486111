import {Box, Step, StepLabel, Stepper, useMediaQuery, useTheme} from "@material-ui/core";
import arrayMutators from "final-form-arrays";
import {isEmpty} from "lodash";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import InnerFooter from "../InnerFooter";
import FormActions from "./FormActions";

const Wizard = (props: {
  initialValues?: {};
  children: React.ReactElement<any>[];
  onSubmit: (values) => void;
  hideStepper?: boolean;
  submitButtonLabel?: string;
  hideFooter?: boolean;
  [rest: string]: any;
}) => {
  const {children, onSubmit, hideStepper = false, hideFooter = false, ...rest} = props;
  const [page, setPage] = React.useState(0);
  const [targetPage, setTargetPage] = React.useState(undefined);
  const childForms: React.ReactNode[] = React.Children.toArray(children);
  const activePage = childForms[page];
  const isLastPage = page === React.Children.count(children) - 1;
  const [submitLabel, setSubmitLabel] = React.useState("");
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);
  const [previousButtonDisabled, setPreviousButtonDisabled] = React.useState(false);

  const handleWizardSubmission = (values) => {
    const submitAction = values.submitAction;
    delete values.submitAction;
    if (submitAction !== "nextPage") {
      onSubmit(values);
    }
  };

  const theme = useTheme();
  const minStepperSize = useMediaQuery(theme.breakpoints.up("sm"));

  const useWizard = () => {
    return {
      setPage: setPage,
      setTargetPage: setTargetPage,
      setSubmitLabel: setSubmitLabel,
      setNextButtonDisabled: setNextButtonDisabled,
      setPreviousButtonDisabled: setPreviousButtonDisabled,
    };
  };

  return (
    <>
      {!hideStepper && (
        <Stepper activeStep={page} orientation={!minStepperSize ? "vertical" : "horizontal"}>
          {childForms.map((step, index) => {
            const {title} = step.props;
            return (
              <Step key={title}>
                <StepLabel>{title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <FinalForm
        onSubmit={handleWizardSubmission}
        mutators={{
          ...arrayMutators,
        }}
        {...rest}
      >
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, ...rest} = props;
          const {onCompleted, onAdvance} = activePage.props;

          return (
            <>
              {/* <BeforeUnload block={!pristine} /> */}
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  {/* <Box mx={2}>{activePage}</Box> */}
                  <Box mx={2} mb={2}>
                    {React.cloneElement(activePage, {values: values, form: form, useWizard: useWizard})}
                  </Box>
                  {!hideFooter && (
                    <InnerFooter>
                      {page > 0 && (
                        <>
                          <FormActions.PreviousButton
                            onClick={(event) => {
                              event.preventDefault();
                              setPage(Math.max(page - 1, 0));
                            }}
                            label="Previous"
                            disabled={previousButtonDisabled}
                          />
                          <Box mr={1} component="span" />
                        </>
                      )}

                      {isLastPage || submitLabel ? (
                        <>
                          <FormActions.SaveButton
                            label={Boolean(submitLabel) ? submitLabel : "Save"}
                            onClick={(e) => {
                              if (onCompleted) {
                                e.preventDefault();
                                onCompleted(values, form);
                              } else {
                                form.change("submitAction", "submit");
                              }
                            }}
                          />
                        </>
                      ) : (
                        <FormActions.NextButton
                          disabled={nextButtonDisabled}
                          onClick={(e) => {
                            form.resumeValidation();
                            form.change("submitAction", "nextPage");
                            setSubmitLabel("");
                            if (isEmpty(form.getState().errors)) {
                              if (onAdvance) {
                                onAdvance(values, form);
                              }
                              form.pauseValidation();
                              setPage(targetPage ?? Math.min(page + 1, children.length - 1));
                            }
                          }}
                        />
                      )}
                    </InnerFooter>
                  )}
                </FinalFormKeyboardShortcuts>
              </form>
              {/* <h3>Values</h3> <pre>{JSON.stringify(values, null, 2)}</pre>
              <h3>Form State</h3>
              <pre>{JSON.stringify(form.getState(), null, 2)}</pre> */}
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

Wizard.Page = (props: {
  title?: string;
  onCompleted?: (values, form) => void;
  onAdvance?: (values, form) => void;
  [rest: string]: any;
}): React.ReactElement<any> => {
  const {children, values, form, useWizard} = props;

  return React.cloneElement(children, {values: values, form: form, useWizard: useWizard});

  // return children;
};

export default Wizard;
