import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import fileDownload from "js-file-download";
import {sumBy} from "lodash";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectWorkLogsBySubcontractor = (props) => {
  const {project, ...rest} = props;
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      workLogs: [],
    },
  });
  const postCurrentPage = usePostCurrentPage({}, {baseURL: "", responseType: "blob"});
  const pageData = pageDataQuery.data;
  const {workLogs} = pageData;
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const totalHours = React.useMemo(() => {
    return sumBy(workLogs, "total_hours");
  }, [workLogs]);

  const totalQuantity = React.useMemo(() => {
    return sumBy(workLogs, "quantity");
  }, [workLogs]);

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching work logs..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Work Logs`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/daily-reports/`}>
          Daily Reports
        </MuiNavLink>
        <Typography color="textPrimary">Sub Work Logs</Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>Subcontractor Work Logs</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Work Logs For {project.display}</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.Button
                    startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                    disabled={pageDataQuery.isFetching}
                    onClick={(event) => {
                      blockUI.blockUI("Creating Excel File...");
                      postCurrentPage.mutateAsync({}).then((response) => {
                        fileDownload(response, `${project.display} - Work Log.xlsx`);
                        blockUI.unblockUI();
                      });
                    }}
                  >
                    Export Excel Crosstab
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>

            <PaperPanel.Body>
              <ReactTableMui
                hover
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["quantity", "total_hours"]}
                showFooter={Boolean(workLogs.length)}
                columns={[
                  {
                    Header: "Company",
                    accessor: "company_name",
                    Cell: ({value, row}) => {
                      return (
                        <MuiNavLink
                          href={`/v2/projects/${project.id}/daily-reports/work-log-by-subcontractor/${row.original.company_id}/`}
                          underline="always"
                        >
                          {value}
                        </MuiNavLink>
                      );
                    },
                    // Footer: () => {
                    //   return (
                    //     <Box textAlign="right">
                    //       <strong>Total:</strong>
                    //     </Box>
                    //   );
                    // },
                  },

                  {
                    Header: "Quantity",
                    accessor: "quantity",
                    Cell: ({value, row}) => {
                      return accounting.formatNumber(value, 0);
                    },
                    Footer: () => {
                      return totalQuantity ? accounting.formatNumber(totalQuantity, 0) : "";
                    },
                  },
                  {
                    Header: "Total Hours",
                    accessor: "total_hours",
                    Cell: ({value, row}) => {
                      return accounting.formatNumber(value, 0);
                    },
                    Footer: () => {
                      return totalHours ? accounting.formatNumber(totalHours, 0) : "";
                    },
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "company_name",
                      asc: true,
                    },
                  ],
                }}
                data={workLogs}
                getRowProps={(row) => {
                  return {
                    onClick: () => {
                      navigate(
                        `/v2/projects/${project.id}/daily-reports/work-log-by-subcontractor/${row.original.company_id}/`
                      );
                    },
                    style: {cursor: "pointer"},
                  };
                }}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(workLogs, null, 2)}</pre> */}
    </>
  );
};

export default ProjectWorkLogsBySubcontractor;
