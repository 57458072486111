import {faFileExcel, faSnowflake, faTachometer} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import {blue, deepOrange, green, red} from "@material-ui/core/colors";
import classnames from "classnames";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {Currency} from "../components/Accounting";
import {ConfirmationDialog} from "../components/Dialogs";
import {FilterOptionChips} from "../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../components/Filters";
import offices from "../components/forms/choices/offices.json";
import {CompanyAutoselectMui} from "../components/forms/Fields";
import {LegacyUILink} from "../components/Links";
import {PageHeader} from "../components/PageHeader";
import {PaginationWithPageSize} from "../components/Pagination";
import PaperPanel from "../components/PaperPanel";
import {ReactTableMui} from "../components/ReactTableMui";
import {TableStatusLabel} from "../components/Status";
import {WatchProjectSwitch} from "../components/WatchSwitches";
import useBlockUI from "../hooks/useBlockUI";
import useFilterOptions from "../hooks/useFilterOptions";
import {useFetchCurrentPage, usePostCurrentPage} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import {saveExcel} from "../utils/excel";

const Dashboard = (props) => {
  // console.log("props: ", props);
  const {permissions, ...rest} = props;
  const [client, setClient] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(250);
  const [showCleanupOldFollowedProjectsConfirmation, setShowCleanupOldFollowedProjectsConfirmation] =
    React.useState(false);
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const pageSizes = [100, 250, 500];

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Client",
    "Office",
  ]);

  const filterParams = {
    q: filterOptions.Search.value,
    client: filterOptions["Client"].value,
    office: filterOptions.Office.value,
  };
  const paginationParams = {
    page_size: pageSize,
    page: page,
  };

  const {
    data: pageData,
    refetch: refetchPageData,
    isFetching: watchedProjectsIsFetching,
  } = useFetchCurrentPage(
    {
      initialData: {
        watchedProjects: {
          results: [],
        },
        watchedProjectTotals: {},
      },
    },
    {...filterParams, ...paginationParams}
  );
  const {watchedProjects, watchedProjectTotals} = pageData;
  const postCurrentPage = usePostCurrentPage();

  const projectColumns = React.useMemo(
    () => [
      {
        id: "project",
        Header: "Project",
        accessor: (value) => {
          return `${value.status_sort}-${value.display}`;
        },
        Cell: ({value, row}) => (
          <>
            {/* <ProjectStatusIcon project={row.original} showTooltip />{" "} */}
            <TableStatusLabel status={row.original.status || "Unknown"}>
              <Link underline="always" href={`/v2/projects/${row.original.id}/`}>
                {row.original.display}
              </Link>
            </TableStatusLabel>
          </>
        ),
      },
      {
        Header: "Current Value",
        accessor: "current_budget",
        Cell: ({value, row}) => (
          <Link href={`/projects/${row.original.id}/budget/current/`} style={{color: green[800]}}>
            <Currency number={value} precision={0} />
          </Link>
        ),
      },
      {
        Header: "Receivable Balance	",
        accessor: "receivable_balance",
        Cell: ({value, row}) =>
          row.original.is_closed || !row.original.timberline_job_id ? (
            <span style={{color: red[800]}}>
              <Currency number={value} precision={0} />
            </span>
          ) : (
            <Link href={`/reports2/projects/${row.original.id}/timberline/aging/`} style={{color: red[800]}}>
              <Currency number={value} precision={0} />
            </Link>
          ),
      },
      {
        Header: "Billed to Date",
        accessor: "billed_to_date",
        Cell: ({value, row}) => <Currency number={value} precision={0} color={blue[800]} />,
      },
      {
        Header: "Cost to Date",
        accessor: "cost_to_date",
        Cell: ({value, row}) => (
          <>
            {row.original.is_frozen && (
              <>
                <Tooltip title="Frozen" placement="left">
                  <span>
                    <FontAwesomeIcon icon={faSnowflake} size="xs" color={blue[900]} />
                  </span>
                </Tooltip>{" "}
              </>
            )}
            {permissions.can_view_job_cost_reports ? (
              <Link
                href={`/reports2/projects/${row.original.id}/timberline/job-cost/`}
                style={{color: deepOrange[800]}}
              >
                <Currency number={value} precision={0} />
              </Link>
            ) : (
              <span style={{color: deepOrange[800]}}>
                <Currency number={value} precision={0} />
              </span>
            )}
          </>
        ),
      },
      {
        Header: "Followed",
        id: "toggle",
        disableSortBy: true,
        Cell: ({value, row}) => <WatchProjectSwitch size="small" initialChecked={true} projectId={row.original.id} />,
      },
    ],
    []
  );

  return (
    <>
      <Helmet title="Dashboard" />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faTachometer} /> Dashboard
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark
            title="Current Value"
            number={watchedProjectTotals?.current_budget}
            color={green[800]}
          />
          <PageHeader.Right.CurrencySpark
            title="Current Receivables"
            number={watchedProjectTotals?.receivable_balance}
            color={red[800]}
            border
          />
          <PageHeader.Right.CurrencySpark
            title="Current Billings "
            number={watchedProjectTotals?.billed_to_date}
            color={blue[800]}
            border
          />
        </PageHeader.Right>
      </PageHeader>
      {/* <Alert severity="info">
        We have made significant progress restoring the functionality of Outlook for Windows. Please contact{" "}
        <Link href="mailto:skimes@novoconstruction.com">Steven Kimes</Link> or{" "}
        <Link href="mailto:jteller@novoconstruction.com">Jeff Teller</Link> for more information.
      </Alert> */}
      {/* <Alert severity="warning">
        DocuSign is experiencing a major outage in North America that is affecting our ability to create and send
        envelopes at this time. We will continue to monitor the situation. Check{" "}
        <Link underline="always" href="https://status.docusign.com/">
          here
        </Link>{" "}
        for updates.
      </Alert> */}
      {/* <Alert severity="warning">
        Box seems to be having issues that are affecting Sentinel. Click{" "}
        <Link underline="always" href="https://status.box.com/">
          here
        </Link>{" "}
        for updates from Box.
      </Alert> */}

      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={watchedProjectsIsFetching}>
          <PaperPanel.Header.Title>Dashboard</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button variant="text" href="/v2/projects/">
                All Accessible Projects
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            {permissions?.is_pm_or_greater && (
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.Button variant="text" href="/v2/pm-billings/">
                  Project Billings
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    {/* <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        refetchPageData();
                        popupState.close();
                      }}
                    >
                      <FontAwesomeIcon icon={faSync} fixedWidth /> Refresh
                    </PaperPanel.Header.Menu.MenuItem> */}
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        refetchPageData();
                        saveExcel(
                          watchedProjects.results.map((project) => {
                            return {
                              Project: project.display,
                              Status: project.status,
                              "Current Budget": {v: project.current_budget, t: "n", z: "$#,##0.00"},
                              Receivables: {v: project.receivable_balance, t: "n", z: "$#,##0.00"},
                              Billed: {v: project.billed_to_date, t: "n", z: "$#,##0.00"},
                              Cost: {v: project.cost_to_date, t: "n", z: "$#,##0.00"},
                            };
                          }),
                          "Watched Projects",
                          [{wch: 35}, {wch: 15}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20}]
                        );
                        popupState.close();
                      }}
                    >
                      <FontAwesomeIcon icon={faFileExcel} fixedWidth /> Export Excel
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        setShowCleanupOldFollowedProjectsConfirmation(true);
                        popupState.close();
                      }}
                    >
                      Cleanup Old Followed Projects
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
              <PaperPanel.Header.RefreshButton
                isFetching={watchedProjectsIsFetching}
                onClick={() => refetchPageData()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <FinalForm onSubmit={() => {}}>
            {() => (
              <>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <FilterSearch
                      label="Search"
                      value={filterOptions.Search.value}
                      name="Search"
                      onChange={(value) => {
                        setPage(1);
                        setFilterOption("Search", value, value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <CompanyAutoselectMui
                      label="Client"
                      name="client"
                      value={client}
                      onChange={(e, value) => {
                        setClient(value);
                        setPage(1);
                        if (value === null) {
                          clearFilterOption("Client");
                        } else {
                          setFilterOption("Client", value?.id, value?.name);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FilterSelect
                      // native
                      label="Office"
                      name="Office"
                      options={offices}
                      value={filterOptions.Office.value}
                      onChange={(value, label) => {
                        setPage(1);
                        setFilterOption("Office", value, label);
                      }}
                    />
                  </Grid>
                </Grid>
                <Box mt={1} />
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <FilterOptionChips
                      filterOptions={filterOptions}
                      onDelete={(key) => {
                        clearFilterOption(key);
                        if (key === "Client") {
                          setClient(null);
                        }
                      }}
                      onDeleteAll={() => {
                        clearAllFilterOptions();
                        setClient(null);
                      }}
                    />
                  </Grid>
                  {watchedProjects.total_pages > 1 && (
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <PaginationWithPageSize
                          count={watchedProjects.total_pages}
                          page={page}
                          pageSize={pageSize}
                          setPage={setPage}
                          setPageSize={setPageSize}
                          pageSizes={pageSizes}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </FinalForm>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["current_budget", "receivable_balance", "billed_to_date", "cost_to_date", "toggle"]}
            columns={projectColumns}
            initialState={{
              sortBy: [
                {
                  id: "project",
                },
              ],
            }}
            data={watchedProjects.results}
          />
        </PaperPanel.Body>
        {watchedProjects.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={watchedProjects.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={pageSizes}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <LegacyUILink href={`/dashboard/legacy/`} mt={2} />
      {/*
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre>
       */}
      <ConfirmationDialog
        isOpen={showCleanupOldFollowedProjectsConfirmation}
        onApprove={() => {
          blockUI.blockUI("Unfollowing Old Projects...");
          setShowCleanupOldFollowedProjectsConfirmation(false);
          postCurrentPage.mutateAsync({action: "cleanup_followed_projects"}).then((response) => {
            refetchPageData().then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => {
          setShowCleanupOldFollowedProjectsConfirmation(false);
        }}
      >
        You want to cleanup old followed project. This will unfollow all the projects that were closed more than a year
        ago. This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export default Dashboard;
