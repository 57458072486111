import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import sentVia from "../../../components/forms/choices/sentVia.json";
import transmittalTransmittedFor from "../../../components/forms/choices/transmittalTransmittedFor.json";
import {
  DatePickerMui,
  NumberFieldMui,
  ProjectContactAutoselectMui,
  SimpleAutoselectMui,
  SingleAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";

export const TransmittalDialogForm = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  projectId: number;

  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, isNew, handleClose, isReadOnly, projectId, initialValues: _initialValues, ...rest} = props;

  const [isSubmitAndEdit, setIsSubmitAndEdit] = React.useState(false);

  const [initialValues, setInitialValues] = React.useState(_initialValues);
  React.useEffect(() => {
    setInitialValues(_initialValues);
  }, [_initialValues]);

  React.useEffect(() => {
    if (!isOpen || !isNew) return;
    if (_initialValues.to_contact) {
      axiosAPI
        .get(`projects/${projectId}/companies/${_initialValues.to_contact.company.id}/location/`)
        .then((response) => {
          if (!response.data?.full_address) return;

          setInitialValues((prevInitialValues) => {
            prevInitialValues["to_address"] = response.data.full_address;
            return {...prevInitialValues};
          });
        });
    }
    if (_initialValues.from_contact) {
      axiosAPI
        .get(`projects/${projectId}/companies/${_initialValues.from_contact.company.id}/location/`)
        .then((response) => {
          if (!response.data?.full_address) return;
          setInitialValues((prevInitialValues) => {
            prevInitialValues["from_address"] = response.data.full_address;
            return {...prevInitialValues};
          });
        });
    }
  }, [_initialValues]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
        {({handleSubmit, form, submitting, pristine = true, values}) => {
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle>{isNew ? "Create" : "Update"} Transmittal</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine && !isSubmitAndEdit} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <TransmittalCommonFields projectId={projectId} form={form} />
                  </Grid>
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || (pristine && !isNew) || isReadOnly} />
                <FormActions.SaveAndEditButton
                  disabled={submitting || (pristine && !isNew) || isReadOnly}
                  onClick={() => {
                    form.change("submitMode", "saveAndEdit");
                    setIsSubmitAndEdit(true);
                  }}
                />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const TransmittalDetailForm = (props) => {
  const {onSubmit, isReadOnly, projectId, ...rest} = props;
  const {pathname} = useLocation();
  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, ...rest} = props;
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <TransmittalCommonFields projectId={projectId} form={form} />
                    <Grid item xs={12}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="cc_contacts"
                        label="CC"
                        multiple
                        getOptionDisabled={(option) => values.cc_contacts.map((value) => value.id).includes(option.id)}
                      />
                    </Grid>
                  </Grid>
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/transmittals/transmittal/${values.id}/?next=/v2/projects/${projectId}/transmittals/&cancel=${pathname}`}
                        disabled={isReadOnly}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

const TransmittalCommonFields = (props) => {
  const {projectId, form, ...rest} = props;
  return (
    <>
      <Grid item xs={6} sm={3}>
        <NumberFieldMui name="number" label="Number" required />
      </Grid>
      <Grid item xs={6} sm={9}>
        <TextFieldMui name="description" label="Description" required autoFocus />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectContactAutoselectMui
          projectId={projectId}
          name="from_contact"
          label="From Contact"
          disableClearable={false}
          required
          onChange={(event, value, reason) => {
            if (reason === "select-option") {
              axiosAPI.get(`projects/${projectId}/companies/${value.company.id}/location/`).then((response) => {
                if (!response.data?.full_address) return;
                form.batch(() => {
                  form.change("from_address", response.data?.full_address);
                });
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectContactAutoselectMui
          projectId={projectId}
          name="to_contact"
          label="To Contact"
          disableClearable={false}
          required
          onChange={(event, value, reason) => {
            if (reason === "select-option") {
              axiosAPI.get(`projects/${projectId}/companies/${value.company.id}/location/`).then((response) => {
                if (!response.data?.full_address) return;
                form.batch(() => {
                  form.change("to_address", response.data?.full_address);
                });
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name="from_address" label="From Address" multiline minRows={2} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name="to_address" label="To Address" multiline minRows={2} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SimpleAutoselectMui options={transmittalTransmittedFor} name="transmitted_for" label="Transmitted For" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePickerMui name="date_sent" label="Date Sent" required />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePickerMui name="date_required" label="Date Required" />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <SimpleAutoselectMui options={sentVia} name="sent_via" label="Sent Via" /> */}
        <SingleAutocompleteMui
          label="Sent Via"
          name="sent_via"
          options={sentVia.map((option) => {
            return {
              value: option.label,
              label: option.label,
            };
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name="tracking_number" label="Tracking Number" />
      </Grid>
      <Grid item xs={12}>
        <TinyMCEMui name="comments" label="Comments" />
      </Grid>
    </>
  );
};

export const TransmittalItemForm = React.forwardRef(
  (
    props: {
      isOpen: boolean;
      isNew?: boolean;
      onSubmit: (values, form) => void;
      handleClose: () => void;
      isReadOnly?: boolean;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, isOpen, handleClose, isNew = false, isReadOnly = false, ...rest} = props;

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                <DialogTitle id="form-dialog-title">{isNew ? `Add New` : `Edit`} Transmittal Item</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={2}>
                        <NumberFieldMui name="number" label="Number" required />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <NumberFieldMui name="quantity" label="Quantity" required />
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <TextFieldMui name="item" label="Item" required autoFocus />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" required />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {isNew && (
                    <FormActions.SaveAndAddButton
                      disabled={pristine || isReadOnly}
                      onClick={() => form.change("submitMode", "addAnother")}
                    />
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);
