import {AppBar, Box, Grid, Link, Tab, Tabs, Typography} from "@material-ui/core";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {sumBy} from "lodash";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {CommitmentBanner} from "../../../components/ClosedBanner";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {PrimeContractDetailForm, PrimeContractItemDialog} from "./PrimeContractForms";
import {PrimeContractItemPaperItem, PrimeContractOCOPaperItem} from "./PrimeContractPaperItems";

const PrimeContractDetail = (props) => {
  const {project, ...rest} = props;
  const {contractId} = useParams();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [editItemDialogIsOpen, setEditItemDialogIsOpen] = React.useState(false);
  const [createItemDialogIsOpen, setCreateItemDialogIsOpen] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const {
    query: primeContractQuery,
    create: createPrimeContract,
    update: updatePrimeContract,
    rpc: primeContractRPC,
  } = useSentinelDetailAPI(`/projects/${project.id}/contracts/prime/${contractId}/`, {
    initialData: {},
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  const contract = primeContractQuery.data;

  const {
    query: contractItemsQuery,
    update: updateContractItem,
    delete: deleteContractItem,
    create: createContractItem,
    rpc: contractItemRPC,
  } = useSentinelListAPI(["projects", project.id, "contracts", "prime", parseInt(contractId), "items"], {
    initialData: {
      results: [],
    },
  });
  const items = contractItemsQuery.data.results;

  const {
    query: ocoQuery,
    update: updateOCO,
    delete: deleteOCO,
    create: createOCO,
    rpc: ocoRPC,
  } = useSentinelListAPI(`/projects/${project.id}/pccos/`, {
    initialData: {
      results: [],
    },
  });
  const ocos = ocoQuery.data.results;

  if (!primeContractQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Contract info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/contracts/`}>
          Contracts
        </MuiNavLink>
        <Typography color="textPrimary">{contract.display}</Typography>
      </ProjectBreadcrumbs>
      {pageDataQuery.data.originalBudget != contract.original_value && (
        <>
          <Alert severity="warning">
            The prime contract original value of <Currency number={contract.original_value} precision={2} /> does not
            match the original budget of <Currency number={pageDataQuery.data.originalBudget} precision={2} />. Please
            change the prime contract to <Currency number={pageDataQuery.data.originalBudget} precision={2} /> on the
            items tab.
          </Alert>
          <Box mb={2} />
        </>
      )}

      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="Original Value" number={contract.original_value} precision={2} />
          <PageHeader.Right.CurrencySpark
            title="Pending Revisions"
            number={contract.pending_revs}
            precision={2}
            border
          />
          <PageHeader.Right.CurrencySpark
            title="Approved Revisions"
            number={contract.approved_revs}
            precision={2}
            border
          />
          <PageHeader.Right.CurrencySpark title="Current Value" number={contract.current_value} precision={2} border />
        </PageHeader.Right>
      </PageHeader>
      <CommitmentBanner object={contract} mb={2} type="contract" />
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={`General Info`} value="general" className={novoClasses.smallTab} />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items
                    <TabCountChip
                      isLoading={!contractItemsQuery.isFetchedAfterMount}
                      count={contractItemsQuery.data.count}
                    />
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    OCOs
                    <TabCountChip isLoading={!ocoQuery.isFetchedAfterMount} count={ocoQuery.data.count} />
                  </Box>
                }
                value="ocos"
                className={novoClasses.smallTab}
              />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={1} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={primeContractQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Box justifyItems="space-between">{contract.display}</Box>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      component={Link}
                      href={`/v2/projects/${project.id}/pcos/items/?primecontract=${contract.id}`}
                      underline="none"
                    >
                      PCO Items
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Body>
                <PrimeContractDetailForm
                  projectId={project.id}
                  initialValues={contract}
                  onSubmit={(values) => {
                    const editAfterSubmit = values.editAfterSubmit;
                    delete values.editAfterSubmit;
                    blockUI.blockUI("Saving...");
                    updatePrimeContract
                      .mutateAsync(values)
                      .then(() => primeContractQuery.refetch())
                      .then(() => {
                        // if (!editAfterSubmit) {
                        //   navigate(`/v2/projects/${project.id}/contracts/#tab=primeContracts`);
                        // }
                        blockUI.unblockUI();
                      });
                  }}
                />
              </PaperPanel.Body>
            </>
          </TabPanel>
          <TabPanel value="items">
            <>
              <PaperPanel.TabHeader isLoading={primeContractQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Box justifyItems="space-between">{contract.display} Items</Box>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton
                      onClick={() => setCreateItemDialogIsOpen(true)}
                      disabled={!permissions.is_in_accounting_group}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Body>
                <Box mx={-3} mt={-2} mb={-3}>
                  {items.map((item) => (
                    <PrimeContractItemPaperItem
                      item={item}
                      projectId={project.id}
                      onEditItem={() => {
                        setActiveItem(item);
                        setEditItemDialogIsOpen(true);
                      }}
                      onDeleteItem={() => {
                        setActiveItem(item);
                        setDeleteConfirmationIsOpen(true);
                      }}
                      key={item.id}
                    />
                  ))}
                  <PaperItem.Header>
                    <PaperItem.Body>
                      <Grid container>
                        <Grid item xs={11}>
                          <Box textAlign="right">
                            <strong>Total: </strong>
                            <Currency number={sumBy(items, "value")} />
                          </Box>
                        </Grid>
                      </Grid>
                    </PaperItem.Body>
                  </PaperItem.Header>
                </Box>
              </PaperPanel.Body>
            </>
          </TabPanel>
          <TabPanel value="ocos">
            <>
              <PaperPanel.TabHeader isLoading={primeContractQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Box justifyItems="space-between">{contract.display} Owner Change Orders</Box>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton href={`/ccos/pcco/${contract.id}/create/`}>
                      Create New OCO
                    </PaperPanel.Header.CreateButton>
                    <Box ml={1} />
                    <PaperPanel.Header.PDFButtons pdfURL={`/reports2/contracts/primecontract/${contract.id}/pccos/`} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Body sectionBody mb={-3}>
                {/* <Box mx={-3} mt={-1} mb={-3}> */}
                <PaperItem.Header>
                  <PaperItem.Body>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        OCO
                      </Grid>
                      <Grid item sm={2} xs={4}>
                        <Box textAlign="right">Prior Value</Box>
                      </Grid>
                      <Grid item sm={2} xs={4}>
                        <Box textAlign="right">Amount</Box>
                      </Grid>
                      <Grid item sm={2} xs={4}>
                        <Box textAlign="right">Current Value</Box>
                      </Grid>
                    </Grid>
                  </PaperItem.Body>
                  <PaperItem.Right minWidth={30} />
                </PaperItem.Header>
                {ocos.map((oco) => (
                  <PrimeContractOCOPaperItem item={oco} projectId={project.id} key={oco.id} />
                ))}
                {/* </Box> */}
              </PaperPanel.Body>
            </>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      {/* <pre>{JSON.stringify(contract, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageDataQuery.data, null, 2)}</pre> */}

      <CreatedByModifiedBy obj={contract} mt={2} />
      <LegacyUILink href={`/contracts/primecontract/${contract.id}/legacy/`} mt={1} />

      {/* Quick Edit */}
      <PrimeContractItemDialog
        isOpen={editItemDialogIsOpen}
        handleClose={() => setEditItemDialogIsOpen(false)}
        onDelete={() => {
          setEditItemDialogIsOpen(false);
          setDeleteConfirmationIsOpen(true);
        }}
        projectId={project.id}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateContractItem.mutateAsync(values).then(() => {
            setEditItemDialogIsOpen(false);
            setActiveItem({});
            blockUI.unblockUI();
            primeContractQuery.refetch();
          });
        }}
        initialValues={activeItem}
      />

      {/* Quick Add */}
      <PrimeContractItemDialog
        isOpen={createItemDialogIsOpen}
        handleClose={() => setCreateItemDialogIsOpen(false)}
        projectId={project.id}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createContractItem.mutateAsync(values).then(() => {
            setCreateItemDialogIsOpen(false);
            setActiveItem({});
            blockUI.unblockUI();
            primeContractQuery.refetch();
          });
        }}
        isNew
      />

      <ConfirmationDialog
        title={`Delete ${activeItem.display}?`}
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteContractItem.mutateAsync(activeItem.id).then(() => {
            setDeleteConfirmationIsOpen(false);
            blockUI.unblockUI();
            setActiveItem({});
          });
        }}
      >
        Do you want to delete this Prime Contract Item?
      </ConfirmationDialog>
    </>
  );
};

export default PrimeContractDetail;
