import {Box, Grid, Link, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import {chain} from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import {VictoryContainer, VictoryLabel, VictoryPie, VictoryTheme} from "victory";
import {Currency, Number} from "../../../components/Accounting";
import {LabelValue} from "../../../components/LabelValue";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import {colorError, colorSuccess} from "../../../theme/colors";
import DeleteIcon from "@material-ui/icons/Delete";

export const RFPChart = (props: {rfps: any[]; property: string; label: string; [rest: string]: any}) => {
  const {rfps, property, label, ...rest} = props;

  const chartData = chain(rfps)
    .countBy(property)
    .map((count, type) => ({
      x: type,
      y: Number(((count / rfps.length) * 100).toFixed(2)),
    }))
    .value();
  return (
    <>
      <Box textAlign="center">
        <Typography variant="h6">{label}</Typography>
      </Box>
      <VictoryPie
        containerComponent={<VictoryContainer title={label} responsive={true} />}
        theme={VictoryTheme.material}
        // labelIndicator
        data={chartData}
        height={300}
        // labelIndicatorOuterOffset={15}
        // labelIndicatorInnerOffset={25}
        labelComponent={
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            text={({datum}) => [datum.x, `(${datum.y}%)`]}
            style={{fill: "black"}}
            // backgroundStyle={{fill: "rgba(255, 255, 255, 0.5)"}}
          />
        }
        sortKey={(datum) => datum.y}
        // events={[
        //   {
        //     target: "data",
        //     eventHandlers: {
        //       onClick: () => {
        //         return [
        //           {
        //             target: "data",
        //             mutation: ({style}) => {
        //               return style.fill === "#c43a31" ? null : {style: {fill: "#c43a31"}};
        //             },
        //           },
        //           {
        //             target: "labels",
        //             mutation: ({text}) => {
        //               return text === "clicked" ? null : {text: "clicked"};
        //             },
        //           },
        //         ];
        //       },
        //     },
        //   },
        // ]}
        {...rest}
      />
    </>
  );
};

export const CompetitorBidPaperItem = (props: {
  competitorBid: {
    company: {name: string; url: string};
    weekly_gcs: number;
    insurance_rate: number;
    fee: number;
    notes: string;
    winning_bid: boolean;
  };
  onEdit: () => void;
  onDelete?: () => void;
}) => {
  const {competitorBid, onEdit, onDelete, ...rest} = props;
  return (
    <PaperItem highlight={competitorBid.winning_bid}>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Link href={competitorBid.company.url} underline="always">
              {competitorBid.company.name}
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography2 type="metadata">Weekly GCs:</Typography2> <Currency number={competitorBid.weekly_gcs} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography2 type="metadata">Insurance:</Typography2>{" "}
            <NumberFormat value={competitorBid.insurance_rate} displayType={"text"} suffix={"%"} decimalScale={3} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography2 type="metadata">Fee:</Typography2>{" "}
            <NumberFormat value={competitorBid.fee} displayType={"text"} suffix={"%"} decimalScale={3} />
          </Grid>

          {competitorBid.notes && (
            <Grid item xs={12} sm={6}>
              <Typography2 type="metadata">Notes:</Typography2> {competitorBid.notes}
            </Grid>
          )}
        </Grid>
      </PaperItem.Body>

      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Edit" onClick={onEdit} />
        {onDelete && <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDelete} />}
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export const WeeklyLogRFPPaperItem = (props) => {
  const {rfp, onClickEdit, ...rest} = props;
  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
              <StatusLabel status={rfp.status_display} hint="rfp" />
              <Typography variant="h6" component="span" sx={{fontWeight: "bold"}}>
                &nbsp;{rfp.project_name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <LabelValue label="📍" value={rfp.address_display} sx={{fontSize: "1rem"}} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Due Date" value={moment(rfp.due_date).format("MM-DD-YYYY")} sx={{fontSize: "1rem"}} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="$" value={<Number number={rfp.anticipated_budget} />} sx={{fontSize: "1rem"}} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Square Footage" value={<Number number={rfp.square_footage} />} sx={{fontSize: "1rem"}} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Bid Type" value={rfp.bid_type_display} sx={{fontSize: "1rem"}} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue
              label="Numbers Review Scheduled"
              value={
                rfp.internal_review_scheduled ? (
                  <Box color={colorSuccess} component="span">
                    Yes
                  </Box>
                ) : (
                  <Box color={colorError} component="span">
                    No
                  </Box>
                )
              }
              sx={{fontSize: "1rem"}}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue
              label="Potential Interview"
              value={
                rfp.potential_interview ? (
                  <Box color={colorSuccess} component="span">
                    Yes
                  </Box>
                ) : (
                  <Box color={colorError} component="span">
                    No
                  </Box>
                )
              }
              sx={{fontSize: "1rem"}}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue
              label="Team"
              value={[rfp.project_executive, rfp.senior_project_manager, rfp.project_manager, rfp.superintendent]
                .filter((member) => member)
                .map((teamMember) => {
                  if (teamMember) {
                    return `${teamMember.first_name} ${teamMember.last_name}`;
                  }
                })
                .join(", ")}
              sx={{fontSize: "1rem"}}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Construction Manager" value={rfp?.construction_manager?.name} sx={{fontSize: "1rem"}} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Architect" value={rfp?.architect?.name} sx={{fontSize: "1rem"}} />
          </Grid>
          {rfp.competitors?.length > 0 && (
            <Grid item xs={12} sm={12}>
              <LabelValue label="Competitors" value={rfp.competitors.join(", ")} sx={{fontSize: "1rem"}} />
            </Grid>
          )}
          {rfp.interview_prep_status && (
            <Grid item xs={12} sm={12}>
              <LabelValue label="Prep Notes" value={rfp.interview_prep_status} sx={{fontSize: "1rem"}} />
            </Grid>
          )}
        </Grid>
      </PaperItem.Body>

      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onClickEdit} />
        <PaperItem.RightHover.IconButton
          icon={LaunchIcon}
          title="Detail"
          href={rfp.url}
          component={Link}
          target="_blank"
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
