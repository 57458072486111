import {faList} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {sumBy} from "lodash";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useSelectIds} from "../../../../js/hooks";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import BudgetPaperItemGrid from "../ChangeOrders/BudgetPaperItemGrid";
import {NamePCCOForm} from "./PCCOCreateForms";

const PCCOCreate = (props) => {
  const {project} = props;
  const navigate = useNavigate();
  const blockUI = useBlockUI();
  const [createOCODialogIsOpen, setCreateOCODialogIsOpen] = React.useState(false);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      cors: [],
      contract: {
        url: "",
      },
    },
  });

  const pageData = pageDataQuery.data;
  const {contract} = pageData;
  const postCurrentPage = usePostCurrentPage();

  const cors = pageData.cors;

  const allItemIds = cors.map((cor) => cor.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  const selectedCors = cors.filter((cor) => selectedItemIds.has(cor.id));

  const selectedApprovedAmount = sumBy(selectedCors, "budget_approved_amount");

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching CORs..." />;
  }

  return (
    <>
      <Helmet title={project.name} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={contract.url}>
          {contract.display}
        </MuiNavLink>
        <Typography color="textPrimary">Create OCO</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedItemIds(allItemIds);
                } else {
                  removeAllSelectedItemIds();
                }
              }}
              indeterminate={Boolean(
                !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
              )}
              checked={allItemIdsSelected}
            />
            Link to CORs
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              Selected Total:
              <Box mx={1}>
                <strong>
                  <Currency number={selectedApprovedAmount} />
                </strong>
              </Box>
              <PaperPanel.Header.CreateButton
                disabled={selectedItemIds.size < 1}
                onClick={() => setCreateOCODialogIsOpen(true)}
              >
                Create OCO
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body>
          {cors.length > 0 ? (
            cors.map((cor) => (
              <CORSelectPaperItem
                cor={cor}
                corIsSelected={selectedItemIds.has(cor.id)}
                onChangeCorSelected={(event, value) => {
                  if (value) {
                    addSelectedItemId(cor.id, event.nativeEvent.shiftKey);
                  } else {
                    removeSelectedItemId(cor.id);
                  }
                }}
                key={cor.id}
              />
            ))
          ) : (
            <Alert severity="warning">
              <AlertTitle>Linkable CORs could be found.</AlertTitle>Why? There are no approved{" "}
              <MuiNavLink href={`/v2/projects/${project.id}/cors/#`} underline="always">
                COR's
              </MuiNavLink>{" "}
              that have not already been assigned to an OCO. Make sure that you have approved any{" "}
              <MuiNavLink href={`/v2/projects/${project.id}/cors/#`} underline="always">
                COR's
              </MuiNavLink>{" "}
              that you wish to apply to this OCO.
            </Alert>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <NamePCCOForm
        isOpen={createOCODialogIsOpen}
        handleClose={() => setCreateOCODialogIsOpen(false)}
        onSubmit={({name}) => {
          blockUI.blockUI("Saving...");
          postCurrentPage
            .mutateAsync({name: name, ids: Array.from(selectedItemIds), primeContract: contract.id})
            .then((response) => {
              // window.location.href = response.url;
              navigate(response.url);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        counts={{selectedApprovedAmount: selectedApprovedAmount, selectedCORCount: selectedItemIds.size}}
        initialValues={{name: `${pageData.contract?.display} OCO #${pageData?.nextPCCONumber}`}}
      />
      {/* <Typography variant="h2">PageData</Typography> */}
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default PCCOCreate;

const CORSelectPaperItem = (props) => {
  const {cor, corIsSelected, onChangeCorSelected} = props;
  const itemCount = cor.pcoitem_count;
  return (
    <PaperItem>
      <PaperItem.Left minWidth={45}>
        <PaperItem.SelectedCheckbox checked={corIsSelected} onChange={onChangeCorSelected} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <StatusLabel status={cor.status_display} />{" "}
              <MuiNavLink href={`/v2/projects/${cor.pco.project_id}/pcos/${cor.pco.id}/#tab=cor`} underline="always">
                {cor.display}
              </MuiNavLink>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6} sm={3}>
                <Typography2 type="metadata">
                  <Link href={`${cor.url}`}>
                    <FontAwesomeIcon icon={faList} /> {pluralize(`${itemCount} Item`, itemCount)}
                  </Link>
                </Typography2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <BudgetPaperItemGrid item={cor} hideCost />
          </Grid>
        </Grid>
      </PaperItem.Body>
    </PaperItem>
  );
};
