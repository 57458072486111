import {faExternalLink, faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Grid, Link, Switch, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {Currency, Percentage} from "../../components/Accounting";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import bonusAccountDistributionRequestStatuses from "../../components/forms/choices/bonusAccountDistributionRequestStatuses.json";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {TableStatusLabel} from "../../components/Status";
import {MountedTabPanel} from "../../components/TabPanel";
import {TabCountChip} from "../../components/Tabs";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import usePermissions from "../../hooks/usePermissions";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {
  BonusAccountCloseoutRequestDialogForm,
  BonusAccountDialogForm,
  BonusAccountDistributionRequestDialogForm,
  BonusAccountTransactionDialogForm,
} from "./BonusForms";

const BonusAccountDetail = (props) => {
  const [selectedTab, setSelectedTab] = useQueryState("tab", "transactions");
  const [showCreateTransactionDialog, setShowCreateTransactionDialog] = React.useState(false);
  const [showUpdateTransactionDialog, setShowUpdateTransactionDialog] = React.useState(false);
  const [showUpdateAccountDialog, setShowUpdateAccountDialog] = React.useState(false);
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = React.useState(false);
  const [showDeleteTransactionDialog, setShowDeleteTransactionDialog] = React.useState(false);
  const [showCreateCloseoutRequestDialog, setShowCreateCloseoutRequestDialog] = React.useState(false);
  const [showCreateDistributionRequestDialog, setShowCreateDistributionRequestDialog] = React.useState(false);
  const [showUpdateDistributionRequestDialog, setShowUpdateDistributionRequestDialog] = React.useState(false);
  const [showDeleteDistributionRequestDialog, setShowDeleteDistributionRequestDialog] = React.useState(false);
  const [showNotesInline, setShowNotesInline] = React.useState(false);
  const [activeTransaction, setActiveTransaction] = React.useState({}) as any;
  const [activeDistributionRequest, setActiveDistributionRequest] = React.useState({}) as any;
  const {bonusAccountId} = useParams();
  const [transactionsPage, setTransactionsPage] = useQueryState("transactionPage", 1);
  const [transactionsPageSize, setTransactionsPageSize] = React.useState(100);
  const [closeoutRequestPage, setCloseoutRequestPage] = useQueryState("distributionRequestPage", 1);
  const [closeoutRequestPageSize, setCloseoutRequestPageSize] = React.useState(100);
  const [distributionRequestPage, setDistributionRequestPage] = useQueryState("distributionRequestPage", 1);
  const [distributionRequestPageSize, setDistributionRequestPageSize] = React.useState(100);

  const navigate = useNavigate();
  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const novoClasses = makeNovoClasses();

  const createTransactionFormRef: any = React.createRef();
  const createCloseoutRequestFormRef: any = React.createRef();
  const createDistributionRequestFormRef: any = React.createRef();
  const updateDistributionRequestFormRef: any = React.createRef();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Search"]);
  const isAdmin = permissions?.can_manage_bonuses;

  const [
    transactionFilterOptions,
    setTransactionFilterOption,
    clearTransactionFilterOption,
    clearAllTransactionFilterOptions,
  ] = useFilterOptions(["Search"]);
  const [
    distributionRequestFilterOptions,
    setDistributionRequestFilterOption,
    clearDistributionRequestFilterOption,
    clearAllDistributionRequestFilterOptions,
  ] = useFilterOptions(["Search", "Status"]);

  const transactionFilterParams = {
    q: transactionFilterOptions.Search.value,
  };
  const distributionRequestFilterParams = {
    q: distributionRequestFilterOptions.Search.value,
    status: distributionRequestFilterOptions.Status.value,
  };

  const {
    query: bonusAccountQuery,
    update: updateBonusAccount,
    delete: deleteBonusAccount,
  } = useSentinelDetailAPI(["bonuses", "accounts", parseInt(bonusAccountId)], {
    initialData: {},
  });
  const account = bonusAccountQuery.data;

  const {
    query: bonusAccountTransactionsQuery,
    create: createBonusAccountTransaction,
    update: updateBonusAccountTransaction,
    delete: deleteBonusAccountTransaction,
    rpc: bonusAccountTransactionRPC,
  } = useSentinelListAPI(
    `bonuses/accounts/${bonusAccountId}/transactions/?page_size=${transactionsPageSize}&page=${transactionsPage}&${qs.stringify(
      transactionFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );

  const {
    query: closeoutRequestQuery,
    create: createCloseoutRequest,
    update: updateCloseoutRequest,
    delete: deleteCloseoutRequest,
    rpc: closeoutRequestRPC,
  } = useSentinelListAPI(
    `bonuses/accounts/${bonusAccountId}/closeout-requests/?page_size=${closeoutRequestPageSize}&page=${closeoutRequestPage}&${qs.stringify(
      distributionRequestFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );

  const {
    query: distributionRequestQuery,
    create: createDistributionRequest,
    update: updateDistributionRequest,
    delete: deleteDistributionRequest,
    rpc: distributionRequestRPC,
  } = useSentinelListAPI(
    `bonuses/accounts/${bonusAccountId}/distribution-requests/?page_size=${distributionRequestPageSize}&page=${distributionRequestPage}&${qs.stringify(
      distributionRequestFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );

  const {query: projectsForCloseoutQuery} = useSentinelListAPI(
    `bonuses/accounts/${bonusAccountId}/closeout-requests/projects-for-closeout/`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const projectsForCloseout = projectsForCloseoutQuery.data.results;

  if (!bonusAccountQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Account info..." />;
  }
  return (
    <>
      <Helmet title={`Bonus Account ${account.description}`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to="/v2/bonuses/accounts/">
          Bonus Accounts
        </MuiNavLink>
        <Typography color="textPrimary">{account.description}</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faSackDollar} /> {account.description}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="Balance" number={account.amount} precision={2} />
        </PageHeader.Right>
      </PageHeader>

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Transactions
                    <TabCountChip
                      isLoading={!bonusAccountTransactionsQuery.isFetchedAfterMount}
                      count={bonusAccountTransactionsQuery.data.count}
                    />
                  </Box>
                }
                value="transactions"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Closeout Requests
                    <TabCountChip
                      isLoading={!closeoutRequestQuery.isFetchedAfterMount}
                      count={closeoutRequestQuery.data.count}
                    />
                  </Box>
                }
                value="closeoutRequests"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Distribution Requests
                    <TabCountChip
                      isLoading={!distributionRequestQuery.isFetchedAfterMount}
                      count={distributionRequestQuery.data.count}
                    />
                  </Box>
                }
                value="distributionRequests"
                className={novoClasses.smallTab}
              />
            </Tabs>
          </AppBar>

          <Box mb={2} />
          <MountedTabPanel value="transactions">
            <PaperPanel.TabHeader isLoading={bonusAccountTransactionsQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faSackDollar} /> {account.description}
              </PaperPanel.Header.Title>

              <PaperPanel.Header.Actions>
                {isAdmin && (
                  <>
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.CreateButton
                        onClick={() => {
                          setShowCreateTransactionDialog(true);
                        }}
                      >
                        Transaction
                      </PaperPanel.Header.CreateButton>
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.EditButton onClick={() => setShowUpdateAccountDialog(true)}>
                        Edit
                      </PaperPanel.Header.EditButton>
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action>
                      <Switch checked={showNotesInline} onChange={() => setShowNotesInline(!showNotesInline)} />
                      Show Notes Inline
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.RefreshButton
                        isFetching={bonusAccountTransactionsQuery.isFetching}
                        onClick={() => bonusAccountTransactionsQuery.refetch()}
                      />
                    </PaperPanel.Header.Action>
                  </>
                )}
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.TabToolbar p={1}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <FilterSearch
                    label="Search"
                    value={transactionFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setTransactionsPage(1);
                      setTransactionFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={1} />
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <FilterOptionChips
                    filterOptions={transactionFilterOptions}
                    onDelete={(key) => {
                      clearTransactionFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllTransactionFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={bonusAccountTransactionsQuery.data.total_pages}
                      page={transactionsPage}
                      pageSize={transactionsPageSize}
                      setPage={setTransactionsPage}
                      setPageSize={setTransactionsPageSize}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.TabToolbar>

            <PaperPanel.Body mx={-3}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["credit", "debit"]}
                columns={[
                  {
                    Header: "Description",
                    accessor: "description",
                    Cell: ({value, row}) => {
                      if (isAdmin) {
                        return (
                          <Link
                            style={{cursor: "pointer"}}
                            onClick={() => {
                              setActiveTransaction(row.original);
                              setShowUpdateTransactionDialog(true);
                            }}
                            underline="always"
                          >
                            {value}
                          </Link>
                        );
                      }
                      return value;
                    },
                  },
                  {
                    Header: "Notes",
                    accessor: "notes",
                  },
                  {
                    Header: "Created",
                    accessor: "created",
                    Cell: ({value}) => (value ? moment(value).format("YYYY-MM-DD hh:mm A") : ""),
                  },
                  {
                    Header: "Closeout Request",
                    accessor: "closeout_request.description",
                    Cell: ({value, row}) => (
                      <MuiNavLink
                        to={`/v2/bonuses/accounts/${bonusAccountId}/closeout-requests/${row.original.closeout_request?.id}/`}
                        underline="always"
                      >
                        {value}
                      </MuiNavLink>
                    ),
                  },
                  {
                    Header: "Distribution Request",
                    accessor: "distribution_request.description",
                    Cell: ({value, row}) => (
                      <MuiNavLink
                        to={`/v2/bonuses/accounts/${bonusAccountId}/distribution-requests/${row.original.distribution_request?.id}/`}
                        underline="always"
                      >
                        {value}
                      </MuiNavLink>
                    ),
                  },
                  {
                    Header: "Credit",
                    id: "credit",
                    accessor: "amount",
                    Cell: ({value, row}) => {
                      if (value >= 0) {
                        return <Currency number={value} />;
                      }
                    },
                  },
                  {
                    Header: "Debit",
                    id: "debit",
                    accessor: "amount",
                    Cell: ({value, row}) => {
                      if (value < 0) {
                        return <Currency number={value} />;
                      }
                    },
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "created",
                      desc: true,
                    },
                  ],
                  hiddenColumns: showNotesInline ? [] : ["notes"],
                }}
                data={bonusAccountTransactionsQuery.data.results}
              />
              {bonusAccountTransactionsQuery.data.total_pages > 1 && (
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={bonusAccountTransactionsQuery.data.total_pages}
                    page={transactionsPage}
                    pageSize={transactionsPageSize}
                    setPage={setTransactionsPage}
                    setPageSize={setTransactionsPageSize}
                    pageSizes={[100, 250, 500, 1000]}
                  />
                </PaperPanel.Footer>
              )}
            </PaperPanel.Body>
          </MountedTabPanel>

          <MountedTabPanel value="closeoutRequests">
            <PaperPanel.TabHeader isLoading={bonusAccountTransactionsQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faSackDollar} /> {account.description}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton
                    onClick={() => {
                      setShowCreateCloseoutRequestDialog(true);
                    }}
                  >
                    Create Closeout Request
                  </PaperPanel.Header.CreateButton>
                </PaperPanel.Header.Action>
                {/* <PaperPanel.Header.Action>
                  <Switch checked={showNotesInline} onChange={() => setShowNotesInline(!showNotesInline)} />
                  Show Notes Inline
                </PaperPanel.Header.Action> */}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={closeoutRequestQuery.isFetching}
                    onClick={() => closeoutRequestQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.TabToolbar p={1}>
              {/* <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <FilterSearch
                    label="Search"
                    value={distributionRequestFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setDistributionRequestPage(1);
                      setDistributionRequestFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FilterSelect
                    // native
                    label="Status"
                    name="Status"
                    options={bonusAccountDistributionRequestStatuses}
                    value={distributionRequestFilterOptions.Status.value}
                    showStatusIcon
                    onChange={(value, label) => {
                      setDistributionRequestPage(1);
                      setFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
              </Grid> */}
              <Box mt={1} />
              <Grid container spacing={1}>
                {/* <Grid item sm={6} xs={12}>
                  <FilterOptionChips
                    filterOptions={distributionRequestFilterOptions}
                    onDelete={(key) => {
                      clearDistributionRequestFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllDistributionRequestFilterOptions();
                    }}
                  />
                </Grid> */}
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={closeoutRequestQuery.data.total_pages}
                      page={closeoutRequestPage}
                      pageSize={closeoutRequestPageSize}
                      setPage={setCloseoutRequestPage}
                      setPageSize={setCloseoutRequestPageSize}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.TabToolbar>
            <PaperPanel.Body mx={-3}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["bonus_amount"]}
                columns={[
                  // {
                  //   Header: "Status",
                  //   accessor: "status_display",
                  //   Cell: ({value, row}) => <StatusLabel status={value} hint="bonusAccountCloseoutRequest" />,
                  // },
                  {
                    Header: "Description",
                    accessor: "description",
                    Cell: ({value, row}) => {
                      return (
                        <>
                          <TableStatusLabel status={row.original.status_display} hint="bonusAccountCloseoutRequest">
                            <MuiNavLink to={row.original.url} underline="always">
                              {value}
                            </MuiNavLink>
                          </TableStatusLabel>
                        </>
                      );
                    },
                  },
                  {
                    Header: "Created",
                    accessor: "created",
                    Cell: ({value}) => {
                      return moment.tz(value, moment.tz.guess()).format("YYYY-MM-DD hh:mm A z");
                    },
                  },
                  {
                    Header: "User",
                    accessor: "account.user.full_name",
                  },
                  {
                    Header: "Bonus Amount",
                    accessor: "bonus_amount",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "created",
                      desc: true,
                    },
                  ],
                }}
                data={closeoutRequestQuery.data.results}
              />
            </PaperPanel.Body>
            <PaperPanel.Body mx={-3} mt={2}>
              <PaperPanel.Header isLoading={projectsForCloseoutQuery.isFetching}>
                <PaperPanel.Header.Title>Projects Eligible For Closeout</PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.HelpButton title="Projects Eligible For Closeout Help">
                      <>
                        <strong>I don't see my project?</strong>
                        <p>Projects for closeout are determined by the following criteria:</p>
                        <ol>
                          <li>Is the project closed?</li>
                          <li>
                            Is an account owned by you have a bonus percent for the project (Project &rarr; Closeouts
                            &rarr; Bonus Closeout Request)?
                          </li>
                          <li>Does this account already have a closeout for the project?</li>
                        </ol>
                      </>
                    </PaperPanel.Header.HelpButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={projectsForCloseoutQuery.isFetching}
                      onClick={() => projectsForCloseoutQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>

              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={[
                  "jtd_work_billed",
                  "jtd_cost",
                  "gross_profit",
                  "gross_profit_percent",
                  "sf_gross_receipts_tax",
                  "overhead_and_insurance",
                  "net_profit",
                  "bonus_percent",
                  "bonus_estimate",
                ]}
                getHeaderProps={(column) => {
                  if (["add"].includes(column.id)) {
                    return {
                      style: {
                        width: "20px",
                      },
                    };
                  }
                }}
                getCellProps={(cell) => {
                  if (["timberline_job"].includes(cell.column.id)) {
                    return {
                      style: {
                        whiteSpace: "nowrap",
                      },
                    };
                  }
                }}
                columns={[
                  {
                    Header: "Job #",
                    accessor: "timberline_job",

                    Cell: ({value, row}) => {
                      return (
                        <Link
                          href={`/v2/projects/${row.original.project_id}/bonuses/closeout-requests/`}
                          underline="always"
                        >
                          {value}
                        </Link>
                      );
                    },
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({value, row}) => {
                      return (
                        <Link
                          href={`/v2/projects/${row.original.project_id}/bonuses/closeout-requests/`}
                          underline="always"
                        >
                          {value}
                        </Link>
                      );
                    },
                  },
                  {
                    Header: "Final Billing",
                    accessor: "jtd_work_billed",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "JTD Cost",
                    accessor: "jtd_cost",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Gross Profit",
                    accessor: "gross_profit",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Profit %",
                    accessor: "gross_profit_percent",
                    Cell: ({value, row}) => <Percentage number={value} precision={2} />,
                  },
                  {
                    Header: "SF Tax",
                    accessor: "sf_gross_receipts_tax",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Overhead & Insurance",
                    accessor: "overhead_and_insurance",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Profit",
                    accessor: "net_profit",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Bonus %",
                    accessor: "bonus_percent",
                    Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                  },
                  {
                    Header: "Bonus Estimate",
                    id: "bonus_estimate",
                    // accessor: "net_profit_percent",
                    Cell: ({value, row}) => {
                      const bonusAmount = ((row.original.bonus_percent || 0) / 100) * row.original.net_profit;
                      return <Currency number={bonusAmount} />;
                    },
                  },
                ]}
                initialState={
                  {
                    // sortBy: [
                    //   {
                    //     id: "timberline_job",
                    //     desc: false,
                    //   },
                    // ],
                  }
                }
                data={projectsForCloseout}
              />
            </PaperPanel.Body>

            {/* <pre>{JSON.stringify(bonusAccountDistributionRequestQuery, null, 2)}</pre> */}
          </MountedTabPanel>

          <MountedTabPanel value="distributionRequests">
            <PaperPanel.TabHeader isLoading={distributionRequestQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faSackDollar} /> {account.description}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton
                    onClick={() => {
                      setShowCreateDistributionRequestDialog(true);
                    }}
                  >
                    Create Distribution Request
                  </PaperPanel.Header.CreateButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action>
                  <Switch checked={showNotesInline} onChange={() => setShowNotesInline(!showNotesInline)} />
                  Show Notes Inline
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={distributionRequestQuery.isFetching}
                    onClick={() => distributionRequestQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.TabToolbar p={1}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <FilterSearch
                    label="Search"
                    value={distributionRequestFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setDistributionRequestPage(1);
                      setDistributionRequestFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FilterSelect
                    // native
                    label="Status"
                    name="Status"
                    options={bonusAccountDistributionRequestStatuses}
                    value={distributionRequestFilterOptions.Status.value}
                    showStatusIcon
                    onChange={(value, label) => {
                      setDistributionRequestPage(1);
                      setFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={1} />
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <FilterOptionChips
                    filterOptions={distributionRequestFilterOptions}
                    onDelete={(key) => {
                      clearDistributionRequestFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllDistributionRequestFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={distributionRequestQuery.data.total_pages}
                      page={distributionRequestPage}
                      pageSize={distributionRequestPageSize}
                      setPage={setDistributionRequestPage}
                      setPageSize={setDistributionRequestPageSize}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.TabToolbar>
            <PaperPanel.Body mx={-3}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["amount"]}
                columns={[
                  // {
                  //   Header: "Status",
                  //   accessor: "status_display",
                  //   Cell: ({value, row}) => <StatusLabel status={value} hint="bonusAccountDistributionRequest" />,
                  // },
                  {
                    Header: "Description",
                    accessor: "description",
                    Cell: ({value, row}) => {
                      return (
                        <>
                          <TableStatusLabel status={row.original.status_display} hint="bonusAccountDistributionRequest">
                            <Link
                              style={{cursor: "pointer"}}
                              onClick={() => {
                                setActiveDistributionRequest(row.original);
                                setShowUpdateDistributionRequestDialog(true);
                              }}
                              underline="always"
                            >
                              {value}
                            </Link>{" "}
                            <Link href={row.original.url} target="_blank">
                              <FontAwesomeIcon icon={faExternalLink} />
                            </Link>
                          </TableStatusLabel>
                        </>
                      );
                    },
                  },
                  {
                    Header: "Notes",
                    accessor: "notes",
                  },
                  {
                    Header: "Created",
                    accessor: "created",
                    Cell: ({value}) => (value ? moment(value).format("YYYY-MM-DD hh:mm A") : ""),
                  },
                  {
                    Header: "Amount",
                    accessor: "amount",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "created",
                      desc: true,
                    },
                  ],
                  hiddenColumns: showNotesInline ? [] : ["notes"],
                }}
                data={distributionRequestQuery.data.results}
              />
            </PaperPanel.Body>
            {/* <pre>{JSON.stringify(bonusAccountDistributionRequestQuery, null, 2)}</pre> */}
          </MountedTabPanel>
        </PaperPanel>
      </TabContext>

      <BonusAccountDialogForm
        isOpen={showUpdateAccountDialog}
        handleClose={() => {
          setShowUpdateAccountDialog(false);
        }}
        initialValues={{...account, is_active: account.status === "active"}}
        onSubmit={(values) => {
          values.status = values.is_active ? "active" : "inactive";
          setShowUpdateAccountDialog(false);
          updateBonusAccount.mutateAsync(values);
        }}
        onDelete={() => {
          setShowDeleteAccountDialog(true);
        }}
      />

      <BonusAccountTransactionDialogForm
        ref={createTransactionFormRef}
        isNew
        isOpen={showCreateTransactionDialog}
        handleClose={() => {
          setShowCreateTransactionDialog(false);
        }}
        initialValues={{}}
        onSubmit={(values, form) => {
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndClose") {
            setShowCreateTransactionDialog(false);
            createBonusAccountTransaction.mutateAsync(values).then(() => {
              bonusAccountQuery.refetch();
              bonusAccountTransactionsQuery.refetch();
            });
          }
          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");
            const focusElement = createTransactionFormRef.current.elements.description;
            createBonusAccountTransaction.mutateAsync(values).then(() => {
              form.restart();
              focusElement.focus();
              bonusAccountQuery.refetch();
              bonusAccountTransactionsQuery.refetch().then(() => blockUI.unblockUI());
            });
          }
        }}
      />

      <BonusAccountTransactionDialogForm
        isOpen={showUpdateTransactionDialog}
        isReadOnly={activeTransaction.is_closed}
        handleClose={() => {
          setShowUpdateTransactionDialog(false);
          // HACK. setActiveBonusAccount to {} (instead of null) prevents tinyMCE from throwing: Cannot call setIn() with null state; on cancel update
          setActiveTransaction({});
        }}
        initialValues={activeTransaction}
        onSubmit={(values) => {
          setShowUpdateTransactionDialog(false);
          updateBonusAccountTransaction.mutateAsync(values).then(() => bonusAccountQuery.refetch());
        }}
        onDelete={() => {
          setShowDeleteTransactionDialog(true);
        }}
      />

      <BonusAccountCloseoutRequestDialogForm
        ref={createCloseoutRequestFormRef}
        isOpen={showCreateCloseoutRequestDialog}
        isNew
        handleClose={() => {
          setShowCreateCloseoutRequestDialog(false);
        }}
        initialValues={{
          description: moment().format("YYYY-MM-DD"),
        }}
        // onDelete={(values, form) => {
        //   // setShowDeleteCloseoutRequestDialog(true);
        // }}
        onSubmit={(values, form) => {
          blockUI.blockUI("Creating...");
          setShowCreateCloseoutRequestDialog(false);
          createCloseoutRequest.mutateAsync(values).then((newCloseoutRequest) => {
            navigate(`/v2/bonuses/accounts/${bonusAccountId}/closeout-requests/${newCloseoutRequest.id}/`);
            blockUI.unblockUI();

            closeoutRequestQuery.refetch();
          });
        }}
      />

      <BonusAccountDistributionRequestDialogForm
        ref={createDistributionRequestFormRef}
        isNew
        isOpen={showCreateDistributionRequestDialog}
        handleClose={() => {
          setShowCreateDistributionRequestDialog(false);
        }}
        initialValues={{}}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "submit") {
            blockUI.blockUI("Submitting...");
            createDistributionRequest.mutateAsync(values).then((newDistributionRequest) => {
              distributionRequestRPC.mutateAsync({action: "submit", id: newDistributionRequest.id}).then(() => {
                Promise.all([bonusAccountQuery.refetch(), distributionRequestQuery.refetch()]).then(() => {
                  blockUI.unblockUI();
                  setShowCreateDistributionRequestDialog(false);
                });
              });
            });
            return;
          }

          setShowCreateDistributionRequestDialog(false);
          createDistributionRequest.mutateAsync(values).then(() => {
            bonusAccountQuery.refetch();
            distributionRequestQuery.refetch();
          });
        }}
      />

      <BonusAccountDistributionRequestDialogForm
        ref={updateDistributionRequestFormRef}
        isOpen={showUpdateDistributionRequestDialog}
        isReadOnly={activeDistributionRequest.is_closed}
        handleClose={() => {
          setShowUpdateDistributionRequestDialog(false);
        }}
        initialValues={activeDistributionRequest}
        onDelete={(values, form) => {
          setShowDeleteDistributionRequestDialog(true);
        }}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (["submit", "approve", "reject"].includes(submitMode)) {
            blockUI.blockUI("Updating...");
            updateDistributionRequest.mutateAsync(values).then(() => {
              distributionRequestRPC.mutateAsync({action: submitMode, id: activeDistributionRequest.id}).then(() => {
                Promise.all([
                  bonusAccountQuery.refetch(),
                  bonusAccountTransactionsQuery.refetch(),
                  distributionRequestQuery.refetch(),
                ]).then(() => {
                  blockUI.unblockUI();
                  setShowUpdateDistributionRequestDialog(false);
                });
              });
            });
            return;
          }

          setShowUpdateDistributionRequestDialog(false);
          updateDistributionRequest.mutateAsync(values).then(() => {
            bonusAccountQuery.refetch();
            distributionRequestQuery.refetch();
          });
        }}
      />

      <ConfirmationDialog
        isOpen={showDeleteAccountDialog}
        onApprove={() => {
          setShowDeleteAccountDialog(false);
          blockUI.blockUI("Deleting...");
          setShowUpdateAccountDialog(false);
          deleteBonusAccount.mutateAsync(account.id).then(() => {
            navigate(`/v2/bonuses/accounts/`);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setShowDeleteAccountDialog(false);
        }}
      >
        You want to delete this entire account and all it's transaction. This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showDeleteTransactionDialog}
        onApprove={() => {
          setShowDeleteTransactionDialog(false);
          //   blockUI.blockUI();
          setShowUpdateTransactionDialog(false);
          deleteBonusAccountTransaction.mutateAsync(activeTransaction.id).then(() => {
            setActiveTransaction({});
            bonusAccountQuery.refetch();
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setShowDeleteTransactionDialog(false);
        }}
      >
        You want to delete this transaction. This cannot be undone
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showDeleteDistributionRequestDialog}
        onApprove={() => {
          setShowDeleteDistributionRequestDialog(false);
          //   blockUI.blockUI();
          setShowUpdateDistributionRequestDialog(false);
          deleteDistributionRequest.mutateAsync(activeDistributionRequest.id).then(() => {
            setActiveDistributionRequest({});
            bonusAccountQuery.refetch();
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setShowDeleteDistributionRequestDialog(false);
        }}
      >
        You want to delete this distribution. This cannot be undone
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(bonusAccountTransactionsQuery  , null, 2)}</pre> */}
    </>
  );
};

export default BonusAccountDetail;
