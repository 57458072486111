import PropTypes from "prop-types";
import React from "react";

import {useBlocker} from "react-router";
// import {Prompt} from "react-router";

const MESSAGE = "You have unsaved changes, are you sure you want to leave?";

export default class BeforeUnload extends React.Component {
  static propTypes = {
    block: PropTypes.bool.isRequired,
    message: PropTypes.string,
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeunload);
  }

  onBeforeunload = (event) => {
    if (!this.props.block) {
      return;
    }
    const message = this.props.message || MESSAGE;
    event.returnValue = message;
    return message;
  };

  render() {
    // This is for react router
    return <Prompt when={this.props.block} message={this.props.message || MESSAGE} />;
  }
}

const Prompt = (props) => {
  const block = props.when;

  useBlocker(() => {
    if (block) {
      return !window.confirm(props.message);
    }
    return false;
  });

  return <div key={block} />;
};
