import {faAddressCard, faCheck, faPhone, faUser} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {textToHTML} from "../../../js/common/utils";
import HtmlRender from "../../../js/components/HtmlRender";
import {Address} from "../../components/Address";
import CreatedByModifiedBy from "../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterSearch} from "../../components/Filters";
import BlockUI from "../../components/GlobalLoaders";
import {PhoneNumberTypeIcon, ProjectStatusIcon} from "../../components/Icons";
import {LabelValue} from "../../components/LabelValue";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {PaginationWithPageSize} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorSuccess} from "../../theme/colors";
import {truncateString} from "../../utils/string";
import {ContactDialogForm, PhoneNumberDialogForm} from "./ContactForms";

const ContactDetail = (props) => {
  const [projectsPage, setProjectsPage] = React.useState(1);
  const [projectsPageSize, setProjectsPageSize] = React.useState(250);
  const [projectsSearch, setProjectsSearch] = React.useState("");
  const [showPhoneNumberCreateDialog, setShowPhoneNumberCreateDialog] = React.useState(false);
  const [showPhoneNumberUpdateDialog, setShowPhoneNumberUpdateDialog] = React.useState(false);
  const [showContactUpdateDialog, setShowContactUpdateDialog] = React.useState(false);
  const [deletePhoneNumberConfirmationIsOpen, setDeletePhoneNumberConfirmationIsOpen] = React.useState(false);
  const [activePhoneNumber, setActivePhoneNumber] = React.useState({} as any);
  const {contactId} = useParams();
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const projectColumns = React.useMemo(
    () => [
      {
        Header: "Project",
        accessor: "project",
        disableSortBy: true,
        Cell: ({value, row}) => {
          return (
            <>
              <ProjectStatusIcon project={value} showTooltip />{" "}
              <Link href={`${value.url}`} underline="always">
                {truncateString(value.display, 80)}
              </Link>
            </>
          );
        },
      },
      {
        Header: "Role",
        accessor: "contact_role_display",
        disableSortBy: true,
      },
    ],
    []
  );

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const {query: contactQuery, update: updateContact} = useSentinelDetailAPI(`contacts/${contactId}/`, {
    initialData: {},
    keepPreviousData: true,
  });
  const contact = contactQuery.data;

  const {
    query: phoneNumbersQuery,
    create: createPhoneNumber,
    update: updatePhoneNumber,
    delete: deletePhoneNumber,
  } = useSentinelListAPI(`contacts/${contactId}/phone-numbers/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });
  const phoneNumbers = phoneNumbersQuery.data?.results;

  const {query: projectsQuery} = useSentinelListAPI(
    `contacts/${contactId}/projects/?page_size=${projectsPageSize}&page=${projectsPage}&q=${projectsSearch}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const projects = projectsQuery.data?.results;

  if (!contactQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching contact info..." />;
  }

  return (
    <>
      <Helmet title={contact.full_name} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">{contact.full_name}</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faUser} /> {contact.full_name}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark
            title="Active Projects"
            symbol=""
            number={pageDataQuery.data.activeProjectCount}
            color={green[800]}
          />
          <PageHeader.Right.CurrencySpark
            title="Total Projects"
            symbol=""
            number={pageDataQuery.data.projectCount}
            border
          />
        </PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <PaperPanel>
            <PaperPanel.Header isLoading={contactQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faUser} /> Details
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <Tooltip title="Download vCard">
                    <span>
                      <PaperPanel.Header.Button
                        href={`/contacts/${contactId}/vcard/`}
                        startIcon={<FontAwesomeIcon icon={faAddressCard} />}
                      >
                        vCard
                      </PaperPanel.Header.Button>
                    </span>
                  </Tooltip>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.EditButton onClick={() => setShowContactUpdateDialog(true)}>
                    Edit
                  </PaperPanel.Header.EditButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={contactQuery.isFetching}
                    onClick={() => contactQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>

            <PaperPanel.Body p={1}>
              <LabelValue label="Company">
                <Link href={contact.company?.url}>{contact.company?.name}</Link>
              </LabelValue>
              <LabelValue label="Email">
                <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
              </LabelValue>
              {contact.title && <LabelValue label="Title">{contact.title}</LabelValue>}

              {contact.has_address && (
                <LabelValue label="Address">
                  <Link href={contact.google_maps_url} target="_blank">
                    <Address addressObject={contact} />
                  </Link>
                </LabelValue>
              )}
              {contact.notes && (
                <LabelValue label="Notes">{<HtmlRender html={textToHTML(contact.notes)} />}</LabelValue>
              )}
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaperPanel>
            <PaperPanel.Header isLoading={phoneNumbersQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faPhone} /> Phone Numbers
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton onClick={() => setShowPhoneNumberCreateDialog(true)}>
                    Create
                  </PaperPanel.Header.CreateButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={phoneNumbersQuery.isFetching}
                    onClick={() => phoneNumbersQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body maxHeight={250} overflow="auto">
              {phoneNumbers.map((phoneNumber) => {
                return (
                  <PaperItem key={phoneNumber.id} p={1}>
                    <PaperItem.Body>
                      <Box flexGrow={1}>
                        {phoneNumber.is_primary && (
                          <div style={{float: "left"}}>
                            <Tooltip title="Primary Phone Number">
                              <span>
                                <FontAwesomeIcon icon={faCheck} color={colorSuccess} />
                                <Box display="inline" mr={1} />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        <Box>
                          <Tooltip title={phoneNumber.type_display}>
                            <span>
                              <PhoneNumberTypeIcon type={phoneNumber.type_display} showTooltip={false} />{" "}
                              <Link href={`tel:${phoneNumber.phone}`}>{phoneNumber.phone}</Link>
                              {phoneNumber.extension && <> ext. {phoneNumber.extension}</>}
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    </PaperItem.Body>
                    <PaperItem.RightHover>
                      <PaperItem.RightHover.IconButton
                        icon={EditIcon}
                        title="Quick Edit"
                        onClick={() => {
                          setActivePhoneNumber(phoneNumber);
                          setShowPhoneNumberUpdateDialog(true);
                        }}
                      />
                    </PaperItem.RightHover>
                  </PaperItem>
                );
              })}
              {/* <pre>{JSON.stringify(phoneNumbers, null, 2)}</pre> */}
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
      </Grid>

      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={projectsQuery.isFetching}>
          <PaperPanel.Header.Title>Projects</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={projectsQuery.isFetching}
                onClick={() => projectsQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <FilterSearch
            label="Search"
            value={projectsSearch}
            name="Search"
            onChange={(value) => {
              setProjectsPage(1);
              setProjectsSearch(value);
            }}
          />
        </PaperPanel.Toolbar>
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
          // rightAlignColumns={["timestamp"]}
          columns={projectColumns}
          data={projects}
        />
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          <PaginationWithPageSize
            count={projectsQuery.data.total_pages}
            page={projectsPage}
            pageSize={projectsPageSize}
            setPage={setProjectsPage}
            setPageSize={setProjectsPageSize}
            // pageSizes={[10, 100, 250, 500, 1000]}
          />
        </PaperPanel.Footer>
      </PaperPanel>

      <CreatedByModifiedBy obj={contact} mt={2} />
      {/* <pre>{JSON.stringify(contact, null, 2)}</pre> */}

      <ContactDialogForm
        isOpen={showContactUpdateDialog}
        handleClose={() => {
          setShowContactUpdateDialog(false);
        }}
        initialValues={contact}
        onSubmit={(values) => {
          setShowContactUpdateDialog(false);

          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
            blockUI.blockUI();
          }
          updateContact.mutateAsync(values).then((newContact) => {
            if (editAfterSubmit) {
              // navigate(`/contacts/${newContact.id}/`);
              window.location.href = `/contacts/${newContact.id}/`;
              blockUI.unblockUI();
            } else {
              contactQuery.refetch();
            }
          });
        }}
      />
      <PhoneNumberDialogForm
        isOpen={showPhoneNumberCreateDialog}
        isNew
        handleClose={() => {
          setShowPhoneNumberCreateDialog(false);
        }}
        initialValues={{}}
        onSubmit={(values) => {
          setShowPhoneNumberCreateDialog(false);
          createPhoneNumber.mutateAsync(values).then((newContact) => {
            phoneNumbersQuery.refetch();
          });
        }}
      />
      <PhoneNumberDialogForm
        isOpen={showPhoneNumberUpdateDialog}
        onDelete={() => {
          setShowPhoneNumberUpdateDialog(false);
          setDeletePhoneNumberConfirmationIsOpen(true);
        }}
        handleClose={() => {
          setShowPhoneNumberUpdateDialog(false);
        }}
        initialValues={activePhoneNumber}
        onSubmit={(values) => {
          setShowPhoneNumberUpdateDialog(false);
          updatePhoneNumber.mutateAsync(values).then((newContact) => {
            phoneNumbersQuery.refetch();
          });
        }}
      />

      <ConfirmationDialog
        isOpen={deletePhoneNumberConfirmationIsOpen}
        onApprove={() => {
          setDeletePhoneNumberConfirmationIsOpen(false);
          deletePhoneNumber.mutateAsync(activePhoneNumber.id).then(() => {
            phoneNumbersQuery.refetch();
          });
        }}
        onDeny={() => setDeletePhoneNumberConfirmationIsOpen(false)}
      >
        You want to delete phone number {activePhoneNumber?.phone}.
      </ConfirmationDialog>
    </>
  );
};

export default ContactDetail;
