import {faTasks} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TableStatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectManagerMeetingList = (props) => {
  const [pageSize, setPageSize] = React.useState(20);
  const [page, setPage] = React.useState(1);
  const [createConfirmationIsOpen, setCreateConfirmationIsOpen] = React.useState(false);
  const {employeeId} = useParams();
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      employee: {},
      team: [],
      teamMeetings: [],
    },
  });
  const pageData = pageDataQuery.data;

  const {
    query: projectManagerMeetingsQuery,
    create: createProjectManagerMeeting,
    update: updateProjectManagerMeeting,
    delete: deleteProjectManagerMeeting,
    rpc: projectManagerMeetingRPC,
  } = useSentinelListAPI(`employees/${employeeId}/pm-meetings/?page_size=${pageSize}&page=${page}`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
    // enabled: Boolean(employeeId),
  });
  const projectManagerMeetings = projectManagerMeetingsQuery.data.results;

  if (!pageDataQuery.isFetchedAfterMount || !projectManagerMeetingsQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching meetings..." />;
  }

  return (
    <>
      <Helmet title="Project Manager Meetings" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Project Manager Meetings</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faTasks} /> Project Manager Meetings - {pageData.employee?.full_name}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={projectManagerMeetingsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faTasks} /> {pageData.employee?.full_name} Meetings
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setCreateConfirmationIsOpen(true)}>
                Create Meeting
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={projectManagerMeetingsQuery.isFetching}
                onClick={() => {
                  projectManagerMeetingsQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                id: "meeting",
                Header: "Meeting",
                accessor: "created",
                Cell: ({value, row}) => (
                  <>
                    <TableStatusLabel status={row.original.status_display || "Unknown"}>
                      <MuiNavLink underline="always" to={`/v2/employees/${employeeId}/pm-meetings/${row.original.id}/`}>
                        {moment(value).format("YYYY-MM-DD")}
                      </MuiNavLink>
                    </TableStatusLabel>
                  </>
                ),
              },
            ]}
            usePagination
            initialState={{
              sortBy: [
                {
                  id: "meeting",
                  desc: true,
                },
              ],
            }}
            data={projectManagerMeetings}
          />
        </PaperPanel.Body>
        {projectManagerMeetingsQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={projectManagerMeetingsQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[20, 50, 100]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      {pageData.teamMeetings.length > 0 && (
        <>
          <Box mb={2} />
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faTasks} /> My Team Meetings
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => {
                      pageDataQuery.refetch();
                    }}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>

            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                columns={[
                  {
                    id: "meeting",
                    Header: "Meeting",
                    accessor: "created",
                    Cell: ({value, row}) => (
                      <>
                        <TableStatusLabel status={row.original.status_display || "Unknown"}>
                          <Link
                            underline="always"
                            href={`/v2/employees/${row.original.employee?.id}/pm-meetings/${row.original.id}/`}
                          >
                            {moment(value).format("YYYY-MM-DD")}
                          </Link>
                        </TableStatusLabel>
                      </>
                    ),
                  },
                  {
                    id: "projectManager",
                    Header: "Project Manager",
                    accessor: (value) => value.employee?.full_name,
                    Cell: ({value, row}) => (
                      <>
                        <MuiNavLink underline="always" to={`/v2/employees/${row.original.employee?.id}/pm-meetings/`}>
                          {value}
                        </MuiNavLink>
                      </>
                    ),
                  },
                ]}
                // usePagination
                initialState={{
                  sortBy: [
                    {
                      id: "meeting",
                      desc: true,
                    },
                  ],
                }}
                data={pageData.teamMeetings}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </>
      )}
      <LegacyUILink href={`/employees/${employeeId}/pm-meetings/`} mt={2} />

      <ConfirmationDialog
        isOpen={createConfirmationIsOpen}
        onApprove={() => {
          setCreateConfirmationIsOpen(false);
          blockUI.blockUI();
          createProjectManagerMeeting.mutateAsync({}).then((data) => {
            navigate(`/v2/employees/${employeeId}/pm-meetings/${data.id}/`);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setCreateConfirmationIsOpen(false);
        }}
      >
        This will create a PM meeting. Projects where {pageData.employee.full_name} is in the directory will
        automatically be added to the meeting.
      </ConfirmationDialog>
      {/* <pre>{JSON.stringify(projectManagerMeetings, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default ProjectManagerMeetingList;
