import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Collapse,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {getDrawerSidebar, getSidebarContent} from "@mui-treasury/layout";
import accounting from "accounting";
import moment from "moment";
import React from "react";
import {NavLink as RouterNavLink, generatePath, matchPath, useParams} from "react-router";
import styled from "styled-components";
import useBlockUI from "../hooks/useBlockUI";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    root: {
      "& .MuiListItem-gutters": {
        paddingLeft: "8px",
      },
      "& svg": {
        fontSize: "1.75em",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "3em",
        justifyContent: "center",
      },
      "& .MuiCollapse-container svg": {
        fontSize: "inherit",
      },
      "& .MuiCollapse-container .MuiListItem-root": {
        paddingLeft: "2em",
      },
      "& .MuiCollapse-container .MuiListItemIcon-root": {
        minWidth: "2em",
      },
    },
    sidebarContent: {
      backgroundColor: theme["sidebar"]?.background,
    },
    sidebarBadge: {
      // backgroundColor: sidebar.badge.background,
      // color: sidebar.badge.color,
    },
  };
});

export const SidebarCollapse = (props) => {
  return <Collapse timeout="auto" unmountOnExit {...props} />;
};

export const SidebarList = (props) => {
  return <List dense {...props} />;
};

export const SidebarListItem = (props) => {
  return <ListItem {...props} />;
};

export const SidebarListItemText = (props) => {
  return <ListItemText {...props} />;
};

export const SidebarListItemIcon = (props) => {
  const {icon} = props;
  return <SidebarListItemIconWrapper>{icon && <FontAwesomeMenuIcon icon={icon} />}</SidebarListItemIconWrapper>;
};

export const SidebarNavLink = React.forwardRef((props: {to: string; className: string}, ref) => {
  return (
    <RouterNavLink
      // innerRef={ref}
      {...props}
      className={({isActive}) => {
        return `${props.className}${isActive ? " Mui-selected" : ""}`;
      }}
      end
    />
  );
});

export const SidebarLink = React.forwardRef((props, ref) => {
  const blockUI = useBlockUI();
  // console.log("MuiLink", props.href);
  return (
    <Link
      innerRef={ref}
      underline="none"
      {...props}
      // onClick={() => blockUI.blockUI()}
    />
  );
});

export const SidebarBadge = (props) => {
  const classes = useStyles();
  return <Chip className={classes.sidebarBadge} size="small" {...props} />;
};

export const SidebarCategory = (props) => {
  const {children, icon, name, isCollapsable, isOpen, badge, ...rest} = props;

  return (
    <>
      <SidebarListItem button {...rest}>
        <SidebarListItemIcon icon={icon} />
        <SidebarListItemText primary={name} />
        {badge ? <SidebarBadge label={badge} /> : ""}
        {isCollapsable ? isOpen ? <ExpandMore /> : <ExpandLess /> : null}
      </SidebarListItem>
    </>
  );
};

const FontAwesomeMenuIcon = styled(FontAwesomeIcon)`
  //font-size: 20px;
`;
export const SidebarListItemIconWrapper = styled(ListItemIcon)`
  //min-width: 40px;
`;

export const SidebarListSubheader = styled(ListSubheader)``;

const SidebarContent = getSidebarContent(styled);
const DrawerSidebar = getDrawerSidebar(styled);

const Sidebar = (props) => {
  const {theme, staticContext, children, routes = [], badgeData = {}, ...rest} = props;
  const classes = useStyles();
  const params = useParams();
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;
    let _routes = {};
    routes.forEach((route, index) => {
      const isOpen = route.open; // this is to mark route as always open in router
      let isActive = false;
      const childRoutes = route.children || [];

      childRoutes.forEach((childRoute) => {
        if (isActive) return;

        const match = matchPath(
          {
            path: childRoute.path,

            end: true,
          },
          pathName
        );

        isActive = match;
      });
      _routes = Object.assign({}, _routes, {[index]: isActive || isOpen});
    });

    return _routes;
  };
  // const [openRoutes, setOpenRoutes] = React.useState(() => initOpenRoutes());
  const [openRoutes, setOpenRoutes] = React.useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, {[item]: false}))
    );
    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  };

  return (
    <DrawerSidebar sidebarId="primarySidebar" className={classes.root} {...rest}>
      <SidebarContent className={classes.sidebarContent}>
        {children}
        <SidebarList>
          {routes.map((category, index) => {
            if (!category.id) {
              return;
            }
            let path = undefined;
            if (category.overrideLink || category.path) {
              path = generatePath(category.overrideLink || category.path, {
                ...{currentDate: moment().format("YYYY-MM-DD")},
                ...params,
              });
              // HACK: always add trailing slash to path
              // path = `${path}/`;
              path = path.replace(/\/?(\?|#|$)/, "/$1");
            }
            // const path = generatePath(category.overrideLink || category.path, {
            //   ...{currentDate: moment().format("YYYY-MM-DD")},
            //   ...params,
            // });

            const renderV2Link = Boolean(category.component && !category.overrideLink);

            return (
              <React.Fragment key={index}>
                {category.header ? <SidebarListSubheader>{category.header}</SidebarListSubheader> : null}

                {category.children ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                      badge={
                        badgeData[category.badgeAttr] !== undefined
                          ? accounting.formatNumber(badgeData[category.badgeAttr])
                          : null
                      }
                    />

                    <SidebarCollapse in={Boolean(openRoutes[index])}>
                      {category.children.map((route, index) => {
                        if (!route.id) {
                          return;
                        }
                        let path = generatePath(route.overrideLink || route.path, params);
                        // HACK: always add trailing slash to path (works with query strings)
                        // path = `${path}/`;
                        path = path.replace(/\/?(\?|#|$)/, "/$1");
                        const renderV2Link = Boolean(route.component && !route.overrideLink);
                        return (
                          <SidebarCategory
                            key={index}
                            name={route.id}
                            to={path}
                            href={renderV2Link ? undefined : path}
                            component={renderV2Link ? SidebarNavLink : SidebarLink}
                            icon={route.icon}
                            badge={
                              badgeData[route.badgeAttr] !== undefined
                                ? accounting.formatNumber(badgeData[route.badgeAttr])
                                : null
                            }
                          />
                        );
                      })}
                    </SidebarCollapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={path}
                    href={renderV2Link ? undefined : path}
                    component={renderV2Link ? SidebarNavLink : SidebarLink}
                    icon={category.icon}
                    badge={
                      badgeData[category.badgeAttr] !== undefined
                        ? accounting.formatNumber(badgeData[category.badgeAttr])
                        : null
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
        </SidebarList>
        {/* Add padding for scrolling */}
        <Box pb={3} />
      </SidebarContent>
    </DrawerSidebar>
  );
};
export default Sidebar;
