import {Box, Tooltip, Typography} from "@material-ui/core";
import classnames from "classnames";
import {filter, isEmpty, map, sum, sumBy} from "lodash";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorSuccess} from "../../../theme/colors";
import {AdjustBudgetAmountForm, ChangeTypeSelectDialog, NameCOForm} from "./NetZeroChangeOrderForms";

const NetZeroChangeOrder = (props) => {
  const {project, ...rest} = props;
  const [budgetChanges, setBudgetChanges] = React.useState({} as any);
  const [activeBudgetItem, setActiveBudgetItem] = React.useState({} as {id: number});
  const [hasCOR, setHasCOR] = React.useState(false);
  const [typeSelectDialogIsOpen, setTypeSelectDialogIsOpen] = React.useState(true);
  const [budgetAdjustDialogIsOpen, setBudgetAdjustDialogIsOpen] = React.useState(false);
  const [changeOrderDescriptionDialogIsOpen, setChangeOrderDescriptionDialogIsOpen] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      budgets: [],
    },
  });

  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const budgets = pageData.budgets;

  const netBudgetChange = sum(map(budgetChanges, (item) => item.amount));

  const activeBudgetChangeAmount = budgetChanges[activeBudgetItem.id]?.amount || 0;

  const handleBudgetChange = (amount, budget) => {
    const newBudgetChanges = budgetChanges;
    newBudgetChanges[budget.id] = {budget: budget, amount: amount};
    if (newBudgetChanges[budget.id].amount === 0) {
      delete newBudgetChanges[budget.id];
    }
    setBudgetChanges(newBudgetChanges);
  };

  if (typeSelectDialogIsOpen) {
    return (
      <>
        <ChangeTypeSelectDialog
          isOpen={typeSelectDialogIsOpen}
          onSelect={(value) => {
            setHasCOR(value);
            setTypeSelectDialogIsOpen(false);
          }}
          handleClose={() => {
            navigate(`/v2/projects/${project.id}/change-order-wizard/`);
            setTypeSelectDialogIsOpen(false);
          }}
        />
      </>
    );
  }

  const totalDebits = sumBy(
    filter(budgetChanges, (change) => change.amount < 0),
    (change) => change.amount
  );

  const totalCredits = sumBy(
    filter(budgetChanges, (change) => change.amount >= 0),
    (change) => change.amount
  );

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Budgets..." />;
  }
  return (
    <>
      <BeforeUnload block={hasUnsavedChanges} />
      <Helmet title={`${project.display} - Create ${hasCOR ? "COR" : "Internal PCO"}`} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>

        <Typography color="textPrimary">Create {hasCOR ? "COR" : "Internal PCO"}</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Create {hasCOR ? "COR" : "Internal PCO"}</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="Credits" precision={2} color="" number={totalCredits} />
          <PageHeader.Right.CurrencySpark title="Debits" precision={2} color="" number={totalDebits} />
          <PageHeader.Right.CurrencySpark title={`Net Change`} number={netBudgetChange} precision={2} color="" />
        </PageHeader.Right>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Budget Items</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip title={netBudgetChange !== 0 ? "Budget Changes Must Have a Net Zero Value" : ""}>
                <span>
                  <PaperPanel.Header.CreateButton
                    onClick={() => setChangeOrderDescriptionDialogIsOpen(true)}
                    disabled={netBudgetChange !== 0 || isEmpty(budgetChanges)}
                  />
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <BudgetTable
            budgets={budgets}
            projectId={project.id}
            budgetChanges={budgetChanges}
            onEditBudget={(budgetItem) => {
              setActiveBudgetItem(budgetItem);
              setBudgetAdjustDialogIsOpen(true);
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={1}>
        <LegacyUILink href={`/pcos/${project.id}/internal-co-wizard/`} />
      </Box>
      {/* <h3>PageData</h3>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
      <AdjustBudgetAmountForm
        isOpen={budgetAdjustDialogIsOpen}
        handleClose={() => setBudgetAdjustDialogIsOpen(false)}
        onSubmit={(values) => {
          handleBudgetChange(values.amount, activeBudgetItem);
          setBudgetAdjustDialogIsOpen(false);
          setHasUnsavedChanges(true);
        }}
        initialValues={{amount: activeBudgetChangeAmount}}
        netBudgetChange={netBudgetChange}
        changed={budgetChanges[activeBudgetItem.id]?.amount ?? 0}
      />
      <NameCOForm
        isOpen={changeOrderDescriptionDialogIsOpen}
        handleClose={() => setChangeOrderDescriptionDialogIsOpen(false)}
        hasCOR={hasCOR}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          setHasUnsavedChanges(false);
          postCurrentPage
            .mutateAsync({
              items: Object.keys(budgetChanges).map((key) => budgetChanges[key]),
              description: values.description,
              hasCOR: hasCOR,
            })
            .then((response) => {
              setChangeOrderDescriptionDialogIsOpen(false);

              window.location.href = response.url;
            });
        }}
      />
    </>
  );
};

export default NetZeroChangeOrder;

const BudgetTable = (props) => {
  const {budgets, projectId, budgetChanges, setBudgetChanges, onEditBudget, ...rest} = props;
  const novoClasses = makeNovoClasses();

  return (
    <ReactTableMui
      hover
      size="small"
      className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
      rightAlignColumns={["current_budget", "Change Amount"]}
      //   showFooter
      columns={[
        {
          Header: "Code",
          accessor: "budget_code",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "Allocation",
          accessor: "allocation_display",
        },
        {
          Header: "Current Budget",
          Cell: ({value, row}) => {
            return <Currency number={value} />;
          },
          accessor: "current_budget",
        },
        {
          Header: "Change Amount",
          //
          Cell: ({value, row}) => {
            const changeAmount = budgetChanges[row.original.id]?.amount || 0;

            return (
              <>
                <Currency number={changeAmount} color={changeAmount > 0 ? colorSuccess : ""} />
              </>
            );
          },
        },
      ]}
      //   initialState={{
      //     sortBy: [{}],
      //   }}
      getRowProps={(row) => {
        return {
          onClick: () => {
            onEditBudget(row.original);
          },
          style: {cursor: "pointer"},
        };
      }}
      data={budgets}
    />
  );
};
