import {faFileExcel, faSnowflake} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import {blue, deepOrange, green, red} from "@material-ui/core/colors";
import accounting from "accounting";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {Currency} from "../components/Accounting";
import {LegacyUILink, MuiNavLink} from "../components/Links";
import PaperPanel from "../components/PaperPanel";
import {ReactTableMui} from "../components/ReactTableMui";
import {TableStatusLabel} from "../components/Status";
import {WatchProjectSwitch} from "../components/WatchSwitches";
import {useFetchCurrentPage} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import {saveExcel} from "../utils/excel";
import {PaginationWithPageSize} from "../components/Pagination";
import {FilterSearch} from "../components/Filters";
import useFilterOptions from "../hooks/useFilterOptions";
import {FilterOptionChips} from "../components/FilterOptions";

const AccessibleProjects = (props) => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(250);
  const {permissions, ...rest} = props;
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Search"]);
  const filterParams = {
    q: filterOptions.Search.value,
  };

  const paginationParams = {
    page_size: pageSize,
    page: page,
  };
  const {
    data: pageData,
    refetch: refetchPageData,
    isFetching: pageDataIsFetching,
  } = useFetchCurrentPage(
    {
      initialData: {
        projects: {
          totalPages: 0,
          count: 0,
          next: null,
          previous: null,
          results: [],
        },
      },
    },
    {...paginationParams, ...filterParams}
  );
  const {projects} = pageData;
  const novoClasses = makeNovoClasses();

  const projectColumns = React.useMemo(
    () => [
      {
        id: "project",
        Header: "Project",
        disableSortBy: true,
        accessor: (value) => {
          return `${value.status_sort}-${value.display}`;
        },
        Cell: ({value, row}) => (
          <>
            {/* <ProjectStatusIcon project={row.original} showTooltip />{" "} */}
            <TableStatusLabel status={row.original.status || "Unknown"}>
              <Link underline="always" href={`/v2/projects/${row.original.id}/`}>
                {row.original.display}
              </Link>
            </TableStatusLabel>
          </>
        ),
      },
      {
        Header: "Current Value",
        accessor: "current_budget",
        disableSortBy: true,
        Cell: ({value, row}) => (
          <Link href={`/projects/${row.original.id}/budget/current/`} style={{color: green[800]}}>
            <Currency number={value} precision={0} />
          </Link>
        ),
      },
      {
        Header: "Receivable Balance	",
        accessor: "receivable_balance",
        disableSortBy: true,
        Cell: ({value, row}) =>
          row.original.is_closed || !row.original.timberline_job_id ? (
            <span style={{color: red[800]}}>
              <Currency number={value} precision={0} />
            </span>
          ) : (
            <Link href={`/reports2/projects/${row.original.id}/timberline/aging/`} style={{color: red[800]}}>
              <Currency number={value} precision={0} />
            </Link>
          ),
      },
      {
        Header: "Billed to Date",
        accessor: "billed_to_date",
        disableSortBy: true,
        Cell: ({value, row}) => <Currency number={value} precision={0} color={blue[800]} />,
      },
      {
        Header: "Cost to Date",
        accessor: "cost_to_date",
        disableSortBy: true,
        Cell: ({value, row}) => (
          <>
            {row.original.is_frozen && (
              <>
                <Tooltip title="Frozen" placement="left">
                  <span>
                    <FontAwesomeIcon icon={faSnowflake} size="xs" color={blue[900]} />
                  </span>
                </Tooltip>{" "}
              </>
            )}
            {permissions.can_view_job_cost_reports ? (
              <Link
                href={`/reports2/projects/${row.original.id}/timberline/job-cost/`}
                style={{color: deepOrange[800]}}
              >
                <Currency number={value} precision={0} />
              </Link>
            ) : (
              <span style={{color: deepOrange[800]}}>
                <Currency number={value} precision={0} />
              </span>
            )}
          </>
        ),
      },
      {
        Header: "Followed",
        id: "toggle",
        disableSortBy: true,
        Cell: ({value, row}) => (
          <WatchProjectSwitch size="small" initialChecked={row.original.is_watched} projectId={row.original.id} />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Helmet title="Project List" />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>

        <Typography color="textPrimary"> All Accessible Projects</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataIsFetching}>
          <PaperPanel.Header.Title>
            Accessible Projects ({accounting.formatNumber(projects.results.length)}/
            {accounting.formatNumber(projects.count)})
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton onClick={() => refetchPageData()} isFetching={pageDataIsFetching} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={projects.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500]}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["current_budget", "receivable_balance", "billed_to_date", "cost_to_date", "toggle"]}
            columns={projectColumns}
            usePagination
            initialState={{
              pageSize: pageSize,
              hiddenColumns: permissions.has_restricted_views
                ? ["current_budget", "receivable_balance", "billed_to_date", "cost_to_date"]
                : [],
              // sortBy: [
              //   {
              //     id: "project",
              //   },
              // ],
            }}
            data={projects.results}
          />
          {/* <pre>{JSON.stringify(permissions, null, 2)}</pre>
          <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/`} mt={2} />
    </>
  );
};

export default AccessibleProjects;
