import {faExternalLink, faMicrochipAi} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import {ConfirmationDialog} from "../../components/Dialogs";
import insuranceAcordForms from "../../components/forms/choices/insuranceAcordForms.json";
import insuranceCertHolders from "../../components/forms/choices/insuranceCertHolders.json";
import {
  CurrencyFieldMui,
  DatePickerMui,
  MaskedInputMui,
  SimpleSelect,
  TextFieldMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import InnerFooter from "../../components/InnerFooter";
import usePermissions from "../../hooks/usePermissions";

const COICommonFields = (props: {values: any; [rest: string]: any}) => {
  const {projectId, values, isNew, isReadOnly, ...rest} = props;

  return (
    <>
      <Grid container spacing={2}>
        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        <Grid item xs={4}>
          <TextFieldMui
            name="insured_company_name"
            label="Insured Company Name"
            required
            autoFocus
            helperText={
              values.insured_fuzzy_company?.name ? (
                <Link href={`${values.insured_fuzzy_company?.url}#tab=cois`} underline="always">
                  {values.insured_fuzzy_company?.name}
                </Link>
              ) : (
                `${values.insured_company_name} - Not Found`
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect name="acord_form" label="ACORD Form" options={insuranceAcordForms} required />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect name="cert_holder" label="Cert Holder" options={insuranceCertHolders} required />
        </Grid>
        <Grid item sm={2}>
          <DatePickerMui label="COI Date" name="coi_date" format="MM/DD/YYYY" />
        </Grid>
        <Grid item xs={2}>
          <MaskedInputMui name="timberline_job_number" label="Job Number" mask="999-999" />
          {/* <TextFieldMui name="timberline_job_number" label="Job Number" /> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui label="Expiration Date(s)" name="expiration_dates_json[0]" format="MM/DD/YYYY" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui label="Expiration Date(s)" name="expiration_dates_json[1]" format="MM/DD/YYYY" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui label="Expiration Date(s)" name="expiration_dates_json[2]" format="MM/DD/YYYY" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui label="Expiration Date(s)" name="expiration_dates_json[3]" format="MM/DD/YYYY" />
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </>
  );
};

export const COIUpdateDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  onDelete?: () => void;
  children?: React.ReactNode;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, onDelete, children, ...rest} = props;
  const [sharedLink, setSharedLink] = React.useState("");
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            React.useEffect(() => {
              axiosAPI.get(`/insurance/cois/${values.uuid}/shared-link/`).then((response) => {
                setSharedLink(response.data.sharedLinkURL);
              });
            }, [values?.uuid]);
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Update COI</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <COICommonFields values={values} />
                  </FinalFormKeyboardShortcuts>
                  <Box mt={1} />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {/* PageMode = 'bookmarks' | 'thumbs' | 'none'; */}
                  {children}
                  {/* <SuperuserInfo
                    objects={[
                      {
                        contentType: "insurance",
                        model: "certificateofinsurance",
                        id: values.id,
                        title: "COI",
                      },
                    ]}
                  /> */}
                  {values.sha1_hash}
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {onDelete && (
                    <FormActions.DeleteButton
                      onClick={() => {
                        setDeleteConfirmationIsOpen(true);
                      }}
                    />
                  )}
                  <Button
                    href={sharedLink}
                    target="_blank"
                    startIcon={<FontAwesomeIcon icon={faExternalLink} />}
                    variant="outlined"
                  >
                    Box File
                  </Button>
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          onDelete();
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete this COI. This will delete the Box file as well. This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export const COIUpdateForm = (props: {
  onSubmit: (values: any) => void;
  children?: React.ReactNode;
  onDelete?: () => void;
  onProcessWithAI?: () => void;
  onProcessLimitsWithAI?: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, onProcessWithAI, onProcessLimitsWithAI, children, ...rest} = props;
  const [sharedLink, setSharedLink] = React.useState("");
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const permissions = usePermissions();

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => {
          React.useEffect(() => {
            axiosAPI.get(`/insurance/cois/${values.uuid}/shared-link/`).then((response) => {
              setSharedLink(response.data.sharedLinkURL);
            });
          }, [values?.uuid]);
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <BeforeUnload block={!pristine} />
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <COICommonFields values={values} />
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <CurrencyFieldMui
                      name="data.limits.commercial_general_liability_limit"
                      label="Commercial General Liability Aggregate"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyFieldMui
                      name="data.limits.automobile_liability_single_limit"
                      label="Automobile Liability Single Limit"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyFieldMui
                      name="data.limits.employers_liability_policy_limit"
                      label="Employers Liability Policy Limit"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyFieldMui name="data.limits.umbrella_aggregate_limit" label="Umbrella Aggregate Limit" />
                  </Grid>
                </Grid>
              </FinalFormKeyboardShortcuts>
              <Box mt={1} />

              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              {/* PageMode = 'bookmarks' | 'thumbs' | 'none'; */}
              {children}
              <InnerFooter>
                <Box display="flex">
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {onDelete && (
                    <>
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        onClick={() => {
                          setDeleteConfirmationIsOpen(true);
                        }}
                      />
                    </>
                  )}
                  <Box ml={1} />
                  <Button
                    href={sharedLink}
                    target="_blank"
                    startIcon={<FontAwesomeIcon icon={faExternalLink} />}
                    variant="outlined"
                  >
                    Box File
                  </Button>
                  {permissions.is_superuser && onProcessWithAI && (
                    <>
                      <Box ml={1} />
                      <Button
                        startIcon={<FontAwesomeIcon icon={faMicrochipAi} />}
                        variant="outlined"
                        onClick={onProcessWithAI}
                      >
                        Process with AI (Superuser)
                      </Button>
                    </>
                  )}
                  {permissions.is_superuser && onProcessLimitsWithAI && (
                    <>
                      <Box ml={1} />
                      <Button
                        startIcon={<FontAwesomeIcon icon={faMicrochipAi} />}
                        variant="outlined"
                        onClick={onProcessLimitsWithAI}
                      >
                        Process limits with AI (Superuser)
                      </Button>
                    </>
                  )}
                </Box>
              </InnerFooter>
            </form>
          );
        }}
      </FinalForm>
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          onDelete();
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete this COI. This will delete the Box file as well. This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};
