import {faMessage, faMicrochipAi, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import Cookies from "js-cookie";
import {groupBy} from "lodash";
import React from "react";
import {Form as FinalForm, FormSpy} from "react-final-form";
import {Helmet} from "react-helmet";
import ReactMarkdown from "react-markdown";
import {useParams} from "react-router";
import {textToHTML} from "../../../js/common/utils";
import HtmlRender from "../../../js/components/HtmlRender";
import nl2br from "../../../js/utils/nl2br";
import {axiosAPI} from "../../api";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import DropzoneDialogMui from "../../components/DropzoneMui/DropzoneDialogMui";
import boxAIModels from "../../components/forms/choices/boxAIModels.json";
import {SimpleSelect, TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import BlockUI from "../../components/GlobalLoaders";
import InnerFooter from "../../components/InnerFooter";
import {MuiNavLink} from "../../components/Links";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";

const AIAnalysisDetail: React.FC = () => {
  const {analysisId} = useParams();

  const [formValues, setFormValues] = React.useState({});
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);
  const [promptDialogIsOpen, setPromptDialogIsOpen] = React.useState(false);
  const [deleteDocumentConfirmationIsOpen, setDeleteDocumentConfirmationIsOpen] = React.useState(false);
  const [deleteOtherAnswersConfirmationIsOpen, setDeleteOtherAnswersConfirmationIsOpen] = React.useState(false);
  const [activeDocument, setActiveDocument] = React.useState({} as any);
  const [activePrompt, setActivePrompt] = React.useState({} as any);
  const blockUI = useBlockUI();

  const bottomRef = React.useRef(null);

  const {query: analysisQuery} = useSentinelDetailAPI(`ai/analysis/${analysisId}/`);
  const analysis = analysisQuery.data;

  const {query: documentsQuery, delete: deleteDocument} = useSentinelListAPI(`ai/analysis/${analysisId}/documents/`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });
  const documents = documentsQuery.data.results;

  const {
    query: answersQuery,
    create: createAnswer,
    rpc: answerRPC,
  } = useSentinelListAPI(`ai/analysis/${analysisId}/answers/`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });
  const answers = answersQuery.data.results;
  const groupedAnswers = groupBy(answers, "prompt_code");
  const otherAnswers = groupedAnswers[""];

  if (!analysisQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching analysis..." />;
  }

  return (
    <>
      <Helmet title={`AI Document Analysis - ${analysis.type_display}`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/ai/analysis/">
          AI Document Analysis
        </MuiNavLink>
        <Typography color="textPrimary">{analysis.type_display}</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={documentsQuery.isFetching}>
          <PaperPanel.Header.Title>{analysis.type_display}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => {
                  console.log("scroll to bottom", bottomRef.current);
                  bottomRef.current?.scrollIntoView({behavior: "smooth"});
                }}
              >
                Scroll to bottom
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action>
              <Tooltip title={documents.length < 1 ? "Add document to analyze" : ""}>
                <span>
                  <PaperPanel.Header.Button
                    startIcon={<FontAwesomeIcon icon={faMicrochipAi} />}
                    // variant="contained"
                    // color="primary"
                    disabled={documents.length < 1}
                    onClick={() => {
                      console.log("analyze");
                      blockUI.blockUI("Analyzing Documents...");
                      axiosAPI.post(`ai/analysis/${analysisId}/analyze/`, formValues).then(() => {
                        answersQuery.refetch();
                        blockUI.unblockUI();
                      });
                    }}
                  >
                    Analyze
                  </PaperPanel.Header.Button>
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faUpload} fixedWidth />}
                onClick={() => {
                  setShowUploadDialog(true);
                }}
              >
                Upload Document(s)
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  documentsQuery.refetch();
                  answersQuery.refetch();
                }}
                isFetching={documentsQuery.isFetching || answersQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <FinalForm
            onSubmit={() => {}}
            initialValues={{box_ai_model: "google__gemini_2_0_flash_001"}}
            subscription={{values: true}}
          >
            {() => (
              <>
                <form>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <SimpleSelect name="box_ai_model" label="AI Model" options={boxAIModels} />
                    </Grid>
                  </Grid>
                  <FormSpy
                    onChange={(formState) => {
                      setFormValues(formState.values);
                    }}
                    subscription={{values: true}}
                  />
                </form>
              </>
            )}
          </FinalForm>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <PaperItem.Header>
            <PaperItem.Body>
              <h3 style={{margin: 0}}>Documents</h3>
            </PaperItem.Body>
          </PaperItem.Header>

          {documents.map((document) => (
            <PaperItem key={document.id}>
              <PaperItem.Body>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <Link
                      underline="always"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        blockUI.blockUI("Fetching box link...");
                        axiosAPI
                          .get(`/ai/analysis/${analysisId}/documents/${document.id}/shared-link/`)
                          .then((response) => {
                            blockUI.unblockUI();
                            window.open(response.data.sharedLinkURL, "_blank");
                          });
                      }}
                    >
                      {document.name}
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    {/* <Typography2 type="metadata">{document.type_display}</Typography2> */}
                  </Grid>
                </Grid>
              </PaperItem.Body>
              <PaperItem.Right minWidth={65}></PaperItem.Right>
              <PaperItem.RightHover>
                <PaperItem.RightHover.IconButton
                  icon={DeleteIcon}
                  title="Delete"
                  onClick={() => {
                    setActiveDocument(document);
                    setDeleteDocumentConfirmationIsOpen(true);
                  }}
                />
              </PaperItem.RightHover>
            </PaperItem>
          ))}

          {analysis.prompts.map((prompt, i) => {
            const answer = groupedAnswers[prompt.code]?.[0]?.answer;
            const firstAnswer = i === 0;
            return (
              <React.Fragment key={prompt.code}>
                <PaperItem.Header borderTop={firstAnswer ? 0 : 1}>
                  <PaperItem.Body>
                    <h3 style={{margin: 0}}>{prompt.name}</h3>
                    {/* <pre>{JSON.stringify(prompt, null, 2)}</pre> */}
                  </PaperItem.Body>
                  <PaperItem.Right minWidth={50}>
                    <Tooltip title="Prompt">
                      <FontAwesomeIcon
                        icon={faMessage}
                        fixedWidth
                        onClick={() => {
                          setActivePrompt(prompt);
                          setPromptDialogIsOpen(true);
                        }}
                        style={{cursor: "pointer"}}
                      />
                    </Tooltip>
                  </PaperItem.Right>
                </PaperItem.Header>
                {answer && (
                  <Box py={1} px={2}>
                    <ReactMarkdown>{answer}</ReactMarkdown>
                  </Box>
                )}
              </React.Fragment>
            );
          })}
          {otherAnswers && (
            <>
              <PaperItem.Header borderTop={1} noHover={false}>
                <PaperItem.Body>
                  <h3 style={{margin: 0}}>Other Questions</h3>
                </PaperItem.Body>
                <PaperItem.Right minWidth={50} />
                <PaperItem.RightHover>
                  <PaperItem.RightHover.IconButton
                    icon={DeleteIcon}
                    title="Delete"
                    onClick={() => {
                      setDeleteOtherAnswersConfirmationIsOpen(true);
                    }}
                  />
                </PaperItem.RightHover>
              </PaperItem.Header>

              {otherAnswers.map((answer, i) => {
                return (
                  <React.Fragment key={answer.id}>
                    <PaperItem.Header borderTop={i === 0 ? 0 : 1}>
                      <PaperItem.Body>
                        <h4 style={{margin: 0}}>{nl2br(answer.question)}</h4>
                      </PaperItem.Body>
                    </PaperItem.Header>
                    <Box py={1} px={2}>
                      <ReactMarkdown>{answer.answer}</ReactMarkdown>
                    </Box>
                  </React.Fragment>
                );
              })}
            </>
          )}
          {/* <pre>{JSON.stringify(formValues, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(boxAIModels, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(analysis, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(documents, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(groupedAnswers, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(otherAnswers, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={20} />
      <div ref={bottomRef} />
      <InnerFooter>
        <FinalForm
          onSubmit={(values, form) => {
            console.log("onSubmit", {...values, ...formValues});
            // bottomRef.current?.scrollIntoView({behavior: "smooth"});
            blockUI.blockUI("Asking question...");
            createAnswer.mutateAsync({...values, ...formValues}).then(() => {
              form.restart();
              blockUI.unblockUI();
              setTimeout(() => {
                console.log("scroll to bottom", bottomRef.current);
                bottomRef.current?.scrollIntoView({behavior: "smooth"});
              });
            });
          }}
        >
          {({handleSubmit, form, submitting, pristine, values}) => {
            // const _handleSubmit = (e) => {
            //   e.preventDefault();
            //   handleSubmit();
            //   form.restart();
            // };
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <Box style={{padding: "20px"}} alignItems="center" display="flex">
                    <TextFieldMui
                      variant="outlined"
                      size="small"
                      id="user-input-query"
                      label="Ask A Question"
                      fullWidth
                      minRows={3}
                      name="question"
                      multiline
                      // placeholder="What is the jobsite address?"
                      // disabled={hasPendingQuestion}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          form.submit();
                        }
                      }}
                      autoFocus
                    />

                    <Box textAlign="center" ml={1}>
                      <Fab
                        color="primary"
                        aria-label="add"
                        // type="submit"
                        onClick={() => {
                          form.submit();
                        }}
                        disabled={submitting || pristine || !values.question}
                      >
                        <SendIcon />
                      </Fab>
                    </Box>
                  </Box>
                </form>
              </>
            );
          }}
        </FinalForm>
      </InnerFooter>

      <DropzoneDialogMui
        getUploadParams={() => {
          return {
            url: `/api/v1/ai/analysis/${analysisId}/documents/upload/`,
            headers: {"X-CSRFToken": Cookies.get("csrftoken")},
          };
        }}
        timeout={1000 * 60 * 5}
        // accept="image/*,application/pdf"
        // accept="*"
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        onChangeStatus={(event) => {
          if (["done"].includes(event.meta.status)) {
            documentsQuery.refetch();
            // setHasUploadedToDropzone(true);
            // form.mutators.validate();
          }
        }}
        isOpen={showUploadDialog}
        handleClose={() => {
          setShowUploadDialog(false);
          // documentsQuery.refetch();
        }}
      />

      <Dialog open={promptDialogIsOpen} onClose={() => setPromptDialogIsOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Prompt for {activePrompt.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <ReactMarkdown>{activePrompt.prompt}</ReactMarkdown> */}
              <HtmlRender html={textToHTML(activePrompt.prompt)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormActions.CloseButton onClick={() => setPromptDialogIsOpen(false)} />
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        isOpen={deleteDocumentConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Deleting analysis...");
          deleteDocument.mutateAsync(activeDocument.id).then(() => {
            setDeleteDocumentConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setDeleteDocumentConfirmationIsOpen(false)}
      >
        You want to delete this document. This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteOtherAnswersConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Deleting other questions...");
          answerRPC.mutateAsync({action: "delete-other-answers", method: "DELETE"}).then(() => {
            answersQuery.refetch().then(() => {
              blockUI.unblockUI();
              setDeleteOtherAnswersConfirmationIsOpen(false);
            });
          });
        }}
        onDeny={() => setDeleteOtherAnswersConfirmationIsOpen(false)}
      >
        You want to delete all other questions. This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export default AIAnalysisDetail;
