import {Box, Button, Divider, Typography} from "@material-ui/core";
import {Refresh as RefreshIcon} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {textToHTML} from "../../../js/common/utils";
import HtmlRender from "../../../js/components/HtmlRender";
import {ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import {ProjectStatusIcon} from "../../components/Icons";
import {PageHeader} from "../../components/PageHeader";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage, useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import {ProjectForecastLMOEChart} from "./ProjectForecast/ProjectForecastCharts";

const ProjectAdminTools = (props) => {
  const {project: initialProject, ...rest} = props;
  const {projectId} = useParams();
  const blockUI = useBlockUI();
  const postCurrentPage = usePostCurrentPage();
  const {enqueueSnackbar} = useSnackbar();

  const {
    data: pageData,
    refetch: refetchPageData,
    isFetching: pageDataIsFetching,
  } = useFetchCurrentPage({
    initialData: {},
  });

  const runAction = (action) => {
    blockUI.blockUI();
    postCurrentPage
      .mutateAsync({action: action})
      .then((response) => {
        blockUI.unblockUI();
        enqueueSnackbar(<HtmlRender html={textToHTML(response.message)} />, {
          variant: "success",
        });
      })
      .catch(() => blockUI.unblockUI());
  };

  const {
    query: projectQuery,
    update: updateProject,
    rpc: projectRPC,
  } = useSentinelDetailAPI(["projects", parseInt(projectId)], {
    initialData: initialProject,
  });
  const project = projectQuery.data;

  return (
    <>
      <Helmet title={`${project.display} - Admin Tools`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Admin Tools</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            <ProjectStatusIcon project={project} /> {project.display} - Admin Tools
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Typography variant="h4" gutterBottom>
        Directory
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          blockUI.blockUI();
          projectRPC
            .mutateAsync({action: "cleanup-team"})
            .then(() => {
              blockUI.unblockUI();
              projectQuery.refetch();
            })
            .catch(() => blockUI.unblockUI());
        }}
      >
        Cleanup Team
      </Button>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Box
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          blockUI.blockUI();
          projectRPC
            .mutateAsync({action: "link-box-folders"})
            .then((response) => {
              blockUI.unblockUI();
              enqueueSnackbar(
                `Fixed ${response.message.fixed_count} ${response.message.mapped_folders_count} of ${response.message.folder_map_count} mapped`,
                {variant: "success"}
              );
              projectQuery.refetch();
            })
            .catch(() => blockUI.unblockUI());
        }}
      >
        Link Box Folders
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          blockUI.blockUI();
          projectRPC
            .mutateAsync({action: "fix-box-shared-folder-permissions"})
            .then((response) => {
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {variant: "success"});
              projectQuery.refetch();
            })
            .catch(() => blockUI.unblockUI());
        }}
      >
        Fix Box Folder Permissions
      </Button>

      <Button
        variant="contained"
        onClick={() => {
          blockUI.blockUI();
          projectRPC
            .mutateAsync({action: "copy-box-template-folders"})
            .then((response) => {
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {variant: "success"});
              projectQuery.refetch();
            })
            .catch(() => blockUI.unblockUI());
        }}
      >
        Copy Box Template Folders
      </Button>

      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Contracts
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          runAction("create_or_update_default_prime_contract");
        }}
      >
        Create Or Update Default Prime Contract
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          runAction("set_missing_prime_contract_on_pcos");
        }}
      >
        Set Missing Prime Contract On PCOs
      </Button>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Misc
      </Typography>
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={() => {
          projectQuery.refetch();
        }}
      >
        Refetch
      </Button>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Cost History
      </Typography>
      <Box my={2}>
        <Divider />
      </Box>
      <ProjectForecastLMOEChart projectId={project.id} />
      {/* <pre>{JSON.stringify(pageData.cost, null, 2)}</pre> */}
      <Typography variant="h4" gutterBottom>
        RPC
      </Typography>
      <pre>{JSON.stringify(projectRPC, null, 2)}</pre>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Project
      </Typography>
      <pre>{JSON.stringify(project, null, 2)}</pre>
    </>
  );
};

export default ProjectAdminTools;
