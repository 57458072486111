import {faCircle, faLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import Moment from "react-moment";
import {PDFObject} from "react-pdfobject";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import insuranceAcordForms from "../../components/forms/choices/insuranceAcordForms.json";
import insuranceCertHolders from "../../components/forms/choices/insuranceCertHolders.json";
import {MuiNavLink} from "../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorError} from "../../theme/colors";
import {COIExpirationDateTableCell} from "./COIComponents";
import {COIUpdateDialogForm} from "./COIForms";

const COIDashboard = (props) => {
  const [activeCOI, setActiveCOI] = React.useState({} as any);
  const [showUpdateCOIDialog, setShowUpdateCOIDialog] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(250);
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Has Project",
    "Latest",
    "Acord Form",
    "Cert Holder",
    "AI Processed",
  ]);
  const filterParams = {
    q: filterOptions.Search.value,
    project__isnull: filterOptions["Has Project"].value,
    latest: filterOptions.Latest.value,
    acord_form: filterOptions["Acord Form"].value,
    cert_holder: filterOptions["Cert Holder"].value,
    ai_processed__isnull: filterOptions["AI Processed"].value,
  };

  const {
    query: coiQuery,
    update: updateCOI,
    delete: deleteCOI,
    rpc: coiRPC,
  } = useSentinelListAPI(
    `insurance/cois/?ordering=ai_processed_is_null,-ai_processed&page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      // keepPreviousData: true,
    },
    {
      idProp: "uuid",
    }
  );
  const cois = coiQuery.data.results;
  return (
    <>
      <Helmet title="COI Dashboard" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">COI Dashboard</Typography>
      </Breadcrumbs>

      {/* <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>COI Dashboard</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader> */}
      <Box my={2}>
        <Alert severity="info">
          Email COI's to{" "}
          <Link href="mailto:certificates@novoconstruction.com" underline="always">
            certificates@novoconstruction.com
          </Link>
          . COI attachments emailed to the address will be automatically processed every hour on the hour.
        </Alert>
      </Box>

      <PaperPanel>
        <PaperPanel.Header isLoading={coiQuery.isFetching}>
          <PaperPanel.Header.Title>COI Dashboard</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        blockUI.blockUI("Setting Insured Company Fuzzy Match...");
                        coiRPC.mutateAsync({action: "set-insured-fuzzy-company"}).then(() => {
                          coiQuery.refetch().then(() => {
                            blockUI.unblockUI();
                          });
                        });
                        popupState.close();
                      }}
                    >
                      Set Insured Company Fuzzy Match
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        blockUI.blockUI("Moving Files To Project Folder...");
                        coiRPC.mutateAsync({action: "move-files-to-project-folder"}).then(() => {
                          coiQuery.refetch().then(() => {
                            blockUI.unblockUI();
                          });
                        });
                        popupState.close();
                      }}
                    >
                      Move Files To Project Folder
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>

              <PaperPanel.Header.RefreshButton isFetching={coiQuery.isFetching} onClick={() => coiQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="Has Project"
                name="has_project"
                options={[
                  {label: "True", value: "False"},
                  {label: "False", value: "True"},
                ]}
                value={filterOptions["Has Project"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Has Project", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="Latest"
                name="latest"
                options={[
                  {label: "True", value: "True"},
                  {label: "False", value: "False"},
                ]}
                value={filterOptions["Latest"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Latest", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="Acord Form"
                name="acord_form"
                options={insuranceAcordForms}
                value={filterOptions["Acord Form"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Acord Form", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="Cert Holder"
                name="cert_holder"
                options={insuranceCertHolders}
                value={filterOptions["Cert Holder"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Cert Holder", value, label);
                }}
              />
            </Grid>
            {/* <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="AI Processed"
                name="ai_processed"
                options={[
                  {label: "True", value: "False"},
                  {label: "False", value: "True"},
                ]}
                value={filterOptions["AI Processed"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("AI Processed", value, label);
                }}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={coiQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            hover
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // rightAlignColumns={["timestamp"]}
            getCellProps={(cell) => {
              if (["timberline_job_number", "coi_date", "min_expiration_date"].includes(cell.column.id)) {
                return {
                  style: {
                    whiteSpace: "nowrap",
                  },
                };
              }
            }}
            columns={React.useMemo(
              () => [
                {
                  Header: "Insured",
                  accessor: "insured_company_name",
                  Cell: ({value, row}) => (
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <Link href={row.original.url} target="_blank">
                          <FontAwesomeIcon icon={faLink} />
                        </Link>
                      </Box>
                      <Box>
                        <Link
                          style={{cursor: "pointer"}}
                          underline="always"
                          onClick={() => {
                            setActiveCOI(row.original);
                            setShowUpdateCOIDialog(true);
                          }}
                        >
                          {value ? value : "No Company Found"}
                        </Link>
                      </Box>
                      {!row.original?.insured_fuzzy_company && (
                        <Box ml={1}>
                          <Tooltip title={`${row.original?.insured_company_name} - Not found`}>
                            <FontAwesomeIcon icon={faCircle} color={colorError} />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  ),
                },
                {
                  Header: "ACORD Form",
                  accessor: "acord_form_display",
                },
                {
                  Header: "Cert Holder",
                  accessor: "cert_holder_display",
                },
                {
                  Header: "Job #",
                  accessor: "timberline_job_number",
                },
                {
                  Header: "Project",
                  accessor: "project.display",
                  Cell: ({value, row}) => (
                    <>
                      {row.original?.project?.id && (
                        <Link underline="always" href={`/v2/projects/${row.original?.project?.id}/insurance/#tab=cois`}>
                          {value}
                        </Link>
                      )}
                    </>
                  ),
                },
                {
                  Header: "COI Date",
                  accessor: "coi_date",
                },
                {
                  Header: "Ex Date(s)",
                  accessor: "min_expiration_date",
                  Cell: ({value, row}) => <COIExpirationDateTableCell value={value} row={row} />,
                },
                {
                  Header: "Processed",
                  accessor: "ai_processed",
                  sortType: "basic",
                  Cell: ({value, row}) => <>{value && <Moment calendar withTitle date={value} />}</>,
                },
              ],
              []
            )}
            initialState={
              {
                // sortBy: [
                //   {
                //     id: "ai_processed",
                //     desc: true,
                //   },
                // ],
              }
            }
            data={cois}
          />

          {/* <pre>{JSON.stringify(cois, null, 2)}</pre> */}
        </PaperPanel.Body>
        {(coiQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={coiQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[10, 100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <COIUpdateDialogForm
        isOpen={showUpdateCOIDialog}
        handleClose={() => {
          setShowUpdateCOIDialog(false);
        }}
        initialValues={activeCOI}
        onSubmit={(values) => {
          values.expiration_dates_json = values.expiration_dates_json.filter((item) => item !== null);
          blockUI.blockUI("Saving...");
          updateCOI.mutateAsync(values).then((newCloseoutRequest) => {
            blockUI.unblockUI();
            setShowUpdateCOIDialog(false);
            coiQuery.refetch();
          });
        }}
        onDelete={() => {
          blockUI.blockUI("Deleting...");
          deleteCOI.mutateAsync(activeCOI.uuid).then(() => {
            blockUI.unblockUI();
            setShowUpdateCOIDialog(false);
            coiQuery.refetch();
          });
        }}
      >
        <PDFObject
          url={`/api/v1/insurance/cois/${activeCOI.uuid}/pdf/`}
          pdfOpenParams={{pagemode: "thumbs", view: "FitH"}}
          containerProps={{style: {width: "100%", height: "60vh"}}}
        />
      </COIUpdateDialogForm>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default COIDashboard;
