import {
  ReactGrid as _ReactGrid,
  CellChange,
  CellLocation,
  Column,
  HeaderCell,
  ReactGridProps,
  Row,
} from "@silevis/reactgrid";
import {uniqWith} from "lodash";
import moment from "moment";
import React from "react";
import {isMacOs} from "react-device-detect";
import "./style.scss";
import {TooltipHeaderCellTemplate} from "./TooltipHeaderCellTemplate";

const ReactGridV2 = (
  props: Omit<ReactGridProps, "rows" | "columns"> & {
    rows?: Row[];
    columns?: Column[];
    data: {id: number}[];
    onChange?: (newData: {}[]) => void;
    cellData?: {
      buildCell: (object: any) => any;
      headerLabel?: string;
      headerTooltip?: string;
    }[];
    customCellTemplates?: any;
  }
) => {
  const {onChange, cellData = [], data, rows, columns: _columns, customCellTemplates, ...rest} = props;
  const [columns, setColumns] = React.useState(_columns);
  const [openedDropdownLocation, setOpenedDropdownLocation] = React.useState<CellLocation>();

  //   const [reactGridContainerWidth, setReactGridContainerWidth] = React.useState(null);
  const [changesMade, setChangesMade] = React.useState([]);

  React.useEffect(() => {
    setColumns(constructedColumns);
  }, [JSON.stringify(cellData)]);
  /**
   * Transforms a list of objects with id, field, and value properties into a structured format.
   * @param {Array} input - Array of objects to be transformed.
   * @returns {Array} Transformed array of objects with nested properties.
   */
  const transformList = (input: {id: any; field: any; value: any}[]) =>
    Object.values(
      input.reduce((acc, {id, field, value}) => {
        if (!acc[id]) acc[id] = {id};

        field.split(".").reduce((o, key, i, arr) => {
          return (o[key] = i === arr.length - 1 ? value : o[key] || {});
        }, acc[id]);

        return acc;
      }, {})
    );

  const formatChanges = (changes: CellChange[]) => {
    if (changes.length > 0) {
      const result = changes.map((change) => ({
        id: change.rowId,
        field: change.columnId,
        value: change.newCell.type === "number" ? change.newCell.value : change.newCell.text,
        type: change.newCell.type,
      }));

      return result;
    }
  };

  const sanitizeChanges = (changes: {id; field; value; type}[]) => {
    changes.forEach((change) => {
      switch (change.type) {
        case "date":
          if (change.value === "") {
            change.value = null;
            break;
          }
          change.value = moment(change.value).format("YYYY-MM-DD");
          break;
        case "text":
          break;
        case "number":
          change.value = parseInt(change.value);
          break;
        case "checkbox":
          change.value = Boolean(change.value);
          break;
      }
      delete change.type;
    });

    return changes;
  };

  const validateChanges = (changes: CellChange[]) => {
    const result = changes.filter((change) =>
      change.newCell?.validator ? change.newCell?.validator(change.newCell?.text) : true
    );
    return result.filter((change) =>
      change.type === "dropdown" ? change.newCell.selectedValue !== change.previousCell.selectedValue : true
    );
  };

  const parseChanges = (changes: CellChange[], isUndo?: boolean) => {
    const dedupedChanges = uniqWith(changes, (a, b) => `${a.columnId}${a.rowId}` === `${b.columnId}${b.rowId}`);
    const validatedChanges = validateChanges(dedupedChanges);
    if (changes[0].type === "dropdown" && changes.length === 1) {
      if (changes[0].newCell.isOpen) {
        setOpenedDropdownLocation({rowId: changes[0].rowId, columnId: changes[0].columnId});
      } else {
        setOpenedDropdownLocation(null);
      }
    }
    if (validatedChanges.length === 0) {
      return [];
    }

    const formattedChanges = formatChanges(validatedChanges);
    // console.log("formatted", formattedChanges);
    const sanitizedChanges = sanitizeChanges(formattedChanges);
    // console.log("sanitized", sanitizedChanges);
    const transformedChanges = transformList(sanitizedChanges);
    // console.log("transformed", transformedChanges);
    if (!isUndo) {
      setChangesMade([...changesMade, dedupedChanges]);
    }
    return transformedChanges;
  };

  const undoLastChange = () => {
    const reverted = changesMade
      .at(-1)
      .map((change) => ({...change, newCell: change.previousCell, previousCell: change.newCell}));
    onChange(parseChanges(reverted, true));
  };

  // const headerRow: Row<{
  //   type: "tooltipHeader";
  //   text: string;
  //   style?: {overflow: string};
  //   tooltip: string;
  // }> = {
  //   rowId: "header",

  //   cells: cellData.map((data) => {
  //     return {
  //       type: "tooltipHeader",
  //       text: data?.headerLabel,
  //       style: {overflow: "auto"},
  //       tooltip: data?.headerTooltip || "",
  //     };
  //   }),
  //   height: 40,
  // };

  // Extend the BaseCell type to include the new properties
  interface TooltipHeaderCell extends Omit<HeaderCell, "type"> {
    type: "tooltipHeader";
    text: string;
    style?: {overflow: string};
    tooltip: string;
  }

  // Use the new type in your component
  const headerRow: Row<TooltipHeaderCell> = {
    rowId: "header",
    cells: cellData.map((data) => ({
      type: "tooltipHeader",
      text: data?.headerLabel,
      style: {overflow: "auto"},
      tooltip: data?.headerTooltip || "",
    })),
    height: 40,
  };

  const getRows = () => {
    return [
      headerRow,
      ...data.map((object, idx) => ({
        rowId: object.id,
        cells: cellData.map((data) => {
          const builtCell = data.buildCell(object);
          if (builtCell.type === "dropdown") {
            builtCell.isOpen =
              openedDropdownLocation?.rowId === object.id && openedDropdownLocation?.columnId === data.columnId;
          }
          return {...builtCell};
        }),
      })),
    ];
  };

  const getColumns = (): Column[] => cellData.map((data) => Object.assign({}, data));

  const constructedColumns = React.useMemo(() => getColumns(), [JSON.stringify(cellData)]);

  const handleColumnResize = (ci, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = {...resizedColumn, width};
      const newColumns = [...prevColumns];
      newColumns[columnIndex] = updatedColumn;
      return newColumns;
    });
  };
  return (
    // <div ref={reactGridContainer}>
    <div
      onKeyDown={(e) => {
        if ((!isMacOs && e.ctrlKey) || e.metaKey) {
          switch (e.key) {
            case "z":
              if (changesMade.length > 0) undoLastChange();
              return;
          }
        }
      }}
    >
      <_ReactGrid
        columns={columns || constructedColumns}
        rows={getRows()}
        onCellsChanged={(changes) => {
          if (!onChange) {
            return;
          }
          const parsedChanges = parseChanges(changes);
          if (parsedChanges.length === 0) return;
          onChange(parsedChanges);
        }}
        onColumnResized={handleColumnResize}
        customCellTemplates={{...customCellTemplates, tooltipHeader: new TooltipHeaderCellTemplate()}}
        {...rest}
      />
      {/* </div> */}
      {/* <div>{reactGridContainerWidth}</div> */}
    </div>
  );
};

ReactGridV2.formatters = {
  date: new Intl.DateTimeFormat("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}),
  time: new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }),
  currency: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }),
  currencyNoDecimals: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }),
};

export default ReactGridV2;
