import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React from "react";
import {usePagination, useSortBy, useTable} from "react-table";
import {Pagination} from "./Pagination";
import PaperPanel from "./PaperPanel";

// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/material-UI-kitchen-sink?file=/src/components/EnhancedTable.js
export const ReactTableMui = (props) => {
  const {
    columns,
    data,
    initialState,
    containerComponent,
    hover = false,
    showFooter = false,
    useSortBy: _useSortBy = true,
    usePagination: _usePagination = false,
    containerStyle = {},
    getRowProps = () => {},
    getHeaderProps = () => {},
    columnWidths = {},
    getCellProps = () => {},
    onSortBy = () => {},
    rightAlignColumns = [],
    ...rest
  } = props;

  let reactTableHooks = [];
  if (_useSortBy) {
    reactTableHooks.push(useSortBy);
  }
  if (_usePagination) {
    reactTableHooks.push(usePagination);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    ...reactTableHooks
  );
  const tableRowIterator = _usePagination ? page : rows;

  const _getHeaderProps = (column) => {
    const headerProps = getHeaderProps(column);
    let widthProps = {};
    if (Object.keys(columnWidths).includes(column.id)) {
      widthProps = {style: {width: `${columnWidths[column.id]}px`}};
    }
    return {...widthProps, ...headerProps};
  };

  React.useEffect(() => {
    // console.log("sort by changed", state.sortBy, state);
    onSortBy ? onSortBy(state.sortBy, rows) : null;
  }, [state.sortBy]);

  return (
    <>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageCount,
              canNextPage,
              canPreviousPage,
              pageOptions,
              state: state,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <TableContainer component={containerComponent} style={containerStyle}>
        <Table {...getTableProps()} {...rest}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      {..._getHeaderProps(column)}
                      // align={rightAlignColumns.includes(column.id) ? "right" : "left"}
                    >
                      <Box display="flex" alignItems="center" lineHeight="1rem">
                        <Box flexGrow={rightAlignColumns.includes(column.id) ? 1 : 0} />
                        <Box textAlign={rightAlignColumns.includes(column.id) ? "right" : "left"}>
                          {column.render("Header")}
                        </Box>
                        {!column.disableSortBy && column.isSorted && (
                          <Box>
                            <TableSortLabel
                              active={column.isSorted}
                              // react-table has a unsorted state which is not treated here
                              direction={column.isSortedDesc ? "desc" : "asc"}
                            />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {tableRowIterator.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} {...getRowProps(row)} hover={hover}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        {...getCellProps(cell)}
                        align={rightAlignColumns.includes(cell.column.id) ? "right" : "left"}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          {showFooter && (
            <TableFooter>
              {footerGroups.map((group) => (
                <TableRow {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <TableCell
                      {...column.getFooterProps()}
                      style={{color: "inherit", fontWeight: "bold"}}
                      align={rightAlignColumns.includes(column.id) ? "right" : "left"}
                    >
                      {column.render("Footer")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {_usePagination && pageCount > 1 && (
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          {/* <PaginationWithPageSize
            count={pageCount}
            page={state.pageIndex + 1}
            pageSize={state.pageSize}
            setPage={(page) => {
              gotoPage(page - 1);
            }}
            setPageSize={setPageSize}
          /> */}
          <Pagination
            count={pageCount}
            page={state.pageIndex + 1}
            setPage={(page) => {
              gotoPage(page - 1);
            }}
          />
        </PaperPanel.Footer>
      )}
    </>
  );
};

export const ReactTableMuiCheckboxSelector = (props: {
  id: number | string;
  selected?: boolean;
  onAddSelected: (id: number | string, shift: boolean) => void;
  onRemoveSelected: (id: number | string) => void;
  // All other props
  [rest: string]: any;
}) => {
  const {id, selected, onAddSelected, onRemoveSelected, ...rest} = props;
  return (
    <Checkbox
      size="small"
      style={{width: "20px", padding: 0}}
      {...rest}
      checked={selected}
      onChange={(event, value) => {
        if (value) {
          // @ts-ignore
          onAddSelected(id, event.nativeEvent.shiftKey);
        } else {
          onRemoveSelected(id);
        }
      }}
    />
  );
};
