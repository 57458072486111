import {faLink, faTrash} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Tooltip, Typography} from "@material-ui/core";
import Block from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {colorError, colorSuccess} from "../../../theme/colors";
import getSafetyFormComponentFromType from "../../../utils/getSafetyFormComponentFromType";
import {ApproveFormDialog, RejectFormDialog, ReviseResubmitDialog} from "./ProjectSafetyFormForms";

const ProjectSafetyFormDetail = (props) => {
  const {project, ...rest} = props;
  const {safetyFormId, formOrigin} = useParams();
  const blockUI = useBlockUI();

  const [showApprovalConfirmationDialog, setShowApprovalConfirmationDialog] = React.useState(false);
  const [showReviseResubmitDialog, setShowReviseResubmitDialog] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [showRejectConfirmationDialog, setShowRejectConfirmationDialog] = React.useState(false);

  const {
    query: safetyFormQuery,
    rpc: safetyFormRPC,
    delete: deleteSafetyForm,
  } = useSentinelDetailAPI(`/projects/${project.id}/safety/permits/${formOrigin}/${safetyFormId}/`, {
    initialData: {},
  });
  const safetyForm = safetyFormQuery.data;

  if (!safetyFormQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Safety Permit Info..." />;
  }

  const FormComponent = getSafetyFormComponentFromType(safetyForm.form_type);
  const isComplete = safetyForm.status === "complete";
  const isApproved = safetyForm.status === "approved";
  return (
    <>
      <Helmet title={`${safetyForm.form_type_display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/safety/permits/`}>
          <Typography color="textSecondary">Safety Permits</Typography>
        </MuiNavLink>

        <Typography color="textPrimary">{safetyForm.form_type_display}</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={safetyForm.isFetching}>
          <PaperPanel.Header.Title>
            <StatusLabel hint="projectSafetyForm" status={safetyForm.status_with_expiration_display} />{" "}
            {safetyForm.form_type_display} - {safetyForm.date}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip
                title={
                  isComplete ? "Reject Permit" : safetyForm.status === "approved" ? "Permit Is Already Approved" : ""
                }
              >
                <div>
                  <PaperPanel.Header.Button
                    onClick={(event) => {
                      if (safetyForm.can_revise_resubmit) {
                        setShowReviseResubmitDialog(true);
                      } else {
                        setShowRejectConfirmationDialog(true);
                      }
                    }}
                    disabled={!isComplete}
                    startIcon={<Block color={isComplete ? "error" : "disabled"} />}
                  >
                    Reject
                  </PaperPanel.Header.Button>
                </div>
              </Tooltip>

              <Box mr={1} />
              <Tooltip
                title={
                  safetyForm.isApproved
                    ? "Permit Is Already Approved"
                    : isComplete
                      ? ""
                      : "Permit Must Be Completed By Sub First"
                }
              >
                <div>
                  <PaperPanel.Header.Button
                    onClick={(event) => {
                      setShowApprovalConfirmationDialog(true);
                    }}
                    disabled={!isComplete}
                    startIcon={<CheckIcon style={{color: isComplete ? colorSuccess : ""}} />}
                  >
                    Approve
                  </PaperPanel.Header.Button>
                </div>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <Tooltip title={safetyForm.status === "approved" ? "" : "Permit Is Not Approved"}>
                <div>
                  <PaperPanel.Header.PDFButton
                    href={`/reports2/external/projects/${project.uuid}/safety/permits/${safetyForm.uuid}?hide_inspection=true`}
                    target="_blank"
                    disabled={safetyForm.status !== "approved"}
                    title="Get Permit"
                  />
                </div>
              </Tooltip>

              <Box mr={1} />
              <PaperPanel.Header.PDFButton href={safetyForm.report_url} target="_blank" />
              <Box mr={1} />
              <PaperPanel.Header.Button
                href={safetyForm.url}
                target="_blank"
                startIcon={<FontAwesomeIcon icon={faLink} fixedWidth />}
              >
                Original Permit
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.BoxFilesButton
                href={`/v2/projects/${project.id}/box/files/superintendents/${formOrigin}safetyform/${safetyForm.id}/`}
                uploadURL={`projects/${project.id}/safety/permits/${formOrigin}/${safetyForm.id}/upload-to-box/`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <Tooltip
                      title={
                        safetyForm.is_closed ? "You can not delete a closed permit." : "Permanently delete this permit."
                      }
                    >
                      <div>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={(event) => {
                            setShowDeleteConfirmation(true);
                            popupState.close();
                          }}
                          disabled={safetyForm.is_closed}
                        >
                          <FontAwesomeIcon icon={faTrash} color={!safetyForm.is_closed ? colorError : ""} fixedWidth />
                          Delete Form
                        </PaperPanel.Header.Menu.MenuItem>
                      </div>
                    </Tooltip>
                  </div>
                )}
              </PaperPanel.Header.Menu>
              <PaperPanel.Header.RefreshButton
                onClick={() => safetyFormQuery.refetch()}
                isFetching={safetyFormQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body px={2}>
          <FormComponent
            safetyForm={safetyForm}
            onSuccess={(values) => {
              // console.log(values);
            }}
            internalDetailView
          />
        </PaperPanel.Body>
      </PaperPanel>
      <ApproveFormDialog
        isOpen={showApprovalConfirmationDialog}
        handleClose={() => setShowApprovalConfirmationDialog(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");

          safetyFormRPC.mutateAsync({action: "approve", data: values}).then(() => {
            safetyFormQuery.refetch().then(() => {
              blockUI.unblockUI();
              setShowApprovalConfirmationDialog(false);
            });
          });
        }}
        formTypeDisplay={safetyForm.form_type_display}
      />

      <RejectFormDialog
        isOpen={showRejectConfirmationDialog}
        handleClose={() => setShowRejectConfirmationDialog(false)}
        formTypeDisplay={safetyForm.form_type_display}
        twilioContact={safetyForm?.twilio_contact}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          safetyFormRPC.mutateAsync({action: "reject", data: values}).then(() => {
            safetyFormQuery.refetch().then(() => {
              blockUI.unblockUI();
              setShowRejectConfirmationDialog(false);
            });
          });
        }}
      />

      <ReviseResubmitDialog
        isOpen={showReviseResubmitDialog}
        handleClose={() => setShowReviseResubmitDialog(false)}
        formTypeDisplay={safetyForm?.form_type_display}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          safetyFormRPC.mutateAsync({action: "mark-not-approved", data: values}).then(() => {
            safetyFormQuery.refetch().then(() => {
              blockUI.unblockUI();
              setShowReviseResubmitDialog(false);
            });
          });
        }}
        twilioContact={safetyForm?.twilio_contact}
      />

      <ConfirmationDialog
        isOpen={showDeleteConfirmation}
        onDeny={() => setShowDeleteConfirmation(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteSafetyForm.mutateAsync().then(() => {
            window.location.href = `/v2/projects/${project.id}/safety/permits/`;
          });
        }}
      >
        You want to permanently delete this safety form? All entered data will be lost. This can not be undone.
      </ConfirmationDialog>
      {/* <h2>Safety Form</h2>
      <pre>{JSON.stringify(safetyForm, null, 2)}</pre> */}
    </>
  );
};

export default ProjectSafetyFormDetail;
