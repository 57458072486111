import {AppBar, Box, Grid, Link, MenuItem, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect, FilterSwitch} from "../../components/Filters";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import safetyReviewStatusChoices from "../../components/forms/choices/safetyReviewStatusChoices.json";

import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TabContext, TabPanel} from "@material-ui/lab";
import {groupBy} from "lodash";
import {Helmet} from "react-helmet";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {LabelValue} from "../../components/LabelValue";
import {PaperItem} from "../../components/PaperItem";
import {TabCountChip} from "../../components/Tabs";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";
import {PSRTable} from "../projects/ProjectSafetyReview/SafetyReviewComponents";
import {useLocation} from "react-router";

export const PSRDashboard = (props) => {
  const {...rest} = props;

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showComments, setShowComments] = React.useState(true);
  const [reviewDateRange, setReviewDateRange] = React.useState<number | "">("");
  const [questionDateRange, setQuestionDateRange] = React.useState<number | "">("");
  const [selectedTab, setSelectedTab] = React.useState("psrs");
  const novoClasses = makeNovoClasses();
  const [reviewFilterOptions, setReviewFilterOption, clearReviewFilterOption, clearAllReviewFilterOptions] =
    useFilterOptions(["Status", "Search", "Start Date", "End Date", "Created By"], {
      "Start Date": {
        value: moment().format("YYYY-MM-DD"),
        label: moment().format("YYYY-MM-DD"),
      },
      "End Date": {
        value: moment().add(1, "days").format("YYYY-MM-DD"),
        label: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    });

  const [
    noncompliantItemsFilterOptions,
    setNoncompliantItemsFilterOption,
    clearNoncompliantItemsFilterOption,
    clearAllNoncompliantItemsFilterOptions,
  ] = useFilterOptions(["Status", "Search", "Start Date", "End Date"], {
    // "Start Date": {
    //   value: moment().format("YYYY-MM-DD"),
    //   label: moment().format("YYYY-MM-DD"),
    // },
    // "End Date": {
    //   value: moment().add(1, "days").format("YYYY-MM-DD"),
    //   label: moment().add(1, "days").format("YYYY-MM-DD"),
    // },
  });

  const reviewFilterParams = {
    q: reviewFilterOptions.Search.value,
    status: reviewFilterOptions.Status.value,
    date_min: reviewFilterOptions["Start Date"].value,
    date_max: reviewFilterOptions["End Date"].value,
    created_by: reviewFilterOptions["Created By"].value,
  };
  const {query: safetyReviewQuery} = useSentinelListAPI(
    `safety/project-safety-reviews/?page_size=${pageSize}&page=${page}&${qs.stringify(reviewFilterParams)}`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const safetyReviews = safetyReviewQuery.data.results;

  const {query: createdByQuery} = useSentinelListAPI(`safety/project-safety-reviews/created-by/`, {initialData: []});

  const createdBy = createdByQuery.data;

  const createdByFilterOptions = createdBy.map((user) => ({label: user.full_name, value: user.id}));

  const nonCompliantFilterParams = {
    q: noncompliantItemsFilterOptions.Search.value,
    status: noncompliantItemsFilterOptions.Status.value,
    date_min: noncompliantItemsFilterOptions["Start Date"].value,
    date_max: noncompliantItemsFilterOptions["End Date"].value,
  };

  const {query: noncompliantItemsQuery} = useSentinelListAPI(
    `/safety/noncompliant-psr-questions/?${qs.stringify(nonCompliantFilterParams)}`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const noncompliantItems = noncompliantItemsQuery.data.results;

  const groupedByCompany = groupBy(noncompliantItems, (item) => {
    const companyName = item.responsible_company ? item.responsible_company.name : "No Responsible Company";
    const projectName = item.safety_review_project.name;
    return `${companyName}_${projectName}`;
  });

  const groupedNonCompliantItems = Object.keys(groupedByCompany).reduce((result, key) => {
    const [companyName, projectName] = key.split("_");
    result[companyName] = result[companyName] || {};
    result[companyName][projectName] = groupedByCompany[key];
    return result;
  }, {});

  const {pathname} = useLocation();
  const isDashboard = pathname.split("/v2/")[1] === "safety/psr-dashboard/";

  return (
    <>
      {isDashboard && (
        <>
          <Helmet title="Project Safety Review Dashboard" />
          <Breadcrumbs>
            <Link href="/v2/dashboard/">
              <Typography color="textSecondary">Dashboard</Typography>
            </Link>
            <Typography color="textPrimary">PSR Dashboard</Typography>
          </Breadcrumbs>
        </>
      )}
      <TabContext value={selectedTab}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  PSRs <TabCountChip count={safetyReviewQuery.data.count} isLoading={safetyReviewQuery.isFetching} />
                </Box>
              }
              value="psrs"
              className={novoClasses.smallTab}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  Noncompliant Questions
                  <TabCountChip
                    count={noncompliantItemsQuery.data.count}
                    isLoading={noncompliantItemsQuery.isFetching}
                  />
                </Box>
              }
              value="noncompliant"
              className={novoClasses.smallTab}
            />
          </Tabs>
        </AppBar>
        <Box mb={2} />
        <TabPanel value="psrs">
          <PaperPanel.TabHeader isLoading={safetyReviewQuery.isFetching}>
            <PaperPanel.Header.Title>Project Safety Reviews</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <Tooltip title={safetyReviews.length < 1 ? "No PSRs Displayed" : ""}>
                  <Box>
                    <PaperPanel.Header.Button
                      startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                      onClick={() => {
                        saveExcel(
                          safetyReviews.map((psr) => {
                            return {
                              Status: psr.status_display,
                              "Review Date/Time": {
                                v: psr.review_datetime,
                                t: "d",
                                z: "[$-en-US]YYYY-MM-DD h:mm AM/PM;@",
                              },
                              Project: psr.project.name,
                              "Reviewed By": psr.created_by.full_name,
                              "Noncompliant Items": psr.noncompliant_count,
                              Comments: psr.comments,
                            };
                          }),
                          `Project Safety Reviews`,
                          [{wch: 10}, {wch: 20}, {wch: 60}, {wch: 16}, {wch: 17}, {wch: 255}]
                        );
                      }}
                      disabled={safetyReviewQuery.isFetching || safetyReviews.length < 1}
                    >
                      Get Excel
                    </PaperPanel.Header.Button>
                  </Box>
                </Tooltip>
                <Box ml={1} mr={-1}>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      safetyReviewQuery.refetch();
                    }}
                    isFetching={safetyReviewQuery.isFetching}
                  />
                </Box>
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.TabHeader>
          <Box mx={-3} mt={-2} mb={-3}>
            <PaperPanel.Toolbar p={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FilterSearch
                    label="Search"
                    value={reviewFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setReviewFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    label="Status"
                    name="Status"
                    options={safetyReviewStatusChoices}
                    value={reviewFilterOptions["Status"].value}
                    onChange={(value, label) => {
                      setPage(1);
                      setReviewFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    name="Created By"
                    label="Created By"
                    options={createdByFilterOptions}
                    value={reviewFilterOptions["Created By"].value}
                    onChange={(value, label) => {
                      setPage(1);
                      setReviewFilterOption("Created By", value, label);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FilterDate
                    name="Start Date"
                    value={reviewFilterOptions["Start Date"].value}
                    label="Start Date"
                    onChange={(date) => {
                      setPage(1);
                      setReviewFilterOption("Start Date", date, date);
                      setReviewDateRange("");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterDate
                    name="End Date"
                    value={reviewFilterOptions["End Date"].value}
                    label="End Date"
                    onChange={(date) => {
                      setPage(1);
                      setReviewFilterOption("End Date", date, date);
                      setReviewDateRange("");
                    }}
                    disableFuture={false}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterSelect
                    name="Date Range"
                    label="Date Range"
                    value={reviewDateRange}
                    options={[
                      {label: "Today", value: 0},
                      {label: "Last Week", value: 7},
                      {
                        label: "Last 2 Weeks",
                        value: 14,
                      },
                      {
                        label: "Last Month",
                        value: 30,
                      },
                    ]}
                    onChange={(value, label) => {
                      const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                      const endDate = moment().add(1, "days").format("YYYY-MM-DD");
                      setPage(1);
                      setReviewFilterOption("Start Date", startDate, startDate);
                      setReviewFilterOption("End Date", endDate, endDate);
                      setReviewDateRange(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterSwitch
                    name="comments"
                    label="Show Comments"
                    checked={showComments}
                    onChange={(value, label) => {
                      setShowComments((show) => !show);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FilterOptionChips
                    filterOptions={reviewFilterOptions}
                    onDelete={(key) => {
                      clearReviewFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllReviewFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination count={safetyReviewQuery.data.total_pages} page={page} setPage={setPage} />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.Toolbar>
            <PaperPanel.Body>
              <PSRTable safetyReviews={safetyReviews} showComments={showComments} />
            </PaperPanel.Body>
            {safetyReviewQuery.data.total_pages > 1 && (
              <PaperPanel.Footer display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={safetyReviewQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </PaperPanel.Footer>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="noncompliant">
          <PaperPanel.TabHeader isLoading={noncompliantItemsQuery.isFetching}>
            <PaperPanel.Header.Title>Noncompliant Questions</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.PDFDropdownButton>
                  {(setOpen) => (
                    <MenuItem
                      component={Link}
                      href={`/reports2/safety/noncompliant-psr-questions-by-company/?${qs.stringify(
                        nonCompliantFilterParams
                      )}`}
                      target="_blank"
                      onClick={() => setOpen(false)}
                    >
                      By Responsible Company
                    </MenuItem>
                  )}
                </PaperPanel.Header.PDFDropdownButton>
              </PaperPanel.Header.Action>
              <PaperPanel.Header.Action border mr={-1}>
                <PaperPanel.Header.RefreshButton
                  onClick={() => {
                    noncompliantItemsQuery.refetch();
                  }}
                  isFetching={noncompliantItemsQuery.isFetching}
                />
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.TabHeader>
          <Box mx={-3} mt={-2} mb={-3}>
            <PaperPanel.Toolbar p={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FilterSearch
                    label="Search"
                    value={noncompliantItemsFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setNoncompliantItemsFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterDate
                    name="Start Date"
                    value={noncompliantItemsFilterOptions["Start Date"].value}
                    label="Start Date"
                    onChange={(date) => {
                      setPage(1);
                      setNoncompliantItemsFilterOption("Start Date", date, date);
                      setQuestionDateRange("");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterDate
                    name="End Date"
                    value={noncompliantItemsFilterOptions["End Date"].value}
                    label="End Date"
                    onChange={(date) => {
                      setPage(1);
                      setNoncompliantItemsFilterOption("End Date", date, date);
                      setQuestionDateRange("");
                    }}
                    disableFuture={false}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterSelect
                    name="Date Range"
                    label="Date Range"
                    allowNull
                    value={questionDateRange}
                    options={[
                      {label: "Today", value: 0},
                      {label: "Last Week", value: 7},
                      {
                        label: "Last 2 Weeks",
                        value: 14,
                      },
                      {
                        label: "Last Month",
                        value: 30,
                      },
                    ]}
                    onChange={(value, label) => {
                      const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                      const endDate = moment().add(1, "days").format("YYYY-MM-DD");
                      setPage(1);
                      setNoncompliantItemsFilterOption("Start Date", startDate, startDate);
                      setNoncompliantItemsFilterOption("End Date", endDate, endDate);
                      setQuestionDateRange(value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FilterOptionChips
                    filterOptions={noncompliantItemsFilterOptions}
                    onDelete={(key) => {
                      clearNoncompliantItemsFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllNoncompliantItemsFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination count={noncompliantItemsQuery.data.total_pages} page={page} setPage={setPage} />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.Toolbar>
            <PaperPanel.Body>
              {Object.keys(groupedNonCompliantItems).map((companyName) => {
                return (
                  <React.Fragment key={companyName}>
                    <Box mt={2} />
                    <PaperItem.HeaderLabel
                      label={`${companyName} - ${
                        Object.keys(groupedNonCompliantItems[companyName]).length
                      } Noncompliant Questions`}
                    />
                    {Object.keys(groupedNonCompliantItems[companyName]).map((projectName) => {
                      return (
                        <React.Fragment key={projectName}>
                          <PaperItem.HeaderLabel labelVariant="h6" label={projectName} />
                          {groupedNonCompliantItems[companyName][projectName].map((item) => {
                            return (
                              <PaperItem key={item.id}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={3}>
                                    <LabelValue
                                      label="Review"
                                      value={
                                        <Link
                                          href={`${item.safety_review_project.url}safety/project-safety-reviews/${item.safety_review}/`}
                                          target="_blank"
                                          underline="always"
                                        >
                                          {moment(item.safety_review_datetime).format("LLLL")}
                                        </Link>
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <LabelValue label="Noncompliant Question" value={item.question} />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <LabelValue label="Comment" value={item.comment} />
                                  </Grid>
                                </Grid>
                              </PaperItem>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </PaperPanel.Body>
          </Box>
        </TabPanel>
      </TabContext>
    </>
  );
};
