import {Box, Breadcrumbs, FormControl, Link, MenuItem, Select, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {axiosAPI} from "../../api";
import {DropdownButton} from "../../components/Buttons";
import {ConfirmationDialog} from "../../components/Dialogs";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {EmployeeReviewCircularProgress, sortByPercentComplete} from "./EmployeeReviewComponents";

const getSelfReviewComponent = (review) => {
  if (!review) {
    return null;
  }
  if (review.is_manual) {
    return (
      <Box display="flex" alignItems="center">
        <Box>
          <StatusLabel status={review.status_display} />
        </Box>
        <Box ml={1}>
          <Link underline="always" href={review.url} target="_blank">
            View Paper Review
          </Link>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box>
          <StatusLabel status={review.status_display} />
        </Box>
        <Box ml={1}>
          <Link underline="always" href={review.report_url} target="_blank">
            Get PDF
          </Link>
        </Box>
        <Box ml={1}>
          <EmployeeReviewCircularProgress percent={review.percent_complete} />
        </Box>
      </Box>
    </>
  );
};

const getManagerReviewComponent = (review, year, employeeId) => {
  if (!review) {
    return (
      <MuiNavLink underline="always" href={`/v2/employees/reviews/manager/${year}/${employeeId}/`}>
        Create Evaluation
      </MuiNavLink>
    );
  }
  if (review.is_closed) {
    return (
      <Box display="flex" alignItems="center">
        <Box>
          <StatusLabel status={review.status_display} />
        </Box>
        <Box ml={1}>
          <Link underline="always" href={review.report_url} target="_blank">
            Get PDF
          </Link>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box>
          <StatusLabel status={review.status_display} />
        </Box>
        <Box ml={1}>
          <Link underline="always" href={review.url} target="_blank">
            Finish Evaluation
          </Link>
        </Box>
        <Box ml={1}>
          <EmployeeReviewCircularProgress percent={review.percent_complete} />
        </Box>
      </Box>
    </>
  );
};

const EmployeeReviewManagerDashboard: React.FC = (props) => {
  const [activeEmployee, setActiveEmployee] = React.useState({} as any);
  const [showUploadPaperEvaluationConfirmation, setShowUploadPaperEvaluationConfirmation] = React.useState(false);
  const navigate = useNavigate();
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const params = useParams();
  const {year} = params;

  const title = `${year} Employee Evaluation Manager Dashboard`;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      question_set_choices: [],
      employees_with_reviews: [],
      prior_years: [],
    },
  });
  const pageData = pageDataQuery.data;
  const employeesWithReviews = pageData.employees_with_reviews;

  const dashboardColumns = React.useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "full_name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Self Evaluation",
        accessor: "self_review",
        disableSortBy: true,
        Cell: ({row, value}) => {
          if (!value) {
            return (
              <>
                <Link
                  underline="always"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setActiveEmployee(row.original);
                    setShowUploadPaperEvaluationConfirmation(true);
                  }}
                >
                  Upload Paper Review
                </Link>
              </>
            );
          }
          return getSelfReviewComponent(value);
        },
        sortType: (prev, curr, columnId) => sortByPercentComplete(prev, curr),
      },
      {
        Header: "Manager Evaluation",
        accessor: "manager_review",
        disableSortBy: true,
        Cell: ({row, value}) => {
          return getManagerReviewComponent(value, year, row.original.id);
        },
        sortType: (prev, curr, columnId) => sortByPercentComplete(prev, curr),
      },
    ],
    []
  );

  // if (!pageDataQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching Reviews..." />;
  // }

  return (
    <>
      <Helmet title={title} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        <Typography>Employee Evaluations</Typography>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>{title}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <DropdownButton title="Blank Review PDF" size="small" fullWidth>
                {(setOpen) => (
                  <div>
                    {pageData.question_set_choices.map((choice) => {
                      return (
                        <MenuItem
                          key={choice.year}
                          onClick={() => {
                            window.open(
                              `/reports2/employees/self-review/blank/?question_set=${choice.value}`,
                              "_blank"
                            );
                            setOpen(false);
                          }}
                        >
                          {choice.description}
                        </MenuItem>
                      );
                    })}
                  </div>
                )}
              </DropdownButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <FormControl variant="outlined" size="small">
                <Select value={year}>
                  {pageData.prior_years.map((year) => {
                    return (
                      <MenuItem
                        component={MuiNavLink}
                        key={year}
                        value={year}
                        underline="none"
                        dense
                        href={`/v2/employees/reviews/manager-dashboard/${year}/`}
                      >
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body>
          {employeesWithReviews.length === 0 && pageDataQuery.isFetchedAfterMount ? (
            <PaperPanel.Alert severity="info">
              You have no employees that you are setup to manage. If you feel this is an error please contact{" "}
              <Link href="mailto:lliang@novoconstruction.com" underline="always">
                Lei Liang
              </Link>
            </PaperPanel.Alert>
          ) : (
            <ReactTableMui
              size="small"
              className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
              columns={dashboardColumns}
              initialState={{
                sortBy: [
                  {
                    id: "full_name",
                    desc: false,
                  },
                ],
              }}
              data={employeesWithReviews}
            />
          )}
          {/* <pre>{JSON.stringify(activeEmployee, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(employeesWithReviews, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={`/employees/reviews/manager-dashboard/${year}/`} mt={2} />

      <ConfirmationDialog
        isOpen={showUploadPaperEvaluationConfirmation}
        onApprove={() => {
          blockUI.blockUI("Removing collaboration...");
          setShowUploadPaperEvaluationConfirmation(false);
          axiosAPI
            .post(`/employees/reviews/self/${year}/`, {
              is_manual: true,
              employee_id: activeEmployee.id,
            })
            .then((response) => {
              blockUI.unblockUI();
              setShowUploadPaperEvaluationConfirmation(false);
              navigate(`/v2/employees/reviews/self/${year}/${response.data.id}/`);
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        onDeny={() => {
          setShowUploadPaperEvaluationConfirmation(false);
        }}
      >
        You want to upload a paper evaluation for {activeEmployee.full_name}?
        <br />
        To do this you need to:
        <ol>
          <li>Download the blank review PDF</li>
          <li>Have the PDF filled out out the PDF</li>
          <li>Upload the completed PDF</li>
        </ol>
      </ConfirmationDialog>
    </>
  );
};

export default EmployeeReviewManagerDashboard;
