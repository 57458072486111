import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import aiAnalysisTypes from "../../components/forms/choices/aiAnalysisTypes.json";
import {SimpleAutoselectMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";

export const AIAnalysisCreateDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  fieldSize?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                <Grid container justifyContent="space-between">
                  <Grid item>Create AI Analysis</Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SimpleAutoselectMui
                        name="type"
                        label="Analysis Type"
                        options={aiAnalysisTypes}
                        required
                        // helperText="TODO: add text explaining analysis types"
                      />
                      {/* <pre>{JSON.stringify(aiAnalysisTypes, null, 2)}</pre> */}
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.CreateButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
