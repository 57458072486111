import {faCheck, faPaperPlane, faReply, faUpRightFromSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {Breadcrumbs, ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import BlockUI from "../../components/GlobalLoaders";
import {StatusIcon} from "../../components/Icons";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import useUser from "../../hooks/useUser";
import {makeNovoClasses} from "../../theme";
import {WorkPlanAnswers, WorkPlanCommunicationPaperItem} from "./WorkPlanComponents";
import {WorkPlanCommunicationDialogForm, WorkPlanForm} from "./WorkPlanForms";
import {colorError, colorSuccess} from "../../theme/colors";

const WorkPlanDetail = (props) => {
  const {project, ...rest} = props;

  const [createCommunicationDialogOpen, setCreateCommunicationDialogOpen] = React.useState(false);
  const [communicationToFlash, setCommunicationToFlash] = React.useState(null);
  const [communicationRecipientDefault, setCommunicationRecipientDefault] = React.useState({});
  const [editCommunicationDialogIsOpen, setEditCommunicationDialogIsOpen] = React.useState(false);
  const [activeCommunication, setActiveCommunication] = React.useState(null);
  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [submitWorkPlanDialogIsOpen, setSubmitWorkPlanDialogIsOpen] = React.useState(false);
  const [approveWorkPlanDialogIsOpen, setApproveWorkPlanDialogIsOpen] = React.useState(false);
  const [reviseResubmitWorkPlanDialogIsOpen, setReviseResubmitWorkPlanDialogIsOpen] = React.useState(false);

  const {workPlanId} = useParams();
  const {pathname} = useLocation();
  const user = useUser();

  const apiPath = pathname.split("/v2/")[1];

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {communicationUserDefaults: {}},
  });
  const pageData = pageDataQuery.data;

  const {communicationUserDefaults, workPlanDocuments, canApprove} = pageData;

  const {
    query: workPlanQuery,
    update: updateWorkPlan,
    rpc: workPlanRPC,
  } = useSentinelDetailAPI(`${apiPath}`, {
    initialData: [],
  });
  const workPlan = workPlanQuery.data;

  const {
    query: communicationQuery,
    update: updateCommunication,
    create: createCommunication,
    delete: deleteCommunication,
  } = useSentinelListAPI(`safety/work-plans/${workPlanId}/communications/`, {
    initialData: {results: []},
  });

  const communications = communicationQuery.data.results;

  const {
    query: answerQuery,
    delete: deleteAnswer,
    create: createAnswer,
  } = useSentinelListAPI(`safety/work-plans/${workPlanId}/answers/`, {
    initialData: {results: []},
    refetchOnWindowFocus: false,
  });

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const refreshQueries = () =>
    Promise.all([
      workPlanQuery.refetch(),
      communicationQuery.refetch(),
      answerQuery.refetch(),
      pageDataQuery.refetch(),
    ]);

  React.useEffect(() => {
    if (communicationQuery.isFetchedAfterMount) {
      setCommunicationRecipientDefault(communicationUserDefaults.recipient);
    }
  }, [pageDataQuery.isFetchedAfterMount]);

  if (
    !workPlanQuery.isFetchedAfterMount ||
    !communicationQuery.isFetchedAfterMount ||
    !pageDataQuery.isFetchedAfterMount
  ) {
    return <BlockUI />;
  }

  return (
    <>
      {project ? (
        <>
          <Helmet title={`${workPlan.project.display} - ${workPlan.type_display}`} />

          <ProjectBreadcrumbs project={project}>
            <Typography color="textSecondary">Safety</Typography>

            <MuiNavLink href={`/v2/projects/${project.id}/safety/work-plans/`}>
              <Typography color="textSecondary">Work Plans</Typography>
            </MuiNavLink>
            <Typography color="textPrimary">Work Plan Detail</Typography>
          </ProjectBreadcrumbs>
        </>
      ) : (
        <>
          <Helmet title={`${workPlan.type_display}`} />

          <Breadcrumbs>
            <MuiNavLink href="/v2/dashboard/">
              <Typography color="textSecondary">Dashboard</Typography>
            </MuiNavLink>

            <Typography color="textSecondary">Safety</Typography>

            <MuiNavLink href="/v2/safety/work-plans/">
              <Typography color="textSecondary">Work Plans</Typography>
            </MuiNavLink>
            <Typography color="textPrimary">Work Plan Detail</Typography>
          </Breadcrumbs>
        </>
      )}
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon
                      status={workPlan.status}
                      tooltip={workPlan.status_display}
                      showTooltip
                      type="workPlan"
                    />
                    <Box ml={1}>{workPlan.type_display}</Box>
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />

              <Tab
                label="AI Review"
                value="questions"
                className={novoClasses.smallTab}
                disabled={workPlanDocuments.length < 1}
              />

              <Tab label="Communications" value="communications" className={novoClasses.smallTab} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
        </PaperPanel>
        <TabPanel value="general">
          <>
            <PaperPanel.TabHeader isLoading={workPlanQuery.isFetching}>
              <PaperPanel.Header.Title>
                <StatusLabel status={workPlan.status_display} hint="workPlan" /> Work Plan Detail
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                {canApprove && (
                  <>
                    <PaperPanel.Header.Action>
                      <Tooltip title="Approve Work Plan">
                        <span>
                          <PaperPanel.Header.Button
                            onClick={() => {
                              setApproveWorkPlanDialogIsOpen(true);
                            }}
                            startIcon={<FontAwesomeIcon icon={faCheck} color={colorSuccess} />}
                          >
                            Approve Work Plan
                          </PaperPanel.Header.Button>
                        </span>
                      </Tooltip>
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action>
                      <Tooltip title="Mark Revise Resubmit">
                        <span>
                          <PaperPanel.Header.Button
                            onClick={() => {
                              setReviseResubmitWorkPlanDialogIsOpen(true);
                            }}
                            startIcon={<FontAwesomeIcon icon={faReply} color={colorError} />}
                          >
                            Mark Revise Resubmit
                          </PaperPanel.Header.Button>
                        </span>
                      </Tooltip>
                    </PaperPanel.Header.Action>
                  </>
                )}
                {workPlan.can_submit && (
                  <PaperPanel.Header.Action>
                    <Tooltip
                      title={workPlanDocuments.length < 1 ? "You must upload a work plan first" : "Submit for Review"}
                    >
                      <span>
                        <PaperPanel.Header.Button
                          onClick={() => setSubmitWorkPlanDialogIsOpen(true)}
                          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                          disabled={workPlanDocuments.length < 1}
                        >
                          Submit Work Plan
                        </PaperPanel.Header.Button>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                )}
                {workPlanDocuments.length > 0 && (
                  <PaperPanel.Header.Action>
                    <Tooltip title="View Work Plan On Box">
                      <span>
                        <PaperPanel.Header.Button
                          href={workPlanDocuments[0].box_url}
                          target="_blank"
                          startIcon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                        >
                          View Work Plan
                        </PaperPanel.Header.Button>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                )}
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      refreshQueries();
                    }}
                    isFetching={workPlanQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <WorkPlanForm
              initialValues={workPlan}
              onSubmit={(values) => {
                blockUI.blockUI("Saving Work Plan...");
                updateWorkPlan.mutateAsync(values).then(() => {
                  blockUI.unblockUI();
                });
              }}
              onUpload={() => {
                return refreshQueries();
              }}
              workPlan={workPlan}
              workPlanDocuments={workPlanDocuments}
            />
          </>
        </TabPanel>
        <TabPanel value="questions">
          <>
            <PaperPanel.TabHeader isLoading={workPlanQuery.isFetching}>
              <PaperPanel.Header.Title>AI Review</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      refreshQueries();
                    }}
                    isFetching={workPlanQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body mx={-3} mt={-2}>
              <WorkPlanAnswers
                workPlan={workPlan}
                answerQuery={answerQuery}
                onDelete={(id) => deleteAnswer.mutateAsync(id)}
                onCreate={(values) => createAnswer.mutateAsync({question: values.question, work_plan: workPlan.id})}
              />
            </PaperPanel.Body>
          </>
        </TabPanel>
        <TabPanel value="communications">
          <>
            <PaperPanel.TabHeader isLoading={workPlanQuery.isFetching}>
              <PaperPanel.Header.Title>Communications</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton onClick={() => setCreateCommunicationDialogOpen(true)} />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      Promise.all([workPlanQuery.refetch(), communicationQuery.refetch(), answerQuery.refetch()]);
                    }}
                    isFetching={workPlanQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body mx={-3} mt={-2}>
              {communications.map((communication) => {
                return (
                  <WorkPlanCommunicationPaperItem
                    communication={communication}
                    key={communication.id}
                    flash={communication.id === communicationToFlash}
                    onClickReply={() => {
                      setCommunicationRecipientDefault(communication.created_by);
                      setCreateCommunicationDialogOpen(true);
                    }}
                    replyTo={
                      communication.created_by && user.id !== communication.created_by.id
                        ? communication.created_by
                        : null
                    }
                    onEdit={() => {
                      setActiveCommunication(communication);
                      setEditCommunicationDialogIsOpen(true);
                    }}
                  />
                );
              })}
            </PaperPanel.Body>
          </>
        </TabPanel>
      </TabContext>

      <WorkPlanCommunicationDialogForm
        isNew
        initialValues={{
          created_by: communicationUserDefaults.created_by,
          recipient: communicationRecipientDefault ?? communicationUserDefaults.recipient,
          work_plan: workPlanId,
        }}
        isOpen={createCommunicationDialogOpen}
        handleClose={() => {
          setCommunicationRecipientDefault(communicationUserDefaults.recipient);
          setCreateCommunicationDialogOpen(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating Work Plan Communication...");
          createCommunication.mutateAsync(values).then((response) => {
            blockUI.unblockUI();
            setCreateCommunicationDialogOpen(false);
            setCommunicationToFlash(response.id);
          });
        }}
      />

      <WorkPlanCommunicationDialogForm
        initialValues={activeCommunication}
        isOpen={editCommunicationDialogIsOpen}
        handleClose={() => {
          setEditCommunicationDialogIsOpen(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Saving Work Plan Communication...");
          updateCommunication.mutateAsync(values).then((response) => {
            blockUI.unblockUI();
            setEditCommunicationDialogIsOpen(false);
            setCommunicationToFlash(response.id);
          });
        }}
      />

      <ConfirmationDialog
        isOpen={submitWorkPlanDialogIsOpen}
        onDeny={() => setSubmitWorkPlanDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Submitting Work Plan...");
          workPlanRPC.mutateAsync({action: "submit"}).then(() => {
            workPlanQuery.refetch().then(() => {
              blockUI.unblockUI();
              setSubmitWorkPlanDialogIsOpen(false);
            });
          });
        }}
      >
        You want to submit this work plan for review? You will not be able to edit it until the review is complete.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={approveWorkPlanDialogIsOpen}
        onDeny={() => setApproveWorkPlanDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Approving Work Plan...");
          workPlanRPC.mutateAsync({action: "approve"}).then(() => {
            Promise.all([workPlanQuery.refetch(), pageDataQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setApproveWorkPlanDialogIsOpen(false);
            });
          });
        }}
      >
        You want to approve this work plan? Ensure that all information is complete and correct before approving. The
        work plan will be read-only after approval.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={reviseResubmitWorkPlanDialogIsOpen}
        onDeny={() => setReviseResubmitWorkPlanDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Resubmitting Work Plan...");
          workPlanRPC.mutateAsync({action: "revise-resubmit"}).then(() => {
            Promise.all([workPlanQuery.refetch(), pageDataQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setReviseResubmitWorkPlanDialogIsOpen(false);
            });
          });
        }}
      >
        You want to mark this work plan revise-resubmit? The super will be able to edit the work plan and resubmit it
        for review.
      </ConfirmationDialog>
    </>
  );
};

export default WorkPlanDetail;
