import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, FormControl, Grid, Link, MenuItem, Select, Tooltip, Typography} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import LaunchIcon from "@material-ui/icons/Launch";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../api";
import {DropdownButton} from "../../components/Buttons";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch} from "../../components/Filters";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import {PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorError} from "../../theme/colors";
import {EmployeeReviewCircularProgress, sortByPercentComplete} from "./EmployeeReviewComponents";
import {PageHeader} from "../../components/PageHeader";

const getSelfReviewComponent = (review) => {
  if (!review) {
    return null;
  }
  if (review.is_manual) {
    return (
      <Box display="flex" alignItems="center">
        <Box>
          <StatusLabel status={review.status_display} />
        </Box>
        <Box ml={1}>
          <Link underline="always" href={review.url} target="_blank">
            View Paper Review
          </Link>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box>
          <StatusLabel status={review.status_display} />
        </Box>
        <Box ml={1}>
          <Link underline="always" href={review.report_url} target="_blank">
            Get PDF
          </Link>
        </Box>
        <Box ml={1}>
          <EmployeeReviewCircularProgress percent={review.percent_complete} />
        </Box>
      </Box>
    </>
  );
};

const getManagerReviewComponent = (review) => {
  if (!review) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center">
      <Box>
        <StatusLabel status={review.status_display} />
      </Box>
      <Box ml={1}>
        <Link underline="always" href={review.report_url} target="_blank">
          Get PDF
        </Link>
      </Box>
      <Box ml={1}>
        <EmployeeReviewCircularProgress percent={review.percent_complete} />
      </Box>
    </Box>
  );
};

const getManagerMismatchComponent = (employeeWithReviews, type) => {
  const review = employeeWithReviews[type];
  if (!review) {
    return null;
  }
  if (!employeeWithReviews?.manager?.id) {
    return null;
  }
  if (employeeWithReviews?.manager?.id === review?.manager?.id) {
    return null;
  }
  return (
    <Box ml={1}>
      <Tooltip title={`Manager mismatch ${review?.manager?.full_name}`}>
        <ErrorIcon style={{color: colorError}} />
      </Tooltip>
    </Box>
  );
};

const EmployeeReviewHRDashboard: React.FC = (props) => {
  // useWhyDidYouUpdate("EmployeeReviewHRDashboard", props);
  const {user} = props as any;
  const [activeEmployee, setActiveEmployee] = React.useState({} as any);
  const [showUploadPaperEvaluationConfirmation, setShowUploadPaperEvaluationConfirmation] = React.useState(false);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const navigate = useNavigate();
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const params = useParams();
  const {year} = params;
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Search"]);
  const pageSizes = [100, 200, 1000];

  const filterParams = {
    q: filterOptions.Search.value,
  };

  const title = `${year} Employee Evaluation HR Dashboard`;

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        stats: {},
        question_set_choices: [],
        employees_with_reviews: {
          results: [],
        },
        prior_years: [],
      },
    },
    {...filterParams, page_size: pageSize, page: page}
  );
  const pageData = pageDataQuery.data;
  const employeesWithReviews = pageData.employees_with_reviews.results;
  const stats = pageData.stats;

  const dashboardColumns = React.useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "full_name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Manger",
        accessor: (value) => {
          return value.manager?.full_name;
          // return value?.manager_review?.manager?.full_name
          //   ? value?.manager_review?.manager?.full_name
          //   : value.manager?.full_name;
        },
      },
      {
        Header: "Self Evaluation",
        accessor: "self_review",
        disableSortBy: true,
        Cell: ({row, value}) => {
          if (!value) {
            return (
              <>
                <Link
                  underline="always"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setActiveEmployee(row.original);
                    setShowUploadPaperEvaluationConfirmation(true);
                  }}
                >
                  Upload Paper Review
                </Link>
              </>
            );
          }
          return (
            <>
              <Box display="flex" alignItems="center">
                <Box>{getSelfReviewComponent(value)}</Box>
                {getManagerMismatchComponent(row.original, "self_review")}
                {user.is_superuser && value?.id && (
                  <>
                    <Box ml={1}>
                      <Link
                        href={`/admin/employees/employeeselfreview/${value.id}/change/`}
                        target="_blank"
                        underline="none"
                      >
                        <FontAwesomeIcon icon={faExternalLink} />
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
            </>
          );
        },
        sortType: (prev, curr, columnId) => sortByPercentComplete(prev, curr),
      },
      {
        Header: "Manager Evaluation",
        accessor: "manager_review",
        disableSortBy: true,
        Cell: ({row, value}) => {
          return (
            <>
              <Box display="flex" alignItems="center">
                <Box>{getManagerReviewComponent(value, year, row.original.id)}</Box>
                {getManagerMismatchComponent(row.original, "self_review")}
                {user.is_superuser && value?.id && (
                  <Box ml={1}>
                    <Link
                      href={`/admin/employees/employeemanagerreview/${value.id}/change/`}
                      target="_blank"
                      underline="none"
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </Box>
                )}
              </Box>
            </>
          );
        },
        sortType: (prev, curr, columnId) => sortByPercentComplete(prev, curr),
      },
    ],
    []
  );

  // if (!pageDataQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching Reviews..." />;
  // }

  return (
    <>
      <Helmet title={title} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        <Typography>Employee Evaluations</Typography>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.RightSpark title="Self Reviews" value={stats.selfReviewCount} />
          <PageHeader.Right.RightSpark title="Pending" value={stats.selfReviewPendingCount} />
          <PageHeader.Right.RightSpark title="Submitted" value={stats.selfReviewSubmittedCount} />
          <PageHeader.Right.RightSpark title="Manger Reviews" value={stats.managerReviewCount} border />
          <PageHeader.Right.RightSpark title="Pending" value={stats.managerReviewPendingCount} />
          <PageHeader.Right.RightSpark title="Submitted" value={stats.managerReviewSubmittedCount} />
        </PageHeader.Right>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>{title}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <DropdownButton title="Blank Review PDF" size="small" fullWidth>
                {(setOpen) => (
                  <div>
                    {pageData.question_set_choices.map((choice) => {
                      return (
                        <MenuItem
                          key={choice.year}
                          onClick={() => {
                            window.open(
                              `/reports2/employees/self-review/blank/?question_set=${choice.value}`,
                              "_blank"
                            );
                            setOpen(false);
                          }}
                        >
                          {choice.description}
                        </MenuItem>
                      );
                    })}
                  </div>
                )}
              </DropdownButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                to={`/v2/employees/roster/`}
                component={MuiNavLink}
                target="_blank"
                underline="none"
                endIcon={<LaunchIcon />}
              >
                Employee Roster
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <FormControl variant="outlined" size="small">
                <Select value={year}>
                  {pageData.prior_years.map((year) => {
                    return (
                      <MenuItem
                        component={MuiNavLink}
                        key={year}
                        value={year}
                        underline="none"
                        dense
                        href={`/v2/employees/reviews/hr-dashboard/${year}/`}
                      >
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={pageData.employees_with_reviews.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={pageSizes}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            columns={dashboardColumns}
            initialState={{
              sortBy: [
                {
                  id: "full_name",
                  desc: false,
                },
              ],
            }}
            data={employeesWithReviews}
          />
          {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(activeEmployee, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(employeesWithReviews, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(pageData.employees_with_reviews, null, 2)}</pre> */}
        </PaperPanel.Body>

        {pageData.employees_with_reviews.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={pageData.employees_with_reviews.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={pageSizes}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <LegacyUILink href={`/employees/reviews/hr-dashboard/${year}/`} mt={2} />

      <ConfirmationDialog
        isOpen={showUploadPaperEvaluationConfirmation}
        onApprove={() => {
          blockUI.blockUI("Removing collaboration...");
          setShowUploadPaperEvaluationConfirmation(false);
          axiosAPI
            .post(`/employees/reviews/self/${year}/`, {
              is_manual: true,
              employee_id: activeEmployee.id,
            })
            .then((response) => {
              blockUI.unblockUI();
              setShowUploadPaperEvaluationConfirmation(false);
              navigate(`/v2/employees/reviews/self/${year}/${response.data.id}/`);
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        onDeny={() => {
          setShowUploadPaperEvaluationConfirmation(false);
        }}
      >
        You want to upload a paper evaluation for {activeEmployee.full_name}?
        <br />
        To do this you need to:
        <ol>
          <li>Download the blank review PDF</li>
          <li>Have the PDF filled out out the PDF</li>
          <li>Upload the completed PDF</li>
        </ol>
      </ConfirmationDialog>
    </>
  );
};

export default EmployeeReviewHRDashboard;
