import {Box} from "@material-ui/core";

import LaunchIcon from "@material-ui/icons/Launch";
import qs from "query-string";
import {useLocation, useParams} from "react-router";

import {BoxIframeAndDropzone} from "../../../../../components/Box";

import React from "react";
import BlockUI from "../../../../../components/GlobalLoaders";
import PaperPanel from "../../../../../components/PaperPanel";
import {useFetchCurrentPage} from "../../../../../hooks/useSentinelAPI";

const ExternalRFIDetail = (props) => {
  const {contact, project, ...rest} = props;
  const params = useParams();
  const location = useLocation();
  const boxIframeRef = React.useRef(null);
  const {contactUUID, projectUUID, UUID} = params;
  const qsValues = qs.parse(location.search);

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        isFollowing: false,
      },
    },
    {
      token: qsValues.token,
    }
  );
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching info..." />;
  }

  return (
    <>
      {/* <Helmet title={`${project.name} - ${rfi.display}`} /> */}

      {pageData.boxSharedFolderId && (
        <>
          <Box mb={1} />
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Box Files/Upload</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                {pageData.boxSharedFolderLink && (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      href={pageData.boxSharedFolderLink}
                      target="_blank"
                      endIcon={<LaunchIcon />}
                      // variant="text"
                    >
                      Box Site
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                )}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => pageDataQuery.refetch()}
                    isFetching={pageDataQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <BoxIframeAndDropzone
                sharedFolderId={pageData.boxSharedFolderId}
                uploadURL={`${location.pathname}?token=${qsValues.token}`}
              />
              {/* <BoxIFrame ref={boxIframeRef} sharedFolderId={pageData.boxSharedFolderId} />
              <DropzoneMui
                getUploadParams={() => {
                  return {
                    url: `.`,
                    headers: {"X-CSRFToken": Cookies.get("csrftoken")},
                  };
                }}
                timeout={1000 * 60 * 5}
                // accept="image/*,application/pdf"
                onChangeStatus={(event) => {
                  if (event.meta.status === "done") {
                    boxIframeRef.current.src = boxIframeRef.current.src;
                  }
                }}
              /> */}
            </PaperPanel.Body>
          </PaperPanel>
        </>
      )}

      <h1>Page Data</h1>
      <pre>{JSON.stringify(location, null, 2)}</pre>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <pre>{JSON.stringify(params, null, 2)}</pre>
      <pre>{JSON.stringify(props, null, 2)}</pre>
    </>
  );
};

export default ExternalRFIDetail;
