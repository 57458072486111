import {faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useQueryState} from "use-location-state";
import {Currency, Percentage} from "../../components/Accounting";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import accountStatuses from "../../components/forms/choices/bonusAccountStatuses.json";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import usePermissions from "../../hooks/usePermissions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {BonusAccountDialogForm} from "./BonusForms";

const BonusAccountsList = (props) => {
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [activeBonusAccount, setActiveBonusAccount] = React.useState({});
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const novoClasses = makeNovoClasses();
  const navigate = useNavigate();

  const createFormRef: any = React.createRef();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Status"],
    {
      Status: {
        value: "active",
        label: "Active",
      },
    }
  );

  const filterParams = {
    q: filterOptions.Search.value,
    status: filterOptions.Status.value,
  };

  const {
    query: bonusAccountsQuery,
    create: createBonusAccount,
    update: updateBonusAccount,
    delete: deleteBonusAccount,
    rpc: bonusAccountRPC,
  } = useSentinelListAPI(`bonuses/accounts/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  return (
    <>
      <Helmet title="Bonus Accounts" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Bonus Accounts</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Accounts
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>

      <Box my={1}>
        <Alert severity="info">Coming Soon.</Alert>
      </Box>

      <PaperPanel>
        <PaperPanel.Header isLoading={bonusAccountsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Accounts
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {permissions?.can_manage_bonuses && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.CreateButton
                  onClick={() => {
                    setShowCreateDialog(true);
                  }}
                >
                  Create
                </PaperPanel.Header.CreateButton>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={bonusAccountsQuery.isFetching}
                onClick={() => bonusAccountsQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                // native
                label="Status"
                name="Status"
                options={accountStatuses}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={bonusAccountsQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["amount"]}
            columns={React.useMemo(
              () => [
                {
                  Header: "Description",
                  accessor: "description",
                  Cell: ({value, row}) => {
                    return (
                      <MuiNavLink to={`/v2/bonuses/accounts/${row.original.id}/`} underline="always">
                        {value}
                      </MuiNavLink>
                    );
                  },
                },
                {
                  Header: "Status",
                  accessor: "status_display",
                },
                {
                  Header: "User",
                  accessor: "user.full_name",
                },
                {
                  Header: "Default %",
                  accessor: "default_bonus_percent",
                  Cell: ({value, row}) => <Percentage number={value / 100} precision={3} />,
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  Cell: ({value, row}) => <Currency number={value} />,
                },
              ],
              []
            )}
            initialState={{
              sortBy: [
                {
                  id: "description",
                  desc: false,
                },
              ],
            }}
            data={bonusAccountsQuery.data.results}
          />
          {bonusAccountsQuery.data.total_pages > 1 && (
            <PaperPanel.Footer display="flex" justifyContent="flex-end">
              <PaginationWithPageSize
                count={bonusAccountsQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSizes={[100, 250, 500, 1000]}
              />
            </PaperPanel.Footer>
          )}
        </PaperPanel.Body>
      </PaperPanel>

      <BonusAccountDialogForm
        ref={createFormRef}
        isNew
        isOpen={showCreateDialog}
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{
          default_bonus_percent: 5.0,
          is_active: true,
        }}
        onSubmit={(values, form) => {
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndClose") {
            setShowCreateDialog(false);
            createBonusAccount.mutateAsync(values).then(() => {
              bonusAccountsQuery.refetch();
            });
          }
          if (submitMode === "editAfterSubmit") {
            blockUI.blockUI("Creating...");
            createBonusAccount.mutateAsync(values).then((newAccount) => {
              blockUI.unblockUI();
              setShowCreateDialog(false);
              navigate(newAccount.url);
            });
          }
          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");
            const focusElement = createFormRef.current.elements.user;
            createBonusAccount.mutateAsync(values).then(() => {
              form.restart();
              focusElement.focus();
              bonusAccountsQuery.refetch().then(() => blockUI.unblockUI());
            });
          }
        }}
      />

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default BonusAccountsList;
