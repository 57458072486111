import {Box, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Form as FinalForm, FormSpy} from "react-final-form";
import {Helmet} from "react-helmet";
import {useQuery} from "react-query";
import {useLocation} from "react-router";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {SimpleAutoselectMui} from "../../../components/forms/Fields";
import {ProjectStatusIcon} from "../../../components/Icons";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";

const AgingByPM = (props) => {
  const [projectManager, setProjectManager] = React.useState(null);
  const {pathname} = useLocation();
  const novoClasses = makeNovoClasses();
  const agingQuery = useQuery(
    [projectManager],
    () =>
      axiosAPI
        .get(`${pathname}?type=ajax&project_manager=${projectManager ? projectManager : ""}`, {baseURL: ""})
        .then((res) => res.data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: {
        jobs: [],
        projectManagers: [],
      },
    }
  );
  // let projectManagers = agingQuery.data.projectManagers;
  const projectManagers = [
    // {label: "", value: ""},
    {label: "All", value: "all"},
  ];
  agingQuery.data.projectManagers.map((projectManager) =>
    projectManagers.push({label: projectManager, value: projectManager})
  );

  return (
    <>
      <Helmet title={`Aging By PM`} />
      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography color="textPrimary">Aging By PM</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Aging By PM</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Box pb={5}>
        <PaperPanel>
          <PaperPanel.Header isLoading={agingQuery.isFetching}>
            <PaperPanel.Header.Title>Aging By PM</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.PDFButton
                  target="_blank"
                  href={`/reports2/accounting-tools/aging-by-pm/?project_manager=${
                    projectManager ? projectManager : ""
                  }`}
                />
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.Header>
          <PaperPanel.Body p={1} border={1} borderColor="grey.400" borderTop={0} borderLeft={0} borderRight={0}>
            <FinalForm
              onSubmit={(values) => {
                setProjectManager(values.project_manager);
              }}
              initialValues={{
                project_manager: projectManager,
              }}
            >
              {({handleSubmit, form, submitting, pristine, values}) => {
                return (
                  <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                    <SimpleAutoselectMui
                      label="Project Manager"
                      name="project_manager"
                      size="small"
                      options={projectManagers}
                    />
                    <FormSpy
                      onChange={(event) => {
                        form.submit();
                      }}
                      subscription={{values: true}}
                    />
                  </form>
                );
              }}
            </FinalForm>
          </PaperPanel.Body>
          <PaperPanel.Body>
            <ReactTableMui
              size="small"
              showFooter={Boolean(agingQuery.data.jobs.length)}
              className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
              // getCellProps={(cell) => {
              //   console.log("@!!!!", cell);
              // }}
              rightAlignColumns={["aging_0", "aging_30", "aging_60", "aging_90", "receivable_balance"]}
              columns={[
                {
                  Header: "Job",
                  accessor: "job",
                  Cell: ({value, row}) => (
                    <>
                      <ProjectStatusIcon project={row.original} showTooltip />{" "}
                      <Link href={`/timberline/jobs/${row.original.pk}/`}>{row.original.job}</Link>
                    </>
                  ),
                },
                {
                  Header: "Project Manager",
                  accessor: "project_manager",
                },
                {
                  Header: "Current",
                  accessor: "aging_0",
                  Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
                  Footer: () => {
                    const total = React.useMemo(
                      () => agingQuery.data.jobs.reduce((sum, row) => row.aging_0 + sum, 0),
                      [agingQuery.data.jobs]
                    );
                    return total ? <Currency number={total} /> : "";
                  },
                },
                {
                  Header: "30",
                  accessor: "aging_30",
                  Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
                  Footer: () => {
                    const total = React.useMemo(
                      () => agingQuery.data.jobs.reduce((sum, row) => row.aging_30 + sum, 0),
                      [agingQuery.data.jobs]
                    );
                    return total ? <Currency number={total} /> : "";
                  },
                },
                {
                  Header: "60",
                  accessor: "aging_60",
                  Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
                  Footer: () => {
                    const total = React.useMemo(
                      () => agingQuery.data.jobs.reduce((sum, row) => row.aging_60 + sum, 0),
                      [agingQuery.data.jobs]
                    );
                    return total ? <Currency number={total} /> : "";
                  },
                },
                {
                  Header: "90",
                  accessor: "aging_90",
                  Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
                  Footer: () => {
                    const total = React.useMemo(
                      () => agingQuery.data.jobs.reduce((sum, row) => row.aging_90 + sum, 0),
                      [agingQuery.data.jobs]
                    );
                    return total ? <Currency number={total} /> : "";
                  },
                },
                {
                  Header: "Receivables",
                  accessor: "receivable_balance",
                  Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
                  Footer: () => {
                    const total = React.useMemo(
                      () => agingQuery.data.jobs.reduce((sum, row) => row.receivable_balance + sum, 0),
                      [agingQuery.data.jobs]
                    );
                    return total ? <Currency number={total} /> : "";
                  },
                },
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "project_manager",
                  },
                ],
              }}
              data={agingQuery.data.jobs}
            />
          </PaperPanel.Body>
        </PaperPanel>
      </Box>
      {/* <pre>{JSON.stringify(agingQuery, null, 2)}</pre> */}
    </>
  );
};
export default AgingByPM;
