import {Box, Chip, Grid, Tooltip, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import pluralize from "pluralize";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useSelectIds} from "../../../../js/hooks";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";

const PCOtoCOR = (props) => {
  const {project, ...rest} = props;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      pcos: [],
    },
  });

  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const {pcos} = pageData;

  const allItemIds = pcos.map((pco) => pco.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching PCOs..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Select Subcontract`} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>

        <Typography color="textPrimary">PCO to COR</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Title>Select PCOs</PageHeader.Title>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedItemIds(allItemIds);
                } else {
                  removeAllSelectedItemIds();
                }
              }}
              indeterminate={Boolean(
                !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
              )}
              checked={allItemIdsSelected}
            />
            PCOs
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip
                title={
                  selectedItemIds.size
                    ? `Convert the Selected ${pluralize("PCO", selectedItemIds.size)} to ${pluralize(
                        "COR",
                        selectedItemIds.size
                      )}`
                    : ""
                }
              >
                <span>
                  <PaperPanel.Header.ColoredButton
                    onClick={() => {
                      blockUI.blockUI("Changing...");
                      postCurrentPage.mutateAsync({pco_ids: Array.from(selectedItemIds)}).then((response) => {
                        // window.location.href = response.url;
                        navigate(response.url);
                        blockUI.unblockUI();
                      });
                    }}
                    disabled={selectedItemIds.size < 1}
                  >
                    <Chip size="small" label={selectedItemIds.size} /> Change {pluralize("PCO", selectedItemIds.size)}{" "}
                    to {pluralize("COR", selectedItemIds.size)}
                    {/* Change PCOs to CORs */}
                  </PaperPanel.Header.ColoredButton>
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {pcos.length > 0 ? (
            pcos.map((pco) => {
              return (
                <PCOPaperItem
                  pco={pco}
                  projectId={project.id}
                  key={pco.id}
                  pcoIsSelected={selectedItemIds.has(pco.id)}
                  onChangePCOSelected={(event, value) => {
                    if (value) {
                      addSelectedItemId(pco.id, event.nativeEvent.shiftKey);
                    } else {
                      removeSelectedItemId(pco.id);
                    }
                  }}
                />
              );
            })
          ) : (
            <Alert severity="warning">
              <AlertTitle>No PCO Found</AlertTitle>To convert a PCO to a COR it must not be an internal PCO and it must
              not already have a COR.
            </Alert>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={1}>
        <LegacyUILink href={`/projects/${project.id}/pcos/pco-cor-wizard/`} />
      </Box>
      {/* <h3>PageData</h3>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default PCOtoCOR;

const PCOPaperItem = (props) => {
  const {pco, projectId, pcoIsSelected, onChangePCOSelected, ...rest} = props;

  return (
    <PaperItem alignItems="center">
      <PaperItem.Left minWidth={25}>
        <PaperItem.SelectedCheckbox checked={pcoIsSelected} onChange={onChangePCOSelected} />
      </PaperItem.Left>
      <PaperItem.Body mr={{xs: 0.5, sm: 0}}>
        <Grid container>
          <Grid item xs={11}>
            <StatusLabel status={pco.status_display}></StatusLabel>

            <Box mr={1} component="span" />
            <MuiNavLink href={`/v2/projects/${pco.project_id}/pcos/${pco.id}/`} underline="always">
              {pco.display}
            </MuiNavLink>
          </Grid>
          <Grid item xs={1}>
            <Currency number={pco.budget_approved_amount} />
          </Grid>
        </Grid>
      </PaperItem.Body>
    </PaperItem>
  );
};
