import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Chip} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import accounting from "accounting";
import {NavLink} from "react-router";

export const PreviousNextTabs = (props: {
  previous?: {
    url: string;
    number: string;
  };
  next?: {
    url: string;
    number: string;
  };
}) => {
  const {previous, next} = props;
  return (
    <Box display="flex" flexGrow={1} alignItems="center" justifyContent="flex-end" mr={1}>
      {previous && (
        <Button size="small" component={NavLink} to={previous.url} startIcon={<ArrowBackIcon />}>
          {previous.number}
        </Button>
      )}
      {next && (
        <Button size="small" component={NavLink} to={next.url} endIcon={<ArrowForwardIcon />}>
          {next.number}
        </Button>
      )}
    </Box>
  );
};

export const TabCountChip = (props: {isLoading: boolean; count: number | string; [rest: string]: any}) => {
  const {isLoading, count, ...rest} = props;
  const label = Number(count) ? accounting.formatNumber(count) : count;

  return (
    <Box ml={1} {...rest}>
      <Chip size="small" label={isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : label} />
    </Box>
  );
};
