import {faFilePdf} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterDate} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorError, colorSuccess} from "../../../theme/colors";
import {NonCompliantItemCountLabel, PrevNextMondayArrows} from "./DailySafetyInspectionComponents";
import {CreateInspectionDialogForm} from "./DailySafetyInspectionForms";

import {useNavigate} from "react-router";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../../api";

const MOMENTFORMAT = "YYYY-MM-DD h:mm A";

const DailySafetyInspections = (props) => {
  const {project, user, ...rest} = props;
  const mondayThisWeek = moment().startOf("week").add(1, "days").format("YYYY-MM-DD");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(500);
  const [weekOf, setWeekOf] = useQueryState("week-of", mondayThisWeek);
  const [showCreateInspectionDialog, setShowCreateInspectionDialog] = React.useState(false);
  const [inspectionInitialValues, setInspectionInitialValues] = React.useState({
    inspection_datetime: moment(),
    shift: moment().format("a"),
  });

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Week Of"], {
    "Week Of": {value: weekOf, label: weekOf},
  });
  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        datesWithMissingInspections: {},
      },
    },
    // {week_of: filterOptions["Week Of"].value}
    {week_of: weekOf}
  );
  const pageData = pageDataQuery.data;

  const novoClasses = makeNovoClasses();
  const navigate = useNavigate();
  const blockUI = useBlockUI();
  const filterParams = {
    // week_of: filterOptions["Week Of"].value,
    week_of: weekOf,
  };
  const {
    query: inspectionQuery,
    // create: createInspection,
    update: updateInspection,
    delete: deleteInspection,
  } = useSentinelListAPI(
    `projects/${project.id}/safety/inspections/?page_size=${pageSize}&page=${page}&parent__isnull=True&${qs.stringify(
      filterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
    // {
    //   disableOnSuccess: true,
    //   disableOnMutate: true,
    // }
  );

  const inspections = inspectionQuery.data.results;

  if (!inspectionQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching Safety Inspections...`} />;
  }
  const {datesWithMissingInspections} = pageData;
  const missingInspections = [];
  Object.keys(datesWithMissingInspections).forEach((date) => {
    const missingShifts = datesWithMissingInspections[date];
    if (missingShifts.length === 0) {
      return;
    }
    if (moment(date).isAfter(moment())) return;
    return missingShifts.map((shift) => {
      missingInspections.push({
        inspection_datetime: date,
        shift_display: shift,
        status_display: "Missing",
        status: "missing",
      });
    });
  });

  const inspectionsWithMissing = [...missingInspections, ...inspections];

  return (
    <>
      <Helmet title={`Daily Safety Inspections`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textSecondary">Safety</Typography>

        <Typography color="textPrimary">Daily Safety Inspections</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.RightSpark
            title="Missing Inspections"
            value={`${missingInspections.length}`}
            color={missingInspections.length > 0 ? colorError : colorSuccess}
          />
          <PageHeader.Right.RightSpark
            title="Incomplete Inspections"
            value={`${inspections.filter((inspection) => inspection.status === "pending").length}`}
            border
            color={
              inspections.filter((inspection) => inspection.status === "pending").length ? colorError : colorSuccess
            }
          />
        </PageHeader.Right>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Daily Safety Inspections</PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setInspectionInitialValues({
                    inspection_datetime: moment(),
                    shift: moment().format("a"),
                  });
                  setShowCreateInspectionDialog(true);
                }}
              >
                Create Inspection
              </PaperPanel.Header.CreateButton>
              <Box ml={1}>
                <PaperPanel.Header.PDFButton
                  href={`/reports2/projects/${project.id}/safety/inspections/?week_of=${filterParams.week_of}`}
                  target="_blank"
                />
              </Box>
              <Box ml={1} mr={-1}>
                <PaperPanel.Header.RefreshButton
                  onClick={inspectionQuery.refetch}
                  isFetching={inspectionQuery.isFetching}
                />
              </Box>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={6}>
              <PrevNextMondayArrows
                // weekOfDate={filterOptions["Week Of"].value}
                weekOfDate={weekOf}
                setDate={(value) => {
                  setPage(1);
                  setWeekOf(value);
                  // setFilterOption("Week Of", value, value);
                }}
              >
                <FilterDate
                  label="Week Of"
                  // value={filterOptions["Week Of"].value}
                  value={weekOf}
                  name="Week Of"
                  onChange={(value) => {
                    setPage(1);
                    setWeekOf(value);
                    // setFilterOption("Week Of", value, value);
                  }}
                />
              </PrevNextMondayArrows>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            getRowProps={(row) => {
              const evenDate = moment(row.original.inspection_datetime).date() % 2 === 0;

              if (evenDate) {
                return {
                  style: {
                    backgroundColor: grey[100],
                  },
                };
              }
              return {};
            }}
            columns={[
              {
                Header: "Status",
                accessor: "status_display",
                Cell: ({row, value}) => <StatusLabel status={row.original.status_display} />,
              },
              {
                Header: "Inspection Date/Time",
                accessor: "inspection_datetime",
                Cell: ({row, value}) => {
                  if (row.original.status === "missing") {
                    return (
                      <Tooltip title="Create Missing Inspection">
                        <Link
                          underline="always"
                          onClick={() => {
                            const suggestedTime = moment(value).add(
                              row.original.shift_display === "AM" ? 8 : 17,
                              "hours"
                            );
                            setInspectionInitialValues({
                              inspection_datetime: suggestedTime,
                              shift: suggestedTime.format("a"),
                            });
                            setShowCreateInspectionDialog(true);
                          }}
                          href="#"
                        >
                          {value}
                        </Link>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <MuiNavLink to={row.original.url} underline="always">
                        {moment(value).format(MOMENTFORMAT)}
                      </MuiNavLink>
                    );
                  }
                },
              },
              {Header: "Shift", accessor: "shift_display"},
              {
                Header: "Inspected By",
                accessor: "created_by.full_name",
              },
              {
                Header: "Noncompliant Items",
                accessor: "noncompliant_count",
                Cell: ({row, value}) => {
                  return (
                    <Box textAlign="center" maxWidth={120}>
                      <NonCompliantItemCountLabel nonCompliantItems={value} status={row.original.status} />
                    </Box>
                  );
                },
              },
              {Header: "Notes", accessor: "notes"},
              {
                accessor: "report_url",
                Cell: ({row, value}) => {
                  return (
                    value && (
                      <Link href={value} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </Link>
                    )
                  );
                },
              },
            ]}
            data={inspectionsWithMissing}
            initialState={{
              sortBy: [
                {
                  id: "inspection_datetime",
                  asc: true,
                },
                {id: "shift_display", asc: true},
              ],
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <CreateInspectionDialogForm
        isOpen={showCreateInspectionDialog}
        handleClose={() => setShowCreateInspectionDialog(false)}
        onSubmit={(values) => {
          blockUI.blockUI();
          // Not using useSentinelAPI here because it doesn't provide an option to disable on create, so the query state
          // gets out of sync. To fix this useSentinelListAPI hook would need to be updated to allow for
          // passing disableOnSuccess and disableOnMutate to the create function.
          axiosAPI.post(`projects/${project.id}/safety/inspections/`, values).then((response) => {
            setShowCreateInspectionDialog(false);
            blockUI.unblockUI();
            navigate(`/v2/projects/${project.id}/safety/inspections/${response.data.id}/`);
            // window.location.href = `/v2/projects/${project.id}/safety/inspections/${response.data.id}/`;
          });
          // createInspection.mutateAsync(values).then((response) => {
          //   setShowCreateInspectionDialog(false);
          //   window.location.href = `/v2/projects/${project.id}/safety/inspections/${response.id}/`;
          // });
        }}
        initialValues={{...inspectionInitialValues, weather_conditions: ""}}
      />
    </>
  );
};
export default DailySafetyInspections;
