import {
  faAddressBook,
  faArrowDown,
  faArrowDownToLine,
  faArrowUp,
  faArrowUpToLine,
  faCalendar,
  faCalendarClock,
  faCheckCircle,
  faEnvelopes,
  faExclamationCircle,
  faLeftRight,
  faPaperPlane,
  faPeopleGroup,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import arrayMove from "array-move";
import classnames from "classnames";
import {groupBy, orderBy} from "lodash";
import moment from "moment";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../../api";
import ActivityStream from "../../../components/ActivityStream";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {CategoryForm, ItemMinutesForm, MeetingItemDialogForm, SelectAttendeesForm} from "./MeetingDetailForms";
import {MeetingItemPaperItem} from "./MeetingDetailPaperItems";
import {AgendaDetailForm, MinutesDetailForm} from "./ProjectMeetingsForms";
import PDFAttachmentsPaperPanel from "../../../components/PDFAttachments";

const ProjectMeetingDetail = (props) => {
  const {project, isAgenda, ...rest} = props;
  const {meetingId} = useParams();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const meetingTypeString = React.useMemo(() => {
    return isAgenda ? "Agenda" : "Minutes";
  }, [isAgenda]);

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [distributeDialogIsOpen, setDistributeDialogIsOpen] = React.useState(false);
  const [createFollowupDialogIsOpen, setCreateFollowupDialogIsOpen] = React.useState(false);
  const [deleteMeetingDialogIsOpen, setDeleteMeetingDialogIsOpen] = React.useState(false);
  const [minutesDialogIsOpen, setMinutesDialogIsOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const [createItemFormIsOpen, setCreateItemFormIsOpen] = React.useState(false);
  const [editItemFormIsOpen, setEditItemFormIsOpen] = React.useState(false);
  const [deleteItemConfirmationIsOpen, setDeleteItemConfirmationIsOpen] = React.useState(false);
  const [renameCategoryDialogIsOpen, setRenameCategoryDialogIsOpen] = React.useState(false);
  const [createCategoryDialogIsOpen, setCreateCategoryDialogIsOpen] = React.useState(false);
  const [convertMeetingDialogIsOpen, setConvertMeetingDialogIsOpen] = React.useState(false);
  const [selectAttendeesDialogIsOpen, setSelectAttendeesDialogIsOpen] = React.useState(false);
  const [hideItems, setHideItems] = React.useState(false);
  const [hideNotes, setHideNotes] = React.useState(false);
  const [lastItemCategory, setLastItemCategory] = React.useState("");

  const createItemFormRef = React.createRef();

  const itemURL = `/projects/${project.id}/meetings/${parseInt(meetingId)}/items/`;

  const {
    query: meetingQuery,
    update: updateMeeting,
    delete: deleteMeeting,
    rpc: meetingRPC,
  } = useSentinelDetailAPI(["projects", project.id, "meetings", parseInt(meetingId)], {
    initialData: {},
  });
  const meeting = meetingQuery.data;

  const {
    create: categoryCreate,
    query: categoryQuery,
    update: updateCategory,
    delete: deleteCategory,
    rpc: categoryRPC,
    queryClient: categoryQueryClient,
  } = useSentinelListAPI(`/projects/${project.id}/meetings/${meetingId}/categories/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const categories = categoryQuery.data.results;

  const categoryOptions = categories.map((category) => ({value: category.id, label: category.description}));

  const {
    query: attendeeQuery,
    update: updateAttendees,
    delete: deleteAttendee,
    create: createAttendee,
  } = useSentinelListAPI(`/projects/${project.id}/meetings/${meetingId}/attendees/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });
  const attendees = attendeeQuery.data.results;

  const {
    create: itemCreate,
    query: itemsQuery,
    update: updateItem,
    delete: deleteItem,
    rpc: itemRPC,
    onDragEnd: itemOnDragEnd,
    queryClient: itemQueryClient,
  } = useSentinelListAPI(itemURL, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const items = itemsQuery.data.results;

  const itemLookup = groupBy(items, "meeting_category_id");

  const {query: contactQuery} = useSentinelListAPI(`/projects/${project.id}/contacts/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });
  const companyContacts = contactQuery.data.results;

  const handleDragDrop = (event) => {
    const {draggableId} = event;

    const movedItem = items.find((item) => item.id === parseInt(draggableId));

    const destinationId = parseInt(event.destination.droppableId);
    const sourceId = parseInt(event.source.droppableId);

    const oldData = groupBy(items, "meeting_category_id");
    let newData = {};

    if (destinationId === sourceId) {
      newData[destinationId] = arrayMove(oldData[destinationId], event.source.index, event.destination.index);

      newData[destinationId] = newData[destinationId].map((item, index) => {
        item.position = index;
        item.number_display = ("000" + (index + 1)).slice(-3);
        return item;
      });

      newData = {...oldData, ...newData};
    } else {
      newData[sourceId] = oldData[sourceId].filter((item) => item.id !== parseInt(draggableId));

      movedItem.meeting_category_id = destinationId;

      if (!oldData[destinationId]) oldData[destinationId] = [];
      newData[destinationId] = [...oldData[destinationId], movedItem];
      newData[destinationId] = arrayMove(newData[destinationId], -1, event.destination.index);
      newData[destinationId] = newData[destinationId].map((item, index) => {
        item.position = index;
        item.number_display = ("000" + (index + 1)).slice(-3);
        return item;
      });

      newData[sourceId] = newData[sourceId].map((item, index) => {
        item.position = index;
        item.number_display = ("000" + (index + 1)).slice(-3);
        return item;
      });

      newData = {...oldData, ...newData};
    }

    let result = [];
    for (let cat in newData) {
      result = [...result, ...newData[cat]];
    }

    return result;
  };

  const handleRepositionCategory = (category, newPosition) => {
    blockUI.blockUI();
    updateCategory.mutateAsync({id: category.id, position: newPosition}).then((response) => {
      Promise.all([categoryQuery.refetch(), itemsQuery.refetch()]).then(() => blockUI.unblockUI());
    });
  };

  const onRemoveAttendee = React.useCallback((attendeeId) => {
    blockUI.blockUI();
    deleteAttendee.mutateAsync(attendeeId).then(() => {
      blockUI.unblockUI();
    });
  }, []);

  if (!meetingQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Meeting info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - ${meeting.number_display} Meeting ${meetingTypeString}`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/meetings/`}>
          Meetings
        </MuiNavLink>
        <Typography color="textPrimary">
          {meeting.number_display} - {meeting.meeting_name} {meetingTypeString}
        </Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPeopleGroup} fixedWidth /> {meeting.number_display} - {meeting.meeting_name}{" "}
            {meetingTypeString}
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>

      <TabContext value={selectedTab}>
        {meeting.is_private && (
          <Box mb={2}>
            <Alert severity="info">
              This meeting is private. Only the meeting creator and scheduled attendees will be able to see the meeting.
            </Alert>
          </Box>
        )}

        {meeting.state !== meetingTypeString.toLowerCase() && (
          <Box mb={1}>
            <Alert severity="warning">
              Meeting is in {meeting.state} mode but you are viewing it in {meetingTypeString.toLowerCase()} mode.{" "}
              <MuiNavLink
                href={`/v2/projects/${project.id}/meetings/${meetingId}/${meeting.state}/#tab=${selectedTab}`}
                underline="always"
              >
                Click here
              </MuiNavLink>{" "}
              to view in {meeting.state} mode.
            </Alert>
          </Box>
        )}
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Meeting {meetingTypeString}
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Scheduled Attendees
                  </Box>
                }
                value="attendance"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Logs
                  </Box>
                }
                value="logs"
                className={novoClasses.smallTab}
              />

              {/* <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} /> */}
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={meetingQuery.isFetching}>
                <PaperPanel.Header.Title></PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.ColoredButton
                      onClick={() => setConvertMeetingDialogIsOpen(true)}
                      startIcon={<FontAwesomeIcon icon={faLeftRight} />}
                    >
                      Convert to {isAgenda ? "Minutes" : "Agenda"}
                    </PaperPanel.Header.ColoredButton>
                    <Box ml={1} />
                    <Tooltip title={isAgenda ? "Followups can only be created in Minutes mode" : ""}>
                      <span>
                        <PaperPanel.Header.ColoredButton
                          onClick={() => setCreateFollowupDialogIsOpen(true)}
                          startIcon={<FontAwesomeIcon icon={faCalendarClock} />}
                          disabled={isAgenda}
                        >
                          Create Followup Meeting
                        </PaperPanel.Header.ColoredButton>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/meetings/meeting/${meetingId}/`}
                      uploadURL={
                        isAgenda
                          ? `/projects/${project.id}/meetings/${meetingId}/upload-meeting-agenda-to-box/`
                          : `/projects/${project.id}/meetings/${meetingId}/upload-meeting-minutes-to-box/`
                      }
                    />
                    <Box ml={1} />
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/meetings/${isAgenda ? "agenda" : "minutes"}/${meetingId}/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <>
                          <PaperPanel.Header.Menu.MenuItem
                            href={`/meetings/${meeting.id}/ical/`}
                            disabled={!moment(meeting.start).isValid()}
                            component={Link}
                          >
                            <FontAwesomeIcon icon={faCalendar} fixedWidth /> Download iCal
                          </PaperPanel.Header.Menu.MenuItem>

                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              popupState.close();
                              setDistributeDialogIsOpen(true);
                            }}
                          >
                            Distribute {meetingTypeString}
                          </PaperPanel.Header.Menu.MenuItem>
                          {/* {isAgenda && (
                            <>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={() => {
                                  popupState.close();
                                  blockUI.blockUI("Fixing...");
                                  meetingRPC.mutateAsync({action: "fix-positions"}).then(() => blockUI.unblockUI());
                                }}
                              >
                                Fix Numbers
                              </PaperPanel.Header.Menu.MenuItem>
                            </>
                          )} */}
                        </>
                      )}
                    </PaperPanel.Header.Menu>

                    <PaperPanel.Header.RefreshButton
                      onClick={() => meetingQuery.refetch()}
                      isFetching={meetingQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              {isAgenda ? (
                <>
                  <AgendaDetailForm
                    projectId={project.id}
                    isReadOnly={false}
                    onSubmit={(values) => {
                      blockUI.blockUI("Saving...");
                      updateMeeting.mutateAsync(values).then(() => {
                        meetingQuery.refetch();
                        blockUI.unblockUI();
                      });
                    }}
                    isAgenda
                    initialValues={meeting}
                    onDeleteMeeting={() => {
                      setActiveItem(meeting);
                      setDeleteMeetingDialogIsOpen(true);
                    }}
                  />
                  <Box mb={2} />
                  <PDFAttachmentsPaperPanel
                    url={`projects/${project.id}/meetings/${meetingId}/pdf-attachments/`}
                    uploadURL={`/api/v1/projects/${project.id}/meetings/${meetingId}/pdf-attachments/upload/`}
                    contentType="rfis"
                    model="meetingpdfattachment"
                  />
                </>
              ) : (
                <>
                  <MinutesDetailForm
                    projectId={project.id}
                    onSubmit={(values) => {
                      blockUI.blockUI("Saving...");
                      updateMeeting.mutateAsync(values).then(() => {
                        meetingQuery.refetch();
                        blockUI.unblockUI();
                      });
                    }}
                    initialValues={meeting}
                    onDeleteMeeting={() => {
                      setActiveItem(meeting);
                      setDeleteMeetingDialogIsOpen(true);
                    }}
                  />
                  <Box mb={2} />
                  <PDFAttachmentsPaperPanel
                    url={`projects/${project.id}/meetings/${meetingId}/pdf-attachments/`}
                    uploadURL={`/api/v1/projects/${project.id}/meetings/${meetingId}/pdf-attachments/upload/`}
                    contentType="rfis"
                    model="meetingpdfattachment"
                  />
                </>
              )}
            </>
          </TabPanel>

          <TabPanel value="items">
            <>
              <PaperPanel.TabHeader isLoading={meetingQuery.isFetching}>
                <PaperPanel.Header.Title></PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton
                      onClick={() => {
                        setLastItemCategory("");
                        setCreateItemFormIsOpen(true);
                      }}
                    >
                      Add Item
                    </PaperPanel.Header.CreateButton>
                    <Box ml={1} />
                    <PaperPanel.Header.CreateButton onClick={() => setCreateCategoryDialogIsOpen(true)}>
                      Add Category
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>

                  <PaperPanel.Header.Action border>
                    {isAgenda && (
                      <PaperPanel.Header.Menu>
                        {(popupState) => [
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setHideItems(!hideItems);
                              popupState.close();
                            }}
                            key="items"
                          >
                            {hideItems ? `Show` : `Hide`} Items
                          </PaperPanel.Header.Menu.MenuItem>,
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setHideNotes(!hideNotes);
                              popupState.close();
                            }}
                            key="notes"
                          >
                            {hideNotes ? `Show` : `Hide`} Notes
                          </PaperPanel.Header.Menu.MenuItem>,
                          <MenuItemHeader border />,
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              blockUI.blockUI("Fixing...");
                              popupState.close();
                              meetingRPC
                                .mutateAsync({
                                  action: "fix-positions",
                                  method: "POST",
                                })
                                .then(() => blockUI.unblockUI())
                                .catch(() => blockUI.unblockUI());
                            }}
                            key="fix"
                          >
                            Fix Numbers
                          </PaperPanel.Header.Menu.MenuItem>,
                        ]}
                      </PaperPanel.Header.Menu>
                    )}
                    <PaperPanel.Header.RefreshButton
                      onClick={() => {
                        itemsQuery.refetch();
                        categoryQuery.refetch();
                      }}
                      isFetching={itemsQuery.isFetching}
                    />
                    <Box mr={-1} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mt={-2} mb={-3}>
                <DragDropContext
                  onDragEnd={(event) => {
                    const dragEndResult = {results: handleDragDrop(event)};

                    const data = itemQueryClient.getQueryData(itemURL).results;

                    itemQueryClient.setQueryData(itemURL, dragEndResult);

                    axiosAPI
                      .patch(`projects/${project.id}/meetings/${meetingId}/items/${event.draggableId}/`, {
                        meeting_category_id: event.destination.droppableId,
                        position: event.destination.index,
                      })
                      .then(() => itemsQuery.refetch());
                  }}
                >
                  {categories.map((category) => (
                    <React.Fragment key={category.id}>
                      <PaperItem.Header>
                        {/* <PaperItem.Left display="flex" alignItems="center" minWidth={100}>

                        </PaperItem.Left> */}
                        <PaperItem.Body display="flex" alignItems="center">
                          <h3
                            style={{margin: 0, cursor: "pointer"}}
                            onClick={() => {
                              setActiveItem(category);
                              setRenameCategoryDialogIsOpen(true);
                            }}
                          >
                            {category.number_display} - {category.description}
                          </h3>
                        </PaperItem.Body>
                        <PaperItem.Right>
                          {category.position !== 0 && (
                            <>
                              <Tooltip title="Move to Top">
                                <span
                                  onClick={() => {
                                    handleRepositionCategory(category, 0);
                                  }}
                                  style={{cursor: "pointer"}}
                                >
                                  <FontAwesomeIcon icon={faArrowUpToLine} fixedWidth />
                                </span>
                              </Tooltip>
                              <Tooltip title="Move Up One">
                                <span
                                  onClick={() => {
                                    handleRepositionCategory(category, category.position - 1);
                                  }}
                                  style={{cursor: "pointer"}}
                                >
                                  <FontAwesomeIcon icon={faArrowUp} fixedWidth />
                                </span>
                              </Tooltip>
                            </>
                          )}
                          {category.position + 1 !== categoryQuery.data.count && (
                            <>
                              <Tooltip title="Move Down One">
                                <span
                                  onClick={() => {
                                    handleRepositionCategory(category, category.position + 1);
                                  }}
                                  style={{cursor: "pointer"}}
                                >
                                  <FontAwesomeIcon icon={faArrowDown} fixedWidth />
                                </span>
                              </Tooltip>

                              <Tooltip title="Move to Bottom">
                                <span
                                  onClick={() => {
                                    handleRepositionCategory(category, categories.length - 1);
                                  }}
                                  style={{cursor: "pointer"}}
                                >
                                  <FontAwesomeIcon icon={faArrowDownToLine} fixedWidth />
                                </span>
                              </Tooltip>
                            </>
                          )}
                          <Box component="span" mr={1} />
                          <Tooltip title={`Add item to ${category.description}`}>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setLastItemCategory(category.id);
                                setCreateItemFormIsOpen(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </PaperItem.Right>
                      </PaperItem.Header>
                      {!hideItems && (
                        <Droppable droppableId={category.id.toString()}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {orderBy(itemLookup[category.id], "position").map((item, index) => {
                                return (
                                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps}>
                                        <MeetingItemPaperItem
                                          item={item}
                                          projectId={project.id}
                                          isDragging={snapshot.isDragging}
                                          dragHandleProps={provided.dragHandleProps}
                                          isAgenda={isAgenda}
                                          onEditMinutes={() => {
                                            setActiveItem(item);
                                            setMinutesDialogIsOpen(true);
                                          }}
                                          onChangeStatus={(status) => {
                                            updateItem.mutateAsync({id: item.id, status: status}).then(() => {
                                              itemsQuery.refetch();
                                            });
                                          }}
                                          onEditItem={() => {
                                            setActiveItem(item);
                                            setEditItemFormIsOpen(true);
                                          }}
                                          onDeleteItem={() => {
                                            setActiveItem(item);
                                            setDeleteItemConfirmationIsOpen(true);
                                          }}
                                          hideNotes={hideNotes}
                                          {...rest}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </React.Fragment>
                  ))}
                </DragDropContext>
              </Box>
            </>
          </TabPanel>

          <TabPanel value="attendance">
            <>
              <PaperPanel.TabHeader isLoading={attendeeQuery.isFetching}>
                <PaperPanel.Header.Title></PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.ColoredButton
                      startIcon={<FontAwesomeIcon icon={faAddressBook} />}
                      href={`/v2/projects/${project.id}/directory/`}
                      component={MuiNavLink}
                      underline="none"
                    >
                      Project Directory
                    </PaperPanel.Header.ColoredButton>
                    <Box ml={1} />
                    <PaperPanel.Header.ColoredButton
                      startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                      onClick={() => setSelectAttendeesDialogIsOpen(true)}
                    >
                      Add Attendees
                    </PaperPanel.Header.ColoredButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.ColoredButton
                      startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                      href={`mailto:${attendees.map((attendee) => attendee.contact?.email).join(";")}?subject=${
                        meeting.meeting_name
                      }`}
                    >
                      Email Attendees
                    </PaperPanel.Header.ColoredButton>
                    <Box ml={1} />

                    <PaperPanel.Header.ColoredButton
                      startIcon={<FontAwesomeIcon icon={faEnvelopes} />}
                      onClick={() => setDistributeDialogIsOpen(true)}
                    >
                      Distribute {meetingTypeString}
                    </PaperPanel.Header.ColoredButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => attendeeQuery.refetch()}
                      isFetching={attendeeQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mt={-2}>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  rightAlignColumns={["remove"]}
                  getHeaderProps={(column) => {
                    if (["remove", "warning"].includes(column.id)) {
                      return {
                        style: {
                          width: "20px",
                        },
                      };
                    }
                  }}
                  columns={[
                    {
                      id: "remove",
                      Cell: ({value, row}) => {
                        return (
                          <Tooltip title="Remove from Meeting">
                            <Box
                              textAlign="center"
                              style={{cursor: "pointer"}}
                              onClick={() => onRemoveAttendee(row.original.id)}
                            >
                              <FontAwesomeIcon icon={faUserMinus} />
                            </Box>
                          </Tooltip>
                        );
                      },
                    },
                    {
                      Header: "Name",
                      accessor: "contact.full_name",
                      Cell: ({value, row}) => {
                        return (
                          <MuiNavLink href={row.original.contact.url} underline="always">
                            {value}
                          </MuiNavLink>
                        );
                      },
                    },
                    {
                      Header: "Company",
                      accessor: "contact.company.name",
                      Cell: ({value, row}) => {
                        return (
                          <MuiNavLink href={row.original.contact.company.url} underline="always">
                            {value}
                          </MuiNavLink>
                        );
                      },
                    },

                    {
                      Header: isAgenda ? "" : "Attendance",
                      accessor: "attendance",
                      Cell: ({value, row}) => {
                        const choices = [
                          {value: "present", label: "Present"},
                          {value: "absent", label: "Absent"},
                          {value: "for_distribution_only", label: "For Distribution Only"},
                          {value: "conference", label: "Conference"},
                        ];
                        if (isAgenda) {
                          return;
                        }
                        return (
                          <>
                            <RadioGroup row value={value}>
                              {choices.map((choice) => (
                                <FormControlLabel
                                  key={choice.value}
                                  value={choice.value}
                                  label={choice.label}
                                  disabled={isAgenda}
                                  control={<Radio />}
                                  onClick={() => {
                                    isAgenda
                                      ? null
                                      : updateAttendees.mutateAsync({id: row.original.id, attendance: choice.value});
                                  }}
                                />
                              ))}
                              <>
                                {row.original.attendance === "" ? (
                                  <Tooltip title="No Attendance Status">
                                    <Box textAlign="center" alignItems="center" display="flex">
                                      <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Attendance Recorded">
                                    <Box textAlign="center" alignItems="center" display="flex">
                                      <FontAwesomeIcon icon={faCheckCircle} color="green" />
                                    </Box>
                                  </Tooltip>
                                )}
                              </>
                            </RadioGroup>
                          </>
                        );
                      },
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "contact.company.name",
                      },
                      {
                        id: "contact.full_name",
                      },
                    ],
                  }}
                  data={attendees}
                />
              </Box>
            </>
          </TabPanel>
          <TabPanel value="logs">
            <>
              {/* <PaperPanel.TabHeader>
                <PaperPanel.Header.Title>Logs</PaperPanel.Header.Title>
              </PaperPanel.TabHeader> */}
              <PaperPanel.Body mx={-3} mt={-4}>
                <ActivityStream url={`actstream/meetings/meeting/${meeting.id}/`} />
              </PaperPanel.Body>
            </>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      <LegacyUILink href={`/meetings/${meetingId}/${meeting.state}/`} mt={2} />

      <ConfirmationDialog
        isOpen={distributeDialogIsOpen}
        onDeny={() => setDistributeDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Sending...");
          meetingRPC
            .mutateAsync({action: `distribute-${meetingTypeString.toLowerCase()}`, method: "POST"})
            .then(() => {
              setDistributeDialogIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title={`Distribute ${meetingTypeString}?`}
      >
        Are you sure you want to distribute the meeting {meetingTypeString.toLowerCase()} to all attendees?
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={createFollowupDialogIsOpen}
        onDeny={() => setCreateFollowupDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI();
          meetingRPC
            .mutateAsync({action: "create-follow-up-meeting"})
            .then((response) => {
              navigate(`/v2/projects/${project.id}/meetings/${response.id}/agenda/`);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
          setCreateFollowupDialogIsOpen(false);
        }}
        title="Create Follow Up"
      >
        This will create a new meeting and copy all open items to the new meeting.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={deleteMeetingDialogIsOpen}
        onDeny={() => setDeleteMeetingDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteMeeting
            .mutateAsync(activeItem.id)
            .then(() => {
              setDeleteMeetingDialogIsOpen(false);
              navigate(`/v2/projects/${project.id}/meetings/`);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title="Delete Meeting"
      >
        Are you sure you want to delete this meeting? This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteItemConfirmationIsOpen}
        onDeny={() => setDeleteItemConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteItem
            .mutateAsync(activeItem.id)
            .then(() => {
              setActiveItem({});
              setDeleteItemConfirmationIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title={`Delete ${activeItem?.title}`}
      >
        Are you sure you want to delete this meeting item? This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={convertMeetingDialogIsOpen}
        onDeny={() => setConvertMeetingDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Converting...");

          meetingRPC
            .mutateAsync({action: `convert-to-${isAgenda ? "minutes" : "agenda"}`, method: "POST"})
            .then((response) => {
              navigate(`/v2/projects/${project.id}/meetings/${meetingId}/${response.state}/#tab=${selectedTab}`);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title={`Convert to ${isAgenda ? "Minutes" : "Agenda"}`}
      >
        Convert this meeting to {isAgenda ? "minutes" : "agenda"}?
      </ConfirmationDialog>

      <ItemMinutesForm
        itemName={activeItem?.title}
        onSubmit={(values) => {
          updateItem.mutateAsync(values).then(() => {
            setMinutesDialogIsOpen(false);
            setActiveItem({});
          });
        }}
        handleClose={() => {
          setMinutesDialogIsOpen(false);
          setActiveItem({});
        }}
        isOpen={minutesDialogIsOpen}
        initialValues={activeItem}
      />

      <MeetingItemDialogForm
        ref={createItemFormRef}
        isOpen={createItemFormIsOpen}
        handleClose={() => setCreateItemFormIsOpen(false)}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          setLastItemCategory(values.meeting_category_id);
          delete values.submitMode;
          blockUI.blockUI("Saving Item...");
          if (submitMode === "addAnother") {
            const focusElement = createItemFormRef.current["elements"].title;
            focusElement.focus();
          }
          itemCreate
            .mutateAsync(values)
            .then(() => categoryQuery.refetch())
            .then(() => {
              if (submitMode === "addAnother") {
                form.restart();
                blockUI.unblockUI();
              } else {
                setCreateItemFormIsOpen(false);
                setLastItemCategory("");
                blockUI.unblockUI();
              }
            })
            .catch(() => blockUI.unblockUI());
        }}
        categories={categoryOptions}
        isNew
        projectId={project.id}
        initialValues={{assigned_to: [], meeting_category_id: lastItemCategory}}
      />

      <MeetingItemDialogForm
        isOpen={editItemFormIsOpen}
        handleClose={() => setEditItemFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateItem
            .mutateAsync(values)
            .then(() => {
              setEditItemFormIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        categories={categoryOptions}
        projectId={project.id}
        initialValues={activeItem}
        isAgenda={isAgenda}
      />

      {/* Rename */}
      <CategoryForm
        isOpen={renameCategoryDialogIsOpen}
        handleClose={() => setRenameCategoryDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateCategory
            .mutateAsync(values)
            .then(() =>
              Promise.all([itemsQuery.refetch(), categoryQuery.refetch()]).then(() => {
                setRenameCategoryDialogIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
            )
            .catch(() => blockUI.unblockUI());
        }}
        initialValues={activeItem}
        onDeleteCategory={() => {
          blockUI.blockUI();
          deleteCategory
            .mutateAsync(activeItem.id)
            .then(() => {
              setRenameCategoryDialogIsOpen(false);
              Promise.all([itemsQuery.refetch(), categoryQuery.refetch()]).then(() => {
                setActiveItem({});
                blockUI.unblockUI();
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
      />

      {/* New */}
      <CategoryForm
        isNew
        isOpen={createCategoryDialogIsOpen}
        handleClose={() => setCreateCategoryDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          categoryCreate
            .mutateAsync(values)
            .then(() =>
              categoryQuery.refetch().then(() => {
                setCreateCategoryDialogIsOpen(false);
                blockUI.unblockUI();
              })
            )

            .catch(() => blockUI.unblockUI());
        }}
      />

      <SelectAttendeesForm
        isOpen={selectAttendeesDialogIsOpen}
        handleClose={() => setSelectAttendeesDialogIsOpen(false)}
        onSubmit={(values) => null}
        onAddContact={(contactId) => {
          blockUI.blockUI();
          createAttendee
            .mutateAsync({attendance: "", contact_id: contactId})
            .then(() => blockUI.unblockUI())
            .catch(() => blockUI.unblockUI());
        }}
        projectId={project.id}
        selectedAttendees={attendees}
        initialValues={{selected: attendees.map((attendee) => attendee.contact)}}
        companyContacts={companyContacts}
      />
    </>
  );
};

export default ProjectMeetingDetail;
