import {
  faBolt,
  faClipboardListCheck,
  faElevator,
  faFileCertificate,
  faForklift,
  faHelmetSafety,
  faListRadio,
  faMegaphone,
  faShieldQuartered,
  faTruckFire,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import EditIcon from "@material-ui/icons/Edit";
import {Alert, AlertTitle} from "@material-ui/lab";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import nl2br from "../../../js/utils/nl2br";
import Typography2 from "../../components/Typography2";
import {colorError, colorSuccess} from "../../theme/colors";
import {VaccineStatusUpdateDialogForm} from "./VaccineStatusCheckForms";

const FullWidthAlert = withStyles({
  message: {
    flexGrow: 1,
    // padding: 0,
  },
})(Alert);

const WellnessCheckQRCode = (props) => {
  const {
    announcements,
    qrCodeURLS,
    wellnessCheckResult: initialWellnessCheckResult,
    isToday,
    isForeman,
    pretaskPlan,
    safetyOrientationEnabled,
    safetyOrientationCompleted,
    stickersEnabled,
    stickerNumber,
    onsiteFormsEnabled,
    certificationsExpiringSoon,
    validCertifications,
  } = props;

  const [showUpdateVaccineStatusDialog, setShowUpdateVaccineStatusDialog] = React.useState(false);
  const [wellnessCheckResult, setWellnessCheckResult] = React.useState(initialWellnessCheckResult);
  // const showUpdateVaccineStatusButton =
  //   wellnessCheckResult.twilio_contact.covid19_vaccine_status &&
  //   wellnessCheckResult.twilio_contact.covid19_vaccine_status !== "fully_vaccinated";
  const fullyVaccinated = wellnessCheckResult.twilio_contact.covid19_vaccine_status === "fully_vaccinated";
  const isSpanish = wellnessCheckResult.twilio_contact?.language === "SP";

  const getCertificationURL = (uuid) => {
    return `/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/certifications/${uuid}/`;
  };

  return (
    <>
      {wellnessCheckResult.job_requires_covid19_fully_vaccinated && !fullyVaccinated ? (
        <Typography style={{fontWeight: 900}}>
          This project is only open to fully vaccinated contractors, subcontractors, and vendors. You are unable to
          proceed past the check in because of your self-certified vaccination status. If that status has changed, you
          can tap below to update it.
        </Typography>
      ) : (
        <Box my={2}>
          <center>
            <img
              src={qrCodeURLS}
              alt="QR Code"
              width="80%"
              style={{maxWidth: "400px", borderColor: "#febf3b", borderWidth: "5px", borderStyle: "solid"}}
            />
          </center>
        </Box>
      )}
      <Box mb={2}>
        <center>
          <Typography variant="h2">
            {wellnessCheckResult?.twilio_contact?.name}
            <IconButton
              aria-label="update contact"
              href={`/twilio-contact/${wellnessCheckResult?.twilio_contact?.uuid}/`}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Typography>
          <Typography variant="h5">{wellnessCheckResult?.twilio_contact?.company}</Typography>
          <Typography variant="h5">{wellnessCheckResult.project.display}</Typography>
          <Typography variant="h5" style={{color: isToday ? colorSuccess : colorError}}>
            {wellnessCheckResult?.compliance_date} - {moment(wellnessCheckResult?.compliance_date).format("dddd")}
          </Typography>
        </center>
        {/* <Box ml={2}>
          <IconButton
            aria-label="update contact"
            href={`/twilio-contact/${wellnessCheckResult?.twilio_contact?.uuid}/`}
          >
            <EditIcon />
          </IconButton>
        </Box> */}
      </Box>

      {announcements.length > 0 && (
        <FullWidthAlert
          severity="warning"
          icon={<FontAwesomeIcon icon={faMegaphone} style={{flexGrow: 1, padding: 0}} />}
        >
          <AlertTitle>
            <Typography variant="h6">Announcements</Typography>
          </AlertTitle>

          <List disablePadding>
            {announcements.map((announcement, index) => {
              return (
                <ListItem key={announcement.id} divider={index + 1 !== announcements.length} dense disableGutters>
                  <ListItemText>
                    {nl2br(isSpanish ? announcement.translated_announcement : announcement.announcement)}
                  </ListItemText>
                  {/* {index + 1 !== announcements.length && (
                    <>
                      <Box mt={1} />
                      <Divider />
                    </>
                  )} */}
                </ListItem>
              );
            })}
          </List>
        </FullWidthAlert>
      )}

      <Box mb={2} />
      {wellnessCheckResult.job_requires_covid19_fully_vaccinated && !fullyVaccinated && (
        <>
          <Box mt={2} />
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            // size="large"
            onClick={() => setShowUpdateVaccineStatusDialog(true)}
          >
            Update Vaccination Status
          </Button>
          <VaccineStatusUpdateDialogForm
            twilioContactUUID={wellnessCheckResult.twilio_contact.uuid}
            isOpen={showUpdateVaccineStatusDialog}
            selfAttest={wellnessCheckResult.check_covid19_vaccine_status_self_attest}
            onSubmitted={(data) => {
              wellnessCheckResult.twilio_contact = data;
              wellnessCheckResult.check_covid19_vaccine_status = false;
              setWellnessCheckResult({...wellnessCheckResult});
              setShowUpdateVaccineStatusDialog(false);
            }}
            handleClose={() => {
              setShowUpdateVaccineStatusDialog(false);
            }}
            initialValues={{
              covid19_vaccine_status: wellnessCheckResult.twilio_contact.covid19_vaccine_status,
            }}
          />
        </>
      )}
      <Box display="flex">
        <Box>
          {safetyOrientationEnabled &&
            (safetyOrientationCompleted ? (
              <Tooltip
                title={`Safety Orientation Completed: ${moment(safetyOrientationCompleted).format(
                  "YYYY-DD-MM h:mm a"
                )}`}
              >
                <span>
                  <FontAwesomeIcon icon={faShieldQuartered} color={colorSuccess} size="2x" />{" "}
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={`Safety Orientation Incomplete`}>
                <span>
                  <FontAwesomeIcon icon={faShieldQuartered} color={colorError} size="2x" />
                </span>
              </Tooltip>
            ))}
        </Box>
        <Box>
          {stickersEnabled && stickerNumber && (
            <Tooltip title={"Sticker Number"}>
              <span>
                <Chip size="small" label={`#${stickerNumber}`} />
              </span>
            </Tooltip>
          )}
        </Box>

        <>
          <Box ml={1} />
          {validCertifications.forklift && (
            <Box mr={1}>
              <Tooltip title={"Forklift Certification"}>
                <span>
                  <Link href={getCertificationURL(validCertifications.forklift)} target="_blank">
                    <FontAwesomeIcon icon={faForklift} size="2x" />
                  </Link>
                </span>
              </Tooltip>
            </Box>
          )}
          {validCertifications.boom_lift && (
            <Box mr={1}>
              <Tooltip title={"Boom Lift Certification"}>
                <span>
                  <Link href={getCertificationURL(validCertifications.boom_lift)} target="_blank">
                    <FontAwesomeIcon icon={faTruckFire} size="2x" />
                  </Link>
                </span>
              </Tooltip>
            </Box>
          )}
          {validCertifications.scissor_lift && (
            <Box mr={1}>
              <Tooltip title={"Scissor Lift Certification"}>
                <span>
                  <Link href={getCertificationURL(validCertifications.scissor_lift)} target="_blank">
                    <FontAwesomeIcon icon={faElevator} size="2x" />
                  </Link>
                </span>
              </Tooltip>
            </Box>
          )}
          {validCertifications.dell_electrical_safety && (
            <Box mr={1}>
              <Tooltip title={"Dell Electrical Safety Certification"}>
                <span>
                  <Link href={getCertificationURL(validCertifications.dell_electrical_safety)} target="_blank">
                    <FontAwesomeIcon icon={faBolt} size="2x" />
                  </Link>
                </span>
              </Tooltip>
            </Box>
          )}
        </>
      </Box>

      {isForeman && (
        <>
          <Box mt={2} />
          {pretaskPlan === null ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faListRadio} />}
              href={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/create-pretask-plan/?date=${wellnessCheckResult.compliance_date}`}
            >
              Create Pretask Plan
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faListRadio} />}
              href={`/pretask-plan/${pretaskPlan.uuid}/`}
            >
              View Pretask Plan
            </Button>
          )}
        </>
      )}

      {onsiteFormsEnabled && (
        <>
          <Box mt={2} />
          <Tooltip
            title={
              wellnessCheckResult.twilio_contact?.fuzzy_company
                ? ``
                : `Company ${wellnessCheckResult.twilio_contact?.company} not found.  Please contact NOVO personnel to get this corrected.`
            }
          >
            <span>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={!wellnessCheckResult.twilio_contact?.fuzzy_company}
                startIcon={<FontAwesomeIcon icon={faClipboardListCheck} />}
                href={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/safety/permits/#date=${wellnessCheckResult.compliance_date}`}
              >
                Permits
              </Button>
            </span>
          </Tooltip>
        </>
      )}

      <>
        <Box mt={2} />
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faFileCertificate} />}
          href={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/certifications/?date=${wellnessCheckResult.compliance_date}`}
        >
          Certifications
        </Button>
        {certificationsExpiringSoon > 0 && (
          <Box width={1} display="flex" justifyContent="center">
            <Typography2 type={"error"}>
              {isSpanish ? (
                <>¡{certificationsExpiringSoon} certificacion(es) que vencen pronto! </>
              ) : (
                <>
                  {certificationsExpiringSoon} {pluralize("certification", certificationsExpiringSoon)} expiring soon!
                </>
              )}
            </Typography2>
          </Box>
        )}
      </>

      {safetyOrientationEnabled && (
        <>
          <Box mt={2} />
          {safetyOrientationCompleted ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faHelmetSafety} />}
              href={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/safety/orientation/#date=${wellnessCheckResult.compliance_date}`}
            >
              Review Safety Orientation
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faHelmetSafety} />}
              href={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/safety/orientation/#date=${wellnessCheckResult.compliance_date}`}
            >
              Complete Safety Orientation
            </Button>
          )}
        </>
      )}
      <>
        <Box mt={2} />
        <Tooltip
          title={
            wellnessCheckResult.twilio_contact?.fuzzy_company
              ? ``
              : `Company ${wellnessCheckResult.twilio_contact?.company} not found.  Please contact NOVO personnel to get this corrected.`
          }
        >
          <span>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={!wellnessCheckResult.twilio_contact?.fuzzy_company}
              startIcon={<CloudUploadIcon />}
              href={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/jobsite-documents/?date=${wellnessCheckResult.compliance_date}`}
            >
              Upload Jobsite Documents
            </Button>
          </span>
        </Tooltip>
      </>

      <Box mt={2}>
        <Typography>Show QR Code to NOVO personnel for site specific training. Check-in required daily.</Typography>
      </Box>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(wellnessCheckResult, null, 2)}</pre> */}
    </>
  );
};

export default WellnessCheckQRCode;
