import {faExclamationCircle, faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, MenuItem, Tooltip} from "@material-ui/core";
import React from "react";
import {SplitButton} from "../../../components/Buttons";
import {MenuItemHeader} from "../../../components/Menu";
import {colorError, colorWarning} from "../../../theme/colors";
import {SendSubcontractCOIReminderDialogForm} from "./SubcontractForms";
import useBlockUI from "../../../hooks/useBlockUI";
import {axiosAPI} from "../../../api";
import {useSnackbar} from "notistack";
import {MuiNavLink} from "../../../components/Links";

const getCOIButtonIcon = (coi) => {
  if (!coi) {
    return <FontAwesomeIcon icon={faPaperclip} fixedWidth />;
  }
  if (coi?.has_expired_policies) {
    return <FontAwesomeIcon icon={faExclamationCircle} color={colorError} fixedWidth />;
  }
  if (coi?.has_policies_expiring_soon) {
    return <FontAwesomeIcon icon={faExclamationCircle} color={colorWarning} fixedWidth />;
  }
  return <FontAwesomeIcon icon={faPaperclip} fixedWidth />;
};
const getCOIButtonTooltip = (coi) => {
  if (!coi) {
    return "";
  }
  if (coi?.all_policies_expired) {
    return "All policies have expired";
  }
  if (coi?.has_expired_policies) {
    return "Some policies have expired";
  }
  if (coi?.has_policies_expiring_soon) {
    return "Some policies are expiring soon";
  }
  return "";
};

export const COIButton = (props) => {
  const {project, coi, commitment, insuranceContacts = [], ...rest} = props;
  const [insuranceDialogIsOpen, setInsuranceDialogIsOpen] = React.useState(false);
  const [activeInsuranceContact, setActiveInsuranceContact] = React.useState(null);
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <Tooltip title={getCOIButtonTooltip(coi)}>
        <span>
          <SplitButton
            href={coi?.url}
            disabled={!coi}
            // disableDropDown={true}
            title={
              <>
                {getCOIButtonIcon(coi)}
                COI
              </>
            }
          >
            {(setOpen) => (
              <>
                <MenuItem dense>
                  <MuiNavLink href={`/v2/projects/${project.id}/insurance/#tab=cois`} underline="none">
                    Project COIs
                  </MuiNavLink>
                </MenuItem>
                <MenuItemHeader border>Send Reminder</MenuItemHeader>
                {insuranceContacts.length > 0 ? (
                  insuranceContacts.map((contact, index) => {
                    return (
                      <MenuItem
                        dense
                        onClick={(e: any) => {
                          setOpen(false);
                          setInsuranceDialogIsOpen(true);
                          setActiveInsuranceContact(contact);
                        }}
                        value={index}
                        key={contact.id}
                      >
                        {contact.full_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <>
                    <MenuItem dense component={Link} href={`${commitment.to_company?.url}#tab=contacts`}>
                      No Insurance Contacts Set
                    </MenuItem>
                  </>
                )}
              </>
            )}
          </SplitButton>
        </span>
      </Tooltip>

      <SendSubcontractCOIReminderDialogForm
        project={project}
        insuranceContact={activeInsuranceContact}
        isOpen={insuranceDialogIsOpen}
        onSubmit={(values) => {
          setInsuranceDialogIsOpen(false);
          setActiveInsuranceContact(0);
          blockUI.blockUI("Sending...");
          axiosAPI
            .post(`/projects/${project.id}/contracts/sub/${commitment.id}/send-coi-reminder/`, {
              contactId: values?.contactId,
              message: values?.message,
            })
            .then((res) => {
              res.data == "success" && enqueueSnackbar("Email Sent!", {variant: "success"});

              blockUI.unblockUI();
            });
        }}
        commitment={commitment}
        handleClose={() => {
          setInsuranceDialogIsOpen(false);
          setActiveInsuranceContact(0);
        }}
        // projectDisplay={project.display}
        // contractDisplay={subcontract.display}
      />
    </>
  );
};
