import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {find} from "lodash";
import {Switches as FinalSwitches} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts, identity} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import contactRoles from "../../components/forms/choices/contactRoles.json";
import phoneNumberTypes from "../../components/forms/choices/phoneNumberTypes.json";
import usStates from "../../components/forms/choices/usStates.json";
import {
  CompanyAutoselectMui,
  GoogleMapsAddressAutocompleteMui,
  GroupedSimpleSelect,
  MaskedInputMui,
  SimpleSelect,
  TextFieldMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import InnerFooter from "../../components/InnerFooter";
import PaperPanel from "../../components/PaperPanel";
import {validateEmailAddress} from "../../utils/validators";

export const ContactDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, onDelete, isNew = false, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, initialValues, values}) => {
            const validateEmail = async (value, values, field) => {
              if (!field.dirty) return;
              if (!value) {
                return "Email is required";
              }
              if (!validateEmailAddress(value)) {
                return "Not a valid email address";
              }
              const response = await axiosAPI.get(
                `/contacts/validate-email/?email=${value}&exclude=${initialValues.email}`
              );
              if (response.data.error) {
                return response.data.error;
              }
            };
            const selectedContactRole = find(contactRoles, (role) => values.contact_role?.id === role.value);
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{isNew ? "Create Contact" : "Update Contact"}</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="email"
                          label="Email"
                          type="email"
                          autoFocus
                          required
                          fieldProps={{
                            validate: validateEmail,
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextFieldMui name="first_name" label="First Name" required />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextFieldMui name="last_name" label="Last Name" required />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextFieldMui name="title" label="Title" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <GroupedSimpleSelect
                          allowNull
                          name="contact_role.id"
                          label="Role"
                          options={contactRoles}
                          helperText={
                            selectedContactRole ? `${selectedContactRole.group} - ${selectedContactRole.label}` : ""
                          }
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <CompanyAutoselectMui
                          required
                          name="company"
                          label="Company"
                          disabled={Boolean(initialValues.company)}
                        />
                      </Grid>
                      {isNew && (
                        <>
                          <Grid item sm={4} xs={12}>
                            <MaskedInputMui name="phone" label="Phone" mask="(999)-999-9999" />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextFieldMui name="extension" label="Extension" />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <SimpleSelect name="type" label="Type" options={phoneNumberTypes} />
                          </Grid>
                        </>
                      )}
                      {!isNew && (
                        <>
                          <Grid item xs={12}>
                            <GoogleMapsAddressAutocompleteMui
                              name="address1"
                              label="Address 1"
                              onPlaceSelected={(place) => {
                                form.change("address1", place.address1);
                                form.change("address2", place.address2);
                                form.change("city", place.locality_long_name);
                                form.change("state", place.administrative_area_level_1_short_name);
                                form.change("postal_code", place.postal_code);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name="address2" label="Address 2" />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextFieldMui name="city" label="City" />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <SimpleSelect name="state" label="State" options={usStates} />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextFieldMui name="postal_code" label="Postal Code" />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FinalSwitches
                              name="can_manage_insurance"
                              data={{label: "Can Manage Insurance", value: true}}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FinalSwitches name="is_primary" data={{label: "Is Primary", value: true}} />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FinalSwitches name="is_active" data={{label: "Is Active", value: true}} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name="notes" label="Notes" multiline minRows={2} />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {isNew && (
                    <FormActions.SaveAndEditButton
                      disabled={submitting || pristine}
                      onClick={() => form.change("editAfterSubmit", true)}
                    />
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ContactCreateForm = (props: {
  onSubmit: (values: any) => void;
  isModal?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, isModal, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine, values}) => {
        const validateEmail = async (value) => {
          if (!value) {
            return "Email is required";
          }
          if (!validateEmailAddress(value)) {
            return "Not a valid email address";
          }
          const response = await axiosAPI.get(`/contacts/validate-email/?email=${value}`);
          if (response.data.error) {
            return response.data.error;
          }
        };
        const selectedContactRole = find(contactRoles, (role) => values.contact_role?.id === role.value);
        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <PaperPanel.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name="email"
                        label="Email"
                        type="email"
                        autoFocus
                        required
                        fieldProps={{
                          parse: identity,
                          validate: validateEmail,
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextFieldMui name="first_name" label="First Name" required />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextFieldMui name="last_name" label="Last Name" required />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextFieldMui name="title" label="Title" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <GroupedSimpleSelect
                        allowNull
                        name="contact_role.id"
                        label="Role"
                        options={contactRoles}
                        helperText={
                          selectedContactRole ? `${selectedContactRole.group} - ${selectedContactRole.label}` : ""
                        }
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <CompanyAutoselectMui required name="company" label="Company" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <MaskedInputMui name="phone" label="Phone" mask="(999)-999-9999" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <TextFieldMui name="extension" label="Extension" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <SimpleSelect name="type" label="Type" options={phoneNumberTypes} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="notes" label="Notes" multiline minRows={2} />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </PaperPanel.Body>
                {isModal ? (
                  <>
                    <FormActions>
                      <Box textAlign="right">
                        <FormActions.CancelButton />
                        <FormActions.SaveButton disabled={submitting || pristine} />
                      </Box>
                    </FormActions>
                  </>
                ) : (
                  <InnerFooter>
                    <FormActions.CreateButton disabled={submitting || pristine} />
                  </InnerFooter>
                )}
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const PhoneNumberDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, onDelete, isNew = false, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, initialValues, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{isNew ? "Add Phone Number" : "Update Phone Number "}</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item sm={9} xs={12}>
                        <MaskedInputMui name="phone" label="Phone" mask="(999)-999-9999" required autoFocus />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <TextFieldMui name="extension" label="Extension" />
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <SimpleSelect name="type" label="Type" options={phoneNumberTypes} required />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <FinalSwitches name="is_primary" data={{label: "Is Primary", value: true}} />
                      </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
