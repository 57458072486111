import {faFileSignature} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Link, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {MasterSubcontractAgreementUpdateForm} from "./MasterSubcontractAgreementForms";
import {StatusLabel} from "../../../components/Status";
import {Alert} from "@material-ui/lab";

const MasterSubcontractAgreementDetail = (props) => {
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);

  const {msaId} = useParams();
  const blockUI = useBlockUI();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const {
    query: msaQuery,
    create: createMSA,
    update: updateMSA,
    delete: deleteMSA,
  } = useSentinelDetailAPI(`/accounting-tools/msas/${msaId}/`, {
    initialData: {
      data: {},
    },
  });
  const msa = msaQuery.data;

  if (!msaQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching MSA info..." />;
  }

  return (
    <>
      <Helmet title={msa.display} />

      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <MuiNavLink color="inherit" to={`/v2/accounting-tools/msas/`}>
          Master Subcontract Agreements
        </MuiNavLink>
        <Typography color="textPrimary">{msa.display}</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      {msa.executed_date && (
        <Box mb={1}>
          <Alert severity="info">This Master Subcontract Agreement was executed on {msa.executed_date}.</Alert>
        </Box>
      )}
      <PaperPanel>
        <PaperPanel.Header isLoading={msaQuery.isFetching}>
          <PaperPanel.Header.Title>
            <StatusLabel status={msa.status_display} /> {msa.display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton target="_blank" href={`/reports2/accounting-tools/msas/${msaId}/`} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.DocuSignButton docuSignObject={msa} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.BoxFilesButton
                href={`/v2/box/files/accounting_tools/mastersubcontractagreement/${msaId}/`}
                uploadURL={`/accounting-tools/msas/${msaId}/upload-to-box/`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton isFetching={msaQuery.isFetching} onClick={() => msaQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <MasterSubcontractAgreementUpdateForm
            initialValues={msa}
            isReadOnly={msa.is_closed}
            onSubmit={(values) => {
              updateMSA.mutateAsync(values).then(() => {
                // msaQuery.refetch();
              });
            }}
            onDelete={() => {
              setDeleteConfirmationIsOpen(true);
            }}
          />
          {/* <pre>{JSON.stringify(pageData, null, 2)}</pre>
          <pre>{JSON.stringify(msa, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteMSA
            .mutateAsync(msa.id)
            .then(() => {
              setDeleteConfirmationIsOpen(false);
              blockUI.unblockUI();
              window.location.href = `/v2/accounting-tools/msas/`;
            })
            .catch(() => blockUI.unblockUI());
        }}
        title={`Delete ${msa?.display}`}
      >
        Do you wish to delete this master subcontract agreement? This can not be undone.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default MasterSubcontractAgreementDetail;
