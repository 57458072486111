import {faFilePdf} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import HelpIcon from "@material-ui/icons/Help";
import {useSidebarCollapse} from "@mui-treasury/layout/hooks";
import {Column, Row} from "@silevis/reactgrid";
import {useSnackbar} from "notistack";
import React from "react";
import {isMobile} from "react-device-detect";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {NavLink as RouterNavLink} from "react-router";
import BeforeUnload from "../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../js/components/FinalFormEnhancers";
import LabeledGroup from "../../js/components/LabeledGroup";
import {axiosAPI} from "../api";
import {
  ColoredButton,
  ColoredSplitButton,
  DropdownButton,
  PDFButton,
  PDFDropdownButton,
  PDFSplitButton,
  SplitButton,
  UploadFileButton,
} from "../components/Buttons";
import {FilterAutocomplete} from "../components/Filters";
import GlobalSearch from "../components/GlobalSearch";
import {MenuItemHeader} from "../components/Menu";
import PaperPanel from "../components/PaperPanel";
import ReactGrid from "../components/ReactGrid";
import WebcamCaptureDialog from "../components/WebcamCaptureDialog";
import {
  AItextFieldMui,
  CSICodeAutocompleteMui,
  DateTimePickerMui,
  GoogleMapsAddressAutocompleteMui,
  ProjectContactAutoselectMui,
  ProjectTagAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../components/forms/Fields";
import {AITinyMCEMui} from "../components/forms/Fields/AIFields";
import FormActions from "../components/forms/FormActions";
import useBlockUI from "../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../hooks/useSentinelAPI";
import {colorError} from "../theme/colors";

const Sink = (props) => {
  const {data: pageData, refetch: refetchPageData} = useFetchCurrentPage();
  const postCurrentPage = usePostCurrentPage(
    {},
    {
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  const [showCameraDialog, setShowCameraDialog] = React.useState(false);
  const [showMCEDialog, setShowMCEDialog] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const blockUI = useBlockUI();
  const theme = useTheme();
  const sidebarCollapse = useSidebarCollapse("primarySidebar", "Consumer");
  const {enqueueSnackbar} = useSnackbar();

  return (
    <>
      <Helmet title="Kitchen Sink Page" />
      <Typography variant="h1">Sink Screen</Typography>
      <Typography variant="h2">Global Search</Typography>
      <GlobalSearch />

      <Typography variant="h2">Tabs</Typography>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
          }}
        >
          <Tab label="Tab 1" />
          <Tab label="Tab 2" />
          <Tab label="Disabled" disabled />
          <Tab label="Tab 3" />
          <Tab label="Tab 4" />
          <Tab label="Tab 5" />
          <Tab label="Tab 6" />
          <Tab label="Tab 7" />
          <Tab label="Tab 8" />
          <Tab label="Tab 9" />
          <Tab label="Tab 10" />
          <Tab label="Tab 11" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && <Paper>Tab 1</Paper>}
      {selectedTab === 1 && <Paper>Tab 2</Paper>}
      <Typography variant="h2">Preloaded State</Typography>
      <Button
        variant="contained"
        onClick={() => {
          refetchPageData();
        }}
      >
        Refetch
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          blockUI.blockUI();
        }}
      >
        Block UI
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          blockUI.blockUI("This is a message");
        }}
      >
        Block UI Message
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          blockUI.unblockUI();
        }}
      >
        Un Block UI
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          sidebarCollapse.setCollapsed("primarySidebar", !sidebarCollapse.state.collapsed);
        }}
      >
        Toggle Collapse Sidebar
      </Button>
      <div>
        <Box display="flex">
          <SplitButton title="Split Button Standard" size="small">
            <MenuItem>Option 1</MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3 Long Title</MenuItem>
          </SplitButton>
          <ColoredSplitButton title="Color Split Button" color={colorError} size="small">
            <MenuItem>
              <Link href={`https://www.novoconstruction.com/`} underline="none">
                Link to Novo
              </Link>
            </MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3 Long Title</MenuItem>
          </ColoredSplitButton>
          <SplitButton
            title="Split Button Variant 1"
            size="small"
            color="secondary"
            variant="contained"
            disableElevation
            startIcon={<PictureAsPdfIcon />}
          >
            <MenuItem>Option 1</MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3 Long Title</MenuItem>
          </SplitButton>
          <SplitButton
            title="Split Button Variant 2"
            size="small"
            color="secondary"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
          >
            <MenuItem>Option 1</MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3 Long Title</MenuItem>
          </SplitButton>
        </Box>
      </div>
      <div>
        <Box display="flex">
          <PDFButton href="#" />
          <PDFDropdownButton>
            <MenuItem>Alternate PDF 1</MenuItem>
            <MenuItem>Alternate PDF 2</MenuItem>
            <MenuItem>Alternate PDF 3</MenuItem>
            <MenuItemHeader>Menu Item Header</MenuItemHeader>
            <MenuItem>Alternate PDF 4</MenuItem>
            <MenuItem>Alternate PDF 5</MenuItem>
          </PDFDropdownButton>
          <PDFSplitButton href="#">
            <MenuItem>Alternate PDF 1</MenuItem>
            <MenuItem>Alternate PDF 2</MenuItem>
            <MenuItem>Alternate PDF 3</MenuItem>
          </PDFSplitButton>
        </Box>
      </div>
      <div>
        <ColoredButton color="success">Success</ColoredButton>
        <ColoredButton color="warning">Warning</ColoredButton>
        <ColoredButton color="error">Error</ColoredButton>
        <ColoredButton color="success" variant="outlined">
          Success
        </ColoredButton>
        <ColoredButton color="warning" variant="outlined">
          Warning
        </ColoredButton>
        <ColoredButton color="error" variant="outlined">
          Error
        </ColoredButton>
        <ColoredButton
          color="error"
          variant="outlined"
          startIcon={
            <Box color={green[700]} display="flex">
              <PictureAsPdfIcon fontSize="small" />
            </Box>
          }
        >
          <Box color={green[300]} display="flex">
            Error
          </Box>
        </ColoredButton>
        <ColoredButton color="error" variant="outlined" startIcon={<FontAwesomeIcon icon={faFilePdf} />}>
          Error
        </ColoredButton>
      </div>
      <div>
        <DropdownButton title="Dropdown Button">
          {(setOpen) => (
            <div>
              <MenuItem
                onClick={() => {
                  console.log("item 1");
                  setOpen(false);
                }}
              >
                Item 1 (should close on click)
              </MenuItem>
              <MenuItem>Item 2</MenuItem>
              <MenuItem>Item 3</MenuItem>
            </div>
          )}
        </DropdownButton>
        <DropdownButton button={<Button>Custom Button</Button>}>
          <MenuItem>Item 1</MenuItem>
          <MenuItem>Item 2</MenuItem>
          <MenuItem>Item 3</MenuItem>
        </DropdownButton>
      </div>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Sample Form</PaperPanel.Header.Title>
        </PaperPanel.Header>

        <FinalForm
          onSubmit={(values) => {
            console.log("on submit", values);
          }}
          initialValues={{
            project_contact: {
              id: 18041,
              first_name: "Bad",
              last_name: "Email",
              full_name: "Bad Email",
              full_name_last: "Email, Bad",
              email: "bademailaddress@novoconstruction.com",
              title: "",
              company: {
                id: 2322,
                name: "Openlink LLC",
                lookup_code: "OPENLINKL001",
                company_role_id: "subcontractor",
                company_role_description: "Subcontractor",
                url: "/companies/2322/",
                is_union: false,
              },
              url: "/contacts/18041/",
              avatar_url: "https://secure.gravatar.com/avatar/74645bda01402f1caedc22e6d1052597.jpg",
              can_manage_insurance: false,
              is_active: true,
            },
            project_contacts: [
              {
                id: 18041,
                first_name: "Bad",
                last_name: "Email",
                full_name: "Bad Email",
                full_name_last: "Email, Bad",
                email: "bademailaddress@novoconstruction.com",
                title: "",
                company: {
                  id: 2322,
                  name: "Openlink LLC",
                  lookup_code: "OPENLINKL001",
                  company_role_id: "subcontractor",
                  company_role_description: "Subcontractor",
                  url: "/companies/2322/",
                  is_union: false,
                },
                url: "/contacts/18041/",
                avatar_url: "https://secure.gravatar.com/avatar/fb4bdd5fd69b5b0a5484f9babfe99aa7.jpg",
                can_manage_insurance: false,
                is_active: true,
              },
            ],
            project_contacts_multi: [
              {
                id: 18041,
                full_name: "Bill Avatar",
                company: {
                  id: 2322,
                  name: "Avatar Construction",
                },
                url: "/contacts/18041/",
                avatar_url: "https://secure.gravatar.com/avatar/9bceb3afbee18f24fb44789c630c5ac2.jpg",
              },
              {
                id: 18041,
                full_name: "Bad Email",
                company: {
                  id: 2322,
                  name: "Novo Construction",
                },
                url: "/contacts/18041/",
                avatar_url: "https://secure.gravatar.com/avatar/74645bda01402f1caedc22e6d1052597.jpg",
              },
              {
                id: 18041,
                full_name: "Bad Email",
                company: {
                  id: 2322,
                  name: "Open Link LLC",
                },
                url: "/contacts/18041/",
                avatar_url: "https://secure.gravatar.com/avatar/74645bda01402f1caedc22e6d1052597.jpg?d=mm",
              },
              {
                id: 18041,
                full_name: "Bad Email Long Long Name",
                company: {
                  id: 2322,
                  name: "Open Link LLC -  Long Company Name",
                },
                url: "/contacts/18041/",
                avatar_url: "https://secure.gravatar.com/avatar/74645bda01402f1caedc22e6d1052597.jpg",
              },
              {
                id: 18041,
                full_name: "Bad Email",
                company: {
                  id: 2322,
                  name: "Novo Construction",
                },
                url: "/contacts/18041/",
                avatar_url: "https://secure.gravatar.com/avatar/74645bda01402f1caedc22e6d1052597.jpg",
              },
            ],
            steps_for_work: "Light Fixture Install",
            hazards: "",
            tags: [],
            date_time: "2021-04-22T08:08:03-07:00",
          }}
        >
          {({handleSubmit, form, submitting, pristine, values}) => (
            <>
              {/* <BeforeUnload block={!pristine} /> */}
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body p={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui projectId={1143} name="project_contact" label="Project Contact" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          projectId={1143}
                          name="project_contacts"
                          label="Project Contacts"
                          getOptionDisabled={(option) => option.full_name === "Sam Kuehn"}
                          multiple
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProjectContactAutoselectMui
                          projectId={1143}
                          name="project_contacts_multi"
                          label="Project Contacts"
                          getOptionDisabled={(option) => option.full_name === "Sam Kuehn"}
                          multiple
                          avatar
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProjectTagAutocompleteMui projectId={1143} name="tags" label="Tags" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CSICodeAutocompleteMui name="csi_code" label="CSI Code" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePickerMui label="Date Time" name="date_time" />
                      </Grid>
                      <Grid item xs={12}>
                        <GoogleMapsAddressAutocompleteMui
                          name="address1"
                          label="Address 1"
                          onPlaceSelected={(place) => {
                            console.log(place);
                            form.change("address1", place.address1);
                            form.change("address2", place.address2);
                            form.change("city", place.locality_long_name);
                            form.change("state", place.administrative_area_level_1_short_name);
                            form.change("postal_code", place.postal_code);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="address2" label="Address 2" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="city" label="City" />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFieldMui name="state" label="State" />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFieldMui name="postal_code" label="Postal Code" />
                      </Grid>
                      <Grid item xs={12}>
                        <AITinyMCEMui name="ai_mce" label="AI MCE" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldMui
                          name="end_adornment"
                          label="End Adornment"
                          required
                          onBlur={() => {
                            if (!pristine) {
                              form.submit();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Do something cool">
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      alert("Do something cool");
                                    }}
                                  >
                                    <HelpIcon />
                                  </IconButton>
                                </InputAdornment>
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AItextFieldMui
                          name="ai_textfield"
                          label="AI Textfield"
                          tooltipTitle="Check with AI"
                          required
                          onClickAIButton={() => {
                            alert("Talking to AI");
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldMui
                          name="end_adornment_multiline"
                          label="End Adornment Multiline"
                          multiline
                          minRows={3}
                          required
                          onBlur={() => {
                            if (!pristine) {
                              form.submit();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Do something cool">
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      alert("Do something cool");
                                    }}
                                  >
                                    <HelpIcon />
                                  </IconButton>
                                </InputAdornment>
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextFieldMui name="steps_for_work" label="Steps For Work" />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <AItextFieldMui
                          name="hazards"
                          label="Hazards"
                          multiline
                          minRows={3}
                          tooltipTitle="Check with AI"
                          disabled={Boolean(!values.steps_for_work)}
                          onClickAIButton={() => {
                            return axiosAPI
                              .post(pageData.aiWorkHazardUrl, {message: values.steps_for_work}, {baseURL: ""})
                              .then((response) => {
                                form.change("hazards", response.data.message);
                              });
                            // setHazards("Foo");
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <UploadFileButton
                          accept="image/*"
                          capture="environment"
                          name="cell_upload"
                          onUpload={(file) => {
                            blockUI.blockUI("Uploading");
                            let formData = new FormData();
                            formData.append("file", file);
                            postCurrentPage.mutateAsync({action: "upload_to_box", data: formData}).then((response) => {
                              enqueueSnackbar(response.message, {
                                variant: "success",
                              });
                              blockUI.unblockUI();
                            });
                          }}
                        >
                          {isMobile ? "Capture Photo" : "Upload Photo"}
                        </UploadFileButton>
                      </Grid>
                    </Grid>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                  <FormActions>
                    <FormActions.Left>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                    </FormActions.Left>
                  </FormActions>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          )}
        </FinalForm>
      </PaperPanel>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Many Buttons List</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 123</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 12</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 1234</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 12</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 1232</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 12323</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 12</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button456</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button122322121</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 213 321</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button>Button 123 456</PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
      </PaperPanel>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Panel 2</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFSplitButton href="#">
                <PaperPanel.Header.Menu.MenuItem>Alternate PDF 1</PaperPanel.Header.Menu.MenuItem>
                <PaperPanel.Header.Menu.MenuItem>Alternate PDF 2</PaperPanel.Header.Menu.MenuItem>
                <PaperPanel.Header.Menu.MenuItem>Alternate PDF 3</PaperPanel.Header.Menu.MenuItem>
              </PaperPanel.Header.PDFSplitButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                component={RouterNavLink}
                to={`/v2/dashboard/`}
                startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                variant="outlined"
              >
                FA Update
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                component={RouterNavLink}
                to={`/v2/dashboard/`}
                startIcon={<PictureAsPdfIcon />}
              >
                Update
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.BuildReportButton />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.DropdownMenu title="Dropdown Menu">
                {(setOpen) => (
                  <>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        console.log("Menu Item 1", event);
                        setOpen(false);
                      }}
                    >
                      Menu Item 1
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        console.log("Menu Item 2", event);
                        setOpen(false);
                      }}
                    >
                      Menu Item 2
                    </PaperPanel.Header.Menu.MenuItem>
                  </>
                )}
              </PaperPanel.Header.DropdownMenu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        console.log("Menu Item 1", event);
                        popupState.close();
                      }}
                    >
                      Menu Item 1
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        console.log("Menu Item 2", event);
                        popupState.close();
                      }}
                    >
                      Menu Item 2
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FilterAutocomplete
                name="filterAutocomplete"
                label="Filter Autocomplete"
                options={[
                  {label: "Red", value: "red"},
                  {label: "Orange", value: "orange"},
                  {label: "Yellow", value: "yellow"},
                  {label: "Green", value: "green"},
                  {label: "Blue", value: "blue"},
                  {label: "Indigo", value: "indigo"},
                  {label: "Violet", value: "violet"},
                ]}
                onChange={(value, label) => {
                  console.log(value);
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>Body 2</PaperPanel.Body>
        <PaperPanel.Alert severity="error">This is an error alert — check it out!</PaperPanel.Alert>
        <PaperPanel.Alert severity="info" variant="outlined">
          This is an info alert — check it out!
        </PaperPanel.Alert>
        <PaperPanel.Alert severity="info" variant="filled">
          This is an info alert — check it out!
        </PaperPanel.Alert>
        <PaperPanel.Alert severity="info">This is an info alert — check it out!</PaperPanel.Alert>
      </PaperPanel>
      {/*
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre>
      */}
      <Box mt={2} p={1} bgcolor={theme.palette.grey[50]}>
        <LabeledGroup label="Labeled Group">Content</LabeledGroup>
      </Box>
      <Typography variant="h2">Typography</Typography>
      <Typography variant="h1" gutterBottom>
        h1. Heading
      </Typography>
      <Typography variant="h2" gutterBottom>
        h2. Heading
      </Typography>
      <Typography variant="h3" gutterBottom>
        h3. Heading
      </Typography>
      <Typography variant="h4" gutterBottom>
        h4. Heading
      </Typography>
      <Typography variant="h5" gutterBottom>
        h5. Heading
      </Typography>
      <Typography variant="h6" gutterBottom>
        h6. Heading
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </Typography>
      <Typography variant="body1" gutterBottom>
        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
        beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
        quasi quidem quibusdam.
      </Typography>
      <Typography variant="body2" gutterBottom>
        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
        beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
        quasi quidem quibusdam.
      </Typography>
      <Typography variant="button" display="block" gutterBottom>
        button text
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        caption text
      </Typography>
      <Typography variant="overline" display="block" gutterBottom>
        overline text
      </Typography>

      <Typography variant="h2">React Grid</Typography>
      <ReactGrid
        onCellsChanged={(changes) => {
          console.log("onCellsChanged", changes);
        }}
        // enableFullWidthHeader
        rows={
          [
            {
              rowId: "header",
              cells: [
                {type: "header", text: "Description"},
                {type: "header", text: "Placeholder"},
                {type: "header", text: "Rest"},
                {type: "header", text: "ROM"},
              ],
            },
            ...Array.from(Array(20).keys()).map((i) => ({
              rowId: i,
              cells: [
                {type: "text", text: `description ${i}`},
                {type: "text", text: ``, placeholder: `Placeholder`},
                {type: "text", text: `rest ${i}`},
                {type: "number", value: i * 1000},
              ],
            })),
          ] as Row[]
        }
        columns={
          [
            {columnId: "description", width: 200},
            {columnId: "placeholder", width: 100},
            {columnId: "rest", width: 0},
            {columnId: "budget_rom", width: 150},
          ] as Column[]
        }
      />
      <MCEDialog
        isOpen={showMCEDialog}
        handleClose={() => setShowMCEDialog(false)}
        onSubmit={(values) => console.log("values", values)}
      />
      <WebcamCaptureDialog
        isOpen={showCameraDialog}
        handleClose={() => setShowCameraDialog(false)}
        onCapture={(imageSrc) => {
          console.log("imageSrc", imageSrc);
        }}
      />
      <Button variant="contained" onClick={() => setShowCameraDialog(true)}>
        Capture photo
      </Button>
      <Button variant="contained" onClick={() => setShowMCEDialog(true)}>
        Open MCE Dialog
      </Button>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      {/* <pre>{JSON.stringify(pcoItems, null, 2)}</pre>s */}
    </>
  );
};

export default Sink;

const MCEDialog = (props) => {
  const {isOpen, handleClose, onSubmit, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Dialog Form With MCE</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TinyMCEMui name="mce" label="MCE" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
