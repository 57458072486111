import {faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import classNames from "classnames";
import arrayMutators from "final-form-arrays";
import {Checkboxes} from "mui-rff";
import pluralize from "pluralize";
import React from "react";
import {Field, Form} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";
import NumberFormat from "react-number-format";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {CreateButton} from "../../components/Buttons";
import {ConfirmationDialog} from "../../components/Dialogs";
import {
  CSVTagsMui,
  MaskedInputMui,
  NumberFieldMui,
  SimpleAutoselectMui,
  SimpleSelect,
  SingleAutocompleteMui,
  TextFieldMui,
} from "../../components/forms/Fields";
import InnerFooter from "../../components/InnerFooter";
import {LabelValue} from "../../components/LabelValue";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {colorError, colorSuccess} from "../../theme/colors";

import {validateEmailAddress} from "../../utils/validators";

const showErrorByDefault = ({meta}) => meta.error;

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography, spacing} = theme;
  return {
    radioButton: {
      color: palette.primary.main,
      minWidth: 80,
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: palette.primary.main,
        fontWeight: 900,
      },
      "&.compliant": {
        backgroundColor: colorSuccess,
        "&.compliantText": {
          color: "white",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
          color: "white",
          backgroundColor: colorSuccess,
          fontWeight: 900,
        },
      },
      "&.nonCompliant": {
        backgroundColor: colorError,
        "&.nonCompliantText": {
          color: "white",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
          color: "white",
          backgroundColor: colorError,
          fontWeight: 900,
        },
      },
      "&.compliantText": {
        color: colorSuccess,
      },
      "&.nonCompliantText": {
        color: colorError,
      },
    },
    helperText: {
      "& .MuiFormHelperText-root:not(.Mui-error)": {
        color: blue[500],
      },
      "& .blue": {
        color: blue[500],
      },
    },
  };
});

const TrueFalseNAButtons = (props: {
  name: string;
  disabled?: boolean;
  isSpanish?: boolean;
  nonCompliantAnswer: boolean;
}) => {
  const {name, disabled = false, isSpanish, nonCompliantAnswer} = props;
  const classes = useStyles();
  return (
    <Field name={name}>
      {(fieldProps) => {
        const {input, meta, ...rest} = fieldProps;

        const color = [true, false, "n/a"].includes(input.value)
          ? input.value === true
            ? nonCompliantAnswer === true
              ? "nonCompliant"
              : "compliant"
            : input.value === false
              ? nonCompliantAnswer === false
                ? "nonCompliant"
                : "compliant"
              : undefined
          : undefined;

        return (
          <FormControl error={meta.invalid}>
            <ToggleButtonGroup
              size="small"
              exclusive
              {...input}
              onChange={(event, value) => {
                input.onChange(value);
              }}
            >
              <ToggleButton
                disabled={disabled}
                value={true}
                className={classNames(
                  classes.radioButton,
                  `${input.value === true && color}`,
                  `${
                    nonCompliantAnswer === true
                      ? "nonCompliantText"
                      : nonCompliantAnswer === false
                        ? "compliantText"
                        : undefined
                  }`
                )}
              >
                {isSpanish ? `Si` : `Yes`}
              </ToggleButton>
              <ToggleButton
                disabled={disabled}
                value={false}
                className={classNames(
                  classes.radioButton,
                  `${input.value === false && color}`,
                  `${
                    nonCompliantAnswer === false
                      ? "nonCompliantText"
                      : nonCompliantAnswer === true
                        ? "compliantText"
                        : undefined
                  }`
                )}
              >
                No
              </ToggleButton>

              <ToggleButton disabled={disabled} value="n/a" className={classNames(classes.radioButton)}>
                N/A
              </ToggleButton>
            </ToggleButtonGroup>
            {/* {meta.invalid && meta.touched && <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>} */}
            {meta.invalid && <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

const AddButton = (props) => {
  const {children = "Add", ...rest} = props;
  return (
    <CreateButton fullWidth variant="outlined" disableElevation {...rest}>
      {children}
    </CreateButton>
  );
};

export const PretaskPlanExternalSurveyForm = (props: {
  onSubmitted: any;
  onUpdateCrew?: any;
  pretaskPlan: any;
  questions: any;
  options: any;
  disabled?: boolean;
  isSpanish?: boolean;
  subcontractChoices?: any[];
  wellnessRedirectURL: string;
  twilioContactNamesToAddToCrew: string[];
  [rest: string]: any;
}) => {
  const {
    onSubmitted,
    onUpdateCrew,
    questions,
    disabled = false,
    pretaskPlan,
    options,
    isSpanish,
    crewAutoCompleteOptions,
    pretaskPlanQuestionsVersion,
    subcontractChoices = [],
    wellnessRedirectURL,
    twilioContactNamesToAddToCrew,
    generalSuperintendent,
    ...rest
  } = props;
  const postCurrentPage = usePostCurrentPage();
  const classes = useStyles();
  const blockUI = useBlockUI();
  const [addCrewDialogIsOpen, setAddCrewDialogIsOpen] = React.useState(false);
  const certificationRef = React.useRef(null);
  const subcontractRef = React.useRef(null);

  const permitsFormsRef = React.useRef(null);

  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return;
    }
    if (!validateEmailAddress(value)) {
      return isSpanish ? "La dirección de email no es válida" : "Not a valid email address";
    }
  };

  const formInitialValues = {general_superintendent: {...generalSuperintendent}, ...pretaskPlan.survey};

  if (subcontractChoices.length === 1) {
    formInitialValues.subcontract = subcontractChoices[0].value;
  }

  return (
    <>
      <Form
        onSubmit={(values) => {
          postCurrentPage.mutateAsync(values).then((data) => onSubmitted(data));
        }}
        validate={(values) => {
          const errors = {
            question_set_1: {},
          };
          questions.question_set_1.map((question, index) => {
            const qs1 = values.question_set_1 || {};
            const value = qs1[question.name];
            if (value === undefined || value === null) {
              errors.question_set_1[question.name] = isSpanish ? `Requerido` : `Required`;
            }
          });
          return errors;
        }}
        mutators={{
          ...arrayMutators,
        }}
        // initialValues={
        //   subcontractChoices.length === 1
        //     ? {...pretaskPlan.survey, subcontract: subcontractChoices[0].value}
        //     : pretaskPlan.survey

        // }
        initialValues={formInitialValues}
        {...rest}
      >
        {(formProps) => {
          const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
          // Add initial question set 3 if one does not exist
          if (values.question_set_2 === undefined) {
            form.mutators.push("question_set_2", undefined);
            form.mutators.push("question_set_2", undefined);
            form.mutators.push("question_set_2", undefined);
          }
          if (values.crew === undefined) {
            values.crew = {};
            // form.mutators.push("crew", {});
          }
          if (values.crew) {
            questions.crew.forEach((question) => {
              if (values.crew[question.name] === undefined) {
                // values.crew[question.name] = [""];
                values.crew[question.name] = [];
              }
            });
          }
          let invalidMessage = isSpanish ? `Complete todos los campos requeridos` : `Complete all required fields`;
          if (errors.question_set_2) {
            invalidMessage = isSpanish
              ? `Complete todos los campos requeridos (Plan de trabajo)`
              : `Complete all required fields (Work Plan)`;
          }
          if (errors.question_set_1) {
            invalidMessage = isSpanish
              ? `Complete todos los campos requeridos (Encuesta del sitio)`
              : `Complete all required fields (Site Survey)`;
          }

          let crewSize = 0;
          for (const key in values.crew) {
            crewSize += values.crew[key].length;
          }
          React.useEffect(() => {
            if (location.hash === "#forms" && permitsFormsRef) {
              permitsFormsRef.current.scrollIntoView({behavior: "smooth", block: "center"});
            } else {
              if (values.certification) {
                if (!values.subcontract && subcontractRef.current) {
                  subcontractRef.current.scrollIntoView({behavior: "smooth", block: "center"});
                  form.change("subcontract", null);
                } else {
                  certificationRef.current.scrollIntoView({behavior: "smooth", inline: "end"});
                }
              }
            }
          }, [values.certification, values.subcontract]);

          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} className={classes.helperText}>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">
                        {isSpanish ? `Tu Información de Contacto` : `Your Contact Information`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`contact.${questions.contact.name.name}`}
                        label={questions.contact.name.question}
                        disabled={disabled}
                        size="small"
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`contact.${questions.contact.company.name}`}
                        label={questions.contact.company.question}
                        disabled={disabled}
                        size="small"
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`contact.${questions.contact.title.name}`}
                        label={questions.contact.title.question}
                        disabled={disabled}
                        size="small"
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`contact.${questions.contact.email.name}`}
                        label={questions.contact.email.question}
                        disabled={disabled}
                        type="email"
                        size="small"
                        required
                        showError={showErrorByDefault}
                        fieldProps={{validate: validateEmail}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MaskedInputMui
                        name={`contact.${questions.contact.cell_number.name}`}
                        label={questions.contact.cell_number.question}
                        disabled={disabled}
                        mask="(999)-999-9999"
                        size="small"
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                  </Grid>
                  {pretaskPlanQuestionsVersion >= 2 && (
                    <>
                      <Box mt={1} />

                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            {isSpanish
                              ? `Tu supervisor${
                                  pretaskPlan?.twilio_contact?.company && ` en ${pretaskPlan?.twilio_contact?.company}`
                                }`
                              : `Your Supervisor${
                                  pretaskPlan?.twilio_contact?.company && ` at ${pretaskPlan?.twilio_contact?.company}`
                                }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name={`general_superintendent.${questions.contact.name.name}`}
                            label={questions.contact.name.question}
                            disabled={disabled}
                            size="small"
                            required
                            showError={showErrorByDefault}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldMui
                            name={`general_superintendent.${questions.contact.email.name}`}
                            label={questions.contact.email.question}
                            disabled={disabled}
                            type="email"
                            size="small"
                            required
                            showError={showErrorByDefault}
                            fieldProps={{validate: validateEmail}}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MaskedInputMui
                            name={`general_superintendent.${questions.contact.cell_number.name}`}
                            label={questions.contact.cell_number.question}
                            disabled={disabled}
                            mask="(999)-999-9999"
                            size="small"
                            required
                            showError={showErrorByDefault}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Box mt={3} />
                  <Typography variant="h4">{isSpanish ? `Inspección del lugar` : `Site Survey`}</Typography>
                  <Box mt={2}>
                    <Typography gutterBottom>
                      <strong>
                        {isSpanish
                          ? `Si la respuesta es SÍ, los peligros deben abordarse en el plan de trabajo a continuación.`
                          : `If answer is YES to any of the following, hazards must be addressed in the work plan below.`}
                      </strong>
                    </Typography>
                  </Box>
                  {questions.question_set_1
                    .filter((question) => question.noncompliant_answer === true)
                    .map((question, index) => {
                      return (
                        <React.Fragment key={question.name}>
                          <div>
                            {index !== 0 && (
                              <Box mt={1}>
                                <Divider />
                              </Box>
                            )}
                            {question.name === "hot_work_required" && <Box ref={permitsFormsRef} />}
                            <div>{question.question}</div>
                            {question?.helperText && <FormHelperText>{question?.helperText}</FormHelperText>}
                            <TrueFalseNAButtons
                              name={`question_set_1.${question.name}`}
                              disabled={disabled}
                              isSpanish={isSpanish}
                              nonCompliantAnswer={question.noncompliant_answer}
                            />
                            {question.name === "hot_work_required" && values?.question_set_1?.hot_work_required && (
                              <>
                                <Box>
                                  {isSpanish
                                    ? `¿En cuántas áreas separadas se realizará el trabajo en caliente?`
                                    : `In how many separate areas will hot work be performed?`}
                                </Box>
                                <Box width="8rem" display="flex" mt={0.5}>
                                  <NumberFieldMui name="question_set_1.hot_work_area_count" label="Areas" required />
                                </Box>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  <Box mt={3}>
                    <Typography gutterBottom>
                      <strong>
                        {isSpanish
                          ? `Si la respuesta a cualquiera de las siguientes preguntas es NO, se suspenderá el trabajo hasta que se solucione el incumplimiento.`
                          : `If answer is NO to any of the following, work shall be stopped until non-compliance is addressed.`}
                      </strong>
                    </Typography>
                  </Box>
                  {questions.question_set_1
                    .filter((question) => question.noncompliant_answer === false)
                    .map((question, index) => {
                      return (
                        <React.Fragment key={question.name}>
                          <div key={question.name}>
                            {index !== 0 && (
                              <Box mt={1}>
                                <Divider />
                              </Box>
                            )}

                            <div>{question.question}</div>
                            {question?.helperText && <FormHelperText>{question?.helperText}</FormHelperText>}
                            <TrueFalseNAButtons
                              name={`question_set_1.${question.name}`}
                              disabled={disabled}
                              isSpanish={isSpanish}
                              nonCompliantAnswer={question.noncompliant_answer}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  <Box mt={2} />

                  <Typography gutterBottom>
                    <strong>
                      {isSpanish
                        ? `Por favor aborde cualquier tema de seguridad no identificado arriba.`
                        : `Please address any safety item not identified above.`}
                    </strong>
                  </Typography>
                  <TextFieldMui
                    name="question_set_1.notes"
                    label={isSpanish ? `Notas` : `Notes`}
                    disabled={disabled}
                    size="small"
                    multiline={true}
                    minRows={4}
                  />

                  <Box mt={3} />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">{isSpanish ? `Trabajo` : `Work`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {subcontractChoices.length > 0 && (
                        <Box ref={subcontractRef}>
                          <SimpleSelect
                            options={subcontractChoices}
                            name="subcontract"
                            label="Subcontract"
                            disabled={subcontractChoices.length === 1}
                            required={subcontractChoices.length > 1}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleAutoselectMui
                        name={`work.${questions.work.trade.name}`}
                        label={questions.work.trade.question}
                        helperText={questions.work.trade.helper_text}
                        disabled={disabled}
                        size="small"
                        required
                        showError={showErrorByDefault}
                        options={options?.trade}
                        // options={options?.trade.map((option) => {
                        //   return {
                        //     value: option,
                        //     label: option,
                        //   };
                        // })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`work.${questions.work.description.name}`}
                        label={questions.work.description.question}
                        helperText={questions.work.description.helper_text}
                        disabled={disabled}
                        size="small"
                        multiline={true}
                        minRows={4}
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`work.${questions.work.days.name}`}
                        label={questions.work.days.question}
                        helperText={questions.work.days.helper_text}
                        type="number"
                        disabled={disabled}
                        size="small"
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name={`work.${questions.work.specific_location_of_work.name}`}
                        label={questions.work.specific_location_of_work.question}
                        helperText={questions.work.specific_location_of_work.helper_text}
                        disabled={disabled}
                        size="small"
                        // multiline={true}
                        // rows={4}
                        required
                        showError={showErrorByDefault}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CSVTagsMui
                        name={`work.${questions.work.type_of_live_systems_to_be_worked_around.name}`}
                        label={questions.work.type_of_live_systems_to_be_worked_around.question}
                        options={options?.liveSystems}
                        helperText={questions.work.type_of_live_systems_to_be_worked_around.helper_text}
                        disabled={disabled}
                        required={
                          values.question_set_1?.are_you_working_around_live_systems_or_energized_equipment === true &&
                          values.work[questions.work.type_of_live_systems_to_be_worked_around.name]?.length < 1
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box mt={3} />
                  <Typography variant="h4">
                    {isSpanish ? `Equipo de protección personal requerido` : `Personal Protective Equipment Required`}
                  </Typography>
                  <Checkboxes
                    name="ppe"
                    disabled={disabled}
                    data={questions.ppe.map((question) => {
                      return {label: question.question, value: question.name};
                    })}
                  />
                  {questions.additional_ppe.map((question) => {
                    return (
                      <Box mt={1} key={question.name}>
                        <TextFieldMui
                          name={`additional_ppe.${question.name}`}
                          label={question.question}
                          disabled={disabled}
                          size="small"
                        />
                      </Box>
                    );
                  })}

                  <Box mt={3} />
                  <Typography variant="h4">{isSpanish ? `Localizar` : `Locate`}</Typography>
                  <Typography variant="subtitle1" className="blue">
                    {isSpanish
                      ? `Debe localizar cada uno de los siguientes artículos`
                      : ` Must locate each of the following items`}
                  </Typography>
                  <Checkboxes
                    name="locate"
                    disabled={disabled}
                    data={questions.locate.map((question) => {
                      return {label: question.question, value: question.name};
                    })}
                    required
                  />

                  <Box mt={3} />
                  <Typography variant="h4">{isSpanish ? `Plan de Trabajo` : `Work Plan`}</Typography>
                  <Box mt={1} />
                  <ol style={{paddingLeft: 4}}>
                    <FieldArray name="question_set_2">
                      {({fields}) => {
                        return (
                          <div>
                            {fields.map((name, i) => (
                              <li key={`questionSet2${i}`}>
                                {i !== 0 && (
                                  <Box mt={1} mb={1}>
                                    <Divider />
                                  </Box>
                                )}
                                <Box display="flex">
                                  <Box flexGrow={1}>
                                    <Grid container spacing={1}>
                                      {questions.question_set_2.map((question) => {
                                        const questionKey = `${name}.${question.name}`;
                                        const isStepsTakenQuestion = question.name === "steps_taken_to_address_hazards";
                                        return (
                                          <Grid item key={questionKey} xs={12} sm={isStepsTakenQuestion ? 6 : 3}>
                                            {isStepsTakenQuestion ? (
                                              <TextFieldMui
                                                name={questionKey}
                                                label={question.question}
                                                disabled={disabled}
                                                size="small"
                                                multiline
                                                required
                                                showError={showErrorByDefault}
                                                fieldProps={{
                                                  validate: (value) => {
                                                    value = value || "";
                                                    if (value.length < 25) {
                                                      return `${
                                                        isSpanish
                                                          ? `Debe tener al menos 25 caracteres`
                                                          : `Must be at least 25 characters`
                                                      } (${value.length})`;
                                                    }
                                                  },
                                                }}
                                              />
                                            ) : (
                                              <TextFieldMui
                                                name={questionKey}
                                                label={question.question}
                                                disabled={disabled}
                                                size="small"
                                                multiline={isStepsTakenQuestion}
                                                required
                                                showError={showErrorByDefault}
                                                // required={question.name === "steps_for_work"}
                                              />
                                            )}
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Box>
                                  <Box ml={1}>
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      disabled={i < 3}
                                      onClick={() => fields.remove(i)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </li>
                            ))}
                            <Box mt={1} />
                            <AddButton size="small" disabled={disabled} onClick={() => fields.push(undefined)}>
                              {isSpanish ? `Agregar paso del plan de trabajo` : `Add Work Plan Step`}
                            </AddButton>
                          </div>
                        );
                      }}
                    </FieldArray>
                  </ol>

                  <Box mt={3} />
                  <Typography variant="h4">{isSpanish ? `Equipo` : `Equipment`}</Typography>
                  <Box mt={1} />
                  <FieldArray name={`equipment`}>
                    {({fields}) => {
                      return (
                        <div>
                          {fields.map((name, i) => (
                            <React.Fragment key={name}>
                              {i !== 0 && <Box mt={1} />}
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  {/* <TextFieldMui
                                  name={name}
                                  label={`Equipment ${i + 1}`}
                                  disabled={disabled}
                                  size="small"
                                  multiline
                                  required
                                /> */}

                                  <SingleAutocompleteMui
                                    name={`${name}.description`}
                                    label={`${isSpanish ? `Equipo` : `Equipment`} ${i + 1}`}
                                    disabled={disabled}
                                    size="small"
                                    required
                                    options={options?.equipment.map((option) => {
                                      return {
                                        value: option,
                                        label: option,
                                      };
                                    })}
                                  />
                                </Box>
                                <Box ml={1}>
                                  <Checkboxes
                                    name={`${name}.inspected`}
                                    size="small"
                                    disabled={disabled}
                                    data={{label: isSpanish ? `Inspeccionado` : `Inspected`, value: true}}
                                    required
                                  />
                                </Box>
                                <Box ml={1}>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    disabled={disabled}
                                    onClick={() => fields.remove(i)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                          <Box my={1}>
                            <AddButton size="small" disabled={disabled} onClick={() => fields.push(undefined)}>
                              {isSpanish ? `Agregar Equipo` : `Add Equipment`}
                            </AddButton>
                          </Box>
                        </div>
                      );
                    }}
                  </FieldArray>

                  <Box mt={3} display="flex" justifyContent="space-between">
                    <Typography variant="h4" component="span">
                      {isSpanish ? `Personal` : `Crew`}
                    </Typography>
                    {twilioContactNamesToAddToCrew.length > 0 && (
                      <Box textAlign="right" component="span">
                        <Button
                          disableElevation
                          variant="outlined"
                          onClick={() => setAddCrewDialogIsOpen(true)}
                          disabled={disabled}
                        >
                          <Chip size="small" label={twilioContactNamesToAddToCrew.length} />
                          <Box ml={1} />
                          {isSpanish ? `Agregar tripulación faltante` : `Add Missing Crew`}
                        </Button>
                        {/* <ColoredButton onClick={onUpdateCrew} disabled={!onUpdateCrew}>

                  </ColoredButton> */}
                      </Box>
                    )}
                  </Box>
                  <Box mt={1} />
                  {Object.entries(questions.crew).map(([key, _crewQuestion]) => {
                    const crewQuestion = _crewQuestion as any;
                    return (
                      <div key={crewQuestion.name}>
                        <FieldArray name={`crew.${crewQuestion.name}`}>
                          {({fields}) => {
                            return (
                              <div>
                                {fields.map((name, i) => (
                                  <React.Fragment key={name}>
                                    {i !== 0 && <Box mt={1} />}
                                    <Box display="flex">
                                      <Box flexGrow={1}>
                                        <SingleAutocompleteMui
                                          name={name}
                                          label={`${crewQuestion.question} ${i + 1}`}
                                          disabled={disabled}
                                          options={crewAutoCompleteOptions}
                                          freeSolo
                                          valueProp="label"
                                          required
                                        />
                                      </Box>
                                      <Box ml={1}>
                                        <IconButton
                                          aria-label="delete"
                                          size="small"
                                          disabled={disabled}
                                          onClick={() => fields.remove(i)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                ))}
                                <Box my={1}>
                                  <AddButton size="small" disabled={disabled} onClick={() => fields.push(undefined)}>
                                    {isSpanish ? `Agregar ` : `Add `} {crewQuestion.question}
                                  </AddButton>
                                </Box>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </div>
                    );
                  })}

                  <Box mt={3} />
                  <Typography variant="h4">{isSpanish ? `Horas` : `Hours`}</Typography>
                  <Box mt={1} />
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <NumberFieldMui
                        name="time.hours_per_crewmember"
                        label="Hours Worked Per Crewmember"
                        size="small"
                        required
                        fieldProps={{
                          defaultValue: 8,
                          initialValue: 8,
                          validate: (value) => {
                            if (parseInt(value) < 1) {
                              return isSpanish
                                ? `
                              Ingrese las horas trabajadas hoy por miembro de la tripulación.`
                                : `Please enter today's hours worked for per crewmember.`;
                            }
                          },
                        }}
                        helperText={
                          isSpanish
                            ? `Tiempo de tiempo que la tripulación está en el sitio hoy (generalmente 8 horas)`
                            : `Length of time crew is onsite today (usually 8 hours)`
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NumberFieldMui
                        name={`time.${questions.time.todays_hours_worked.name}`}
                        label={questions.time.todays_hours_worked.question}
                        disabled
                        size="small"
                        // prefix="$"

                        value={values?.time?.hours_per_crewmember * crewSize || 0}
                      />
                      {/* {" "}
                      <NumberFieldMui
                        name={`time.${questions.time.todays_hours_worked.name}-json`}
                        label={"Hours Per Crewmember from JSON"}
                        disabled
                        value={pretaskPlan?.survey?.time?.todays_hours_worked}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NumberFieldMui
                        name={`time.${questions.time.current_hours_worked_to_date.name}`}
                        label={questions.time.current_hours_worked_to_date.question}
                        helperText={questions.time.current_hours_worked_to_date.helper_text}
                        disabled
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NumberFieldMui
                        name="new_hours_to_date"
                        label="Total Hours to Date"
                        disabled
                        size="small"
                        value={
                          (values?.time?.current_hours_worked_to_date || 0) + (values?.time?.todays_hours_worked || 0)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box mt={3} />
                  <Typography variant="h4">
                    {isSpanish ? `Total de lesiones hasta la fecha` : `Total Injuries to Date`}
                  </Typography>
                  <Box mt={1} />
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <NumberFieldMui
                        name={`injuries.${questions.injuries.first_aids.name}`}
                        label={questions.injuries.first_aids.question}
                        disabled={disabled}
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberFieldMui
                        name={`injuries.${questions.injuries.recordables.name}`}
                        label={questions.injuries.recordables.question}
                        disabled={disabled}
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberFieldMui
                        name={`injuries.${questions.injuries.lost_times.name}`}
                        label={questions.injuries.lost_times.question}
                        disabled={disabled}
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LabelValue
                        label={isSpanish ? `Tasa de incidentes:` : `Incident Rate:`}
                        value={
                          <NumberFormat
                            // title={`Total Hours`}
                            thousandSeparator={true}
                            displayType="text"
                            value={pretaskPlan.incident_rate}
                          />
                        }
                      />
                      <Typography variant="subtitle1">
                        {isSpanish
                          ? `Tasa de Incidentes = (# de Registrables + # de Tiempos Perdidos * 200,000)/Total de Horas Trabajadas`
                          : `Incident Rate = (# of Recordables + # of Lost Times * 200,000)/Total Hours Worked`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box mt={3} ref={certificationRef}>
                    <Checkboxes
                      name="certification"
                      disabled={disabled}
                      data={questions.certification.map((question) => {
                        return {label: question.question, value: question.name};
                      })}
                      required
                    />
                  </Box>
                  <InnerFooter hasSidebar={false}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      type="submit"
                      size="large"
                      disabled={invalid || submitting || pristine || postCurrentPage.isLoading || disabled}
                      // disabled={submitting || pristine || postCurrentPage.isLoading || disabled}
                    >
                      {invalid ? invalidMessage : isSpanish ? `Guardar` : `Save`}
                    </Button>
                    <Box mt={2} />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="small"
                      href={wellnessRedirectURL}
                      startIcon={<FontAwesomeIcon icon={faQrcode} />}
                    >
                      {isSpanish ? `Volver al Código QR` : `Back to QR Code`}
                    </Button>
                  </InnerFooter>
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(questions, null, 2)}</pre> */}
                </FinalFormKeyboardShortcuts>
                <ConfirmationDialog
                  isOpen={addCrewDialogIsOpen}
                  onApprove={() => {
                    blockUI.blockUI(isSpanish ? `Agregando tripulación...` : `Adding Crew...`);
                    postCurrentPage.mutateAsync(values).then(() => {
                      onUpdateCrew();
                    });
                  }}
                  onDeny={() => setAddCrewDialogIsOpen(false)}
                  title={
                    isSpanish
                      ? `Quiere agregar la siguiente tripulación faltante ${twilioContactNamesToAddToCrew.length}`
                      : `You Want to Add the Following Missing ${twilioContactNamesToAddToCrew.length} Crew ${pluralize(
                          "Member",
                          twilioContactNamesToAddToCrew.length
                        )}`
                  }
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      {isSpanish
                        ? `Las siguientes personas se han registrado en el trabajo, pero actualmente no están en el Plan de tareas previas:`
                        : `The following people have checked into the job, but are not currently on the Pretask Plan:`}
                    </Grid>
                    {twilioContactNamesToAddToCrew.map((name) => (
                      <Grid item xs={6} sm={4} key={name}>
                        {name}
                      </Grid>
                    ))}
                  </Grid>
                </ConfirmationDialog>
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
};

export const PretaskPlanExternalEmailLinkFrom = (props: {onSubmit: any; isSpanish?: boolean; [rest: string]: any}) => {
  const {onSubmit, isSpanish, ...rest} = props;
  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return;
    }
    if (!validateEmailAddress(value)) {
      return isSpanish ? "La dirección de email no es válida" : "Not a valid email address";
    }
  };
  return (
    <Form onSubmit={onSubmit} {...rest}>
      {(formProps) => {
        const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
        return (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={7} md={10}>
                  <TextFieldMui
                    name="email"
                    label="Email"
                    type="email"
                    size="small"
                    helperText={
                      isSpanish
                        ? `Ingrese un correo electrónico para enviar este enlace a otro dispositivo o persona`
                        : "Enter an email to send this link to another device or person"
                    }
                    required
                    fieldProps={{validate: validateEmail}}
                  />
                </Grid>
                <Grid item xs={5} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                    // size="large"
                    startIcon={<SendIcon />}
                    disabled={invalid || submitting || !values.email}
                  >
                    {isSpanish ? `Enviar Enlance` : `Email Link`}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        );
      }}
    </Form>
  );
};
