import {faComments, faExclamationCircle, faRightLeft} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import classnames from "classnames";
import pluralize from "pluralize";
import qs from "query-string";
import {Helmet} from "react-helmet";
import NumberFormat from "react-number-format";
import {useParams} from "react-router";
import BlockUI from "../../../../components/GlobalLoaders";
import {StatusIcon} from "../../../../components/Icons";
import {LegacyUILink} from "../../../../components/Links";
import {PageHeader} from "../../../../components/PageHeader";
import PaperPanel from "../../../../components/PaperPanel";
import {ReactTableMui} from "../../../../components/ReactTableMui";
import Typography2 from "../../../../components/Typography2";
import useBlockUI from "../../../../hooks/useBlockUI";
import {useFetchCurrentPage} from "../../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../../theme";
import {colorError, colorWarning} from "../../../../theme/colors";

const ExternalProjectDetail = (props) => {
  const {contact, project, user, ...rest} = props;
  const params = useParams();
  const blockUI = useBlockUI();
  const {contactUUID, projectUUID, rfiUUID} = params;
  const qsValues = qs.parse(location.search);
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        isFollowing: false,
      },
    },
    {
      token: qsValues.token,
    }
  );
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Project info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display}`} />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>{project.display}</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      {contact.has_portal_user && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>
              {contact.first_name}, it looks like you are signed up to use the NOVO Construction portal.
            </AlertTitle>
            <Link href={project.portal_url} underline="always" target="_blank">
              Click here to sign in to the portal
            </Link>
            . This will give you access to all of your projects with NOVO Construction.
          </Alert>
        </Box>
      )}
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title maxWidth={"100%"}>{project.display}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton onClick={pageDataQuery.refetch} isFetching={pageDataQuery.isFetching} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body mx={1} my={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PaperPanel>
                <PaperPanel.Header>
                  <FontAwesomeIcon icon={faComments} />
                  <Box mr={1} />
                  RFIs
                </PaperPanel.Header>
                <ReactTableMui
                  mx={-1}
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  rightAlignColumns={["timestamp"]}
                  columns={[
                    {
                      Header: "RFI",
                      accessor: "display",
                      Cell: ({value, row}) => {
                        return (
                          <>
                            <StatusIcon
                              status={row.original.status}
                              type="rfi"
                              tooltip={row.original.status_display}
                              showTooltip
                            />{" "}
                            <Link href={row.original.url} underline="always">
                              {value}
                            </Link>
                          </>
                        );
                      },
                    },

                    {
                      Header: "Required",
                      accessor: "date_required",
                      Cell: ({value, row}) => {
                        const rfi = row.original;
                        return (
                          <>
                            <Box>{value}</Box>
                            {rfi.is_overdue && rfi.status !== "external_response" && (
                              <Typography2 type="metadata" style={{color: colorError}}>
                                <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                                <NumberFormat
                                  title={rfi.date_required}
                                  thousandSeparator={true}
                                  displayType="text"
                                  value={rfi.business_days_required}
                                />{" "}
                                {`${pluralize("business day", rfi.business_days_required)} overdue`}
                              </Typography2>
                            )}
                            {rfi.due_soon && (
                              <Typography2 type="metadata" style={{color: colorWarning}}>
                                <FontAwesomeIcon icon={faExclamationCircle} /> due in{" "}
                                <NumberFormat
                                  title={rfi.date_required}
                                  thousandSeparator={true}
                                  displayType="text"
                                  value={rfi.business_days_required}
                                />{" "}
                                {`${pluralize("business day", rfi.business_days_required)}`}
                              </Typography2>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                  data={pageData.rfis}
                />
              </PaperPanel>
            </Grid>
            <Grid item xs={12} md={6}>
              <PaperPanel>
                <PaperPanel.Header>
                  <FontAwesomeIcon icon={faRightLeft} />
                  <Box mr={1} />
                  Submittals
                </PaperPanel.Header>
                <ReactTableMui
                  mx={-1}
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  columns={[
                    {
                      Header: "Submittal",
                      accessor: "display",
                      Cell: ({value, row}) => {
                        return (
                          <>
                            <StatusIcon
                              status={row.original.status}
                              type="submittal"
                              tooltip={row.original.status_display}
                              showTooltip
                            />{" "}
                            <Link href={row.original.url} underline="always">
                              {value}
                            </Link>
                          </>
                        );
                      },
                    },

                    {
                      Header: "Submitted",
                      accessor: "submitted_date",
                    },
                    {
                      Header: "Due Date",
                      accessor: "due_date",
                      Cell: ({value, row}) => {
                        const submittal = row.original;
                        return (
                          <>
                            <Box>{value}</Box>
                            {!submittal.is_closed && submittal.is_overdue && (
                              <Typography2 type="metadata" style={{color: colorError}}>
                                <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                                <NumberFormat
                                  title={submittal.due_date}
                                  thousandSeparator={true}
                                  displayType="text"
                                  value={submittal.business_days_overdue}
                                />{" "}
                                {`${pluralize("business day", submittal.business_days_overdue)} overdue`}
                              </Typography2>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                  data={pageData.submittals}
                />
              </PaperPanel>
            </Grid>
          </Grid>
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={pageData.legacyURL} mt={2} />
      {/* <h1>Page Data</h1>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <h1>Project</h1>
      <pre>{JSON.stringify(project, null, 2)}</pre>
      <h1>Props</h1>
      <pre>{JSON.stringify(project, null, 2)}</pre> */}
    </>
  );
};

export default ExternalProjectDetail;
