import {Box, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ProjectStatusIcon} from "../../../components/Icons";
import {LegacyUILink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {ProjectUpdateForm} from "./ProjectForms";

const ProjectUpdate = (props) => {
  const {project: initialProject, ...rest} = props;
  const {projectId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const {
    query: projectQuery,
    create: createProject,
    update: updateProject,
    delete: deleteProject,
  } = useSentinelDetailAPI(["projects", parseInt(projectId)], {
    initialData: initialProject,
  });
  const project = projectQuery.data;

  const {data: pageData, refetch: refetchPageData} = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      extraProjectProps: {},
    },
  });
  const {extraProjectProps} = pageData;

  return (
    <>
      <Helmet title={`${project.display} - Update`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Update</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            <ProjectStatusIcon project={project} /> {project.display} - Update
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      {/*
      <Box color="text.novo">
        <Typography>{nl2br(project?.description)}</Typography>
      </Box>
       */}
      <Box mb={1} />
      <ProjectUpdateForm
        initialValues={project}
        needsJobStartSubmission={extraProjectProps.needsJobStartSubmission}
        onSubmit={(values) => {
          updateProject.mutateAsync(values).then((project) => {
            console.log("project updated", project);
          });
        }}
      />
      <LegacyUILink href={`/projects/${projectId}/update/legacy/`} mt={2} />
      {/*
      <Typography variant="h2">pageData</Typography>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <Typography variant="h2">projectQuery</Typography>
      <pre>{JSON.stringify(projectQuery, null, 2)}</pre>
      <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre>
      */}
    </>
  );
};

export default ProjectUpdate;
