import {faSlack} from "@fortawesome/free-brands-svg-icons";
import {faArrowUpRightFromSquare, faInfoCircle, faSync, faUsers} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LabelValueList} from "../../../components/LabelValue";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";

const SlackProject = (props) => {
  const {user, project, slackTeamId, ...rest} = props;

  const hasChannel = project.slack_channel_id;

  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const {query: memberQuery} = useSentinelListAPI(`projects/${project.id}/slack/channel/members/`, {
    initialData: [],
    enabled: Boolean(hasChannel),
  });

  const members = memberQuery.data;

  const {query: channelQuery} = useSentinelDetailAPI(`projects/${project.id}/slack/channel/`, {
    initialData: {
      channel: {},
    },
    enabled: Boolean(hasChannel),
  });

  const slackChannel = channelQuery.data.channel;

  const isMemberOfChannel = members.map((member) => member.slack_user_id).includes(user?.slack_user_id);

  if (hasChannel)
    if (!channelQuery.isFetchedAfterMount || !memberQuery.isFetchedAfterMount) {
      return <BlockUI show={true} message="Fetching Slack Info..." />;
    }

  return (
    <>
      <Helmet title={`${project.display} - Slack`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Slack</Typography>
      </ProjectBreadcrumbs>
      {!user.slack_user_id && (
        <Box mb={1}>
          <Alert severity="warning">
            It looks like either your Slack account is not linked to Sentinel or you do not have a Slack account. Click{" "}
            <Link href="/accounts/detail/" underline="always">
              here
            </Link>{" "}
            to setup Slack.
          </Alert>
        </Box>
      )}
      {hasChannel && !isMemberOfChannel && user.slack_user_id && (
        <Box mb={1}>
          <Alert severity="warning">
            It looks like you are not a member of this channel.{" "}
            <Link
              onClick={() => {
                blockUI.blockUI("Joining...");
                axiosAPI
                  .post(`projects/${project.id}/slack/channel/members/`)
                  .then(() => Promise.all([memberQuery.refetch(), channelQuery.refetch()]))
                  .then(() => blockUI.unblockUI())
                  .catch(() => blockUI.unblockUI());
              }}
              underline="always"
              style={{cursor: "pointer"}}
            >
              Click here to join.
            </Link>
          </Alert>
        </Box>
      )}
      <PaperPanel>
        <PaperPanel.Header isLoading={channelQuery.isFetching || memberQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faSlack} fixedWidth />
            Slack
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {hasChannel && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.ColoredButton
                  startIcon={<FontAwesomeIcon icon={faSync} />}
                  onClick={() => {
                    blockUI.blockUI("Updating...");
                    axiosAPI
                      .put(`projects/${project.id}/slack/channel/`)
                      .then(() => channelQuery.refetch())
                      .then(() => blockUI.unblockUI())
                      .catch(() => blockUI.unblockUI());
                  }}
                >
                  Update Name and Topic
                </PaperPanel.Header.ColoredButton>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  memberQuery.refetch();
                  channelQuery.refetch();
                }}
                isFetching={memberQuery.isFetching || channelQuery.isFetching}
                disabled={!hasChannel}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {hasChannel ? (
          <PaperPanel.Body>
            <Box my={1} mx={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <PaperPanel>
                    <PaperPanel.Header isLoading={memberQuery.isFetching}>
                      <PaperPanel.Header.Title>
                        <FontAwesomeIcon icon={faInfoCircle} /> Channel Info
                      </PaperPanel.Header.Title>
                      <PaperPanel.Header.Actions></PaperPanel.Header.Actions>
                    </PaperPanel.Header>
                    <PaperPanel.Body p={1} maxHeight={550} overflow="auto">
                      <LabelValueList>
                        <LabelValueList.Item label="Channel ID" value={slackChannel?.id} />
                        <LabelValueList.Item label="Channel Name" value={slackChannel?.name} />
                        <LabelValueList.Item label="Channel Topic" value={slackChannel?.topic?.value} />
                        {isMemberOfChannel && (
                          <>
                            <LabelValueList.Item
                              label="App Link"
                              value={
                                <Link
                                  href={`slack://channel?team=${slackChannel.context_team_id}&id=${slackChannel?.id}`}
                                >
                                  Open In Slack <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </Link>
                              }
                            />
                            <LabelValueList.Item
                              label="Web Link"
                              value={
                                <Link
                                  href={`https://app.slack.com/client/${slackChannel.context_team_id}/${slackChannel?.id}`}
                                  target="_blank"
                                >
                                  Open In Browser <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </Link>
                              }
                            />
                          </>
                        )}
                      </LabelValueList>
                    </PaperPanel.Body>
                  </PaperPanel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PaperPanel>
                    <PaperPanel.Header isLoading={memberQuery.isFetching}>
                      <PaperPanel.Header.Title>
                        <FontAwesomeIcon icon={faUsers} /> Users
                      </PaperPanel.Header.Title>
                      <PaperPanel.Header.Actions></PaperPanel.Header.Actions>
                    </PaperPanel.Header>
                    <PaperPanel.Body p={1} maxHeight={550} overflow="auto">
                      <Table size="small" padding="none">
                        <TableBody>
                          {members.map((member) => {
                            if (member.full_name !== "Novo Sentinel")
                              return (
                                <TableRow key={member.id}>
                                  <TableCell>{member.full_name}</TableCell>
                                </TableRow>
                              );
                          })}
                        </TableBody>
                      </Table>
                    </PaperPanel.Body>
                  </PaperPanel>
                </Grid>
              </Grid>
            </Box>
          </PaperPanel.Body>
        ) : (
          <>
            <Alert severity="warning">
              It looks like this project does not currently have a Slack channel. <p />
              <Link
                onClick={() => {
                  blockUI.blockUI("Creating...");
                  axiosAPI
                    .post(`projects/${project.id}/slack/channel/`)
                    .then(() => {
                      window.location.href = `/v2/projects/${project.id}/slack/`;
                      blockUI.unblockUI();
                    })

                    .catch(() => blockUI.unblockUI());
                }}
                underline="always"
                style={{cursor: "pointer"}}
              >
                Click here
              </Link>{" "}
              to create a Slack channel for <strong>{project.display}</strong>
            </Alert>
          </>
        )}
      </PaperPanel>
    </>
  );
};

export default SlackProject;
