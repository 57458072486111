import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const WorkLogBySubcontractor = (props) => {
  const {project, ...rest} = props;
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      subcontractor: {},
      workLog: [],
    },
  });
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching work log..." />;
  }
  return (
    <>
      <Helmet title={`Work Log For ${pageData.subcontractor.name}`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/daily-reports/`}>
          Daily Reports
        </MuiNavLink>
        <MuiNavLink href={`/v2/projects/${project.id}/daily-reports/work-log-by-subcontractor/`}>
          <Typography color="textSecondary">Work Logs</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">Work Log For {pageData.subcontractor.name}</Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>Work Log For {pageData.subcontractor.name}</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Work Log For {pageData.subcontractor.name}</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.Button
                    startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                    disabled={pageDataQuery.isFetching}
                    onClick={(event) => {
                      saveExcel(
                        pageData.workLog.map((item) => {
                          return {
                            Date: {v: item.date, t: "d", z: "yyyy-mm-dd"},
                            Description: item.description,
                            Quantity: {v: item.quantity, t: "n"},
                            Hours: {v: item.hours, t: "n"},
                            Total: {v: item.total_hours, t: "n"},
                          };
                        }),
                        `${pageData.subcontractor.name} - Work Log`,
                        [{wch: 10}, {wch: 50}, {wch: 10}, {wch: 10}, {wch: 10}]
                      );
                    }}
                  >
                    Export Excel
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>

                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>

            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["quantity", "hours", "total"]}
                showFooter={Boolean(pageData.workLog.length)}
                getCellProps={(cell) => {
                  if (["date"].includes(cell.column.id)) {
                    return {
                      style: {
                        whiteSpace: "nowrap",
                      },
                    };
                  }
                }}
                columns={[
                  {
                    Header: "Date",
                    accessor: "date",
                    Cell: ({value, row}) => {
                      return (
                        <MuiNavLink
                          underline="always"
                          to={`/v2/projects/${project.id}/daily-reports/${row.original.daily_report_id}/`}
                        >
                          {value ? moment(value).format("YYYY-MM-DD - ddd") : ""}
                        </MuiNavLink>
                      );
                    },
                  },

                  {
                    Header: "Description",
                    accessor: "description",
                  },
                  {
                    Header: "Quantity",
                    accessor: "quantity",
                    Cell: ({value, row}) => {
                      return accounting.formatNumber(value, 0);
                    },
                  },
                  {
                    Header: "Hours",
                    accessor: "hours",
                    Cell: ({value, row}) => {
                      return accounting.formatNumber(value, 0);
                    },
                  },
                  {
                    Header: "Total",
                    id: "total",
                    accessor: (value) => {
                      return value.total_hours;
                    },
                    Cell: ({value, row}) => {
                      return accounting.formatNumber(value, 0);
                    },
                    Footer: () => {
                      const total = React.useMemo(
                        () => pageData.workLog.reduce((sum, row) => row.total_hours + sum, 0),
                        [pageData.workLog]
                      );
                      return total ? accounting.formatNumber(total, 0) : "";
                    },
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "date",
                      desc: true,
                    },
                  ],
                }}
                data={pageData.workLog}
              />

              {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
      </Grid>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default WorkLogBySubcontractor;
