import {faAward, faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumbs, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {Currency} from "../../components/Accounting";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";
import {SubcontractorAwardFilterForm} from "./SubcontractorAwards";

const SubcontractorAwardVendor = (props) => {
  // const appContext = React.useContext(AppContext);
  const novoClasses = makeNovoClasses();
  const location = useLocation();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Start Date", "End Date", "Office"],
    {
      "Start Date": {
        value: moment().startOf("year").format("YYYY-MM-DD"),
        label: moment().startOf("year").format("YYYY-MM-DD"),
      },
      // "End Date": {
      //   value: moment().format("YYYY-MM-DD"),
      //   label: moment().format("YYYY-MM-DD"),
      // },
    }
  );

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        commitments: [],
      },
    },
    {
      start_date: filterOptions["Start Date"].value,
      end_date: filterOptions["End Date"].value,
      office: filterOptions.Office.value,
    }
    // qs.parse(location.search)
  );
  const vendor = pageDataQuery.data.vendor;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching subcontractor awards..." />;
  }

  // const otherCodesCount = Object.entries(pageDataQuery.data.otherCodesCount).map(([key, value]) => `${key} (${value})`);

  return (
    <>
      <Helmet title="Subcontractor Awards" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to="/v2/preconstruction/subcontractor-awards/">
          Subcontractor Awards
        </MuiNavLink>
        <Typography color="textPrimary">{vendor.name}</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faAward} /> {vendor.name} Awards
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageDataQuery.data.commitments.map((item) => {
                      return {
                        "Cost Code": item.cost_code,
                        "Job Code": item.job_code,
                        "Job Description": item.job_description,
                        "Commitment Date": item.commitment_date,
                        Paid: item.amount_paid,
                        Amount: item.amount,
                      };
                    }),
                    `${vendor.name} Awards`,
                    [{wch: 15}, {wch: 15}, {wch: 40}, {wch: 15}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <SubcontractorAwardFilterForm
            filterOptions={filterOptions}
            setFilterOption={setFilterOption}
            clearFilterOption={clearFilterOption}
            clearAllFilterOptions={clearAllFilterOptions}
          />
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            rightAlignColumns={["amount_paid", "amount"]}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            showFooter={Boolean(pageDataQuery.data.commitments.length)}
            columns={[
              {
                Header: "Cost Code",
                accessor: "cost_code",
              },
              {
                Header: "Job Code",
                accessor: "job_code",
              },
              {
                Header: "Job Description",
                accessor: "job_description",
              },
              {
                Header: "Commitment Date",
                accessor: "commitment_date",
              },
              {
                Header: "Paid",
                accessor: "amount_paid",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageDataQuery.data.commitments.reduce((sum, row) => row.amount_paid + sum, 0),
                    [pageDataQuery.data.commitments]
                  );
                  return total ? <Currency number={total} /> : "";
                },
              },
              {
                Header: "Amount",
                accessor: "amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageDataQuery.data.commitments.reduce((sum, row) => row.amount + sum, 0),
                    [pageDataQuery.data.commitments]
                  );
                  return total ? <Currency number={total} /> : "";
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "commitment_date",
                  desc: true,
                },
              ],
            }}
            data={pageDataQuery.data.commitments}
          />
        </PaperPanel.Body>
      </PaperPanel>

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default SubcontractorAwardVendor;
