import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import accounting from "accounting";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useQueryState} from "use-location-state";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../../components/Filters";
import {PageHeader} from "../../../components/PageHeader";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {SubcontractorLienReleaseDetails} from "./SubcontractorLienReleaseDetail";
import SubcontractorLienReleasePaperItem from "./SubcontractorLienReleasePaperItem";

const SubcontractorLienReleaseList = (props) => {
  const {project, user, ...rest} = props;
  // const novoClasses = makeNovoClasses();
  const [showItemDialog, setShowItemDialog] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Date",
    "Search",
    "Status",
    "Envelope Status",
  ]);
  const filterParams = {
    q: filterOptions.Search.value,
    date: filterOptions.Date.value,
    status: filterOptions.Status.value,
    docusign_envelope_status: filterOptions["Envelope Status"].value,
  };
  const url = `accounting-tools/subcontractor-lien-releases/?page_size=${pageSize}&page=${page}&${qs.stringify(
    filterParams
  )}`;

  const {query: lienReleaseQuery, update: updateLienRelease} = useSentinelListAPI(url, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  // const postCurrentPage = usePostCurrentPage({});
  const lienReleases = lienReleaseQuery.data.results;

  const {query: statusQuery} = useSentinelListAPI(`accounting-tools/subcontractor-lien-releases/status/`, {
    initialData: [],
  });
  const statusOptions = statusQuery.data;
  const {query: envelopeStatusQuery} = useSentinelListAPI(
    `accounting-tools/subcontractor-lien-releases/envelope-status/`,
    {
      initialData: [],
    }
  );
  const envelopeStatusOptions = envelopeStatusQuery.data;

  return (
    <>
      <Helmet title={`Subcontractor Lien Releases`} />
      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography color="textPrimary">Subcontractor Lien Releases</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Subcontractor Lien Releases</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={lienReleaseQuery.isFetching}>
          <PaperPanel.Header.Title>
            Subcontractor Lien Releases{" "}
            {lienReleases.length !== 0 && (
              <>
                ({accounting.formatNumber(lienReleases.length)}/{accounting.formatNumber(lienReleaseQuery.data.count)})
              </>
            )}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => lienReleaseQuery.refetch()}
                isFetching={lienReleaseQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <FilterDate
                name="Date"
                value={filterOptions.Date.value}
                label="Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("Date", date, date);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                // native
                label="Status"
                name="Status"
                options={statusOptions}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                // native
                label="Envelope Status"
                name="Envelope Status"
                options={envelopeStatusOptions}
                value={filterOptions["Envelope Status"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Envelope Status", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PaginationWithPageSize
                count={lienReleaseQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          {lienReleases.map((lienRelease) => {
            return (
              <SubcontractorLienReleasePaperItem
                key={lienRelease.id}
                lienRelease={lienRelease}
                setActiveItem={setActiveItem}
                setShowItemDialog={setShowItemDialog}
                showProject
                showCommitment
              />
            );
          })}
        </PaperPanel.Body>
        {lienReleaseQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={lienReleaseQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <Dialog open={showItemDialog} onClose={() => setShowItemDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle id="form-dialog-title">Item Detail</DialogTitle>
        <DialogContent>
          <PaperPanel.Body>
            <SubcontractorLienReleaseDetails project={activeItem.project} lienRelease={activeItem} />
            <Link href={`/v2/projects/${activeItem?.project?.id}/subcontractor-lien-releases/${activeItem?.id}/`}>
              /v2/projects/{activeItem?.project?.id}/subcontractor-lien-releases/{activeItem?.id}/
            </Link>
            {/* <pre>{JSON.stringify(activeItem, null, 2)}</pre> */}
          </PaperPanel.Body>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setShowItemDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* <pre>{JSON.stringify(payments, null, 2)}</pre> */}
    </>
  );
};
export default SubcontractorLienReleaseList;
