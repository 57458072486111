import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, FormControl, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {Currency, Percentage} from "../../components/Accounting";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterDate, FilterSelect, FilterSwitch} from "../../components/Filters";
import companyDiversity from "../../components/forms/choices/companyDiversity.json";
import offices from "../../components/forms/choices/offices.json";
import {CompanyAutoselectMui} from "../../components/forms/Fields";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchAJAXPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";

const FilterForm = (props) => {
  const {filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions, dataSlug} = props;
  const [client, setClient] = React.useState(null);
  const [dateRange, setDateRange] = React.useState(null);

  const currentYear = moment().year();
  const lastYear = moment().subtract(1, "years").year();

  let dateRangeOptions = [
    {
      label: "YTD",
      value: "ytd",
    },
    {
      label: "Last Year",
      value: "last_year",
    },
  ];
  for (let i = moment().quarter(); i >= 1; i--) {
    dateRangeOptions.push({
      label: `Q${i} ${currentYear}`,
      value: `q${i}_${currentYear}`,
    });
  }
  for (let i = 4; i >= 1; i--) {
    dateRangeOptions.push({
      label: `Q${i} ${lastYear}`,
      value: `q${i}_${lastYear}`,
    });
  }
  return (
    <>
      <FinalForm onSubmit={() => {}}>
        {() => (
          <>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={12}>
                <FilterSelect
                  name="Date Range"
                  label="Date Range"
                  value={dateRange}
                  options={dateRangeOptions}
                  onChange={(value, label) => {
                    setDateRange(value);
                    if (value === "ytd") {
                      const startDate = moment().startOf("year").format("YYYY-MM-DD");
                      setFilterOption("Start Date", startDate, startDate);
                      clearFilterOption("End Date");
                    }
                    if (value === "last_year") {
                      const startDate = moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD");
                      const endDate = moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD");
                      setFilterOption("Start Date", startDate, startDate);
                      setFilterOption("End Date", endDate, endDate);
                    }
                    if (value.startsWith("q")) {
                      const quarter = value[1];
                      const year = value.slice(-4);
                      const startDate = moment(year, "YYYY").quarter(quarter).startOf("quarter").format("YYYY-MM-DD");
                      const endDate = moment(year, "YYYY").quarter(quarter).endOf("quarter").format("YYYY-MM-DD");
                      setFilterOption("Start Date", startDate, startDate);
                      setFilterOption("End Date", endDate, endDate);
                    }
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FilterDate
                  name="Start Date"
                  value={filterOptions["Start Date"].value}
                  label="Start Date"
                  onChange={(date) => {
                    setDateRange(null);
                    setFilterOption("Start Date", date, date);
                    setFilterOption("Start Date", date, date);
                  }}
                  disableFuture={false}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FilterDate
                  name="End Date"
                  value={filterOptions["End Date"].value}
                  label="End Date"
                  onChange={(date) => {
                    setDateRange(null);
                    setFilterOption("End Date", date, date);
                  }}
                  disableFuture={false}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FilterSelect
                  // native
                  label="Office"
                  name="Office"
                  options={offices}
                  value={filterOptions.Office.value}
                  onChange={(value, label) => {
                    setFilterOption("Office", value, label);
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FilterSelect
                  // native
                  label="Company Diversity"
                  name="Company Diversity"
                  options={companyDiversity}
                  renderValue={(selected) => filterOptions["Company Diversity"].label}
                  multiple
                  value={filterOptions["Company Diversity"].value}
                  onChange={(value, label) => {
                    setFilterOption("Company Diversity", value, label);
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <CompanyAutoselectMui
                  label="Client"
                  name="client"
                  value={client}
                  onChange={(e, value) => {
                    setClient(value);
                    if (value === null) {
                      clearFilterOption("Client");
                    } else {
                      setFilterOption("Client", value?.id, value?.name);
                    }
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FilterSwitch
                  label="All Diversity Classifications"
                  name="has_company_diversity"
                  checked={filterOptions["All Diversity Classifications"].value}
                  onChange={(value, label) => {
                    setFilterOption("All Diversity Classifications", value, label);
                  }}
                />
              </Grid>
              {dataSlug === "timberline/invoices" && (
                <Grid item sm={6} xs={12}>
                  <FilterSwitch
                    label="Exclude Negative Invoices"
                    name="exclude_negative_invoices"
                    checked={filterOptions["Exclude Negative Invoices"].value || false}
                    onChange={(value, label) => {
                      setFilterOption("Exclude Negative Invoices", value, label);
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FilterOptionChips
                  filterOptions={filterOptions}
                  onDelete={(key) => {
                    clearFilterOption(key);
                    if (key === "Client") {
                      setClient(null);
                    }
                  }}
                  onDeleteAll={() => {
                    clearAllFilterOptions();
                    setDateRange(null);
                    setClient(null);
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </FinalForm>
    </>
  );
};

const MinorityOwnedSubcontractorSpend = (props) => {
  const [dataSlug, setDataSlug] = React.useState("timberline/invoices");
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    [
      "Start Date",
      "End Date",
      "Office",
      "All Diversity Classifications",
      "Company Diversity",
      "Exclude Negative Invoices",
      "Client",
    ],
    {
      "Start Date": {
        value: moment().startOf("year").format("YYYY-MM-DD"),
        label: moment().startOf("year").format("YYYY-MM-DD"),
      },
      "All Diversity Classifications": {
        value: true,
        label: "True",
      },
    }
  );

  const searchParams = {
    start_date: filterOptions["Start Date"].value,
    end_date: filterOptions["End Date"].value,
    office: filterOptions.Office.value,
    has_company_diversity: filterOptions["All Diversity Classifications"].value,
    exclude_negative_invoices: filterOptions["Exclude Negative Invoices"].value,
    company_diversity: filterOptions["Company Diversity"].value,
    client: filterOptions["Client"].value,
  };

  const pageDataQuery = useFetchAJAXPage(
    `/v2/mbe/spend/${dataSlug}/`,
    {
      refetchOnWindowFocus: false,
      initialData: {
        df: [],
      },
    },
    searchParams
    // qs.parse(location.search)
  );

  React.useEffect(() => {
    if (pageDataQuery.isFetching) {
      blockUI.blockUI("Fetching data...");
    } else {
      blockUI.unblockUI();
    }
  }, [pageDataQuery.isFetching]);

  // if (!pageDataQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching data..." />;
  // }

  return (
    <>
      <Helmet title="Diverse Subcontractor Spend" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Diverse Subcontractor Spend</Typography>
      </Breadcrumbs>
      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}

      {/* <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>Subcontractor DEI</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader> */}
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>Diverse Subcontractor Spend</PaperPanel.Header.Title>
          {/* <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>Action</PaperPanel.Header.Action>
          </PaperPanel.Header.Actions> */}
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <FormControl fullWidth variant="outlined" size="small">
                <Select
                  value={dataSlug}
                  onChange={(event) => {
                    setDataSlug(event.target.value as string);
                  }}
                >
                  <MenuItem value={"timberline/invoices"}>Timberline Invoices</MenuItem>
                  <MenuItem value="timberline/distributions">Timberline Distributions</MenuItem>
                  <MenuItem value={"timberline/commitments"}>Timberline Commitments</MenuItem>
                  <MenuItem value={"sentinel/commitments"}>Sentinel Commitments</MenuItem>
                </Select>
              </FormControl>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                href={`/v2/mbe/spend/${dataSlug}/?export_type=excel&${qs.stringify(searchParams)}`}
              >
                Export Raw Data
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageDataQuery.data.df.map((item) => {
                      return {
                        Vendor: item.vendor_name,
                        "Job Count": item.job_code_count,
                        Percentage: item.percentage,
                        Amount: item.amount,
                      };
                    }),
                    `Diverse Subcontractor Spend`,
                    [{wch: 40}, {wch: 10}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={pageDataQuery.isFetching}
                onClick={() => pageDataQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {dataSlug === "timberline/invoices" && (
          <PaperPanel.Alert severity="info">Timberline invoices by invoice date</PaperPanel.Alert>
        )}
        {dataSlug === "timberline/distributions" && (
          <PaperPanel.Alert severity="info">Timberline distributions by date of commitment</PaperPanel.Alert>
        )}
        {dataSlug === "timberline/commitments" && (
          <PaperPanel.Alert severity="info">
            Timberline commitments by last check date includes all COs
          </PaperPanel.Alert>
        )}
        {dataSlug === "sentinel/commitments" && (
          <PaperPanel.Alert severity="info">
            Sentinel commitments (Subcontracts and PO's) by issued date includes all COs
          </PaperPanel.Alert>
        )}
        <PaperPanel.Toolbar p={1}>
          <FilterForm
            filterOptions={filterOptions}
            setFilterOption={setFilterOption}
            clearFilterOption={clearFilterOption}
            clearAllFilterOptions={clearAllFilterOptions}
            dataSlug={dataSlug}
          />
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            rightAlignColumns={["job_code_count", "amount", "percentage"]}
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            showFooter={Boolean(pageDataQuery.data.df?.length)}
            columns={[
              {
                Header: "Vendor",
                accessor: "vendor_name",
                Cell: ({value, row}) => {
                  return (
                    <MuiNavLink
                      underline="always"
                      // href={`/v2/companies/${row.original.company_id}/update/#tab=diversity`}
                      href={`/v2/companies/${row.original.company_id}/`}
                    >
                      {value}
                    </MuiNavLink>
                  );
                },
              },
              {
                Header: "Job Count",
                accessor: "job_code_count",
                Cell: ({value, row}) => accounting.formatNumber(value),
              },
              {
                Header: "Amount",
                accessor: "amount",
                sortType: "basic",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageDataQuery.data.df.reduce((sum, row) => row.amount + sum, 0),
                    [pageDataQuery.data.df]
                  );
                  return total ? <Currency number={total} /> : "";
                },
              },
              // {
              //   Header: "Percentage",
              //   accessor: "percentage",
              //   sortType: "basic",
              // },
              {
                Header: "Percentage",
                accessor: "percentage",
                sortType: "basic",
                Cell: ({value, row}) => <Percentage number={value} precision={4} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageDataQuery.data.df.reduce((sum, row) => row.percentage + sum, 0),
                    [pageDataQuery.data.df]
                  );
                  return total ? <Percentage number={total} precision={4} /> : "";
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "vendor_name",
                },
              ],
            }}
            data={pageDataQuery.data.df}
          />
          {/* <p>{pageDataQuery.data.totalSpend}</p>
          <pre>{JSON.stringify(pageDataQuery.data.df, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default MinorityOwnedSubcontractorSpend;
