import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import FormActions from "../forms/FormActions";
import "./dropzone.scss";
import DropzoneMui from "./DropzoneMui";

const DropzoneDialogMui = (props) => {
  const {isOpen, handleClose, ...rest} = props;
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <DropzoneMui
          styles={{
            dropzone: {
              minHeight: "200px",
              border: "2px dashed #80b7ff",
            },
          }}
          {...rest}
        />
      </DialogContent>
      <DialogActions>
        <FormActions.CloseButton onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default DropzoneDialogMui;
