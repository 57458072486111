import {AppBar, Box, Link, Tab, Tabs, Typography} from "@material-ui/core";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {useQueryState} from "use-location-state";
import ActivityStream from "../../../components/ActivityStream";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink, ProjectAuditLink} from "../../../components/Links";
import MailLog from "../../../components/MailLog";
import PaperPanel from "../../../components/PaperPanel";
import PDFAttachmentsPaperPanel from "../../../components/PDFAttachments";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import useWaffle from "../../../hooks/useWaffle";
import {makeNovoClasses} from "../../../theme";
import {SubmittalItemUpdateForm} from "./SubmittalItemForms";

const SubmittalItemDetail = (props) => {
  const {project, userContact, isNew = false, isReadOnly = project.is_closed, ...rest} = props;
  const {submittalId} = useParams();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "detail");
  const blockUI = useBlockUI();
  const waffle = useWaffle();

  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const {
    query: submittalItemQuery,
    update: updateSubmittalItem,
    delete: deleteSubmittalItem,
    rpc: submittalRPC,
  } = useSentinelDetailAPI(["projects", project.id, "submittals/items", parseInt(submittalId)], {
    initialData: {},
  });

  const submittalItem = submittalItemQuery.data;

  if (!submittalItemQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching submittal item info..." />;
  }

  return (
    <>
      <Helmet title={`${props.project.display} - Submittal Items`} />
      <ProjectBreadcrumbs project={project}>
        {/* <MuiNavLink to={`/v2/projects/${project.id}/submittals/packages/`}> Submittal Packages</MuiNavLink> */}
        {/* <MuiNavLink to={`/projects/${project.id}/submittals/packages/`}> Submittal Packages</MuiNavLink> */}
        <Link href={`/projects/${project.id}/submittals/packages/`}>
          <Typography color="textSecondary">Submittal Packages</Typography>
        </Link>
        {submittalItem.submittal_package && (
          // <MuiNavLink color="inherit" to={submittalId?.submittal_package?.url}>
          //   {submittalItem.submittal_package?.display}
          // </MuiNavLink>
          <Link href={submittalItem?.submittal_package?.url}>
            <Typography color="textSecondary">{submittalItem.submittal_package?.display}</Typography>
          </Link>
        )}
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/submittals/items/`}>
          Submittal Items
        </MuiNavLink>
        <Typography color="textPrimary">{submittalItem.display}</Typography>
      </ProjectBreadcrumbs>
      {pageDataQuery.data.currentRevisionURL && (
        <Box mb={2}>
          <Alert severity="info">
            This is a prior revision of this submittal item.{" "}
            <MuiNavLink to={`${pageDataQuery.data.currentRevisionURL}`}>Click here</MuiNavLink> to go to the current
            revision.
          </Alert>
        </Box>
      )}
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Detail" value="detail" className={novoClasses.smallTab} />
              <Tab label="Logs" value="logs" className={novoClasses.smallTab} />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <PaperPanel.Body>
            <TabPanel value="detail">
              <PaperPanel.TabHeader isLoading={submittalItemQuery.isLoading || submittalItemQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <StatusLabel status={submittalItem?.status_display} width={100} /> Submittal Item Detail
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.RevisionsButtons
                      url={`projects/${project.id}/submittals/items/${submittalId}/revisions/`}
                      obj={submittalItem}
                    />
                  </PaperPanel.Header.Action>

                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/submittals/submittalitem/${submittalId}/`}
                    />
                  </PaperPanel.Header.Action>

                  <PaperPanel.Header.Menu>
                    {(popupState) => (
                      <PaperPanel.Header.Menu.MenuItem
                        onClick={(event) => {
                          popupState.close();
                          // window.open(`/pcos/${project.id}/create/?submittal_item=${submittalId}`, "_blank");
                        }}
                        // href={`/pcos/${project.id}/create/?submittal_item=${submittalId}`}
                        href={`/v2/projects/${project.id}/change-order-wizard/pco/?submittal_item=${submittalId}`}
                        target="_blank"
                        component={Link}
                      >
                        {/* <FontAwesomeIcon icon={faCirclePlus} fixedWidth />  */}
                        Create PCO
                      </PaperPanel.Header.Menu.MenuItem>
                    )}
                  </PaperPanel.Header.Menu>
                  {/* <PaperPanel.Header.Action>
                    <PaperPanel.Header.RefreshButton
                      isFetching={submittalItemQuery.isFetching}
                      onClick={() => submittalItemQuery.refetch()}
                    />
                  </PaperPanel.Header.Action> */}
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <SubmittalItemUpdateForm
                onSubmit={(values) => {
                  values = {
                    ...{spec_section_code: ""},
                    ...values,
                  };
                  blockUI.blockUI("Saving...");
                  updateSubmittalItem
                    .mutateAsync(values)
                    .then(() => submittalItemQuery.refetch())
                    .then(blockUI.unblockUI());
                }}
                initialValues={submittalItem}
                projectId={project.id}
              />
              <Box ml={-3} mr={-3} mt={2}>
                <PDFAttachmentsPaperPanel
                  url={`projects/${project.id}/submittals/items/${submittalId}/pdf-attachments/`}
                  uploadURL={`/api/v1/projects/${project.id}/submittals/items/${submittalId}/pdf-attachments/upload/`}
                  contentType="submittals"
                  model="submittalitempdfattachment"
                />
              </Box>
              <Box mt={2}>
                <CreatedByModifiedBy obj={submittalItem} />
              </Box>
            </TabPanel>
            <TabPanel value="logs">
              <Box mx={-3} mt={-4}>
                <ActivityStream url={`actstream/submittals/submittalitem/${submittalId}/`} />
                <Box mb={2} />
                <MailLog url={`mailer/submittals/submittalitem/${submittalId}/log/`} />
              </Box>
            </TabPanel>
          </PaperPanel.Body>
        </PaperPanel>
      </TabContext>

      <ProjectAuditLink projectId={project.id} app="submittals" model="submittalitem" id={submittalId} mt={2} />
      <LegacyUILink href={`/submittals/items/${submittalId}/legacy/`} />

      {/* <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          deleteSubmittalItem.mutateAsync(submittalItem.id).then(() => navigate(`${project.url}submittals/items`));
          navigate(`${project.url}submittals/items`);
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete {submittalItem?.display}
      </ConfirmationDialog> */}

      {/* <Typography variant="h2">submittalItem</Typography>
      <pre>{JSON.stringify(submittalItem, null, 2)}</pre>
      <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default SubmittalItemDetail;
