import {
  faCircle as faCircleOutline,
  faThumbsDown as faThumbsDownOutline,
  faThumbsUp as faThumbsUpOutline,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faCheckCircle,
  faCircleExclamation,
  faCircleX,
  faClock,
  faCogs,
  faFax,
  faFolder,
  faHistory,
  faMinusCircle,
  faMobile,
  faPhone,
  faPhoneOffice,
  faPhoneRotary,
  faQuestionCircle,
  faReply,
  faShare,
  faThumbsDown,
  faThumbsUp,
  faTimesCircle,
  faUnlock,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import React from "react";
import {unslugify} from "../../js/utils/string";
import {colorError, colorSuccess, getStatusColor} from "../theme/colors";

export const ProjectStatusIcon = React.memo(
  (props: {status?: string; project?: {status: string}; showTooltip?: boolean}) => {
    const {status: status_, project, showTooltip, ...rest} = props;
    const status = status_ || project?.status;
    const theme = useTheme();

    const icon = ((status) => {
      if (!status) status = "";
      if (["unstarted"].includes(status.toLowerCase())) {
        return {
          icon: faClock,
          color: theme.palette.info.main,
        };
      }
      if (["complete", "closed"].includes(status.toLowerCase())) {
        return {
          icon: faCheck,
          color: colorError,
        };
      }
      if (["in progress"].includes(status.toLowerCase())) {
        return {
          icon: faCogs,
          color: colorSuccess,
        };
      }
      if (["submitted"].includes(status.toLowerCase())) {
        return {
          icon: faShare,
          color: theme.palette.text.primary,
        };
      }
      if (["submitted for closeout"].includes(status.toLowerCase())) {
        return {
          icon: faShare,
          color: colorError,
        };
      }
      return {
        icon: faQuestionCircle,
        color: theme.palette.text.secondary,
      };
    })(status);
    if (showTooltip) {
      return (
        <Tooltip title={status || "Unknown"} placement="right">
          <span>
            <FontAwesomeIcon icon={icon.icon} style={{color: icon.color}} {...rest} />
          </span>
        </Tooltip>
      );
    }
    return <FontAwesomeIcon icon={icon.icon} style={{color: icon.color}} {...rest} />;
  }
);

export const StatusIcon = React.memo(
  (props: {
    status?: string;
    tooltip?: string;
    showTooltip?: boolean;
    tooltipPlacement?:
      | "right"
      | "bottom-end"
      | "bottom-start"
      | "bottom"
      | "left-end"
      | "left-start"
      | "left"
      | "right-end"
      | "right-start"
      | "top-end"
      | "top-start"
      | "top";
    type?: "submittal" | "changeOrder" | "rfi" | "pcco" | "rfp" | "workPlan";
  }) => {
    const {status = "", tooltip, showTooltip, tooltipPlacement = "right", type, ...rest} = props;
    const theme = useTheme();

    const statusDisplay = tooltip || unslugify(status);
    const color = getStatusColor(unslugify(status), type);

    const icon = ((status) => {
      if (!status) status = "";
      if (["created"].includes(status.toLowerCase())) {
        return faClock;
      }
      if (["rfp"].includes(type)) {
        if (["pending"].includes(status.toLowerCase())) {
          return faCheckCircle;
        }
        if (["lost"].includes(status.toLowerCase())) {
          return faThumbsDown;
        }
        if (["won"].includes(status.toLowerCase())) {
          return faThumbsUp;
        }
        if (["on_hold"].includes(status.toLowerCase())) {
          return faClock;
        }
        if (["declined_to_bid"].includes(status.toLowerCase())) {
          return faCircleX;
        }
      }
      if (
        ["unstarted", "pending", "pending_approval", "pending_work_proceeding", "pending_approved_to_proceed"].includes(
          status.toLowerCase()
        )
      ) {
        return faClock;
      }
      if (["pending_work_on_hold"].includes(status.toLowerCase())) {
        return faClock;
      }
      if (["complete", "closed"].includes(status.toLowerCase())) {
        if (["rfi"].includes(type)) {
          return faCheckCircle;
        }
        return faCheck;
      }
      if (["submitted"].includes(status.toLowerCase())) {
        if (["submittal", "workPlan"].includes(type)) {
          return faShare;
        }
        return faCheck;
      }
      if (["in_review"].includes(status.toLowerCase())) {
        return faHistory;
      }
      if (["revised"].includes(status.toLowerCase())) {
        return faCircleExclamation;
      }
      if (["approved"].includes(status.toLowerCase())) {
        return faThumbsUp;
      }
      if (["approved_as_noted"].includes(status.toLowerCase())) {
        return faThumbsUpOutline;
      }
      if (["complete"].includes(status.toLowerCase())) {
        return faCheckCircle;
      }
      if (["review_complete"].includes(status.toLowerCase())) {
        return faCheck;
      }
      if (["revise_resubmit"].includes(status.toLowerCase())) {
        return faThumbsDownOutline;
      }
      if (["rejected"].includes(status.toLowerCase())) {
        return faThumbsDown;
      }
      if (["cancelled"].includes(status.toLowerCase())) {
        return faMinusCircle;
      }
      if (["void"].includes(status.toLowerCase())) {
        if (["rfi", "changeOrder"].includes(type)) {
          return faTimesCircle;
        }
        return faTimesCircle;
      }
      if (["closed"].includes(status.toLowerCase())) {
        return faTimesCircle;
      }
      if (["zero_contract"].includes(status.toLowerCase())) {
        return faCircleOutline;
      }
      if (["external_response"].includes(status.toLowerCase())) {
        return faReply;
      }
      if (["external_response"].includes(status.toLowerCase())) {
        return faReply;
      }
      if (["partial_response"].includes(status.toLowerCase())) {
        return faReply;
      }
      if (["unlocked"].includes(status.toLowerCase())) {
        return faUnlock;
      }
      if (["for_record_only"].includes(status.toLowerCase())) {
        return faFolder;
      }

      return faQuestionCircle;
    })(status);
    // console.log(icon);
    if (showTooltip && status) {
      return (
        <Tooltip title={statusDisplay} placement={tooltipPlacement}>
          <span>
            <FontAwesomeIcon icon={icon} style={{color: color}} {...rest} />
          </span>
        </Tooltip>
      );
    }
    return <FontAwesomeIcon icon={icon} style={{color: color}} {...rest} />;
  }
);

export const PhoneNumberTypeIcon = React.memo((props: {type?: string; showTooltip?: boolean}) => {
  const {type, showTooltip = true, ...rest} = props;

  const icon = ((type) => {
    //  ("fax", "Fax"), ("home", "Home"), ("mobile", "Mobile"), ("toll_free", "Toll Free"), ("work", "Work")
    if (["Fax"].includes(type)) {
      return faFax;
    }
    if (["Home"].includes(type)) {
      return faPhoneRotary;
    }
    if (["Mobile"].includes(type)) {
      return faMobile;
    }
    if (["Toll Free"].includes(type)) {
      return faPhone;
    }
    if (["Work"].includes(type)) {
      return faPhoneOffice;
    }
  })(type);
  if (!icon?.icon) return null;
  if (showTooltip) {
    return (
      <Tooltip title={type || "Unknown"} placement="right">
        <span>
          <FontAwesomeIcon icon={icon} {...rest} />
        </span>
      </Tooltip>
    );
  }
  return <FontAwesomeIcon icon={icon} {...rest} />;
});
