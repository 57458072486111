import {Box, Typography} from "@material-ui/core";
import axios from "axios";
import React from "react";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";
import SafetyOrientationSpeedBump from "./SafetyOrientationSpeedBump";
import StickerSpeedBump from "./StickerSpeedBump";
import VaccineStatusCheck from "./VaccineStatusCheck";
import {WellnessCheckExternalQuestionnaireFrom} from "./WellnessCheckExternalQuestionnaireForms";
import WellnessCheckQRCode from "./WellnessCheckQRCode";
import WellnessCheckSiteInvalidDateMessage from "./WellnessCheckSiteInvalidDateMessage";
import WellnessCheckSiteRestrictedMessage from "./WellnessCheckSiteRestrictedMessage";
import {MissingFormsSpeedBump} from "./MissingFormsSpeedBump";
import PretaskPlanReviseResubmitSpeedBump from "./PretaskPlanReviseResubmitSpeedBump";

const WellnessCheckExternalQuestionnaire = (props) => {
  const {
    announcements,
    wellnessCheckResult: initialWellnessCheckResult,
    questionSet,
    qrCodeURLS,
    isToday,
    isForeman,
    twilioNumber,
    twilioNumberDisplay,
    pretaskPlan,
    safetyOrientationEnabled,
    safetyOrientationCompleted,
    safetyOrientationSpeedBumpEnabled,
    stickersEnabled,
    stickerSpeedBumpEnabled,
    hasSticker: initialHasSticker,
    stickerNumber,
    missingForms,
    missingFormSpeedBumpEnabled,
    onsiteFormsEnabled,
    hotWorkPermits,
    certificationsExpiringSoon,
    validCertifications,
  } = props;
  const [wellnessCheckResult, setWellnessCheckResult] = React.useState(initialWellnessCheckResult);
  const [stickerIssued, setStickerIssued] = React.useState(initialHasSticker);
  const refreshStickerIssued = () => {
    return axios.get(`/wellness-check/${wellnessCheckResult.uuid}/has-sticker/`).then((result) => {
      setStickerIssued(result.data.hasSticker);
    });
  };

  React.useEffect(() => {
    refreshStickerIssued();
  }, []);

  if (wellnessCheckResult?.twilio_contact?.is_restricted) {
    return (
      <UnauthenticatedLayout>
        <Box mt={2}>
          <Typography variant="h5">{wellnessCheckResult.project.display}</Typography>
        </Box>
        <WellnessCheckSiteRestrictedMessage wellnessCheckResult={wellnessCheckResult} />
      </UnauthenticatedLayout>
    );
  }
  if (wellnessCheckResult?.check_covid19_vaccine_status) {
    return (
      <UnauthenticatedLayout>
        {/* <pre>{JSON.stringify(wellnessCheckResult, null, 2)}</pre> */}
        <VaccineStatusCheck
          wellnessCheckResult={wellnessCheckResult}
          onSubmitted={(data) => {
            window.location.reload();
            // wellnessCheckResult.twilio_contact = data;
            // wellnessCheckResult.check_covid19_vaccine_status = false;
            // setWellnessCheckResult({...wellnessCheckResult});
          }}
        />
      </UnauthenticatedLayout>
    );
  }

  if (wellnessCheckResult?.is_complete) {
    if (pretaskPlan && pretaskPlan.status === "revise_resubmit") {
      return (
        <UnauthenticatedLayout>
          <Box mt={2} mb={2}>
            <PretaskPlanReviseResubmitSpeedBump
              project={wellnessCheckResult.project}
              twilioContact={wellnessCheckResult.twilio_contact}
              pretaskPlan={pretaskPlan}
            />
          </Box>
        </UnauthenticatedLayout>
      );
    }
    if (safetyOrientationEnabled && !safetyOrientationCompleted && safetyOrientationSpeedBumpEnabled) {
      return (
        <UnauthenticatedLayout>
          <Box mt={2} mb={2}>
            <Typography variant="h5">{wellnessCheckResult.project.display}</Typography>
          </Box>
          <SafetyOrientationSpeedBump
            twilioContact={wellnessCheckResult?.twilio_contact}
            safetyOrientationCompleted={safetyOrientationCompleted}
            project={wellnessCheckResult.project}
          />
        </UnauthenticatedLayout>
      );
    }
    if (stickerSpeedBumpEnabled && !stickerIssued) {
      return (
        <UnauthenticatedLayout>
          <Box mt={2} mb={2} textAlign="center">
            <Typography variant="h5">{wellnessCheckResult.project.display}</Typography>
          </Box>
          <StickerSpeedBump
            twilioContact={wellnessCheckResult?.twilio_contact}
            project={wellnessCheckResult.project}
            stickerIssued={stickerIssued}
            refreshStickerIssued={refreshStickerIssued}
          />
        </UnauthenticatedLayout>
      );
    }

    if (missingFormSpeedBumpEnabled && missingForms && Object.values(missingForms).some((value) => value)) {
      return (
        <UnauthenticatedLayout>
          <MissingFormsSpeedBump
            missingForms={missingForms}
            pretaskPlanLink={`/pretask-plan/${pretaskPlan.uuid}/`}
            isSpanish={wellnessCheckResult?.twilio_contact?.language === "SP"}
            hotWorkPermits={hotWorkPermits}
            permitLink={`/projects/${wellnessCheckResult.project.uuid}/twilio-contact/${wellnessCheckResult.twilio_contact.uuid}/safety/permits/#date=${wellnessCheckResult.compliance_date}`}
          />
        </UnauthenticatedLayout>
      );
    }
    return (
      <UnauthenticatedLayout>
        {/* <Typography variant="h1">Jobsite Check In</Typography> */}
        <WellnessCheckQRCode
          announcements={announcements}
          qrCodeURLS={qrCodeURLS}
          wellnessCheckResult={wellnessCheckResult}
          isToday={isToday}
          isForeman={isForeman}
          pretaskPlan={pretaskPlan}
          safetyOrientationEnabled={safetyOrientationEnabled}
          safetyOrientationCompleted={safetyOrientationCompleted}
          stickersEnabled={stickersEnabled}
          stickerNumber={stickerNumber}
          onsiteFormsEnabled={onsiteFormsEnabled}
          certificationsExpiringSoon={certificationsExpiringSoon}
          validCertifications={validCertifications}
        />
      </UnauthenticatedLayout>
    );
  }
  if (!wellnessCheckResult?.is_complete && !isToday) {
    return (
      <UnauthenticatedLayout>
        <Box mt={2}>
          <Typography variant="h5">{wellnessCheckResult.project.display}</Typography>
        </Box>
        <WellnessCheckSiteInvalidDateMessage
          wellnessCheckResult={wellnessCheckResult}
          twilioNumber={twilioNumber}
          twilioNumberDisplay={twilioNumberDisplay}
        />
      </UnauthenticatedLayout>
    );
  }
  return (
    <UnauthenticatedLayout>
      <Box mt={2}>
        <Typography variant="h5">{wellnessCheckResult.project.display}</Typography>
      </Box>
      <WellnessCheckExternalQuestionnaireFrom
        questionSet={questionSet}
        onSubmitted={(data) => {
          setWellnessCheckResult(data);
        }}
        language={wellnessCheckResult?.twilio_contact?.language}
      />
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(wellnessCheckResult, null, 2)}</pre> */}
    </UnauthenticatedLayout>
  );
};

export default WellnessCheckExternalQuestionnaire;
