import {faBuilding} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumbs, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import {CompanyUpdateForm} from "./CompanyForms";

const CompanyUpdate = (props) => {
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  let {companyId} = useParams();
  const {query: companyQuery, update: updateCompany} = useSentinelDetailAPI(`companies/${companyId}/`, {
    initialData: {},
  });

  const company = companyQuery.data;

  if (!companyQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching company info..." />;
  }
  return (
    <>
      <Helmet title={company.display} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" href={`/v2/companies/${companyId}/`}>
          {company.name}
        </MuiNavLink>
        <Typography color="textPrimary">Update</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faBuilding} /> {company.name} - Update
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <CompanyUpdateForm
        onSubmit={(values) => {
          updateCompany.mutateAsync(values);
        }}
        initialValues={company}
      />

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
      <pre>{JSON.stringify(companyQuery, null, 2)}</pre> */}
    </>
  );
};

export default CompanyUpdate;
