import {
  faAddressBook,
  faClouds,
  faDollarSign,
  faLock,
  faMapMarker,
  faPhone,
  faTasks,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {blue, green, red} from "@material-ui/core/colors";
import {Alert} from "@material-ui/lab";
import accounting from "accounting";
import {useSnackbar} from "notistack";
import React from "react";
import {Helmet} from "react-helmet";
import {NavLink as RouterNavLink, useParams} from "react-router";
import nl2br from "../../../../js/utils/nl2br";
import {Address} from "../../../components/Address";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {PDFButton} from "../../../components/Buttons";
import GoogleMap from "../../../components/GoogleMap";
import {ProjectStatusIcon} from "../../../components/Icons";
import {LabelValueList} from "../../../components/LabelValue";
import {LegacyUILink, ProjectAuditLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {WatchProjectSwitch} from "../../../components/WatchSwitches";
import {ProjectWeather} from "../../../components/Weather";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import Finances from "./Finances";
import Team from "./Team";

const ProjectDashboard = (props) => {
  const {project: initialProject, isWatchedByUser, ...rest} = props;
  const {projectId} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const blockUI = useBlockUI();

  const {
    data: pageData,
    refetch: refetchPageData,
    isFetching: pageDataIsFetching,
    isFetched: pageDataIsFetched,
  } = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      finances: {},
      team: {},
      access: [],
      extraProjectProps: {},
    },
  });

  const {team, finances, access, extraProjectProps} = pageData as any;

  const {
    query: projectQuery,
    create: createProject,
    update: updateProject,
    delete: deleteProject,
    rpc: projectRPC,
  } = useSentinelDetailAPI(["projects", parseInt(projectId)], {
    initialData: initialProject,
  });
  const project = projectQuery.data as any;

  const renderGoogleMapMarker = React.useCallback(
    ({map, maps}) => {
      new maps.marker.AdvancedMarkerElement({
        position: {
          lat: project.latitude,
          lng: project.longitude,
        },
        map: map,
        title: project.display,
      });
    },
    [project.latitude, project.longitude, project.display]
  );

  return (
    <>
      <Helmet title={`${project.display} - Dashboard`} />
      <Breadcrumbs>
        <Link color="inherit" href={`/v2/dashboard/`}>
          Dashboard
        </Link>
        <Typography color="textPrimary">{project.display}</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            <ProjectStatusIcon project={project} /> {project.display}
          </PageHeader.Title>
        </PageHeader.Left>
        {pageDataIsFetched && (
          <PageHeader.Right>
            <PageHeader.Right.CurrencySpark title="Project Value" number={finances.currentBudget} color={green[800]} />
            <PageHeader.Right.CurrencySpark
              title="Project Receivables"
              number={finances.receivableBalance}
              color={red[800]}
              border
            />
            <PageHeader.Right.CurrencySpark
              title="Project Billed"
              number={finances.jtdWorkBilled}
              color={blue[800]}
              border
            />
          </PageHeader.Right>
        )}
      </PageHeader>
      {project.status === "Submitted" && project.job_start_submitted && (
        <Box mb={2}>
          <Alert severity="warning">
            This project's job start was submitted on {project.job_start_submitted} and was assigned job #
            {project.timberline_job}. It has not yet been linked to an actual Timberline job. Please allow a day or two
            for the Timberline project to be created and linked.
          </Alert>
        </Box>
      )}
      {/* {extraProjectProps.needsJobStartSubmission && (
        <Box pb={2}>
          <Alert severity="info">
            This project has not completed a job start form.{" "}
            <Link href={`/projects/${project.id}/start/`} underline="always">
              Click here
            </Link>{" "}
            to create and submit the job start form and get a job number assigned.
          </Alert>
        </Box>
      )} */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box pb={2}>
            <PaperPanel>
              <PaperPanel.Header>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faTasks} /> Overview
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Box display="flex" alignItems="center">
                      <WatchProjectSwitch size="small" initialChecked={isWatchedByUser} projectId={project.id} /> Watch
                    </Box>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.EditButton component={RouterNavLink} to={`/v2/projects/${project.id}/update/`}>
                      Edit
                    </PaperPanel.Header.EditButton>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Body p={1}>
                <Address addressObject={project} />
                {project.job_phone && (
                  <div>
                    <FontAwesomeIcon icon={faPhone} /> {project.job_phone}
                  </div>
                )}
                <LabelValueList>
                  <LabelValueList.Item label="Start Date" value={project.start_date} />
                  <LabelValueList.Item label="Finish Date" value={project.finish_date} />
                  <LabelValueList.Item label="Warranty Date" value={project.warranty_date} />
                  <LabelValueList.Item label="Contract Type" value={extraProjectProps.typeOfContract} />
                  <LabelValueList.Item label="Project Delivery" value={extraProjectProps.projectDelivery} />
                  <LabelValueList.Item label="Size" value={`${accounting.formatNumber(project.square_feet)} sf`} />
                  {project.job_start_authorization_number && (
                    <LabelValueList.Item
                      label="Job Start Authorization #"
                      value={project.job_start_authorization_number}
                    />
                  )}
                  {project.description && (
                    <LabelValueList.Item label="Description" value={nl2br(project.description)} />
                  )}
                </LabelValueList>
                {project.job_start_submitted && (
                  <PDFButton size="small" title="Job Start" href={`/reports2/projects/${project.id}/start/`} />
                )}
              </PaperPanel.Body>
            </PaperPanel>
          </Box>
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataIsFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faDollarSign} /> Finances
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                {/* {project.job_start_submitted && (
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButton title="Job Start" href={`/reports2/projects/${project.id}/start/`} />
                  </PaperPanel.Header.Action>
                )} */}
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            {finances.isUnderBilled && (
              <PaperPanel.Alert severity="error">This project is currently under billed.</PaperPanel.Alert>
            )}
            <PaperPanel.Body p={1}>
              <Finances project={project} finances={finances} />
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box pb={2}>
            <PaperPanel>
              <PaperPanel.Header isLoading={pageDataIsFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faUsers} /> Team
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      href={`/projects/${project.id}/directory/`}
                      startIcon={<FontAwesomeIcon icon={faAddressBook} />}
                      variant="outlined"
                    >
                      Directory
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border pr={0}>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              blockUI.blockUI();
                              projectRPC.mutateAsync({action: "cleanup-team"}).then(() => {
                                blockUI.unblockUI();
                                refetchPageData();
                                enqueueSnackbar("Team cleanup successful", {variant: "success"});
                              });
                              popupState.close();
                            }}
                          >
                            Cleanup Team From Directory
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Body p={1}>
                <Team team={team} />
              </PaperPanel.Body>
            </PaperPanel>
          </Box>
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataIsFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faLock} /> Project Access
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.EditButton href={`/v2/projects/${project.id}/permissions/`}>
                    Edit
                  </PaperPanel.Header.EditButton>
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body p={1} maxHeight={550} overflow="auto">
              <Table size="small" padding="none">
                <TableBody>
                  {access.map((user) => {
                    return (
                      <TableRow key={user}>
                        <TableCell>{user}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item lg={6} xs={12}>
          <PaperPanel>
            <PaperPanel.Header>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faClouds} /> Weather
              </PaperPanel.Header.Title>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <ProjectWeather project={project} />
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item lg={6} xs={12}>
          <PaperPanel>
            <PaperPanel.Header>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faMapMarker} /> Map
              </PaperPanel.Header.Title>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <div style={{height: "500px", width: "100%"}}>
                <GoogleMap
                  defaultCenter={{
                    lat: project.latitude,
                    lng: project.longitude,
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={renderGoogleMapMarker}
                  googleMapsAPIKey={props.googleMapsAPIKey}
                />
              </div>
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
      </Grid>

      <ProjectAuditLink projectId={project.id} app="projects" model="project" id={project.id} mt={2} />
      <LegacyUILink href={`/projects/${projectId}/legacy/`} />

      {/*
      <Typography variant="h2">pageData</Typography>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <Typography variant="h2">projectQuery</Typography>
      <pre>{JSON.stringify(projectQuery, null, 2)}</pre>
      <Typography variant="h2">createProject</Typography>
      <pre>{JSON.stringify(createProject, null, 2)}</pre>
      <Typography variant="h2">projectUpdate</Typography>
      <pre>{JSON.stringify(updateProject, null, 2)}</pre>
      <Typography variant="h2">deleteProject</Typography>
      <pre>{JSON.stringify(deleteProject, null, 2)}</pre>
      <Typography variant="h2">Project</Typography>
      <pre>{JSON.stringify(project, null, 2)}</pre>
      <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre>
      */}
    </>
  );
};

export default ProjectDashboard;
