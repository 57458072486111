import {faChartLine} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectForecastList = (props) => {
  const {project, ...rest} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [createConfirmationIsOpen, setCreateConfirmationIsOpen] = React.useState(false);

  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const novoClasses = makeNovoClasses();

  const {
    query: forecastQuery,
    create: createForecast,
    update: updateForecast,
    delete: deleteForecast,
    rpc: forecastRFI,
  } = useSentinelListAPI(`projects/${project.id}/forecasts/?page_size=${pageSize}&page=${page}`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    // keepPreviousData: true,
  });

  const forecasts = forecastQuery.data.results;

  const canCreateForecast = Boolean(project.timberline_job);

  const forecastColumns = React.useMemo(
    () => [
      {
        Header: "Forecast",
        accessor: "display",
        Cell: ({value, row}) => (
          <MuiNavLink href={`/v2/projects/${project.id}/forecasts/${row.original.id}/`} underline="always">
            {value}
          </MuiNavLink>
        ),
      },
      {
        Header: "Current Budget",
        accessor: "sentinel_current_budget",
        Cell: ({value, row}) => <Currency number={value} precision={0} />,
      },
      {
        Header: "JTD Cost",
        accessor: "total_cost",
        Cell: ({value, row}) => <Currency number={value} precision={0} />,
      },
      {
        Header: "Expected Cost",
        accessor: "expected_total_cost",
        Cell: ({value, row}) => <Currency number={value} precision={0} />,
      },
      {
        Header: "Overhead Cost",
        accessor: "overhead",
        Cell: ({value, row}) => <Currency number={value} precision={0} />,
      },
      {
        Header: "Expected Value",
        accessor: "expected_total_value",
        Cell: ({value, row}) => <Currency number={value} precision={0} />,
      },
      {
        Header: "Expected Profit",
        accessor: "expected_total_profit",
        Cell: ({value, row}) => <Currency number={value} precision={0} />,
      },
    ],
    []
  );

  return (
    <>
      <Helmet title={`${project.display} - Forecasts`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Forecasts</Typography>
      </ProjectBreadcrumbs>

      {/*
      <PaperPanel.Alert severity="info">
        This feature is currently in <strong>beta</strong> prerelease testing. Please refrain from using it unless
        specifically requested to do so.
      </PaperPanel.Alert>
      <Box mb={2} />
      */}

      <PaperPanel>
        <PaperPanel.Header isLoading={forecastQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faChartLine} /> Forecasts
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => setCreateConfirmationIsOpen(true)}
                disabled={!canCreateForecast}
              >
                Create Forecast
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={forecastQuery.isFetching}
                onClick={() => forecastQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            showFooter={true}
            rightAlignColumns={["expected_total_cost", "overhead", "expected_total_value", "expected_total_profit"]}
            getRowProps={(row) => {
              return {
                "data-id": row.original.id,
              };
            }}
            columns={forecastColumns}
            initialState={{
              sortBy: [
                {
                  id: "budget_code",
                  desc: false,
                },
              ],
            }}
            data={forecasts}
          />
          {/* <pre>{JSON.stringify(forecastQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
        {(forecastQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={forecastQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[10, 100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/forecast/`} mt={2} />

      <ConfirmationDialog
        isOpen={createConfirmationIsOpen}
        onApprove={() => {
          setCreateConfirmationIsOpen(false);
          blockUI.blockUI("Creating...");
          createForecast.mutateAsync().then((newForecast) => {
            navigate(`/v2/projects/${project.id}/forecasts/${newForecast.id}/`);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setCreateConfirmationIsOpen(false);
        }}
      >
        This will create a new forecast for {project.display}.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(forecasts, null, 2)}</pre> */}
    </>
  );
};

export default ProjectForecastList;
