import {Box, Grid, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useQueryState} from "use-location-state";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import {MuiNavLink} from "../../components/Links";
import {PaginationWithPageSize} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import Typography2 from "../../components/Typography2";
import useBlockUI from "../../hooks/useBlockUI";
import usePermissions from "../../hooks/usePermissions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {AIAnalysisCreateDialogForm} from "./AIAnalysisForms";

const AIAnalysisList: React.FC = () => {
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [activeAnalysis, setActiveAnalysis] = React.useState({} as any);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(50);
  const navigate = useNavigate();
  const permissions = usePermissions();
  const blockUI = useBlockUI();
  const {
    query: analysisQuery,
    create: createAnalysis,
    delete: deleteAnalysis,
  } = useSentinelListAPI(`ai/analysis/?page_size=${pageSize}&page=${page}`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });

  const analyses = analysisQuery.data.results;

  // if (!analysisQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching analysis..." />;
  // }

  return (
    <>
      <Helmet title={`AI Document Analysis`} />
      <Box mb={3} />

      {/* <Breadcrumbs>
        <Typography color="textPrimary">AI Document Analysis</Typography>
      </Breadcrumbs> */}
      <PaperPanel>
        <PaperPanel.Header isLoading={analysisQuery.isFetching}>
          <PaperPanel.Header.Title>AI Document Analysis</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                Create New Analysis
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => analysisQuery.refetch()}
                isFetching={analysisQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {analyses.map((analysis) => (
            <PaperItem key={analysis.id}>
              <PaperItem.Body>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <MuiNavLink to={`/v2/ai/analysis/${analysis.id}/`} underline="always">
                      {analysis.document_names || "No documents"}
                    </MuiNavLink>
                  </Grid>
                  <Grid item xs={2}>
                    <div>
                      <Typography2 type="metadata">{analysis.type_display}</Typography2>
                    </div>
                    {permissions.is_superuser && (
                      <div>
                        <Typography2 type="metadata">{analysis.created_by?.full_name}</Typography2>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right">
                      <Typography2 type="metadata">{moment(analyses?.created).format("ddd, MMM D, YYYY")}</Typography2>
                    </Box>
                  </Grid>
                </Grid>
              </PaperItem.Body>
              <PaperItem.Right minWidth={65}></PaperItem.Right>
              <PaperItem.RightHover>
                <PaperItem.RightHover.IconButton
                  icon={DeleteIcon}
                  title="Delete"
                  onClick={() => {
                    setActiveAnalysis(analysis);
                    setDeleteConfirmationIsOpen(true);
                  }}
                />
              </PaperItem.RightHover>
            </PaperItem>
          ))}
          {/* <pre>{JSON.stringify(analyses, null, 2)}</pre> */}
        </PaperPanel.Body>
        {(analysisQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={analysisQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              // pageSizes={[20, 50, 100]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <AIAnalysisCreateDialogForm
        isOpen={showCreateDialog}
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{}}
        onSubmit={(values) => {
          setShowCreateDialog(false);
          blockUI.blockUI("Creating analysis...");
          createAnalysis.mutateAsync(values).then((newAnalysis) => {
            navigate(`/v2/ai/analysis/${newAnalysis.id}/`);
            blockUI.unblockUI();
          });
        }}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Deleting analysis...");
          deleteAnalysis.mutateAsync(activeAnalysis.id).then(() => {
            setDeleteConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete this analysis. This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export default AIAnalysisList;
