import {Box, Link, Typography} from "@material-ui/core";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import projectTypeChoices from "../../../components/forms/choices/rfpProjectTypeChoices.json";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {CompetitorBidPaperItem} from "./RFPComponents";
import {RFPCompetitorBidDialogForm, RFPOnboardingForm} from "./RFPForms";

const RequestForPricingDetail = (props) => {
  const {isFetching, ...rest} = props;
  const permissions = usePermissions();
  const [createCompetitorBidDialogOpen, setCreateCompetitorBidDialogOpen] = React.useState(false);
  const [editCompetitorBidDialogOpen, setEditCompetitorBidDialogOpen] = React.useState(false);
  const [activeCompetitorBid, setActiveCompetitorBid] = React.useState(null);
  const [deleteRFPConfirmationDialogIsOpen, setDeleteRFPConfirmationDialogIsOpen] = React.useState(false);
  const [deleteCompetitorBidConfirmationDialogIsOpen, setDeleteCompetitorBidConfirmationDialogIsOpen] =
    React.useState(false);
  const {rfpId} = useParams();
  const blockUI = useBlockUI();
  const {
    query: rfpQuery,
    create: createRFP,
    update: updateRFP,
    delete: deleteRFP,
  } = useSentinelDetailAPI(`marketing/rfps/${rfpId}/`, {
    initialData: {},
  });

  const {query: tagQuery} = useSentinelListAPI(`marketing/rfps/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  const {
    query: competitorBidQuery,
    create: createCompetitorBid,
    update: updateCompetitorBid,
    delete: deleteCompetitorBid,
  } = useSentinelListAPI(`marketing/rfps/competitor-bids/?rfp_id=${rfpId}`, {
    initialData: {
      results: [],
    },
  });

  const competitorBids = competitorBidQuery.data.results;

  // const pageDataQuery = useFetchCurrentPage({
  //   refetchOnWindowFocus: false,
  //   initialData: {},
  // });
  // const pageData = pageDataQuery.data;

  const rfp = rfpQuery.data;

  const projectTypeOptions = projectTypeChoices.map((choice) => ({code: choice.value, description: choice.label}));

  if (!rfpQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching RFP Details...`} />;
  }

  return (
    <>
      <Helmet title={`RFP - ${rfp.display}`} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        {/* </Link> */}
        <Link href="/v2/marketing/rfps/">
          <Typography color="textSecondary">RFPs</Typography>
        </Link>
        <Typography color="textPrimary">{rfp.display}</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>RFP - {rfp.display}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.BoxFilesButton href={`/v2/box/files/marketing/rfp/${rfpId}/`} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton href={`/reports2/marketing/rfps/${rfpId}/`} target="_blank" />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border mr={-1}>
              <PaperPanel.Header.RefreshButton
                isFetching={rfpQuery.isFetching || tagQuery.isFetching || competitorBidQuery.isFetching}
                onClick={() => {
                  rfpQuery.refetch();
                  tagQuery.refetch();
                  competitorBidQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <RFPOnboardingForm
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              tagQuery.refetch();
              updateRFP.mutateAsync(values).then(() => blockUI.unblockUI());
            }}
            initialValues={rfp}
            tagOptions={tagOptions.map((tag) => tag.label)}
            isFetching={isFetching}
            projectTypeOptions={projectTypeOptions}
            onDelete={() => {
              setDeleteRFPConfirmationDialogIsOpen(true);
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box my={1} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Competitors</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setCreateCompetitorBidDialogOpen(true);
                }}
              >
                Add Competitor
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {competitorBids.map((competitorBid) => {
            return (
              <CompetitorBidPaperItem
                key={competitorBid.id}
                competitorBid={competitorBid}
                onEdit={() => {
                  setActiveCompetitorBid(competitorBid);
                  setEditCompetitorBidDialogOpen(true);
                }}
                onDelete={
                  permissions.can_manage_rfps
                    ? () => {
                        setActiveCompetitorBid(competitorBid);
                        setDeleteCompetitorBidConfirmationDialogIsOpen(true);
                      }
                    : undefined
                }
              />
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>

      <RFPCompetitorBidDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          setCreateCompetitorBidDialogOpen(false);
          createCompetitorBid.mutateAsync(values).then(() => blockUI.unblockUI());
        }}
        initialValues={{rfp: rfp}}
        isNew
        open={createCompetitorBidDialogOpen}
        onClose={() => setCreateCompetitorBidDialogOpen(false)}
      />

      <RFPCompetitorBidDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Updating...");
          setEditCompetitorBidDialogOpen(false);
          updateCompetitorBid.mutateAsync(values).then(() => blockUI.unblockUI());
        }}
        initialValues={activeCompetitorBid}
        open={editCompetitorBidDialogOpen}
        onClose={() => setEditCompetitorBidDialogOpen(false)}
        onDelete={
          permissions.can_manage_rfps
            ? () => {
                setDeleteCompetitorBidConfirmationDialogIsOpen(true);
              }
            : undefined
        }
      />

      <ConfirmationDialog
        isOpen={deleteRFPConfirmationDialogIsOpen}
        onDeny={() => setDeleteRFPConfirmationDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteRFP.mutateAsync(rfpId).then(() => {
            blockUI.unblockUI();
            window.location.href = "/v2/marketing/rfps/";
          });
        }}
        approveLabel="Delete"
      >
        You want to delete the RFP for <strong>{rfp.project_name}</strong>?{" "}
        {competitorBids.length > 0 && (
          <>
            This will also delete {competitorBids.length} associated competitor{" "}
            {pluralize("bid", competitorBids.length)}.
          </>
        )}{" "}
        This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteCompetitorBidConfirmationDialogIsOpen}
        onDeny={() => setDeleteCompetitorBidConfirmationDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");

          deleteCompetitorBid.mutateAsync(activeCompetitorBid.id).then(() => {
            blockUI.unblockUI();
            setDeleteCompetitorBidConfirmationDialogIsOpen(false);
            setEditCompetitorBidDialogOpen(false);
          });
        }}
        approveLabel="Delete"
      >
        You want to delete this competitor bid?
      </ConfirmationDialog>
    </>
  );
};

export default RequestForPricingDetail;
