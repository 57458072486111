import {faEnvelope, faPaperPlaneTop, faShoppingCart, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import {sumBy} from "lodash";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {CommitmentBanner} from "../../../components/ClosedBanner";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {PurchaseOrderItemPaperItem} from "./PurchaseOrderDetailPaperItems";
import {PurchaseOrderAmendmentDetailForm, PurchaseOrderItemDialog} from "./PurchaseOrderForms";

const PurchaseOrderAmendmentDetail = (props) => {
  const {project, ...rest} = props;
  const {purchaseOrderId, amendmentId} = useParams();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [activeAmendmentItem, setActiveAmendmentItem] = React.useState({});
  const [amendmentItemCreateDialogIsOpen, setAmendmentItemCreateDialogIsOpen] = React.useState(false);
  const [amendmentItemUpdateDialogIsOpen, setAmendmentItemUpdateDialogIsOpen] = React.useState(false);
  const [requestDialogIsOpen, setRequestDialogIsOpen] = React.useState(false);
  const [voidDialogIsOpen, setVoidDialogIsOpen] = React.useState(false);
  const [submitDialogIsOpen, setSubmitDialogIsOpen] = React.useState(false);
  const [deleteMultipleItemsConfirmationIsOpen, setDeleteMultipleItemsConfirmationIsOpen] = React.useState(false);

  const createItemFormRef = React.createRef();

  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const permissions = usePermissions();

  const {
    query: amendmentQuery,
    update: updateAmendment,
    delete: deleteAmendment,
    create: createAmendment,
    rpc: amendmentRPC,
  } = useSentinelDetailAPI(
    ["projects", project.id, "pos", parseInt(purchaseOrderId), "amendments", parseInt(amendmentId)],
    {
      initialData: {},
    }
  );

  const {
    query: amendmentItemsQuery,
    update: updateAmendmentItem,
    delete: deleteAmendmentItem,
    create: createAmendmentItem,
    rpc: amendmentItemRPC,
  } = useSentinelListAPI(
    ["projects", project.id, "pos", parseInt(purchaseOrderId), "amendments", parseInt(amendmentId), "items"],
    {
      initialData: {
        results: [],
      },
    }
  );

  const amendmentItems = amendmentItemsQuery.data.results;
  const amendment = amendmentQuery.data;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const allItemIds = amendmentItems.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  // console.log("Project", project);
  // console.log("Amendment", amendment);
  // console.log("items", amendmentItems);

  if (!amendmentQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Amendment info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/pos/`}>
          Purchase Orders
        </MuiNavLink>
        <MuiNavLink color="inherit" href={`/v2/projects/${project.id}/pos/${purchaseOrderId}/`}>
          {amendment?.purchase_order_display}
        </MuiNavLink>
        <MuiNavLink color="inherit" href={`/v2/projects/${project.id}/pos/${purchaseOrderId}/#tab=amendments`}>
          Amendments
        </MuiNavLink>
        <Typography color="textPrimary">{amendment?.display}</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="Total" number={amendment.total_price} precision={2} />
        </PageHeader.Right>
      </PageHeader>

      <CommitmentBanner object={amendment} type="purchase order" mb={1} />

      {/* <pre>{JSON.stringify(amendment, null, 2)}</pre> */}

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
              className={novoClasses.smallTab}
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={amendment.status} tooltip={amendment.status_display} showTooltip />
                    <Box ml={1} />
                    General Info
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items{" "}
                    <TabCountChip isLoading={!amendmentItemsQuery.isFetchedAfterMount} count={amendmentItems.length} />
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={amendmentQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <StatusLabel status={amendment.status_display} /> <FontAwesomeIcon icon={faShoppingCart} />{" "}
                  {amendment.display}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/pos/${purchaseOrderId}/amendments/${amendment.id}/?display_vendor_signature=on`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.DocuSignButton docuSignObject={amendment} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/pos/purchaseorderamendment/${amendment.id}/`}
                      uploadURL={`/projects/${project.id}/pos/${amendment.purchase_order_id}/amendments/${amendment.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {permissions.can_import_subcontracts && (
                            <>
                              <Tooltip title="Request this PO amendment be submitted">
                                <span>
                                  <PaperPanel.Header.Menu.MenuItem
                                    onClick={(event) => {
                                      popupState.close();
                                      setRequestDialogIsOpen(true);
                                    }}
                                    disabled={
                                      amendment.submitted_date || ["void", "submitted"].includes(amendment.status)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEnvelope} fixedWidth /> <Box ml={1} /> Request Submission
                                  </PaperPanel.Header.Menu.MenuItem>
                                </span>
                              </Tooltip>
                              <MenuItemHeader border />
                            </>
                          )}
                          <Tooltip title={!amendment.can_void ? amendment.can_void_reason : ""}>
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  popupState.close();
                                  setVoidDialogIsOpen(true);
                                }}
                                disabled={!amendment.can_void}
                              >
                                <FontAwesomeIcon icon={faXmark} fixedWidth /> Void
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              !amendment.can_submit_to_accounting ? amendment?.can_submit_to_accounting_reason : ""
                            }
                          >
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  setSubmitDialogIsOpen(true);
                                  popupState.close();
                                }}
                                disabled={!amendment.can_submit_to_accounting}
                              >
                                <FontAwesomeIcon icon={faPaperPlaneTop} /> Submit
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <PaperPanel.Body>
                <PurchaseOrderAmendmentDetailForm
                  projectId={project.id}
                  isReadOnly={amendment.is_closed}
                  restrictNovoSigners={project.restrict_novo_signers}
                  onSubmit={(values) => {
                    blockUI.blockUI("Saving...");

                    // Promise.all([updateAmendment.mutateAsync(values), amendmentQuery.refetch()]).then(() =>
                    //   blockUI.unblockUI()
                    updateAmendment.mutateAsync(values).then(() =>
                      amendmentQuery.refetch().then(() => {
                        blockUI.unblockUI();
                      })
                    );
                    // );
                  }}
                  initialValues={amendment}
                />
              </PaperPanel.Body>
            </>
          </TabPanel>
          <TabPanel value="items">
            <>
              <PaperPanel.TabHeader isLoading={amendmentItemsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faShoppingCart} /> {amendment.display}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Tooltip title={amendment.is_closed ? amendment.is_closed_reason : ""}>
                      <span>
                        <PaperPanel.Header.CreateButton
                          onClick={() => setAmendmentItemCreateDialogIsOpen(true)}
                          disabled={project.is_closed || amendment.is_closed}
                        />
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <Tooltip title={amendment.is_closed ? amendment.is_closed_reason : ""}>
                      <span>
                        <PaperPanel.Header.DeleteButton
                          onClick={() => setDeleteMultipleItemsConfirmationIsOpen(true)}
                          disabled={amendment.is_closed || selectedItemIds.size < 1}
                        />
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/pos/${purchaseOrderId}/amendments/${amendment.id}/`}
                      // buildReportURL={`/reports2/projects/${project.id}/pos/detail/filter/?pk=${amendmentId}`}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Body>
                <Box mx={-3} mb={-3} mt={-2}>
                  <PaperItem.Header>
                    <PaperItem.Left>
                      <PaperItem.SelectedCheckbox
                        label={"Select All"}
                        onChange={(event, value) => {
                          if (value) {
                            addSelectedItemIds(allItemIds);
                          } else {
                            removeAllSelectedItemIds();
                          }
                        }}
                        indeterminate={
                          !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
                        }
                        checked={allItemIdsSelected}
                      />
                    </PaperItem.Left>
                    <PaperItem.Body>
                      <Grid container>
                        <Grid item md={1} xs={1}>
                          #
                        </Grid>
                        <Grid item md={5} xs={11}>
                          Description
                        </Grid>
                        <Grid item md={1} xs={2}>
                          Unit Price
                        </Grid>
                        <Grid item md={2} xs={2}>
                          Quantity
                        </Grid>
                        {/* <Grid item md={1} xs={2}>
                          UOM
                        </Grid> */}
                        <Grid item md={1} xs={3}>
                          Subtotal
                        </Grid>
                        <Grid item md={1} xs={3}>
                          Taxes
                        </Grid>
                        {/* <Grid item md={1} xs={2}>
                          Taxable
                        </Grid> */}
                        <Grid item md={1} xs={2}>
                          <Box textAlign="right">Total</Box>
                        </Grid>
                      </Grid>
                    </PaperItem.Body>
                    <PaperItem.Right minWidth={60}></PaperItem.Right>
                  </PaperItem.Header>
                  {amendmentItems.map((item) => (
                    <PurchaseOrderItemPaperItem
                      key={item.id}
                      item={item}
                      isSelected={selectedItemIds.has(item.id)}
                      isReadOnly={amendment.is_closed}
                      onDelete={() => {
                        deleteAmendmentItem.mutateAsync(item.id).then(() => amendmentQuery.refetch());
                      }}
                      onEdit={() => {
                        setActiveAmendmentItem(item);
                        setAmendmentItemUpdateDialogIsOpen(true);
                      }}
                      onSelectedChange={(e, value) => {
                        if (value) {
                          addSelectedItemId(item.id, e.nativeEvent.shiftKey);
                        } else {
                          removeSelectedItemId(item.id);
                        }
                      }}
                    />
                  ))}
                  <PaperItem.Header>
                    <PaperItem.Left></PaperItem.Left>
                    <Grid container>
                      <Grid item xs={11} />
                      <Grid item xs={1}>
                        <Box textAlign="right">
                          <strong>
                            <Currency number={sumBy(amendmentItems, "total_price")} />
                          </strong>
                        </Box>
                      </Grid>
                    </Grid>
                    <PaperItem.Right minWidth={60}></PaperItem.Right>
                  </PaperItem.Header>
                </Box>
              </PaperPanel.Body>
            </>
          </TabPanel>
        </PaperPanel>
        {/* <LegacyUILink href={`/pos/${purchaseOrderId}/amendments/${amendmentId}/legacy/`} mt={2} /> */}
      </TabContext>

      <ConfirmationDialog
        isOpen={requestDialogIsOpen}
        title="Send Email?"
        onApprove={() => {
          setRequestDialogIsOpen(false);
          blockUI.blockUI("Requesting...");
          amendmentRPC.mutateAsync({action: "request-submission"}).then((res) => {
            res === "success" && enqueueSnackbar("Email Sent!", {variant: "success"});

            blockUI.unblockUI();
            amendmentQuery.refetch();
          });
        }}
        onDeny={() => {
          setRequestDialogIsOpen(false);
        }}
      >
        This will send an email to {amendment.created_by.full_name} and request that they submit this PO amendment.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteMultipleItemsConfirmationIsOpen}
        title="Are you sure?"
        onApprove={() => {
          setDeleteMultipleItemsConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          amendmentItemRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedItemIds)},
            })
            .then(() => {
              removeAllSelectedItemIds();
              amendmentQuery.refetch();
              amendmentItemsQuery.refetch().then(() => {
                blockUI.unblockUI();
              });
            });
        }}
        onDeny={() => setDeleteMultipleItemsConfirmationIsOpen(false)}
      >
        You want to delete {`${selectedItemIds.size} ${pluralize(`item`, selectedItemIds.size)}`}? This can not be
        undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={voidDialogIsOpen}
        title="Void Purchase Order Amendment?"
        onApprove={() => {
          blockUI.blockUI("Voiding...");
          amendmentRPC.mutateAsync({action: "void"}).then(() => {
            amendmentQuery.refetch().then(() => {
              blockUI.unblockUI();
              setVoidDialogIsOpen(false);
            });
            amendmentItemsQuery.refetch();
          });
        }}
        onDeny={() => setVoidDialogIsOpen(false)}
      >
        Void this purchase order amendment? This will mark it as void and create a deductive purchase order amendment to
        zero out this amendment and submit it to accounting.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={submitDialogIsOpen}
        title={"Submit this Purchase Order Amendment?"}
        onApprove={() => {
          blockUI.blockUI("Submitting...");
          amendmentRPC.mutateAsync({action: "submit"}).then(() => {
            amendmentQuery.refetch().then(() => {
              blockUI.unblockUI();
              setSubmitDialogIsOpen(false);
            });
            amendmentItemsQuery.refetch();
          });
        }}
        onDeny={() => setSubmitDialogIsOpen(false)}
      >
        Submit this PO amendment to accounting? You will be unable to change the PO amendment once submitted.
      </ConfirmationDialog>

      <PurchaseOrderItemDialog
        ref={createItemFormRef}
        isOpen={amendmentItemCreateDialogIsOpen}
        isNew
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          blockUI.blockUI("Creating...");
          if (submitMode === "addAnother") {
            const focusElement = createItemFormRef.current.elements.description;
            focusElement.focus();
          }
          createAmendmentItem.mutateAsync(values).then(() => {
            if (submitMode === "addAnother") {
              // form.reset();
              form.restart();
            } else {
              setAmendmentItemCreateDialogIsOpen(false);
            }
            amendmentQuery.refetch();
            amendmentItemsQuery.refetch();
            blockUI.unblockUI();
          });
        }}
        handleClose={() => setAmendmentItemCreateDialogIsOpen(false)}
        initialValues={{is_taxable: true, quantity: 1, tax_rate: amendment.tax_rate}}
      />

      <PurchaseOrderItemDialog
        isOpen={amendmentItemUpdateDialogIsOpen}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateAmendmentItem.mutateAsync(values).then(() => {
            amendmentQuery.refetch();
            setAmendmentItemUpdateDialogIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        handleClose={() => setAmendmentItemUpdateDialogIsOpen(false)}
        initialValues={{...activeAmendmentItem, tax_rate: amendment.tax_rate}}
        isReadOnly={project.is_closed}
      />
    </>
  );
};

export default PurchaseOrderAmendmentDetail;
