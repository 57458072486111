import {faArrowDown, faClipboard, faTrash} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Fab, Tooltip, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {QuestionAnswer} from "../../components/AI";
import {Breadcrumbs, ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import {AIQuestionForm} from "../../components/forms/AIForms";
import BlockUI from "../../components/GlobalLoaders";
import InnerFooter from "../../components/InnerFooter";
import {MuiNavLink} from "../../components/Links";
import useBlockUI from "../../hooks/useBlockUI";

import {useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
export const AIChat = (props) => {
  const {user, project} = props;
  const {projectId} = useParams();
  const [copiedResponse, setCopiedResponse] = React.useState(undefined as string);
  const [reindexConfirmationIsOpen, setReindexConfirmationIsOpen] = React.useState(false);
  const [clearQuestionsDialogIsOpen, setClearQuestionsDialogIsOpen] = React.useState(false);
  const [hideJumpButton, setHideJumpButton] = React.useState(true);
  const [pendingQuestions, setPendingQuestions] = React.useState([]);
  const {documentId} = useParams();
  const blockUI = useBlockUI();
  const questionAnswerListRef = React.useRef<HTMLInputElement | null>(null);

  const {query: documentQuery, rpc: documentRPC} = useSentinelDetailAPI(
    projectId ? `projects/${projectId}/ai/documents/${documentId}/` : `ai/documents/${documentId}/`,
    {
      initialData: {},
      enabled: Boolean(documentId),
    }
  );

  const {
    query: questionsQuery,
    create: createQuestion,
    delete: deleteQuestion,
    rpc: questionRPC,
    update: updateQuestion,
  } = useSentinelListAPI(
    projectId ? `projects/${projectId}/ai/documents/${documentId}/questions/` : `ai/documents/${documentId}/questions/`,
    // `ai/documents/${documentId}/questions/`
    {
      initialData: {results: []},
      keepPreviousResults: true,
    }
  );

  const questionAnswerPairs = questionsQuery.data.results;

  const aiDocument = documentQuery.data;
  const scrollToBottomTargetRef = React.useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      scrollToBottomTargetRef.current?.scrollIntoView({
        behavior: "smooth",
        inline: "end",
      });
    }, 100);
  };

  const reIndex = () => {
    // return documentRPC.mutateAsync({action: "index"});
    return Promise.all([questionRPC.mutateAsync({action: "clear"}), documentRPC.mutateAsync({action: "index"})]);
  };

  const TargetBox = React.forwardRef((props, ref) => {
    return <Box height={75} ref={ref} {...props} />;
  });

  const handleScroll = () => {
    const rect = scrollToBottomTargetRef?.current?.getBoundingClientRect();
    const bottomIsInView =
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.right <= (window.innerWidth || document.documentElement.clientWidth);
    setHideJumpButton(rect && bottomIsInView);
  };

  React.useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sourceIsBox = ["box_file", "box_folder"].includes(aiDocument.source);
  const needsInitialIndexing = aiDocument.has_index === false && !sourceIsBox;

  React.useEffect(() => {
    if (needsInitialIndexing) {
      reIndex().then(() => {
        window.location.reload();
      });
    }
  }, [needsInitialIndexing]);

  if (!documentQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching File info..." />;
  }

  if (needsInitialIndexing) {
    return <BlockUI show={true} message="Preparing document for first use. This will take a minute..." />;
  }
  return (
    <>
      {hideJumpButton === false && (
        <Tooltip title="Jump to Bottom">
          <Fab
            style={{position: "fixed", bottom: "20%", right: "5%"}}
            onClick={() => {
              scrollToBottom();
            }}
            size="small"
          >
            <FontAwesomeIcon icon={faArrowDown} />
          </Fab>
        </Tooltip>
      )}
      <Helmet title={`AI Chat - ${aiDocument.name}`} />
      {projectId ? (
        <ProjectBreadcrumbs project={project}>
          <MuiNavLink href={`/v2/projects/${project.id}/ai/documents/`}>
            <Typography color="textSecondary">AI Documents</Typography>
          </MuiNavLink>
          <Typography color="textPrimary">{aiDocument.name}</Typography>
        </ProjectBreadcrumbs>
      ) : (
        <Breadcrumbs>
          <MuiNavLink href={`/v2/ai/documents/`}>
            <Typography color="textSecondary">AI Documents</Typography>
          </MuiNavLink>
          <Typography color="textPrimary">{aiDocument.name}</Typography>
        </Breadcrumbs>
      )}

      <Box display="flex" justifyContent="center">
        <Box style={{width: "75%"}} ref={questionAnswerListRef}>
          {questionAnswerPairs.map((pair) => (
            <QuestionAnswer key={pair.uuid}>
              <QuestionAnswer.Item text={pair.question}>
                <Tooltip title="Delete">
                  <Box
                    onClick={() => {
                      deleteQuestion.mutateAsync(pair.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Box>
                </Tooltip>
              </QuestionAnswer.Item>

              <QuestionAnswer.Item
                text={pair.answer}
                // text={pair.response.result}
                cost={user.is_superuser ? pair.data.total_cost : undefined}
                response
                source_documents={pair.response?.source_documents}
                onFeedback={(feedback) => {
                  updateQuestion.mutateAsync({id: pair.id, is_helpful: feedback === pair.is_helpful ? null : feedback});
                }}
                is_helpful={pair.is_helpful}
              >
                {pair.response.result && (
                  <>
                    <Tooltip title={pair.response.result === copiedResponse ? `Copied!` : `Copy Text`}>
                      <Box
                        onClick={() => {
                          navigator.clipboard.writeText(pair.response.result);
                          setCopiedResponse(pair.response.result);
                        }}
                        style={{cursor: "pointer"}}
                      >
                        <FontAwesomeIcon icon={faClipboard} />
                      </Box>
                    </Tooltip>
                  </>
                )}
              </QuestionAnswer.Item>
            </QuestionAnswer>
          ))}

          {pendingQuestions.map((text) => {
            return (
              <QuestionAnswer key={text}>
                <QuestionAnswer.Item text={text} />
                <QuestionAnswer.Item text={undefined} response />
              </QuestionAnswer>
            );
          })}
        </Box>
      </Box>
      {/* <pre>{JSON.stringify(aiDocument, null, 2)}</pre> */}
      <TargetBox ref={scrollToBottomTargetRef} />

      <InnerFooter>
        <AIQuestionForm
          onSubmit={(values, form) => {
            setPendingQuestions([...pendingQuestions, values.question]);
            createQuestion.mutateAsync(values).then(() => {
              setPendingQuestions(pendingQuestions.filter((question) => question !== values.question));
              scrollToBottom();
            });
            scrollToBottom();
          }}
          initialValues={{question: undefined}}
          onReIndex={sourceIsBox ? null : () => setReindexConfirmationIsOpen(true)}
          onClearQuestions={() => setClearQuestionsDialogIsOpen(true)}
          documentURL={aiDocument.url}
          disabled={pendingQuestions.length > 2}
        />
      </InnerFooter>
      <ConfirmationDialog
        isOpen={reindexConfirmationIsOpen}
        onDeny={() => setReindexConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Reindexing document. This will take a minute...");
          setReindexConfirmationIsOpen(false);
          reIndex().then(() => {
            location.reload();
          });
        }}
      >
        You want to reindex this document? This will delete all previous responses and will take a few minutes to
        complete. You should only do this if the document has been changed, this can not be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={clearQuestionsDialogIsOpen}
        onDeny={() => setClearQuestionsDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setClearQuestionsDialogIsOpen(false);
          questionRPC.mutateAsync({action: "clear"}).then(() => {
            blockUI.unblockUI();
          });
        }}
      >
        You want to delete all questions and responses?
      </ConfirmationDialog>
    </>
  );
};

export default AIChat;
