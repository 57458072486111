import {faCheck, faFilePdf, faFileSignature, faPaperPlane, faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {useSnackbar} from "notistack";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {useQueryState} from "use-location-state";
import HtmlRender from "../../../js/components/HtmlRender";
import {axiosAPI} from "../../api";
import {CheckboxMui, RadioGroupMui, TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {LabelValue} from "../../components/LabelValue";
import {PageHeader} from "../../components/PageHeader";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";
import {validateEmailAddress} from "../../utils/validators";

const ExternalSafetyOrientation = (props) => {
  const {
    contact,
    project,
    user,
    twilioContact,
    safetyOrientationCompleted,
    siteInfo,
    hospitalNavigationLink,
    urgentCareNavigationLink,
    ...rest
  } = props;

  const params = useParams();
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const isSpanish = twilioContact?.language === "SP";
  const [confirmationDialigIsOpen, setConfirmationDialogIsOpen] = React.useState(false);
  const [hasSeenPDF, setHasSeenPDF] = React.useState(false);
  const [showSeePDFWarning, setShowSeePDFWarning] = React.useState(false);
  const [emailDocumentDialogIsOpen, setEmailDocumentDialogIsOpen] = React.useState(false);
  const [date, setDate] = useQueryState("date", "");
  const postCurrentPage = usePostCurrentPage();

  return (
    <UnauthenticatedLayout>
      <Helmet title={`${project?.display}`} />

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            {project?.display} - {isSpanish ? `Orientación de Seguridad` : `Safety Orientation`}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        {date && (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              href={`/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`}
              startIcon={<FontAwesomeIcon icon={faQrcode} />}
            >
              {isSpanish ? `Volver al Código QR` : `Back to QR Code`}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            target="_blank"
            href={`/reports2/external/projects/${project.uuid}/safety/orientation/${
              isSpanish ? `?lang=${twilioContact.language}` : ``
            }`}
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            onClick={() => setHasSeenPDF(true)}
          >
            {isSpanish ? `Ver PDF de Orientación` : `View Orientation PDF`}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
            onClick={() => setEmailDocumentDialogIsOpen(true)}
          >
            {isSpanish ? `Enviar PDF de Orientación` : `Send Orientation PDF`}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <span
            onClick={() => {
              if (!hasSeenPDF && !showSeePDFWarning) {
                setShowSeePDFWarning(true);
              }
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={() => setConfirmationDialogIsOpen(true)}
              disabled={safetyOrientationCompleted || !hasSeenPDF}
              startIcon={<FontAwesomeIcon icon={safetyOrientationCompleted ? faCheck : faFileSignature} />}
              color={safetyOrientationCompleted ? "primary" : "secondary"}
            >
              {!isSpanish && (safetyOrientationCompleted ? `Acknowledged` : `Acknowledge`)}
              {isSpanish && (safetyOrientationCompleted ? `Admitido` : `Reconocer`)}
            </Button>
          </span>
          {showSeePDFWarning && !hasSeenPDF && !safetyOrientationCompleted && (
            <Box width="100%" display="flex" justifyContent="center">
              <FormHelperText error>
                {isSpanish
                  ? `Primero debe revisar la Orientación de seguridad.`
                  : `You must first review the Safety Orientation.`}
              </FormHelperText>
            </Box>
          )}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6">{isSpanish ? "Acceso del Sitio:" : "Site Access:"}</Typography>
          </Grid>
          <Grid item xs={isSpanish ? 12 : 6}>
            <strong>{isSpanish ? "Días de la semana:" : "Weekdays:"}</strong> {siteInfo?.weekday_start ?? "TBD"}{" "}
            {isSpanish ? "a" : "to"} {siteInfo?.weekday_end ?? "TBD"}
          </Grid>
          <Grid item xs={isSpanish ? 12 : 6}>
            {(siteInfo?.weekend_start || siteInfo.weekend_end) && (
              <>
                <strong>{isSpanish ? "Fines de semana:" : "Weekends:"}</strong> {siteInfo?.weekend_start ?? "TBD"}{" "}
                {isSpanish ? "a" : "to"} {siteInfo?.weekend_end ?? "TBD"}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{isSpanish ? "Elementos de Seguridad:" : "Safety Items:"}</Typography>
        </Grid>
        {siteInfo?.emergency_signal && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Señal de Evacuación de Emergencia:" : "Emergency Evacuation Signal:"}
              value={siteInfo?.emergency_signal}
            />
          </Grid>
        )}
        {siteInfo?.emergency_signal && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación de la Señal de Emergencia:" : "Emergency Signal Location:"}
              value={siteInfo?.emergency_signal_location}
            />
          </Grid>
        )}
        {siteInfo?.emergency_signal && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación de Derecho a Saber/HAZCOM:" : "Right to Know/HAZCOM Location:"}
              value={siteInfo?.rtk_area_location}
            />
          </Grid>
        )}
        {siteInfo?.first_aid_location && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación del Kit de Primeros Auxilios:" : "First Aid Kit Location:"}
              value={siteInfo?.first_aid_location}
            />
          </Grid>
        )}

        {siteInfo?.fire_sprinkler_shutoff_location && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={
                isSpanish ? "Ubicación de cierre de rociadores contra incendios:" : "Fire Sprinkler Shutoff Location:"
              }
              value={siteInfo?.fire_sprinkler_shutoff_location}
            />
          </Grid>
        )}
        {siteInfo?.domestic_water_shutoff_location && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación del corte de agua doméstica:" : "Domestic Water Shutoff Location:"}
              value={siteInfo?.domestic_water_shutoff_location}
            />
          </Grid>
        )}
        {siteInfo?.gas_shutoff_location && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación del corte de gas:" : "Gas Shutoff Location:"}
              value={siteInfo?.gas_shutoff_location}
            />
          </Grid>
        )}
        {siteInfo?.electrical_shutoff_location && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación del corte eléctrico:" : "Electrical Shutoff Location:"}
              value={siteInfo?.electrical_shutoff_location}
            />
          </Grid>
        )}
        {siteInfo?.sanitary_facilities_location && (
          <Grid item xs={12} sm={6}>
            <LabelValue
              label={isSpanish ? "Ubicación de las instalaciones sanitarias:" : "Sanitary Facilities Location:"}
              value={siteInfo?.sanitary_facilities_location}
            />
          </Grid>
        )}

        {hospitalNavigationLink && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? `Hospital Más Cercano:` : `Nearest Hospital:`}
              value={
                <Link href={hospitalNavigationLink} underline="always">
                  {siteInfo?.nearest_hospital}
                </Link>
              }
            />
          </Grid>
        )}
        {urgentCareNavigationLink && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? `Clinica de Urgencias Más Cercano:` : `Nearest Urgent Care:`}
              value={
                <Link href={urgentCareNavigationLink} underline="always">
                  {siteInfo?.nearest_urgent_care}
                </Link>
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6">
            {isSpanish ? "Información Específica del Sitio:" : "Site Specific Information:"}
          </Typography>
          <HtmlRender html={siteInfo?.site_specific_information} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{isSpanish ? "Contactos:" : "Contacts:"}</Typography>
        </Grid>
        {siteInfo?.superintendent && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? "Superintendente del Proyecto:" : "Project Superintendent:"}
              value={siteInfo?.superintendent}
            />
          </Grid>
        )}
        {siteInfo?.project_assistant_superintendent && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? "Asistente de Superintendente del Proyecto:" : "Project Assistant Superintendent:"}
              value={siteInfo?.project_assistant_superintendent}
            />
          </Grid>
        )}
        {siteInfo?.project_foreman && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? "Capataz del Proyecto:" : "Project Foreman:"}
              value={siteInfo?.project_foreman}
            />
          </Grid>
        )}
        {siteInfo?.project_engineer && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? "Ingeniero del Proyecto:" : "Project Engineer:"}
              value={siteInfo?.project_engineer}
            />
          </Grid>
        )}
        {siteInfo?.project_manager && (
          <Grid item xs={12}>
            <LabelValue
              label={isSpanish ? "Gerente del Proyecto:" : "Project Manager:"}
              value={siteInfo?.project_manager}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <LabelValue
            label={isSpanish ? "Director de Seguridad:" : "Safety Director:"}
            value={siteInfo?.safety_director ?? "Matt Buzzetta - (650) 339-5341"}
          />
        </Grid>
      </Grid>

      <OrientationConfirmationDialog
        isOpen={confirmationDialigIsOpen}
        handleClose={() => setConfirmationDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI(isSpanish ? `Guardando...` : `Saving...`);
          postCurrentPage.mutateAsync({action: "complete_orientation", ...values}).then(() => {
            // window.location.href = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`;
            window.location.reload();
            // setConfirmationDialogIsOpen(false);
            // blockUI.unblockUI();
          });
        }}
        userName={twilioContact?.name}
        isSpanish={isSpanish}
      />

      <SafetyOrientationEmailLinkForm
        isOpen={emailDocumentDialogIsOpen}
        handleClose={() => setEmailDocumentDialogIsOpen(false)}
        isSpanish={isSpanish}
        onSubmit={(values) => {
          blockUI.blockUI("Emailing link...");
          axiosAPI
            .post(`/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/email-safety-orientation/`, values, {
              baseURL: "",
            })
            .then((res) => {
              setEmailDocumentDialogIsOpen(false);
              setHasSeenPDF(true);
              blockUI.unblockUI();
              enqueueSnackbar(res.data.message, {variant: "success"});
            })
            .catch((err) => {
              setEmailDocumentDialogIsOpen(false);
              blockUI.unblockUI();
              enqueueSnackbar("Error sending email", {variant: "error"});
            });
        }}
        initialValues={{email: twilioContact?.contact?.email, language: twilioContact?.language}}
      />

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      {/* <h1>Page Data</h1>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <h1>Project</h1>
      <pre>{JSON.stringify(project, null, 2)}</pre>
      <h1>Props</h1>
      <pre>{JSON.stringify(project, null, 2)}</pre> */}
    </UnauthenticatedLayout>
  );
};

export default ExternalSafetyOrientation;

export const OrientationConfirmationDialog = (props: {
  onSubmit: any;
  isSpanish?: boolean;
  isOpen: boolean;
  handleClose: () => void;
  userName: string;
  [rest: string]: any;
}) => {
  const {onSubmit, isSpanish, isOpen, handleClose, userName, ...rest} = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isSpanish ? `Confirmación` : `Confirmation`}</DialogTitle>
      <DialogContent>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {(formProps) => {
            const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {isSpanish
                        ? "Estas Reglas Básicas de Seguridad son adicionales a cualquier regla, reglamento o requisito requerido por cualquier agencia pública con jurisdicción apropiada. Entiendo que es mi responsabilidad aprender y seguir todas las reglas y regulaciones de seguridad aplicables que pertenecen a mi alcance de trabajo y visita a este proyecto. También entiendo que es mi responsabilidad final garantizar que mi área de trabajo sea segura antes de iniciar y realizar el trabajo. He tenido la oportunidad de hacer preguntas y comprender que la violación de las reglas de seguridad del sitio puede resultar en medidas disciplinarias, incluida la expulsión del sitio del proyecto. También he preguntado sobre los peligros y condiciones específicos del sitio y sé dónde obtener más información sobre las reglas de seguridad de NOVO Construction."
                        : "These Basic Safety Rules are in addition to any rules, regulations or requirements required by any public agency with appropriate jurisdiction. I understand that it is my responsibility to learn and follow all the applicable safety rules and regulations that pertain to my scope of work and visit to this project. I also understand that it is my ultimate responsibility to ensure that my work area is safe prior to entering into and performing work.  I have had the opportunity to ask questions and understand that violation of the site safety rules may result in disciplinary action, including removal from the project site.  I have also asked about any site-specific hazards and conditions, and I am aware of where to get further information about NOVO Construction safety rules. "}
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxMui
                        label={
                          isSpanish
                            ? `Yo, ${userName}, confirmo que he recibido, leído y comprendido la ORIENTACIÓN DE SEGURIDAD DEL SITIO DE NOVO Construction, Inc. y acepto cumplir con esas reglas y regulaciones. Esto pasará a formar parte de mi expediente personal. El abajo firmante declara que antes de ejecutar este comunicado, lo ha leído, lo comprende y lo ejecuta con pleno conocimiento de su contenido y significado.`
                            : `I, ${userName}, confirm that I have received, read, and understand the NOVO Construction, Inc. SITE SAFETY ORIENTATION and agree to abide by those rules and regulations. This will become part of my personnel file. The undersigned hereby states that before executing this release, that he or she has read it and understands it and executes it with full knowledge of its content and meaning.`
                        }
                        name="confirm"
                        required
                      />
                    </Grid>

                    {/* <Grid item xs={12}>

                      <FormActions.SaveButton disabled={!values.confirm}>Confirm</FormActions.SaveButton>
                      <FormActions.CancelButton onClick={handleClose} color="primary">
                        Cancel
                      </FormActions.CancelButton>
                    </Grid> */}
                  </Grid>
                  <DialogActions>
                    <FormActions.SaveButton disabled={!values.confirm}>Confirm</FormActions.SaveButton>
                    <FormActions.CancelButton onClick={handleClose} color="primary">
                      Cancel
                    </FormActions.CancelButton>
                  </DialogActions>
                </form>
              </>
            );
          }}
        </FinalForm>
      </DialogContent>
    </Dialog>
  );
};

const SafetyOrientationEmailLinkForm = (props: {
  onSubmit: any;
  isSpanish?: boolean;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isSpanish, isOpen, handleClose, ...rest} = props;
  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return;
    }
    if (!validateEmailAddress(value)) {
      return isSpanish ? "La dirección de email no es válida" : "Not a valid email address";
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{isSpanish ? `Enviar PDF` : `Send PDF`}</DialogTitle>
      <DialogContent>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {(formProps) => {
            const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <TextFieldMui
                        name="email"
                        label="Email"
                        type="email"
                        size="small"
                        helperText={
                          isSpanish
                            ? `Ingrese un correo electrónico para enviar este documento a otro dispositivo o persona`
                            : "Enter an email to send this document to another device or person"
                        }
                        required
                        autoFocus
                        fieldProps={{validate: validateEmail}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RadioGroupMui
                        label={isSpanish ? `Idioma` : `Language`}
                        name="language"
                        options={[
                          {label: isSpanish ? `Ingles` : `English`, value: "EN"},
                          {label: isSpanish ? `Español` : `Spanish`, value: "SP"},
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        type="submit"
                        // size="large"
                        startIcon={<SendIcon />}
                        disabled={invalid || submitting || !values.email}
                      >
                        {isSpanish ? `Enviar Enlance` : `Email Link`}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </>
            );
          }}
        </FinalForm>
      </DialogContent>
    </Dialog>
  );
};
