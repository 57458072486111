import {Button, ButtonGroup, Tooltip} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";

export const PreviousNextDate = (props: {
  allowFuture?: boolean;
  disabled?: boolean;
  setDate: (date: string) => void;
  date: string;
  dateLabelFormat?: string;
  buttonProps?: {[key: string]: any};
  rest?: {[key: string]: any};
}) => {
  const {allowFuture, disabled, date, setDate, dateLabelFormat = "dddd, MMMM Do", buttonProps, ...rest} = props;
  const dateObject = moment(date);

  const disableNext = allowFuture ? false : dateObject.clone().add(1, "days").isAfter(moment(), "day");

  return (
    <>
      <ButtonGroup {...rest}>
        <Tooltip title={dateObject.clone().subtract(1, "days").format(dateLabelFormat)}>
          <Button
            onClick={() => {
              setDate(dateObject.clone().subtract(1, "days").format("YYYY-MM-DD"));
            }}
            startIcon={<ArrowBackIcon />}
            disabled={disabled}
            variant="outlined"
            {...buttonProps}
          >
            Prev
          </Button>
        </Tooltip>
        <Tooltip title={disableNext ? "" : dateObject.clone().add(1, "days").format(dateLabelFormat)}>
          <Button
            onClick={() => {
              setDate(dateObject.clone().add(1, "days").format("YYYY-MM-DD"));
            }}
            startIcon={<ArrowForwardIcon />}
            disabled={disableNext || disabled}
            variant="outlined"
            {...buttonProps}
          >
            Next
          </Button>
        </Tooltip>
      </ButtonGroup>
    </>
  );
};
