import {faList, faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import pluralize from "pluralize";
import {useLocation} from "react-router";
import {Currency} from "../../../components/Accounting";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";

const PurchaseOrderPaperItem = (props) => {
  const {
    purchaseOrder,
    dragHandleProps,
    purchaseOrderId,
    boxFolderId,
    onEditPurchaseOrder,

    ...rest
  } = props;
  const {pathname} = useLocation();
  const itemCount = purchaseOrder.item_count;

  return (
    <PaperItem isDragging={purchaseOrder.isDragging} {...rest}>
      <PaperItem.Left minWidth={45}>
        <Box display="flex" alignItems="center">
          <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
          <PaperItem.Position position={purchaseOrder.position} />
        </Box>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={0}>
          <Grid item sm={8} xs={12}>
            <StatusLabel status={purchaseOrder.status_display} />{" "}
            {/* <Link href={purchaseOrder.url} underline="always">
              {purchaseOrder.description}
            </Link> */}
            <MuiNavLink to={`/v2/projects/${purchaseOrder.project_id}/pos/${purchaseOrder.id}/`} underline="always">
              {purchaseOrder.description}
            </MuiNavLink>
          </Grid>

          <Grid item sm={4} xs={12}>
            <Tooltip title={purchaseOrder.budget?.display}>
              <span>
                <Typography2 noWrap type="metadata">
                  {purchaseOrder.budget?.display}
                </Typography2>
              </span>
            </Tooltip>
          </Grid>
          {/* New line */}
          <Grid item sm={2} xs={12}>
            <Typography2 type="metadata">
              <MuiNavLink to={`/v2/projects/${purchaseOrder.project_id}/pos/${purchaseOrder.id}/#tab=items`}>
                <FontAwesomeIcon icon={faList} /> {pluralize(`${itemCount} Item`, itemCount)}
              </MuiNavLink>
            </Typography2>
          </Grid>

          <Grid item sm={4} xs={12}>
            <Link href={purchaseOrder.to_company?.url}>
              <small>{purchaseOrder.to_company.name}</small>
            </Link>
          </Grid>

          <Grid item sm={2} xs={6}>
            {purchaseOrder.docusign_envelope_status && (
              <Link href={purchaseOrder.current_docusign_envelope_detail_url}>
                <Tooltip title="DocuSign Status">
                  <small>
                    <FontAwesomeIcon icon={faPenFancy} /> {purchaseOrder.docusign_envelope_status}
                  </small>
                </Tooltip>
              </Link>
            )}
          </Grid>

          <Grid item sm={2} xs={6}>
            {purchaseOrder.timberline_subcontract_id && (
              <Tooltip title="Timberline Subcontract">
                <small>
                  {/* <Link href={`/contracts/subcontract/${purchaseOrder.id}/#tab_timberline`}>
                    {purchaseOrder.timberline_subcontract_id}
                  </Link> */}
                  <Link href={`/pos/${purchaseOrder.id}/#tab_timberline`}>
                    {purchaseOrder.timberline_subcontract_id}
                  </Link>
                </small>
              </Tooltip>
            )}
          </Grid>
          <Grid item sm={2} xs={6}>
            {purchaseOrder.total_price !== purchaseOrder.current_value && (
              <Box textAlign="right">
                <Tooltip title="Original Value">
                  <small>
                    <Currency number={purchaseOrder.total_price} precision={2} />
                  </small>
                </Tooltip>
              </Box>
            )}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={110}>
        <Box textAlign="right">
          <Box>
            <Typography2 noWrap type="metadata">
              {purchaseOrder.number_display}
            </Typography2>
          </Box>
          <Box>
            <Tooltip
              title={
                <>
                  Original Value: <Currency number={purchaseOrder.total_price} precision={2} />
                  <br />
                  Current Value: <Currency number={purchaseOrder.current_value} precision={2} />
                </>
              }
            >
              <strong>
                <Currency number={purchaseOrder.current_value} precision={2} />
              </strong>
            </Tooltip>
          </Box>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={LinkIcon}
          title="Link"
          component={MuiNavLink}
          to={`/v2/projects/${purchaseOrder.project_id}/pos/${purchaseOrder.id}/`}
        />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditPurchaseOrder} />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete"
          disabled={purchaseOrder.is_closed}
          component={Link}
          href={`/v2/projects/${purchaseOrder.project_id}/delete/pos/purchaseorder/${purchaseOrder.id}/?next=${pathname}&cancel=${pathname}`}
        />
        <BoxFilesIconButtonLink
          projectId={purchaseOrder.project_id}
          app="pos"
          model="purchaseorder"
          objectId={purchaseOrder.id}
          boxFolderId={boxFolderId}
        />
        <Box textAlign="right">
          <Tooltip
            title={
              <>
                Original Value: <Currency number={purchaseOrder.total_price} precision={2} />
                <br />
                Current Value: <Currency number={purchaseOrder.current_value} precision={2} />
              </>
            }
          >
            <strong>
              <Currency number={purchaseOrder.current_value} precision={2} />
            </strong>
          </Tooltip>
        </Box>
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export default PurchaseOrderPaperItem;
