import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Link} from "@material-ui/core";
import React from "react";
import {NavLink} from "react-router";
import usePermissions from "../hooks/usePermissions";

export const MuiNavLink = React.forwardRef((props: any, ref) => {
  const {to, href, children, component, ...rest} = props;
  const Component = component || Link;
  return (
    <Component ref={ref} component={NavLink} to={href || to} {...rest}>
      {children}
    </Component>
  );
});

export const PortalLink = (props) => {
  const {href, ...rest} = props;
  const fullHref = `https://portal.novoconstruction.com${href}`;
  return (
    <Box {...rest}>
      <strong>Portal Link:</strong>{" "}
      <Link href={fullHref} target="_blank">
        <FontAwesomeIcon icon={faExternalLink} /> {fullHref}
      </Link>
    </Box>
  );
};

export const LegacyUILink = (props: {href: string; title?: string; [rest: string]: any}) => {
  const {href, title = "Legacy UI Link:", ...rest} = props;
  return (
    <Box {...rest}>
      <strong>{title}</strong> <Link href={href}>{href}</Link>
    </Box>
  );
};

export const ProjectAuditLink = (props: {
  projectId: number;
  app: string;
  model: string;
  id: string | number;
  title?: string;
  [rest: string]: any;
}) => {
  const {projectId, app, model, id, title = "Audit", ...rest} = props;
  const auditURL = `/v2/projects/${projectId}/audit/${app}/${model}/${id}/`;
  const permissions = usePermissions();
  if (permissions.has_restricted_views) return null;
  return (
    <Box {...rest}>
      <strong>{title}:</strong>{" "}
      <Link href={auditURL} target="_blank">
        <FontAwesomeIcon icon={faExternalLink} /> {auditURL}
      </Link>
      {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
    </Box>
  );
};

export const AuditLink = (props: {
  app: string;
  model: string;
  id: string | number;
  title?: string;
  [rest: string]: any;
}) => {
  const {projectId, app, model, id, title = "Audit", ...rest} = props;
  const auditURL = `/v2/admin-tools/audit/${app}/${model}/${id}/`;
  const permissions = usePermissions();
  if (permissions.has_restricted_views) return null;
  return (
    <Box {...rest}>
      <strong>{title}:</strong>{" "}
      <Link href={auditURL} target="_blank">
        <FontAwesomeIcon icon={faExternalLink} /> {auditURL}
      </Link>
      {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
    </Box>
  );
};
