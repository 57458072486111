import {AppBar, Box, Grid, Link, MenuItem, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {Link as LinkIcon} from "@material-ui/icons";
import Block from "@material-ui/icons/Block";
import Check from "@material-ui/icons/Check";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import SendIcon from "@material-ui/icons/Send";
import {TabContext, TabPanel} from "@material-ui/lab";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../../components/Filters";
import {LabelValue} from "../../../components/LabelValue";
import {MuiNavLink} from "../../../components/Links";
import {Pagination} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import {TabCountChip} from "../../../components/Tabs";
import safetyFormStatusChoices from "../../../components/forms/choices/safetyFormStatusChoices.json";
import formTypes from "../../../components/forms/choices/safetyFormTypeChoices.json";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {validateEmailAddress} from "../../../utils/validators";
import {
  ApproveFormDialog,
  CreateAndSendFormDialog,
  FormTypeSelectDialog,
  ManageEmailContactsForm,
  ReviseResubmitDialog,
  SendCopyDialog,
} from "./ProjectSafetyFormForms";

const ProjectSafetyForms = (props) => {
  const {project, ...rest} = props;
  const [showFormTypeSelectDialog, setShowFormTypeSelectDialog] = React.useState(false);
  const [activeForm, setActiveForm] = React.useState(undefined as any);
  const [activeFormType, setActiveFormType] = React.useState("" as "offsite" | "onsite");
  const [showApprovalConfirmationDialog, setShowApprovalConfirmationDialog] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("onsite");
  const [showReviseResubmitDialog, setShowReviseResubmitDialog] = React.useState(false);
  const [showSendCopyDialog, setShowSendCopyDialog] = React.useState(false);
  const [showCreateAndSendFormDialog, setShowCreateAndSendFormDialog] = React.useState(false);
  const [addDefaultContactsConfirmationIsOpen, setAddDefaultContactsConfirmationIsOpen] = React.useState(false);

  const [formTypeToCreate, setFormTypeToCreate] = React.useState<{
    value: "hot_work" | "scissor_boom_lift_inspection" | "forklift_inspection";
    label: string;
  }>({label: "Hot Work", value: "hot_work"});
  const [onSiteDateRange, setOnSiteDateRange] = React.useState<number | "">(30);
  const [offSiteDateRange, setOffSiteDateRange] = React.useState<number | "">(30);
  const [offSitePage, setOffSitePage] = React.useState(1);
  const [offSitePageSize, setOffSitePageSize] = React.useState(100);
  const [onSitePage, setOnSitePage] = React.useState(1);
  const [onSitePageSize, setOnSitePageSize] = React.useState(100);
  const sendCopyRef = React.useRef(null);

  const postCurrentPage = usePostCurrentPage();

  const [
    onSiteSafetyFormFilterOptions,
    setOnSiteSafetyFormFilterOption,
    clearOnSiteSafetyFormFilterOption,
    clearAllOnSiteSafetyFormFilterOptions,
  ] = useFilterOptions(["Status", "Search", "Permit Type", "Start Date", "End Date"], {
    "Start Date": {
      value: moment().subtract(30, "days").format("YYYY-MM-DD"),
      label: moment().subtract(30, "days").format("YYYY-MM-DD"),
    },
    "End Date": {
      value: moment().add(1, "days").format("YYYY-MM-DD"),
      label: moment().add(1, "days").format("YYYY-MM-DD"),
    },
  });

  const [
    offSiteSafetyFormFilterOptions,
    setOffSiteSafetyFormFilterOption,
    clearOffSiteSafetyFormFilterOption,
    clearAllOffSiteSafetyFormFilterOptions,
  ] = useFilterOptions(["Status", "Search", "Permit Type", "Start Date", "End Date"], {
    "Start Date": {
      value: moment().subtract(30, "days").format("YYYY-MM-DD"),
      label: moment().subtract(30, "days").format("YYYY-MM-DD"),
    },
    "End Date": {
      value: moment().add(1, "days").format("YYYY-MM-DD"),
      label: moment().add(1, "days").format("YYYY-MM-DD"),
    },
  });

  const blockUi = useBlockUI();
  const novoClasses = makeNovoClasses();
  const onSiteFilterParams = {
    q: onSiteSafetyFormFilterOptions.Search.value,
    status: onSiteSafetyFormFilterOptions.Status.value,
    form_type: onSiteSafetyFormFilterOptions["Permit Type"].value,
    date_min: onSiteSafetyFormFilterOptions["Start Date"].value,
    date_max: onSiteSafetyFormFilterOptions["End Date"].value,
  };
  const offSiteFilterParams = {
    q: offSiteSafetyFormFilterOptions.Search.value,
    status: offSiteSafetyFormFilterOptions.Status.value,
    form_type: offSiteSafetyFormFilterOptions["Permit Type"].value,
    date_min: offSiteSafetyFormFilterOptions["Start Date"].value,
    date_max: offSiteSafetyFormFilterOptions["End Date"].value,
  };

  const {query: onSiteSafetyFormQuery, rpc: onSiteSafetyFormRPC} = useSentinelListAPI(
    `projects/${project.id}/safety/permits/onsite/?page_size=${onSitePageSize}&page=${onSitePage}&${qs.stringify(
      onSiteFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const {query: offSiteSafetyFormQuery, rpc: offSiteSafetyFormRPC} = useSentinelListAPI(
    `projects/${project.id}/safety/permits/offsite/?page_size=${offSitePageSize}&page=${offSitePage}&${qs.stringify(
      offSiteFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const {
    query: emailContactsQuery,
    rpc: emailContactsRPC,
    update: updateEmailContacts,
  } = useSentinelDetailAPI(`projects/${project.id}/safety/safety-form-email-notification-contacts/`, {
    keepPreviousData: true,
    initialData: {results: []},
  });

  const emailNotificationContacts = emailContactsQuery.data.results ?? emailContactsQuery.data.contacts;

  const emailNotificationContactsOptions = emailContactsQuery.data.results
    ? emailNotificationContacts.map((notificationContact) => ({
        ...notificationContact.contact,
      }))
    : emailContactsQuery.data.contacts;

  const onSiteSafetyForms = onSiteSafetyFormQuery.data.results;

  const offSiteSafetyForms = offSiteSafetyFormQuery.data.results;

  const pageDataQuery = useFetchCurrentPage({
    initialData: {
      defaultEmailContacts: [],
    },
  });
  const pageData = pageDataQuery.data;

  const {defaultEmailContacts} = pageData;

  const queryRPC = React.useMemo(() => {
    if (activeFormType === "onsite") {
      return onSiteSafetyFormRPC;
    } else {
      return offSiteSafetyFormRPC;
    }
  }, [activeFormType]);

  const compileEmails = (values, useTypes = false) => {
    const formData = {...values};
    if (useTypes) {
      formData.types = Object.keys(values.types);
    }

    formData.email_contacts = values.email_contacts.map((contact) => contact.email);
    formData.cc_contacts = values.cc_contacts.map((contact) => contact.email);
    if (formData.other_contact && validateEmailAddress(formData.other_contact)) {
      formData.email_contacts.push(formData.other_contact);
    }

    delete formData.other_contact;

    return formData;
  };

  const onSiteFormTypes = ["hot_work", "scissor_boom_lift_inspection", "forklift_inspection"];
  // if (
  //   !onSiteSafetyFormQuery.isFetchedAfterMount ||
  //   !offSiteSafetyFormQuery.isFetchedAfterMount ||
  //   !emailContactsQuery.isFetchedAfterMount
  // ) {
  //   return <BlockUI show={true} message="Fetching Safety Forms..." />;
  // }

  return (
    <>
      <Helmet title={`${project.display} - Safety Permits`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Safety Permits</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                value="onsite"
                className={novoClasses.smallTab}
                label={
                  <Box display="flex" alignItems="center">
                    Onsite Permits
                    <TabCountChip
                      isLoading={!onSiteSafetyFormQuery.isFetchedAfterMount}
                      count={onSiteSafetyFormQuery.data.count}
                    />
                  </Box>
                }
              />
              <Tab label="Configuration" value="configuration" className={novoClasses.smallTab} />
              {/* <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Offsite Permits
                    <TabCountChip
                      isLoading={!offSiteSafetyFormQuery.isFetchedAfterMount}
                      count={offSiteSafetyFormQuery.data.count}
                    />
                  </Box>
                }
                value="offsite"
                className={novoClasses.smallTab}
              /> */}
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="onsite">
            <PaperPanel.TabHeader isLoading={onSiteSafetyFormQuery.isFetching}>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.DropdownMenu title="Create and Send">
                    {(setOpen) => (
                      <span>
                        <PaperPanel.Header.Menu.MenuItem onClick={() => setShowCreateAndSendFormDialog(true)}>
                          Hot Work Permit
                        </PaperPanel.Header.Menu.MenuItem>
                      </span>
                    )}
                  </PaperPanel.Header.DropdownMenu>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action>
                  <Tooltip title="Get Permits that are valid for today" placement="top">
                    <div>
                      <PaperPanel.Header.PDFDropdownButton title="Get Permits">
                        {(setOpen) => (
                          <>
                            <MenuItem
                              component={Link}
                              target="_blank"
                              href={`/reports2/projects/${project.id}/safety/permits/?form_type=hot_work&status=approved&hide_expired=true&hide_upcoming=true&hide_inspection=true`}
                              onClick={() => setOpen(false)}
                            >
                              Hot Work
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              target="_blank"
                              href={`/reports2/projects/${
                                project.id
                              }/safety/permits/?form_type=scissor_boom_lift_inspection&status=approved&date=${moment().format(
                                "YYYY-MM-DD"
                              )}&hide_inspection=true`}
                              onClick={() => setOpen(false)}
                            >
                              Scissor/Boom Lift
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              target="_blank"
                              onClick={() => setOpen(false)}
                              href={`/reports2/projects/${
                                project.id
                              }/safety/permits/?form_type=forklift_inspection&status=approved&date=${moment().format(
                                "YYYY-MM-DD"
                              )}&hide_inspection=true`}
                            >
                              Forklift
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              target="_blank"
                              onClick={() => setOpen(false)}
                              href={`/reports2/projects/${project.id}/safety/permits/today/`}
                            >
                              All
                            </MenuItem>
                          </>
                        )}
                      </PaperPanel.Header.PDFDropdownButton>
                    </div>
                  </Tooltip>
                  <Box mr={1} />
                  <PaperPanel.Header.RefreshButton
                    onClick={() => onSiteSafetyFormQuery.refetch()}
                    isFetching={onSiteSafetyFormQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Toolbar mt={-2} mx={-3} mb={3} pb={1} px={1}>
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <FilterSearch
                    label="Search"
                    value={onSiteSafetyFormFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setOnSitePage(1);
                      setOnSiteSafetyFormFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FilterSelect
                    label="Status"
                    name="status"
                    options={safetyFormStatusChoices}
                    value={onSiteSafetyFormFilterOptions.Status.value}
                    onChange={(value, label) => {
                      setOnSitePage(1);
                      setOnSiteSafetyFormFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FilterSelect
                    label="Permit Type"
                    name="form_type"
                    options={formTypes}
                    value={onSiteSafetyFormFilterOptions.Status.value}
                    onChange={(value, label) => {
                      setOnSitePage(1);
                      setOnSiteSafetyFormFilterOption("Permit Type", value, label);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FilterDate
                    name="Start Date"
                    value={onSiteSafetyFormFilterOptions["Start Date"].value}
                    label="Start Date"
                    onChange={(date) => {
                      setOnSitePage(1);
                      setOnSiteSafetyFormFilterOption("Start Date", date, date);
                      setOnSiteDateRange("");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterDate
                    name="End Date"
                    value={onSiteSafetyFormFilterOptions["End Date"].value}
                    label="End Date"
                    onChange={(date) => {
                      setOnSitePage(1);
                      setOnSiteSafetyFormFilterOption("End Date", date, date);
                      setOnSiteDateRange("");
                    }}
                    disableFuture={false}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    name="Date Range"
                    label="Date Range"
                    value={onSiteDateRange}
                    options={[
                      {label: "Today", value: 0},
                      {label: "Last Week", value: 7},
                      {
                        label: "Last 2 Weeks",
                        value: 14,
                      },
                      {
                        label: "Last Month",
                        value: 30,
                      },
                    ]}
                    onChange={(value, label) => {
                      const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                      const endDate = moment().add(1, "days").format("YYYY-MM-DD");
                      setOnSitePage(1);
                      setOnSiteSafetyFormFilterOption("Start Date", startDate, startDate);
                      setOnSiteSafetyFormFilterOption("End Date", endDate, endDate);
                      setOnSiteDateRange(value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FilterOptionChips
                    filterOptions={onSiteSafetyFormFilterOptions}
                    onDelete={(key) => {
                      clearOnSiteSafetyFormFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllOnSiteSafetyFormFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={onSiteSafetyFormQuery.data.total_pages}
                      page={onSitePage}
                      setPage={setOnSitePage}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.Toolbar>
            <PaperPanel.Body mx={-3} mt={-3}>
              {onSiteSafetyForms.map((form) => (
                <SafetyFormPaperItem
                  form={form}
                  key={form.id}
                  onClickApprove={() => {
                    setActiveFormType("onsite");
                    setActiveForm(form);
                    setShowApprovalConfirmationDialog(true);
                  }}
                  onClickUnlock={() => {
                    setActiveFormType("onsite");
                    setActiveForm(form);
                    setShowReviseResubmitDialog(true);
                  }}
                />
              ))}
            </PaperPanel.Body>
          </TabPanel>
          <TabPanel value="offsite">
            <PaperPanel.TabHeader isLoading={onSiteSafetyFormQuery.isFetching}>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton onClick={() => setShowFormTypeSelectDialog(true)}>
                    Create New Forms
                  </PaperPanel.Header.CreateButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <Box ml={-1} mr={-1}>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => offSiteSafetyFormQuery.refetch()}
                      isFetching={offSiteSafetyFormQuery.isFetching}
                    />
                  </Box>
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Toolbar mt={-2} mx={-3} mb={3} pb={1} px={1}>
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <FilterSearch
                    label="Search"
                    value={offSiteSafetyFormFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setOffSitePage(1);
                      setOffSiteSafetyFormFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FilterSelect
                    label="Status"
                    name="status"
                    options={safetyFormStatusChoices}
                    value={offSiteSafetyFormFilterOptions.Status.value}
                    onChange={(value, label) => {
                      setOffSitePage(1);
                      setOffSiteSafetyFormFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FilterSelect
                    label="Permit Type"
                    name="form_type"
                    options={formTypes}
                    value={offSiteSafetyFormFilterOptions.Status.value}
                    onChange={(value, label) => {
                      setOffSitePage(1);
                      setOffSiteSafetyFormFilterOption("Permit Type", value, label);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FilterDate
                    name="Start Date"
                    value={onSiteSafetyFormFilterOptions["Start Date"].value}
                    label="Start Date"
                    onChange={(date) => {
                      setOffSitePage(1);
                      setOffSiteSafetyFormFilterOption("Start Date", date, date);
                      setOffSiteDateRange("");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterDate
                    name="End Date"
                    value={onSiteSafetyFormFilterOptions["End Date"].value}
                    label="End Date"
                    onChange={(date) => {
                      setOffSitePage(1);
                      setOffSiteSafetyFormFilterOption("End Date", date, date);
                      setOffSiteDateRange("");
                    }}
                    disableFuture={false}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    name="Date Range"
                    label="Date Range"
                    value={offSiteDateRange}
                    options={[
                      {label: "Today", value: 0},
                      {label: "Last Week", value: 7},
                      {
                        label: "Last 2 Weeks",
                        value: 14,
                      },
                      {
                        label: "Last Month",
                        value: 30,
                      },
                    ]}
                    onChange={(value, label) => {
                      const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                      const endDate = moment().add(1, "days").format("YYYY-MM-DD");
                      setOffSitePage(1);
                      setOffSiteSafetyFormFilterOption("Start Date", startDate, startDate);
                      setOffSiteSafetyFormFilterOption("End Date", endDate, endDate);
                      setOffSiteDateRange(value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FilterOptionChips
                    filterOptions={offSiteSafetyFormFilterOptions}
                    onDelete={(key) => {
                      clearOffSiteSafetyFormFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllOffSiteSafetyFormFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={offSiteSafetyFormQuery.data.total_pages}
                      page={offSitePage}
                      setPage={setOffSitePage}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.Toolbar>
            <PaperPanel.Body mx={-3} mt={-3}>
              {offSiteSafetyForms.map((form) => {
                return (
                  <SafetyFormPaperItem
                    form={form}
                    key={form.id}
                    onClickApprove={() => {
                      setActiveForm(form);
                      setActiveFormType("offsite");
                      setShowApprovalConfirmationDialog(true);
                    }}
                    onClickUnlock={() => {
                      setActiveForm(form);
                      setActiveFormType("offsite");
                      setShowReviseResubmitDialog(true);
                    }}
                    onClickSend={() => {
                      setActiveForm(form);
                      setActiveFormType("offsite");
                      setShowSendCopyDialog(true);
                    }}
                  />
                );
              })}
            </PaperPanel.Body>
          </TabPanel>
          <TabPanel value="configuration">
            {/* <PaperPanel.TabHeader isLoading={emailContactsQuery.isLoading}></PaperPanel.TabHeader> */}
            <PaperPanel.Body mx={-3} mt={-3} py={0.5} px={1}>
              <ManageEmailContactsForm
                onSubmit={(values) => {
                  blockUi.blockUI("Saving Changes...");
                  updateEmailContacts.mutateAsync({contacts: values.contacts}).then(() =>
                    pageDataQuery.refetch().then(() => {
                      blockUi.unblockUI();
                    })
                  );
                }}
                initialValues={{contacts: emailNotificationContactsOptions}}
                setAddDefaultContactsConfirmationIsOpen={setAddDefaultContactsConfirmationIsOpen}
                isFetching={emailContactsQuery.isLoading}
                onRefresh={() => {
                  emailContactsQuery.refetch();
                  pageDataQuery.refetch();
                }}
                disabled={defaultEmailContacts.length === 0}
              />
            </PaperPanel.Body>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <ConfirmationDialog
        isOpen={addDefaultContactsConfirmationIsOpen}
        onDeny={() => setAddDefaultContactsConfirmationIsOpen(false)}
        title="Add Default Contacts"
        onApprove={() => {
          blockUi.blockUI("Adding Default Contacts...");
          emailContactsRPC.mutateAsync({action: "add-default-contacts"}).then(() => {
            emailContactsQuery.refetch().then(() =>
              pageDataQuery.refetch().then(() => {
                blockUi.unblockUI();
                setAddDefaultContactsConfirmationIsOpen(false);
              })
            );
          });
        }}
      >
        The following default contacts will be added to the list of email contacts:
        {defaultEmailContacts.map((contact) => (
          <div key={contact.id}>
            {contact.contact.full_name} ({contact.contact_role_display})
          </div>
        ))}
      </ConfirmationDialog>

      <FormTypeSelectDialog
        isOpen={showFormTypeSelectDialog}
        handleClose={() => setShowFormTypeSelectDialog(false)}
        onSubmit={(values) => {
          const emails = compileEmails(values, true);
          offSiteSafetyFormRPC
            .mutateAsync({action: "send-forms", id: activeForm?.id, data: emails})
            .then((response) => setShowFormTypeSelectDialog(false));
        }}
        formTypes={formTypes.filter((form) => !onSiteFormTypes.includes(form.value))}
        projectId={project.id}
      />
      <ApproveFormDialog
        isOpen={showApprovalConfirmationDialog}
        handleClose={() => setShowApprovalConfirmationDialog(false)}
        onSubmit={(values) => {
          blockUi.blockUI("Saving...");

          queryRPC.mutateAsync({action: "approve", id: activeForm?.id, data: values}).then(() => {
            blockUi.unblockUI();
            setShowApprovalConfirmationDialog(false);
          });
        }}
        formTypeDisplay={activeForm?.form_type_display}
      />

      <ReviseResubmitDialog
        isOpen={showReviseResubmitDialog}
        handleClose={() => setShowReviseResubmitDialog(false)}
        formTypeDisplay={activeForm?.form_type_display}
        onSubmit={(values) => {
          blockUi.blockUI("Saving...");
          queryRPC.mutateAsync({action: "mark-not-approved", id: activeForm?.id, data: values}).then(() => {
            blockUi.unblockUI();
            setShowReviseResubmitDialog(false);
          });
        }}
        twilioContact={activeForm?.twilio_contact}
      />

      <CreateAndSendFormDialog
        isOpen={showCreateAndSendFormDialog}
        handleClose={() => {
          setShowCreateAndSendFormDialog(false);
        }}
        onSubmit={(values) => {
          blockUi.blockUI("Creating and Sending...");
          postCurrentPage
            .mutateAsync({action: "create-and-send", form_type: formTypeToCreate.value, ...values})
            .then((response) => {
              setShowCreateAndSendFormDialog(false);
              onSiteSafetyFormQuery.refetch().then(() => {
                blockUi.unblockUI();
              });
            });
        }}
        projectId={project.id}
        formType={formTypeToCreate.label}
      />

      <SendCopyDialog
        isOpen={showSendCopyDialog}
        handleClose={() => setShowSendCopyDialog(false)}
        onSubmit={(values) => {
          const emails = compileEmails(values);

          queryRPC.mutateAsync({id: activeForm?.id, action: "send-copy", data: emails}).then((response) => {
            setShowSendCopyDialog(false);
          });
        }}
        projectId={project.id}
        ref={sendCopyRef}
      />
    </>
  );
};

export default ProjectSafetyForms;

const SafetyFormPaperItem = (props) => {
  const {form, onClickApprove, onClickUnlock, onClickSend, ...rest} = props;
  const canApproveOrUnlock = form.status === "complete";

  const getStatusMessage = (status, isUpcoming = false) => {
    switch (status) {
      case "approved":
        return isUpcoming ? "Upcoming Permit" : "Get Permit";
      case "expired":
        return "Permit Expired";
      default:
        return "Not Approved";
    }
  };

  return (
    <PaperItem>
      <Grid container spacing={1}>
        <Grid item sm={5}>
          <StatusLabel status={form.status_with_expiration_display} hint="projectSafetyForm" />{" "}
          <MuiNavLink href={form.detail_url} underline="always" style={{cursor: "pointer"}}>
            {/* {form.form_type_display} */}
            {form.display}
            {form.form_type === "hot_work" && form.answers?.general?.location
              ? ` (${form.answers.general.location})`
              : ""}
          </MuiNavLink>
        </Grid>
        <Grid item sm={5} xs={4}>
          {form?.twilio_contact ? (
            <>
              {form.twilio_contact.company} ({form.twilio_contact.name})
            </>
          ) : (
            form.responsible_company.name
          )}
        </Grid>
        <Grid item sm={2} xs={4}>
          <Tooltip title="Date">
            <span>{form.date}</span>
          </Tooltip>
        </Grid>
        {form.status === "approved" && (
          <>
            <Grid item xs={4}>
              <LabelValue label="Approved By" value={form.approved_by?.full_name} />
            </Grid>
            <Grid item xs={4}>
              <LabelValue label="Approved On" value={moment(form.approved_datetime).format("LLLL")} />
            </Grid>

            <Grid item xs={4}>
              {form.expiration_date && (
                <LabelValue label="Valid" value={`${form?.start_date} - ${form?.expiration_date}`} />
              )}
            </Grid>

            {form.approver_comments && (
              <Grid item xs={12}>
                <LabelValue label={"Comments"} value={form.approver_comments} />
              </Grid>
            )}
          </>
        )}

        {form.status === "failed" && (
          <>
            <Grid item xs={4}>
              <LabelValue label="Rejected By" value={form.approved_by?.full_name} />
            </Grid>
            <Grid item xs={4}>
              <LabelValue label="Rejected On" value={moment(form.approved_datetime).format("LLLL")} />
            </Grid>

            {form.approver_comments && (
              <Grid item xs={12}>
                <LabelValue label={"Comments"} value={form.approver_comments} />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <PaperItem.Right minWidth={60} />
      <PaperItem.RightHover>
        {canApproveOrUnlock && (
          <>
            <PaperItem.RightHover.IconButton
              icon={Check}
              title={canApproveOrUnlock ? "Approve" : ""}
              onClick={onClickApprove}
              disabled={!canApproveOrUnlock}
            />
            {form.can_revise_resubmit && (
              <PaperItem.RightHover.IconButton
                icon={Block}
                title={canApproveOrUnlock ? "Reject" : ""}
                onClick={onClickUnlock}
                disabled={!canApproveOrUnlock}
              />
            )}
          </>
        )}

        <PaperItem.RightHover.IconButton icon={LinkIcon} title="" href={form.url} target="_blank" />
        <PaperItem.RightHover.IconButton
          icon={PictureAsPdfIcon}
          title="View and Print"
          href={form.report_url}
          target="_blank"
        />
        <PaperItem.RightHover.IconButton
          icon={PictureAsPdfIcon}
          target="_blank"
          title={getStatusMessage(form.status_with_expiration, form.is_upcoming)}
          disabled={form.status_with_expiration !== "approved" || form.is_upcoming}
          href={`${form.report_url}?hide_inspection=true`}
        />
        {onClickSend && <PaperItem.RightHover.IconButton icon={SendIcon} title="Email a Copy" onClick={onClickSend} />}
      </PaperItem.RightHover>
    </PaperItem>
  );
};
