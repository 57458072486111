import {Box, Checkbox, IconButton, makeStyles, SvgIconTypeMap, Tooltip, Typography} from "@material-ui/core";
import {blue, grey} from "@material-ui/core/colors";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {Variant} from "@material-ui/core/styles/createTypography";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import classnames from "classnames";
import React from "react";
import {colorNovo} from "../theme/colors";
import ConditionalWrapper from "./ConditionalWrapper";
import Typography2 from "./Typography2";

const colorHover = "#fffaf0";
const colorHoverLeft = colorNovo;
const highlightBackgroundColor = blue[50];
const highlightLeftBorderColor = blue[500];

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    // root: {overflow: "hidden"},
    "@keyframes color_change": {
      // from: {backgroundColor: colorHover},
      from: {backgroundColor: highlightBackgroundColor},
      to: {backgroundColor: "none"},
    },

    item: {
      position: "relative",
      borderLeft: "2px solid transparent",
      "&.left-highlight": {
        borderLeft: `2px solid ${highlightLeftBorderColor}`,
      },
      "&:hover": {
        backgroundColor: colorHover,
        borderLeft: `2px solid ${colorHoverLeft}`,
        boxShadow: `rgb(0 0 0 / 10%) 0px 2px 1px -1px, rgb(0 0 0 / 7%) 0px 1px 1px 0px, rgb(0 0 0 / 6%) 0px 1px 3px 0px;`,

        "& .right-hover": {
          display: "flex",
        },
      },
      "&.is-dragging": {
        backgroundColor: colorHover,
        border: `1px dotted ${grey[400]}`,
      },
      "&.paper-item-flash": {
        animationName: "$color_change",
        animationDuration: "3s",
        animationIterationCount: "initial",
        animationTimingFunction: "ease-in",
      },
      "& .right-hover": {
        display: "none",
        zIndex: 10,
        backgroundColor: colorHover,
      },
    },
    itemNoHover: {
      position: "relative",
      borderLeft: "2px solid inherit",
      "&.is-dragging": {
        backgroundColor: colorHover,
        border: `1px dotted ${grey[400]}`,
      },
      "& .right-hover": {
        display: "none",
        zIndex: 10,
        backgroundColor: colorHover,
      },
    },
    rightHover: {
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      height: "98%",
      position: "absolute",
    },
    dragHandle: {
      color: grey[400],
      // height: 22,
    },
    selectedCheckbox: {
      padding: 0,
    },
  };
});

export const PaperItem = (props: {
  children?: React.ReactNode;
  isDragging?: boolean;
  noHover?: boolean;
  highlight?: boolean;
  flash?: boolean;
  [rest: string]: any;
}) => {
  const classes = useStyles();
  const {children, isDragging, className, noHover, highlight = false, leftHighlight = false, flash, ...rest} = props;
  const itemClasses = noHover ? classes.itemNoHover : classes.item;
  return (
    <Box
      display="flex"
      border={1}
      borderColor="grey.400"
      borderTop={0}
      borderLeft={0}
      borderRight={0}
      p={0.5}
      className={classnames(
        itemClasses,
        {"is-dragging": isDragging, "paper-item-flash": flash, "left-highlight": leftHighlight},
        className
      )}
      bgcolor={highlight ? highlightBackgroundColor : ""}
      {...rest}
    >
      {children}
    </Box>
  );
};

PaperItem.Header = (props: {children?: React.ReactNode; [rest: string]: any}) => {
  const {children, ...rest} = props;
  return (
    <PaperItem bgcolor="grey.100" noHover {...rest}>
      {children}
    </PaperItem>
  );
};

PaperItem.HeaderLabel = (props: {label?: React.ReactNode; labelVariant?: Variant; [rest: string]: any}) => {
  const {label = "", labelVariant = "h3", ...rest} = props;
  return (
    <PaperItem bgcolor="grey.100" noHover {...rest}>
      <Typography variant={labelVariant} style={{margin: 0}}>
        {label}
      </Typography>
    </PaperItem>
  );
};

PaperItem.Left = (props: {children?: React.ReactNode; [rest: string]: any}) => {
  const {children, ...rest} = props;
  return (
    <Box minWidth={20} {...rest}>
      {children}
    </Box>
  );
};

PaperItem.Body = (props: {children?: React.ReactNode; [rest: string]: any}) => {
  const {children, ...rest} = props;
  return (
    <Box flexGrow={1} {...rest}>
      {children}
    </Box>
  );
};

PaperItem.Right = (props: {children?: React.ReactNode; [rest: string]: any}) => {
  const {children, ...rest} = props;
  return (
    <Box minWidth={20} textAlign="right" {...rest}>
      {children}
    </Box>
  );
};

PaperItem.RightItemNumber = (props: {number: number; rightAlign?: boolean; [rest: string]: any}) => {
  const {number, rightAlign = true, ...rest} = props;
  return (
    <ConditionalWrapper condition={rightAlign} wrapper={(children) => <Box textAlign="right">{children}</Box>}>
      <Typography2 noWrap type="metadata">
        {number}
      </Typography2>
    </ConditionalWrapper>
  );
};

PaperItem.DragHandle = (props: {dragHandleProps: any; [rest: string]: any}) => {
  const {dragHandleProps, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Box
      {...dragHandleProps}
      display="flex"
      draggable={true}
      className={classnames(classes.dragHandle, className)}
      {...rest}
    >
      <DragHandleIcon />
    </Box>
  );
};

PaperItem.SelectedCheckbox = (props) => {
  const {className, ...rest} = props;
  const classes = useStyles();
  return <Checkbox size="small" className={classnames(classes.selectedCheckbox, className)} {...rest} />;
};

PaperItem.Position = (props) => {
  const {position, ...rest} = props;
  return (
    <Typography2 type="metadata" style={{display: "none"}} {...rest}>
      {position + 1}
    </Typography2>
  );
};

const RightHover = (props: {children?: React.ReactNode; [rest: string]: any}) => {
  const {children, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Box
      justifyContent="flex-end"
      alignItems="center"
      className={classnames("right-hover", classes.rightHover, className)}
      {...rest}
    >
      <Box alignItems="center" pr={1} pl={1}>
        {children}
      </Box>
    </Box>
  );
};

PaperItem.RightHover = RightHover;

RightHover.IconButton = (props: {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  title: string;
  [rest: string]: any;
}) => {
  const {icon: Icon, title, iconProps = {}, ...rest} = props;
  return (
    <Tooltip title={title}>
      <span>
        <IconButton size="small" {...rest}>
          <Icon fontSize="inherit" {...iconProps} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

RightHover.ItemNumber = (props: {number: number; rightAlign?: boolean; [rest: string]: any}) => {
  const {number, rightAlign = true, ...rest} = props;

  return (
    <ConditionalWrapper condition={rightAlign} wrapper={(children) => <Box textAlign="right">{children}</Box>}>
      <Typography2 noWrap type="metadata">
        {number}
      </Typography2>
    </ConditionalWrapper>
  );
};
