import {faGavel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Typography} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import ClosedBanner from "../../../components/ClosedBanner";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {CreateSubcontractForLetterOfIntentDialogForm, LetterOfIntentUpdateForm} from "./LetterOfIntentForms";

const LetterOfIntentDetail = (props) => {
  const {project, ...rest} = props;
  const [showCreateSubcontractDialog, setShowCreateSubcontractDialog] = React.useState(false);
  const {letterOfIntentId} = useParams();
  const {
    query: letterOfIntentQuery,
    update: updateLetterOfIntent,
    delete: deleteLetterOfIntent,
    rpc: letterOfIntentRPC,
  } = useSentinelDetailAPI(["projects", project.id, "letters-of-intent", parseInt(letterOfIntentId)], {
    initialData: {},
  });
  const letterOfIntent = letterOfIntentQuery.data;

  const blockUI = useBlockUI();

  if (!letterOfIntentQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching letter of intent..." />;
  }

  return (
    <>
      <Helmet title={`${letterOfIntent.display} - Letter of Intent`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink to={`/v2/projects/${project.id}/letters-of-intent/`}>
          <Typography color="textSecondary">Letters of Intent</Typography>
        </MuiNavLink>

        <Typography color="textPrimary">{letterOfIntent.display}</Typography>
      </ProjectBreadcrumbs>
      <ClosedBanner object={letterOfIntent} mb={2} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faGavel} /> {letterOfIntent.display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              {letterOfIntent.subcontract ? (
                <PaperPanel.Header.Button href={letterOfIntent.subcontract.url}>
                  Subcontract: {letterOfIntent.subcontract.display}
                </PaperPanel.Header.Button>
              ) : (
                <PaperPanel.Header.CreateButton
                  onClick={() => {
                    setShowCreateSubcontractDialog(true);
                  }}
                  disabled={letterOfIntent.subcontract}
                >
                  Create Subcontract for LOI
                </PaperPanel.Header.CreateButton>
              )}
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/contracts/letter-of-intent/${letterOfIntentId}/`}
                buildReportURL={`/reports2/projects/${project.id}/letters-of-intent/detail/filter/?pk=${letterOfIntentId}`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.DocuSignButton docuSignObject={letterOfIntent} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.BoxFilesButton
                href={`/v2/projects/${project.id}/box/files/contracts/letterofintent/${letterOfIntentId}/`}
                uploadURL={`/projects/${project.id}/letters-of-intent/${letterOfIntentId}/upload-to-box/`}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        <PaperPanel.Body p={2}>
          <LetterOfIntentUpdateForm
            onSubmit={(values) => {
              values = {
                ...{spec_section_code: ""},
                ...values,
              };
              blockUI.blockUI("Saving...");
              updateLetterOfIntent
                .mutateAsync(values)
                .then(() => letterOfIntentQuery.refetch())
                .then(() => blockUI.unblockUI());
            }}
            initialValues={letterOfIntent}
            projectId={project.id}
            isReadOnly={letterOfIntent.is_closed}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/contracts/letter-of-intent/${letterOfIntentId}/legacy/`} mt={2} />
      {/* <pre>{JSON.stringify(letterOfIntent, null, 2)}</pre> */}
      <CreateSubcontractForLetterOfIntentDialogForm
        projectId={project.id}
        isOpen={showCreateSubcontractDialog}
        isNew
        onSubmit={(values) => {
          blockUI.blockUI("Creating subcontract...");
          setShowCreateSubcontractDialog(false);
          letterOfIntentRPC.mutateAsync({action: "create-subcontract", data: values}).then((subcontract) => {
            letterOfIntentQuery.refetch();
            window.location.href = subcontract.url;
            // blockUI.unblockUI();
          });
        }}
        handleClose={() => {
          setShowCreateSubcontractDialog(false);
        }}
        initialValues={{
          issued_date: moment().format("YYYY-MM-DD"),
          value: letterOfIntent.not_to_exceed_value,
        }}
      />
    </>
  );
};

export default LetterOfIntentDetail;
