import {faCloud} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Link, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LaunchIcon from "@material-ui/icons/Launch";
import LockIcon from "@material-ui/icons/Lock";
import RestoreIcon from "@material-ui/icons/Restore";
import {Alert} from "@material-ui/lab";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {axiosAPI} from "../../api";
import {ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import {ColoredButton} from "../../components/Buttons";
import {ConfirmationDialog} from "../../components/Dialogs";
import BlockUI from "../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import PaperPanel, {paperPanelButtonProps} from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import usePermissions from "../../hooks/usePermissions";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";

const BoxFilesBreadcrumbs = (props) => {
  const {pageData, ...rest} = props;
  const params = useParams();
  return pageData.project ? (
    params.model === "project" ? (
      <ProjectBreadcrumbs project={pageData.project}>
        <Typography color="textPrimary">Files</Typography>
      </ProjectBreadcrumbs>
    ) : (
      <ProjectBreadcrumbs project={pageData.project}>
        {pageData.listLink && (
          <Link color="inherit" href={pageData.listLink}>
            {pageData.objectTypeName}
          </Link>
        )}
        <Link color="inherit" href={pageData.objectLink}>
          {pageData.objectDisplay}
        </Link>
        <Typography color="textPrimary">Files</Typography>
      </ProjectBreadcrumbs>
    )
  ) : (
    <Breadcrumbs>
      <MuiNavLink color="inherit" to={`/v2/dashboard/`}>
        Dashboard
      </MuiNavLink>
      <Link color="inherit" href={pageData.objectLink}>
        {pageData.objectDisplay}
      </Link>
      <Typography color="textPrimary">Files</Typography>
    </Breadcrumbs>
  );
};

const BoxFiles = (props) => {
  const [removeCollaborationConfirmationIsOpen, setRemoveCollaborationConfirmationIsOpen] = React.useState(false);
  const [iframeKey, setIframeKey] = React.useState(Math.random());
  const params = useParams();
  const permissions = usePermissions();
  const blockUI = useBlockUI();
  const qsValues = qs.parse(location.search);

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {},
    },
    {folder_id: qsValues.folder_id || ""}
  );
  const pageData = pageDataQuery.data as any;
  const postCurrentPage = usePostCurrentPage();
  const showProjectSecureFolderLink =
    pageData.objectTypeName === "Projects" && permissions.can_view_secure_project_folders;

  const addCollaboration = () => {
    blockUI.blockUI("Adding collaboration...");
    const data = {
      item: {id: pageData.collaborationFolderId, type: "folder"},
      accessible_by: {login: props.user.email},
      role: "editor",
    };
    axiosAPI({
      url: "/box/api/collaborations/",
      method: "post",
      baseURL: "",
      data: data,
      params: {
        notify: false,
      },
    })
      .then(() => {
        pageDataQuery.refetch().then(() => {
          blockUI.unblockUI();
          setIframeKey(Math.random());
        });
      })
      .catch(() => {
        pageDataQuery.refetch().then(() => blockUI.unblockUI());
      });
  };

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Box folder info..." />;
  }

  if (pageData.folderId === null) {
    return (
      <Box>
        {pageData.secureProjectFolder ? (
          <ProjectBreadcrumbs project={pageData.project}>
            <Typography color="textPrimary">Secure Files</Typography>
          </ProjectBreadcrumbs>
        ) : (
          <BoxFilesBreadcrumbs pageData={pageData} />
        )}
        <Alert severity="warning">
          No folder has been created for {pageData.objectDisplay} Click the button below to create a folder. This may
          take a few seconds.
        </Alert>
        <Box mb={2} />
        <ColoredButton
          color="success"
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          fullWidth
          onClick={() => {
            blockUI.blockUI("Creating folder...");
            postCurrentPage
              .mutateAsync({action: "create"})
              .then((data) => {
                pageDataQuery
                  .refetch()
                  .then(() => {
                    blockUI.unblockUI();
                    setIframeKey(Math.random());
                  })
                  .catch(() => blockUI.unblockUI());
              })
              .catch(() => blockUI.unblockUI());
          }}
        >
          Create Box Folder For {pageData.objectDisplay}
        </ColoredButton>
        {!pageData.secureProjectFolder && (
          <LegacyUILink href={`/box/files/${params.app}/${params.model}/${params.object_id}/legacy/`} mt={2} />
        )}
      </Box>
    );
  }
  if (pageData.trashed) {
    return (
      <Box>
        {pageData.secureProjectFolder ? (
          <ProjectBreadcrumbs project={pageData.project}>
            <Typography color="textPrimary">Secure Files</Typography>
          </ProjectBreadcrumbs>
        ) : (
          <BoxFilesBreadcrumbs pageData={pageData} />
        )}
        <Alert severity="warning">
          Box folder for {pageData.objectDisplay} was deleted and is in the trash. Click the button below to restore the
          folder. This may take a few seconds.
        </Alert>
        <Box mb={2} />
        <ColoredButton
          color="info"
          variant="outlined"
          size="large"
          startIcon={<RestoreIcon />}
          fullWidth
          onClick={() => {
            blockUI.blockUI("Restoring folder...");
            postCurrentPage
              .mutateAsync({action: "restore"})
              .then((data) => {
                pageDataQuery.refetch().then(() => {
                  blockUI.unblockUI();
                  setIframeKey(Math.random());
                });
              })
              .catch(() => blockUI.unblockUI());
          }}
        >
          Restore Box Folder For {pageData.objectDisplay}
        </ColoredButton>
      </Box>
    );
  }

  if (pageData.notFound) {
    return (
      <Box>
        {pageData.secureProjectFolder ? (
          <ProjectBreadcrumbs project={pageData.project}>
            <Typography color="textPrimary">Secure Files</Typography>
          </ProjectBreadcrumbs>
        ) : (
          <BoxFilesBreadcrumbs pageData={pageData} />
        )}
        <Alert severity="warning">
          Box folder for {pageData.objectDisplay} was deleted and could not be found in the trash. Click the button
          below to create a folder. This may take a few seconds.
        </Alert>
        <Box mb={2} />
        <ColoredButton
          color="success"
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          fullWidth
          onClick={() => {
            blockUI.blockUI("Creating folder...");
            postCurrentPage
              .mutateAsync({action: "create_deleted"})
              .then((data) => {
                pageDataQuery.refetch().then(() => {
                  blockUI.unblockUI();
                  setIframeKey(Math.random());
                });
              })
              .catch(() => blockUI.unblockUI());
          }}
        >
          Create Box Folder For {pageData.objectDisplay}
        </ColoredButton>
      </Box>
    );
  }

  return (
    <>
      <Helmet title={pageData.objectDisplay ? `${pageData.objectDisplay} - Files` : "Box Files"} />
      <BlockUI show={!pageDataQuery.isFetched} message="Fetching Box folder info..." />
      {pageData.secureProjectFolder ? (
        <ProjectBreadcrumbs project={pageData.project}>
          <Typography color="textPrimary">Secure Files</Typography>
        </ProjectBreadcrumbs>
      ) : (
        <BoxFilesBreadcrumbs pageData={pageData} />
      )}

      {pageData.secureProjectFolder && (
        <>
          <Alert severity="info">
            This is a secure project folder for {pageData.project.display}. Only users PM and above and members of the
            precon team can add add themselves as collaborators. Sensitive information should be stored here instead of
            the main project folder.
          </Alert>
          <Box mb={2} />
        </>
      )}
      {pageDataQuery.isFetchedAfterMount && (
        <PaperPanel>
          <PaperPanel.Header>
            <PaperPanel.Header.Title>
              <FontAwesomeIcon icon={faCloud} /> Box Files
            </PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              {showProjectSecureFolderLink && (
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.Button
                    href={`/v2/projects/${pageData.project.id}/box/files/secure/`}
                    startIcon={<LockIcon />}
                  >
                    Secure Project Folder
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>
              )}
              <PaperPanel.Header.Action border={showProjectSecureFolderLink}>
                {pageData.collaborationId ? (
                  <ColoredButton
                    color="error"
                    startIcon={<HighlightOffIcon />}
                    {...paperPanelButtonProps}
                    onClick={() => setRemoveCollaborationConfirmationIsOpen(true)}
                  >
                    Remove Me As Collaborator
                  </ColoredButton>
                ) : (
                  <ColoredButton
                    color="success"
                    startIcon={<GroupAddIcon />}
                    {...paperPanelButtonProps}
                    onClick={() => addCollaboration()}
                  >
                    Join Folder as Collaborator
                  </ColoredButton>
                )}
              </PaperPanel.Header.Action>
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.Button
                  href={
                    pageData.sharedLinkURL ? pageData.sharedLinkURL : `https://box.com/files/0/f/${pageData.folderId}/`
                  }
                  target="_blank"
                  endIcon={<LaunchIcon />}
                  // variant="text"
                >
                  Box Site
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.RefreshButton
                  onClick={() => pageDataQuery.refetch().then(() => setIframeKey(Math.random()))}
                  isFetching={pageDataQuery.isFetching}
                />
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.Header>
          {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
          {!pageData.collaborationId && (
            <PaperPanel.Alert severity="warning">
              You are not a collaborator on this folder. As such you will not be able to make changes or see some
              subfolders. If you are getting a warning "The item you are trying to access has either been deleted or is
              unavailable to you" this is the reason.
              <br />
              <ColoredButton
                startIcon={<GroupAddIcon />}
                size="small"
                variant="outlined"
                color="success"
                onClick={() => addCollaboration()}
              >
                Click here
              </ColoredButton>{" "}
              to join this folder as a collaborator.
            </PaperPanel.Alert>
          )}
          <PaperPanel.Body>
            <iframe
              key={iframeKey}
              src={
                pageData.shareId
                  ? `https://novoconstruction1.app.box.com/embed/s/${pageData.shareId}?view=list&sortColumn=name`
                  : `https://box.com/embed_widget/000000000000/files/0/f/${pageData.folderId}?view=list&sortColumn=name`
              }
              width="100%"
              height="850"
              frameBorder={0}
            />
          </PaperPanel.Body>
          {/* <PaperPanel.Footer p={1}>Footer</PaperPanel.Footer> */}
        </PaperPanel>
      )}
      {/*
      <LegacyUILink
        href={`/box/files/${params.app}/${params.model}/${params.object_id}/legacy/?folder_id=${
          qsValues.folder_id || ""
        }`}
        mt={2}
      /> */}
      {/* <pre>{JSON.stringify(params, null, 2)}</pre>
      <pre>{JSON.stringify(qsValues, null, 2)}</pre> */}

      <ConfirmationDialog
        isOpen={removeCollaborationConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Removing collaboration...");
          setRemoveCollaborationConfirmationIsOpen(false);
          axiosAPI
            .delete(`/box/api/collaborations/${pageData.collaborationId}/`, {baseURL: ""})
            .then(() => {
              pageDataQuery.refetch().then(() => {
                blockUI.unblockUI();
                setIframeKey(Math.random());
              });
            })
            .catch(() => {
              pageDataQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => {
          setRemoveCollaborationConfirmationIsOpen(false);
        }}
      >
        You want to remove yourself as a collaborator from this folder?
        <br />
        This will also remove this folder form Box sync.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(params, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default BoxFiles;
