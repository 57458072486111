import {Box, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {SubcontractGeneralInfoForm} from "./SubcontractForms";

const SubcontractDetailRestricted = (props) => {
  const {project, ...rest} = props;
  const {subcontractId} = useParams();
  const blockUI = useBlockUI();
  const {
    query: subcontractQuery,
    update: updateSubcontract,
    delete: deleteSubcontract,
    rpc: subcontractRPC,
  } = useSentinelDetailAPI(["projects", project.id, "contracts", "sub", parseInt(subcontractId)], {
    initialData: {},
  });
  const subcontract = subcontractQuery.data;

  if (!subcontractQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Subcontract info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/contracts/`}>
          Subcontracts
        </MuiNavLink>
        <Typography color="textPrimary">{subcontract.display}</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <Box justifyItems="space-between">
              <StatusLabel status={subcontract.status_display} /> {subcontract.display}
            </Box>
          </PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <Box pt={3}>
            <SubcontractGeneralInfoForm
              projectId={project.id}
              onSubmit={(values) => {
                blockUI.blockUI("Saving...");
                updateSubcontract
                  .mutateAsync(values)
                  .then(() => subcontractQuery.refetch())
                  .then(() => blockUI.unblockUI());
              }}
              initialValues={subcontract}
              restrictNovoSigners={project.restrict_novo_signers}
              isReadOnly={true}
            />
          </Box>
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default SubcontractDetailRestricted;
