import {faCircleX, faListRadio} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {Autocomplete} from "@material-ui/lab";
import classnames from "classnames";
import {differenceBy, groupBy, keyBy} from "lodash";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useSelectIds} from "../../../../js/hooks";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSelect} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorError} from "../../../theme/colors";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {PretaskPlanDialogForm} from "./PretaskPlanForms";
import PretaskPlanStatusChoices from "../../../components/forms/choices/pretaskPlanStatusChoices.json";

const ProjectPretaskPlans = (props) => {
  const {project, userContact, ...rest} = props;

  const [groupByCompany, setGroupByCompany] = React.useState(true);
  const [showUnlockDialog, setShowUnlockDialog] = React.useState(false);
  const [showPretaskPlanDialog, setShowPretaskPlanDialog] = React.useState(false);
  const [currentPretaskPlan, setCurrentPretaskPlan] = React.useState({} as any);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(300);
  const [dateRange, setDateRange] = React.useState(0);
  const [foremanDialogIsOpen, setForemanDialogIsOpen] = React.useState(false);
  const [auditConfirmationIsOpen, setAuditConfirmationIsOpen] = React.useState(false);
  const [auditedFormValues, setAuditedFormValues] = React.useState({});
  const [reviseResubmitDialogIsOpen, setReviseResubmitDialogIsOpen] = React.useState(false);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    [
      "Foreman",
      "Start Date",
      "End Date",
      "Status",
      // "Date Range"
    ],
    {
      "Start Date": {
        value: moment().format("YYYY-MM-DD"),
        label: moment().format("YYYY-MM-DD"),
      },
      "End Date": {
        value: moment().format("YYYY-MM-DD"),
        label: moment().format("YYYY-MM-DD"),
      },
    }
  );

  const filterParams = {
    twilio_contact: filterOptions["Foreman"].value,
    date_min: filterOptions["Start Date"].value,
    date_max: filterOptions["End Date"].value,
    status: filterOptions["Status"].value,
  };

  const canUnlock =
    ["submitted", "audited"].includes(currentPretaskPlan.status) &&
    !currentPretaskPlan.is_unlocked &&
    currentPretaskPlan.is_closed;

  const blockUI = useBlockUI();
  const {
    query: pretaskPlanQuery,
    update: updatePretaskPlan,
    rpc: pretaskPlanRPC,
  } = useSentinelListAPI(
    `projects/${project.id}/superintendents/pretask-plans/?page=${page}&page_size=${pageSize}&${qs.stringify(
      filterParams
    )}&ordering=-created`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const pretaskPlans = pretaskPlanQuery.data.results;

  const pretaskPlanCompanies = groupBy(pretaskPlans, "twilio_contact.fuzzy_company.name");
  const companyLookup = keyBy(pretaskPlans, "twilio_contact.fuzzy_company.name");

  const {query: foremenQuery} = useSentinelListAPI(`projects/${project.id}/superintendents/pretask-plans/foremen/`, {
    initialData: [],
    keepPreviousData: true,
  });
  const foremen = foremenQuery.data;

  const allPretaskPlanIds = pretaskPlans.map((plan) => plan.id);

  const {
    selectedIds,
    addSelectedId,
    addSelectedIds,
    removeSelectedId,
    addAllSelectedIds,
    removeAllSelectedIds,
    removeSelectedIds,
    allIdsSelected,
  } = useSelectIds(allPretaskPlanIds);

  const pdfQueryString = React.useMemo(() => getQueryStringFromIds(selectedIds), [selectedIds]);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      twilioContacts: [],
    },
  });

  const pageData = pageDataQuery.data;

  const {
    query: projectForemenQuery,
    create: createForemen,
    delete: deleteForemen,
  } = useSentinelListAPI(`projects/${project.id}/foremen/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });
  const projectForemen = projectForemenQuery.data.results;

  const reviseResubmitContactEmail =
    currentPretaskPlan?.survey?.contact?.email ?? currentPretaskPlan?.twilio_contact?.email;

  React.useEffect(() => {
    removeAllSelectedIds();
  }, [page, pretaskPlans.length]);

  if (
    !pageDataQuery.isFetchedAfterMount ||
    !foremenQuery.isFetchedAfterMount ||
    !pretaskPlanQuery.isFetchedAfterMount
  ) {
    return <BlockUI show={true} message="Fetching Pretask Plan info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Daily Check-ins`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Pretask Plans</Typography>
      </ProjectBreadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faListRadio} /> Pretask Plans
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={pretaskPlanQuery.isFetching}>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Switch onChange={() => setGroupByCompany(!groupByCompany)} checked={groupByCompany} />
              Group By Company
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <Tooltip title={selectedIds.size < 1 ? "Please Select at Least One Pretask Plan" : ""}>
                <span>
                  <PaperPanel.Header.PDFButton
                    href={`/reports2/projects/${project.id}/pretask-plans/?${pdfQueryString}`}
                    target="_blank"
                    disabled={selectedIds.size < 1}
                  />
                </span>
              </Tooltip>
              <Box ml={1} />
              <PaperPanel.Header.ColoredButton onClick={() => setForemanDialogIsOpen(true)} color="success">
                <Chip size="small" label={projectForemen.length} /> Add/Remove Foremen
              </PaperPanel.Header.ColoredButton>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pretaskPlanQuery.refetch()}
                isFetching={pretaskPlanQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={2} xs={6}>
              <FilterDate
                name="Start Date"
                value={filterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("Start Date", date, date);
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={2} xs={6}>
              <FilterDate
                name="End Date"
                value={filterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("End Date", date, date);
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                name="Date Range"
                label="Date Range"
                value={dateRange}
                options={[
                  {label: "Today", value: 0},
                  {label: "Last Week", value: 7},
                  {
                    label: "Last 2 Weeks",
                    value: 14,
                  },
                  {
                    label: "Last Month",
                    value: 30,
                  },
                ]}
                onChange={(value, label) => {
                  const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                  const endDate = moment().format("YYYY-MM-DD");
                  setPage(1);
                  setFilterOption("Start Date", startDate, startDate);
                  setFilterOption("End Date", endDate, endDate);
                  // setFilterOption("Date Range", value, label);
                  setDateRange(value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Foreman"
                name="foreman"
                options={foremen}
                value={filterOptions.Foreman.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Foreman", value, label);
                }}
                renderOption={(option) => (
                  <Box mt={-1}>
                    {option.label}
                    <Box>
                      <Typography2 type="metadata">
                        <span>{option?.company}</span>
                      </Typography2>
                    </Box>{" "}
                  </Box>
                )}
                renderValue={(value) => {
                  return <Box>{foremen.find((person) => person.value === value).label}</Box>;
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={PretaskPlanStatusChoices}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  setPage(1);
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  setPage(1);
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={pretaskPlanQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {/* <PaperPanel.Body p={1} mt={1}>
          <ProjectForemen
            options={pageData.twilioContacts}
            label={"Project Foremen"}
            value={projectForemen}
            loading={pageDataQuery.isFetching || foremenQuery.isFetching}
            onChange={(event, value_, reason) => {
              const originalIds = new Set(projectForemen.map((value) => value.uuid));
              const currentIds = new Set(value_.map((value) => value.uuid));
              // console.log(reason);
              if (reason === "remove-option") {
                const removedIds = Array.from(originalIds).filter((item) => !currentIds.has(item));
                removedIds.map((removedId) => {
                  deleteForemen.mutateAsync(removedId);
                });
              }
              if (reason === "select-option") {
                const addedIds = Array.from(currentIds).filter((item) => !originalIds.has(item));
                addedIds.map((addedId) => {
                  createForemen.mutateAsync({uuid: addedId});
                });
              }
            }}
          />
        </PaperPanel.Body> */}
        <PaperPanel.Body>
          {groupByCompany
            ? Object.keys(pretaskPlanCompanies)
                .sort()
                .map((company) => {
                  const planIdsInThisCompany = pretaskPlanCompanies[company].map((plan) => plan.id);
                  const allIdsSelected = planIdsInThisCompany.every((planId) => selectedIds.has(planId));
                  const anyIdsSelected = planIdsInThisCompany.some((planId) => selectedIds.has(planId));
                  return (
                    <React.Fragment key={company}>
                      <PaperItem.Header>
                        <PaperItem.Left>
                          <PaperItem.SelectedCheckbox
                            indeterminate={!allIdsSelected && anyIdsSelected}
                            checked={allIdsSelected}
                            onChange={(event, value) => {
                              if (value) {
                                addSelectedIds(planIdsInThisCompany);
                              } else {
                                removeSelectedIds(planIdsInThisCompany);
                              }
                            }}
                          />
                        </PaperItem.Left>
                        <PaperItem.Body>
                          {companyLookup[company].twilio_contact.fuzzy_company ? (
                            <MuiNavLink href={companyLookup[company].twilio_contact?.fuzzy_company?.url}>
                              <strong>{company}</strong>
                            </MuiNavLink>
                          ) : (
                            <strong>{companyLookup[company]?.twilio_contact?.company ?? "No Company Matched"}</strong>
                          )}
                        </PaperItem.Body>
                      </PaperItem.Header>
                      {pretaskPlanCompanies[company].map((plan) => {
                        plan.pdfURL = `/reports2/projects/${project.id}/pretask-plans/?pk=${plan.id}`;
                        return (
                          <PreTaskPaperItem
                            plan={plan}
                            key={plan.id}
                            groupByCompany={groupByCompany}
                            onEdit={() => {
                              setCurrentPretaskPlan(plan);
                              setShowPretaskPlanDialog(true);
                            }}
                            isSelected={selectedIds.has(plan.id)}
                            onChangePlanSelected={(e, value) => {
                              if (value) {
                                addSelectedId(plan.id, e.nativeEvent.shiftKey);
                              } else {
                                removeSelectedId(plan.id);
                              }
                            }}
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                })
            : pretaskPlans.map((plan) => {
                plan.pdfURL = `/reports2/projects/${project.id}/pretask-plans/?pk=${plan.id}`;
                return (
                  <PreTaskPaperItem
                    plan={plan}
                    key={plan.id}
                    groupByCompany={groupByCompany}
                    onEdit={() => {
                      setCurrentPretaskPlan(plan);
                      setShowPretaskPlanDialog(true);
                    }}
                    isSelected={selectedIds.has(plan.id)}
                    onChangePlanSelected={(e, value) => {
                      if (value) {
                        addSelectedId(plan.id, e.nativeEvent.shiftKey);
                      } else {
                        removeSelectedId(plan.id);
                      }
                    }}
                  />
                );
              })}
        </PaperPanel.Body>
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          <PaginationWithPageSize
            count={pretaskPlanQuery.data.total_pages}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSizes={[100, 300, 500]}
          />
        </PaperPanel.Footer>
      </PaperPanel>

      {/* <pre>{JSON.stringify(pretaskPlans, null, 2)}</pre> */}

      <PretaskPlanDialogForm
        projectId={project.id}
        userContact={userContact}
        initialValues={{
          ...currentPretaskPlan,
          audited_by: currentPretaskPlan.audited_by ?? userContact,
          audited_date: currentPretaskPlan.audited_date ?? moment().format("YYYY-MM-DD"),
        }}
        isOpen={showPretaskPlanDialog}
        handleClose={() => setShowPretaskPlanDialog(false)}
        onSubmit={(values) => {
          setAuditedFormValues(values);
          setAuditConfirmationIsOpen(true);
        }}
        onReviseResubmit={(values) => {
          setAuditedFormValues(values);
          setReviseResubmitDialogIsOpen(true);
        }}
        canUnlock={canUnlock}
        onUnlockPlan={() => {
          setShowPretaskPlanDialog(false);
          setShowUnlockDialog(true);
        }}
        isReadOnly={currentPretaskPlan.status !== "submitted"}
      />

      <ConfirmationDialog
        isOpen={showUnlockDialog}
        onDeny={() => setShowUnlockDialog(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/unlock-pretask-plan`})
            .then(() => {
              setShowUnlockDialog(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        title="Unlock this Pretask Plan?"
      >
        Do you want to unlock this Pretask Plan for editing? This will allow the foreman to make changes.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={auditConfirmationIsOpen}
        onDeny={() => setAuditConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/mark-audited`, data: auditedFormValues})
            .then(() => {
              // updatePretaskPlan.mutateAsync(auditedFormValues).then(() => {
              setShowPretaskPlanDialog(false);
              setAuditConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        title="Audit this Pretask Plan?"
      >
        I have audited this Pretask Plan and certify that is has been completed correctly.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={reviseResubmitDialogIsOpen}
        onDeny={() => setReviseResubmitDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/mark-revise-resubmit`, data: auditedFormValues})
            .then(() => {
              setReviseResubmitDialogIsOpen(false);
              setShowPretaskPlanDialog(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
      >
        You want to mark this Pretask Plan <strong>Revise Resubmit</strong>.{" "}
        {reviseResubmitContactEmail
          ? `${currentPretaskPlan?.twilio_contact?.name} will receive an email at ${reviseResubmitContactEmail} with a link to make corrections.`
          : `No email address found for ${currentPretaskPlan?.twilio_contact?.name}, please inform them manually.`}
      </ConfirmationDialog>

      <ForemanSelectDialog
        isOpen={foremanDialogIsOpen}
        onClose={() => setForemanDialogIsOpen(false)}
        foremen={projectForemen}
        contacts={pageData.twilioContacts}
        onAddForeman={(foreman) => {
          createForemen.mutateAsync({uuid: foreman.uuid});
        }}
        onDeleteForeman={(foreman) => {
          deleteForemen.mutateAsync(foreman.uuid);
        }}
      />
    </>
  );
};

export default ProjectPretaskPlans;

const PreTaskPaperItem = (props: {
  groupByCompany: boolean;
  plan: any;
  onEdit: () => void;
  isSelected: boolean;
  onChangePlanSelected: (e, value) => void;
}) => {
  const {plan, groupByCompany, onEdit, isSelected, onChangePlanSelected, ...rest} = props;
  const {twilio_contact} = plan;
  const {fuzzy_company} = twilio_contact;

  return (
    <PaperItem>
      <PaperItem.Left minWidth="100px">
        <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangePlanSelected} />
        <StatusLabel status={plan.status_display} hint="pretaskPlan" />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={3} xs={12}>
            {/* <MuiNavLink href={`/v2/projects/${plan.project.id}/pretask-plans/${plan.id}/`}> */}
            <Link style={{cursor: "pointer"}} onClick={onEdit}>
              {twilio_contact.name}
            </Link>
            {/* </MuiNavLink> */}
          </Grid>

          {!groupByCompany &&
            (fuzzy_company ? (
              <Grid item sm={3} xs={12}>
                <MuiNavLink href={fuzzy_company?.url}>{fuzzy_company?.name}</MuiNavLink>
              </Grid>
            ) : (
              <Grid item sm={3} xs={12}>
                {twilio_contact.company ?? "No Company Matched"}
              </Grid>
            ))}
          <Grid item sm={3} xs={12}>
            {moment(plan.date).format("YYYY-MM-DD - dddd")}
          </Grid>
          <Grid item sm={3} xs={12}>
            <Typography2 type="metadata">
              {plan.audited_date && `Audited ${plan.audited_date} by ${plan.audited_by.full_name}`}
            </Typography2>
          </Grid>
        </Grid>
      </PaperItem.Body>

      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={PictureAsPdfIcon}
          title="Get PDF"
          component={Link}
          href={plan.pdfURL}
          target="_blank"
        />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

const ProjectForemen = (props) => {
  const {
    options = [],
    labelProp = "name",
    sort = "name",
    // group = "company",
    label = "",
    placeholder = "",
    valueProp = "uuid",
    onChange,
    ...rest
  } = props;
  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      groupBy={(option) => option?.fuzzy_company?.name || option.company}
      // options={sortBy(sortBy(options, sort), group)}
      options={options}
      limitTags={8}
      disableClearable
      size={"small"}
      renderInput={(params) => <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />}
      getOptionLabel={(option) => option[labelProp]}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              size="small"
              variant="outlined"
              // color="secondary"
              label={
                <>
                  {option[labelProp]} - {option?.fuzzy_company?.name}
                </>
              }
              {...getTagProps({index})}
            />
          );
        })
      }
      getOptionSelected={(option, value) => {
        return option[valueProp] === value[valueProp];
      }}
      onChange={onChange}
      {...rest}
    />
  );
};

const ForemanSelectDialog = (props) => {
  const {isOpen, onClose, foremen, contacts, onAddForeman, onDeleteForeman, ...rest} = props;
  const [fieldValue, setFieldValue] = React.useState({});

  const novoClasses = makeNovoClasses();

  const unSelectedContacts = differenceBy(contacts, foremen, "uuid");

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle>Add/Remove Foremen </DialogTitle>
      <DialogContent>
        <Autocomplete
          value={fieldValue}
          options={unSelectedContacts}
          size={"small"}
          filterSelectedOptions
          disableClearable
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={"Add Foreman"} placeholder={"Start Typing..."} />
          )}
          getOptionLabel={(option) => {
            return `${option?.name ? `${option.name} ` : ""}${option?.fuzzy_company?.name || option.company || ""}`;
            // return `${option?.name || ""}`;
          }}
          renderOption={(option: any) => {
            return (
              <>
                <div>
                  <div>{option?.name}</div>
                  <div>
                    <Typography2 type="metadata">
                      {`${option?.fuzzy_company?.name || option.company || ""}`}
                    </Typography2>
                  </div>
                </div>
              </>
            );
          }}
          onChange={(event, value, reason) => {
            if (reason === "select-option") {
              onAddForeman(value);
              setFieldValue({});
            }
          }}
          getOptionSelected={(option, value) => option["uuid"] === value["uuid"]}
        />
        <Box mb={2} />
        {/* <Box mb={2} mx={2}> */}
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
          // containerComponent={Paper}
          // rightAlignColumns={["timestamp"]}
          getHeaderProps={(column) => {
            if (["remove"].includes(column.id)) {
              return {
                style: {
                  width: "20px",
                },
              };
            }
          }}
          columns={[
            {
              id: "remove",
              Cell: ({value, row}) => (
                <Tooltip title="Remove as Foreman">
                  <FontAwesomeIcon icon={faCircleX} color={colorError} onClick={() => onDeleteForeman(row.original)} />
                </Tooltip>
              ),
            },
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Company",
              id: "company",
              accessor: (value) => {
                return value?.fuzzy_company?.name || value.company || "";
              },
              // Cell: ({value, row}) => <>{row.original?.fuzzy_company?.name || row.original.company}</>,
            },
          ]}
          initialState={{
            sortBy: [
              {
                id: "company",
                asc: true,
              },
              {id: "name", asc: true},
            ],
          }}
          data={foremen}
          {...rest}
        />
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
