import {faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {useQueryState} from "use-location-state";
import FSMLog from "../../components/FSMLog";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {BonusAccountDistributionRequestForm} from "./BonusForms";

const BonusAccountDistributionRequestDetail = (props) => {
  const {bonusAccountId, distributionRequestId} = useParams();
  const [selectedTab, setSelectedTab] = useQueryState("tab", "detail");

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const {query: bonusAccountQuery} = useSentinelDetailAPI(`/bonuses/accounts/${bonusAccountId}/`, {
    initialData: {},
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  const account = bonusAccountQuery.data;

  const {
    query: bonusAccountDistributionRequestQuery,
    create: createBonusAccountDistributionRequest,
    update: updateBonusAccountDistributionRequest,
    delete: deleteBonusAccountDistributionRequest,
    rpc: bonusAccountDistributionRequestRPC,
  } = useSentinelDetailAPI(`/bonuses/accounts/${bonusAccountId}/distribution-requests/${distributionRequestId}/`, {
    initialData: {},
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  const distributionRequest = bonusAccountDistributionRequestQuery.data;

  if (!bonusAccountDistributionRequestQuery.isFetchedAfterMount || !bonusAccountQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching distribution request info..." />;
  }

  return (
    <>
      <Helmet title="Bonus Distribution Requests" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to="/v2/bonuses/accounts/">
          Bonus Accounts
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/bonuses/accounts/${bonusAccountId}/`}>
          {account.description}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/bonuses/accounts/${bonusAccountId}/#tab=distributionRequests`}>
          Bonus Distribution Requests
        </MuiNavLink>
        <Typography color="textPrimary">{distributionRequest.description}</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Distribution Request
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          {distributionRequest.transaction && (
            <MuiNavLink to={`/v2/bonuses/accounts/${bonusAccountId}/`} underline="none">
              <PageHeader.Right.CurrencySpark
                title="Transaction Amount"
                number={distributionRequest.transaction.amount}
                precision={2}
              />
            </MuiNavLink>
          )}
          <PageHeader.Right.CurrencySpark
            title="Account Balance"
            number={account.amount}
            precision={2}
            border={distributionRequest.transaction}
          />
        </PageHeader.Right>
      </PageHeader>

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Detail" value="detail" className={novoClasses.smallTab} />
              <Tab label="Logs" value="logs" className={novoClasses.smallTab} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="detail">
            <PaperPanel.TabHeader isLoading={bonusAccountDistributionRequestQuery.isFetching}>
              <PaperPanel.Header.Title>
                <StatusLabel
                  status={distributionRequest.status_display}
                  width={100}
                  hint="bonusAccountDistributionRequest"
                />{" "}
                Bonus Distribution Request
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setShowCreateDialog(true);
                }}
              >
                Create
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action> */}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={bonusAccountDistributionRequestQuery.isFetching}
                    onClick={() => bonusAccountDistributionRequestQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Body p={1}>
                <BonusAccountDistributionRequestForm
                  initialValues={distributionRequest}
                  isReadOnly={distributionRequest.is_closed}
                  onSubmit={(values, form) => {
                    const submitMode = values.submitMode;
                    delete values.submitMode;

                    if (["submit", "approve", "reject"].includes(submitMode)) {
                      blockUI.blockUI("Updating...");
                      updateBonusAccountDistributionRequest.mutateAsync(values).then(() => {
                        bonusAccountDistributionRequestRPC
                          .mutateAsync({action: submitMode, id: distributionRequest.id})
                          .then(() => {
                            Promise.all([
                              bonusAccountQuery.refetch(),
                              bonusAccountDistributionRequestQuery.refetch(),
                            ]).then(() => {
                              blockUI.unblockUI();
                            });
                          });
                      });
                      return;
                    }
                    updateBonusAccountDistributionRequest.mutateAsync(values).then(() => {
                      bonusAccountQuery.refetch();
                      bonusAccountDistributionRequestQuery.refetch();
                    });
                  }}
                />
                {/* <pre>{JSON.stringify(bonusAccountDistributionRequestQuery, null, 2)}</pre> */}
              </PaperPanel.Body>
            </Box>
          </TabPanel>
          <TabPanel value="logs">
            <Box mx={-3} mb={-3} mt={-4}>
              <FSMLog
                url={`/bonuses/accounts/${bonusAccountId}/distribution-requests/${distributionRequestId}/state-log/`}
              />
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      {/* <pre>{JSON.stringify(distributionRequest, null, 2)}</pre> */}
    </>
  );
};

export default BonusAccountDistributionRequestDetail;
