import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Tooltip, Typography} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import InnerFooter from "../../../components/InnerFooter";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {
  BillingsFields,
  DesignFields,
  FollowupFields,
  GeneralConditionsStaffingFields,
  InsuranceFields,
  JobWalkFields,
  OperationsFields,
  OwnerTermsFields,
  ProjectFields,
  SafetyFields,
  SiteRequirementsFields,
  SubcontractorsFields,
  TeamFields,
} from "./ProjectReviewFormFields";

const onChangeSectionErrors = (state: Set<unknown>, action: {type: string; data: string}): Set<unknown> => {
  const result = new Set(state);

  switch (action.type) {
    case "addSection": {
      result.add(action.data);
      return result;
    }
    case "removeSection": {
      result.delete(action.data);
      return result;
    }
  }
  return state;
};

const ProjectReviewDetail = (props) => {
  const {project, ...rest} = props;
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);

  const [sectionErrors, setSectionErrors] = React.useReducer(onChangeSectionErrors, new Set());
  const blockUI = useBlockUI();
  const {reviewId} = useParams();
  const navigate = useNavigate();
  const {
    query: projectReviewQuery,
    update: updateProjectReview,
    delete: deleteProjectReview,
    rpc: projectReviewRPC,
  } = useSentinelDetailAPI(`/projects/${project.id}/reviews/${reviewId}/`, {
    initialData: {review: {}},
    keepPreviousData: true,
  });

  const projectReview = projectReviewQuery.data;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const {projectReview: headerData} = pageData;

  const {review: formData} = projectReview;

  if (!projectReviewQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Review Info..." />;
  }

  const submitSection = (values, section) => {
    const newData = {review: {...formData, [section]: values}};

    const DATE_FIELDS = [
      "permit_submission_date",
      "permit_received_date",
      "start_date",
      "substantial_completion_date",
      "last_time_thomas_walked_the_site",
      "last_time_gs_walked_the_site",
    ];
    DATE_FIELDS.forEach((dateField) => {
      const destination = newData.review[section];
      destination[dateField] = destination[dateField] ? moment(destination[dateField]).format("YYYY-MM-DD") : "";
      // if (destination[dateField]) {
      //   destination[dateField] = moment(destination[dateField]).format("YYYY-MM-DD");
      // }
    });
    updateProjectReview.mutateAsync(newData);
  };

  return (
    <>
      <Helmet title={`${project.display} - Reviews`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/reviews/`}>
          <Typography color="inherit">Project Reviews</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">{headerData?.reviewer?.full_name}</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left />
        <PageHeader.Right>
          {/* <PageHeader.Right.RightSpark
            title="Status"
            value={<StatusLabel status={headerData?.status_display} hint="projectReview" />}
            textAlign="center"
          /> */}
          <PageHeader.Right.RightSpark title="Created" value={moment(headerData?.created).format("YYYY-MM-DD")} />
          <PageHeader.Right.RightSpark title="Reviewer" value={headerData?.reviewer?.full_name} />
        </PageHeader.Right>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <StatusLabel status={headerData?.status_display} hint="projectReview" /> Project Reviews
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip title={sectionErrors.size > 0 ? "Please Complete All Sections" : ""}>
                <span>
                  <PaperPanel.Header.PDFButton
                    href={`/reports2/projects/${project.id}/reviews/${projectReview.id}/`}
                    target="_blank"
                    disabled={sectionErrors.size > 0}
                  />
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Menu>
                {(popupState) => [
                  <PaperPanel.Header.Menu.MenuItem
                    key="delete"
                    onClick={() => {
                      popupState.close();
                      setDeleteDialogIsOpen(true);
                    }}
                  >
                    Delete
                  </PaperPanel.Header.Menu.MenuItem>,
                ]}
              </PaperPanel.Header.Menu>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body mt={-1.5}>
          <ProjectFields
            data={formData.project}
            onSubmit={(values) => {
              submitSection(values, "project");
            }}
            projectId={project.id}
            setSectionErrors={setSectionErrors}
          />

          <TeamFields
            projectId={project.id}
            data={formData.team}
            onSubmit={(values) => {
              submitSection(values, "team");
            }}
            setSectionErrors={setSectionErrors}
          />
          <InsuranceFields
            projectId={project.id}
            data={formData.insurance}
            onSubmit={(values) => {
              submitSection(values, "insurance");
            }}
            setSectionErrors={setSectionErrors}
          />
          <BillingsFields
            projectId={project.id}
            data={formData.billings}
            onSubmit={(values) => {
              submitSection(values, "billings");
            }}
            setSectionErrors={setSectionErrors}
          />
          <GeneralConditionsStaffingFields
            projectId={project.id}
            data={formData.general_conditions}
            onSubmit={(values) => {
              submitSection(values, "general_conditions");
            }}
            setSectionErrors={setSectionErrors}
          />
          <SiteRequirementsFields
            projectId={project.id}
            data={formData.site_requirements}
            onSubmit={(values) => {
              submitSection(values, "site_requirements");
            }}
            setSectionErrors={setSectionErrors}
          />
          <OperationsFields
            projectId={project.id}
            data={formData.operations}
            onSubmit={(values) => {
              submitSection(values, "operations");
            }}
            setSectionErrors={setSectionErrors}
          />
          <OwnerTermsFields
            projectId={project.id}
            data={formData.owner_terms}
            onSubmit={(values) => {
              submitSection(values, "owner_terms");
            }}
            setSectionErrors={setSectionErrors}
          />
          <DesignFields
            projectId={project.id}
            data={formData.design}
            onSubmit={(values) => {
              submitSection(values, "design");
            }}
            setSectionErrors={setSectionErrors}
          />
          <SafetyFields
            projectId={project.id}
            data={formData.safety}
            onSubmit={(values) => {
              submitSection(values, "safety");
            }}
            setSectionErrors={setSectionErrors}
          />
          <SubcontractorsFields
            projectId={project.id}
            data={formData.subcontractors}
            onSubmit={(values) => {
              submitSection(values, "subcontractors");
            }}
            onRefresh={() => projectReviewQuery.refetch()}
            isFetching={projectReviewQuery.isFetching}
            setSectionErrors={setSectionErrors}
          />
          <JobWalkFields
            projectId={project.id}
            data={formData.job_walk}
            onSubmit={(values) => {
              submitSection(values, "job_walk");
            }}
            setSectionErrors={setSectionErrors}
          />
          <FollowupFields
            projectId={project.id}
            data={formData.follow_up}
            onSubmit={(values) => {
              submitSection(values, "follow_up");
            }}
            setSectionErrors={setSectionErrors}
          />
        </PaperPanel.Body>

        <InnerFooter>
          <Box display="flex">
            {projectReview.status !== "complete" && (
              <Tooltip title={sectionErrors.size > 0 ? "Please Resolve All Errors Before Completing the Review" : ""}>
                <div>
                  <Button
                    disabled={sectionErrors.size > 0}
                    variant="contained"
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={faCheck} />}
                    onClick={() => {
                      blockUI.blockUI("Saving...");
                      projectReviewRPC.mutateAsync({action: "complete"}).then((result) => {
                        Promise.all([pageDataQuery.refetch(), projectReviewQuery.refetch()]).then(() => {
                          blockUI.unblockUI();
                        });
                      });
                    }}
                  >
                    Mark Complete
                  </Button>
                </div>
              </Tooltip>
            )}

            <Box ml={1} />
          </Box>
        </InnerFooter>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/reviews/${reviewId}/`} mt={2} />
      <ConfirmationDialog
        isOpen={deleteDialogIsOpen}
        onDeny={() => setDeleteDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteDialogIsOpen(false);

          deleteProjectReview
            .mutateAsync()
            .then((response) => {
              // window.location.href = `/projects/${project.id}/reviews/`;
              navigate(`/v2/projects/${project.id}/reviews/`);
              blockUI.unblockUI();
            })
            .catch((error) => {
              blockUI.unblockUI();
            });
        }}
        title="Delete This Review?"
      >
        Are you sure you want to delete this review? This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export default ProjectReviewDetail;
