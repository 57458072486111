import {faUser} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumbs, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {axiosAPI} from "../../api";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import {ContactCreateForm} from "./ContactForms";

const ContactCreate = (props) => {
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  return (
    <>
      <Helmet title={`Create New Contact`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Create Contact</Typography>
      </Breadcrumbs>
      <PaperPanel mt={2}>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUser} /> Create New Contact
          </PaperPanel.Header.Title>
        </PaperPanel.Header>

        <PaperPanel.Body p={1}>
          <ContactCreateForm
            onSubmit={(values) => {
              blockUI.blockUI("Creating contact...");
              axiosAPI.post("/contacts/", values).then((res) => {
                navigate(`/v2/contacts/${res.data.id}/`);
                blockUI.unblockUI();
              });
            }}
            // initialValues={{}}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default ContactCreate;
