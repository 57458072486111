import {faImageSlash, faSquareMinus, faSquarePlus} from "@fortawesome/pro-regular-svg-icons";
import {faCompassDrafting, faExternalLink, faUpRightFromSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {Skeleton} from "@material-ui/lab";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router";
import {useQueryState} from "use-location-state";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import subcontractTypes from "../../../components/forms/choices/subcontractTypes.json";
import {
  ApiAutoselectMui,
  CurrencyFieldMui,
  DatePickerMui,
  NovoRestrictedSignersAutoselectMui,
  ProjectBudgetAutoselectMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  SimpleSelect,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import {ProjectAuditLink} from "../../../components/Links";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../../components/ReactTableMui";
import useFilterOptions from "../../../hooks/useFilterOptions";
import usePermissions from "../../../hooks/usePermissions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import getCompanySignator from "../../../utils/getCompanySignator";
import {beforeDialogClose} from "../../../components/forms/Form";

export const SubContractCommonFields = (props: {
  projectId: number;
  values: {
    id?: number;
    commitment_type?: "subcontract" | "consulting_agreement";
    commitment_type_display?: string;
    budget?: {
      id: number;
      available_budget: number;
      display: string;
      allocation: string;
    };
    to_company?: {
      id: number;
    };
    to_company_signator?: {
      id: number;
    };
    timberline_subcontract_id?: string;
  };
  isReadOnly?: boolean;
  isNew?: boolean;
  onChangeToCompany?: (selection) => void;
  disableSignator?: boolean;
  restrictNovoSigners?: boolean;
}) => {
  const {projectId, isReadOnly, values, isNew, onChangeToCompany, disableSignator, restrictNovoSigners, ...rest} =
    props;
  const permissions = usePermissions();

  const validateSubcontractBudget = async (value, values, field) => {
    if (!["budget"].includes(field.name)) return;
    if (!values.budget) return "Required";
    if (!field.dirty) return;
    const response = await axiosAPI.get(
      `/projects/${projectId}/contracts/sub/validate-subcontract/?id=${values.id || ""}&budget_id=${
        values.budget?.id || ""
      }&to_company_id=${values.to_company?.id || ""}`
    );
    if (response.data.error) {
      return response.data.error;
    }
  };
  const validateSubcontractToCompany = async (value, values, field) => {
    if (!["to_company"].includes(field.name)) return;
    if (!values.to_company) return "Required";
    if (!field.dirty) return;
    const response = await axiosAPI.get(
      `/projects/${projectId}/contracts/sub/validate-subcontract/?id=${values.id || ""}&budget_id=${
        values.budget?.id || ""
      }&to_company_id=${values.to_company?.id || ""}`
    );
    if (response.data.error) {
      return response.data.error;
    }
  };

  const isConsultingAgreement = values.commitment_type === "consulting_agreement";
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextFieldMui type="number" name="number" label="Number" required disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldMui name="description" label="Description" required autoFocus disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectBudgetAutoselectMui
            projectId={projectId}
            name="budget"
            label="Budget"
            required
            disabled={isReadOnly}
            disableClearable={false}
            fieldProps={{
              validate: validateSubcontractBudget,
              validateFields: ["budget", "to_company"],
            }}
            helperText={
              <>
                {values?.budget && isNew && (
                  <span>
                    <Currency number={values?.budget?.available_budget} precision={2} /> left in{" "}
                    {values?.budget?.display}
                  </span>
                )}
                {values?.budget?.allocation && values?.budget?.allocation !== "sub" && (
                  <Box style={{color: "red"}}>This budget code is not allocated to Subcontract</Box>
                )}
              </>
            }
          />

          {/* {values?.budget && isNew && (
            <Box ml={2}>
              <FormHelperText>
                <Currency number={values?.budget?.available_budget} precision={2} /> left in {values?.budget?.display}
              </FormHelperText>
            </Box>
          )} */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SimpleSelect name="type" label="Type" options={subcontractTypes} required disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui label="Issued Date" name="issued_date" required disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui label="Required Date" name="required_date" disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui
            label="Executed Date"
            name="executed_date"
            helperText={`Date the ${isConsultingAgreement ? "agreement" : "contract"} was signed`}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickerMui
            label="Substantial Completion Date"
            name="substantial_completion_date"
            disabled={isReadOnly}
            helperText={`Date the work is to be completed`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui
                projectId={projectId}
                name="from_company"
                label="From Company"
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12}>
              {restrictNovoSigners ? (
                <NovoRestrictedSignersAutoselectMui
                  projectId={projectId}
                  name="from_signed_by"
                  label="From Signed By"
                  disabled={isReadOnly}
                />
              ) : (
                <ProjectContactAutoselectMui
                  projectId={projectId}
                  name="from_signed_by"
                  label="From Signed By"
                  disabled={isReadOnly}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui
                projectId={projectId}
                name="to_company"
                label="To Company"
                required
                disabled={isReadOnly}
                fieldProps={{
                  validate: async (value, values, field) => {
                    if (!field.dirty) return;
                    if (value?.is_subcontract_blocklisted && !permissions.is_executive) {
                      return "This company is blocklisted. This will need to be overridden by an executive.";
                    }
                    const error = await validateSubcontractToCompany(value, values, field);
                    return error;
                  },
                  validateFields: ["budget", "to_company"],
                }}
                onChange={(e, selection, reason) => {
                  if (onChangeToCompany && reason === "select-option") {
                    onChangeToCompany(selection);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectContactAutoselectMui
                projectId={projectId}
                name="to_signed_by"
                label="To Signed By"
                disabled={isReadOnly || disableSignator}
                helperText={disableSignator ? "Set by company signator" : ""}
              />
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </Grid>
        {isNew && (
          <Grid item xs={12} sm={12}>
            <CurrencyFieldMui name="value" label="Value" disabled={isReadOnly} />
          </Grid>
        )}
        {permissions.link_subcontracts && !isNew && (
          <Grid item xs={12}>
            <TextFieldMui
              name="timberline_subcontract_id_override"
              label="Timberline Subcontract Override"
              helperText={`For accounting use only: Override Timberline subcontract ${
                values?.timberline_subcontract_id ? ` current id: ${values?.timberline_subcontract_id}` : ""
              }`}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TinyMCEMui
            name="scope_of_work"
            label={isConsultingAgreement ? "Scope of Services" : "Scope of Work"}
            disabled={isReadOnly}
          />
        </Grid>
        {isConsultingAgreement && (
          <Grid item xs={12} sm={6}>
            <TinyMCEMui name="milestones" label="Milestones" disabled={isReadOnly} />
          </Grid>
        )}
        <Grid item xs={12} sm={isConsultingAgreement ? 12 : 6}>
          <TinyMCEMui name="notes" label="Notes" />
          <FormHelperText>For internal use. Does not show up on the {values.commitment_type_display}.</FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};

export const SubcontractGeneralInfoForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  restrictNovoSigners?: boolean;
  showDrawings?: boolean;
  // drawings?: Drawing[];
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isReadOnly, restrictNovoSigners = false, showDrawings = true, ...rest} = props;
  const {pathname} = useLocation();

  const [drawingSelectIsOpen, setDrawingSelectIsOpen] = React.useState(false);
  const [disableSignator, setDisableSignator] = React.useState(false);
  const novoClasses = makeNovoClasses();

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, ...rest} = props;
          const isConsultingAgreement = values.commitment_type === "consulting_agreement";
          const nextPath = isConsultingAgreement ? "consulting-agreements" : "contracts";
          const onChangeSelectedDrawings = (selected) => {
            form.batch(() => form.change("drawings", selected));
            form.submit();
            setDrawingSelectIsOpen(false);
          };
          const drawings = values.drawings;

          const onChangeToCompany = async (selection) => {
            const signator = await getCompanySignator(selection.id);
            form.batch(() => {
              if (signator) {
                form.change("to_signed_by", signator);
                setDisableSignator(true);
              } else {
                form.change("to_signed_by", undefined);
                setDisableSignator(false);
              }
            });
          };
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <SubContractCommonFields
                    projectId={projectId}
                    values={values}
                    isReadOnly={isReadOnly}
                    onChangeToCompany={onChangeToCompany}
                    disableSignator={disableSignator}
                    restrictNovoSigners={restrictNovoSigners}
                  />
                  <Box my={1}>
                    {/* <ProjectDrawingAutoSelectMui
                      projectId={projectId}
                      name="drawings"
                      label="Drawings"
                      multiple
                      disabled
                    /> */}
                  </Box>
                  <Box textAlign="right">
                    {showDrawings && (
                      <Button
                        onClick={() => setDrawingSelectIsOpen(true)}
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faCompassDrafting} />}
                        disabled={isReadOnly}
                      >
                        Select Drawings...
                      </Button>
                    )}
                  </Box>
                  <Box my={1}>
                    {/* HACK: Don't show table until the drawings actually have data to show. RFF is modifying the initialValues when using form.change  */}
                    {showDrawings && drawings && drawings[0]?.display && (
                      <ReactTableMui
                        size="small"
                        className={classnames(
                          novoClasses.stripedTable,
                          novoClasses.smallTable,
                          novoClasses.boldHeaderTable
                        )}
                        showFooter={false}
                        rightAlignColumns={[]}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "name",
                            Cell: ({value, row}) => {
                              return (
                                <Link href={row.original.system_url} underline="always" target="_blank">
                                  {value} <FontAwesomeIcon icon={faExternalLink} />
                                </Link>
                              );
                            },
                          },
                          {
                            Header: "Version Name",
                            accessor: "version_name",
                          },
                          {
                            Header: "Description",
                            accessor: "description",
                          },
                        ]}
                        data={drawings}
                      />
                    )}
                  </Box>

                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/contracts/subcontract/${values.id}/?next=/v2/projects/${projectId}/${nextPath}/&cancel=${pathname}`}
                        disabled={isReadOnly}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
                <DrawingSelectDialog
                  isOpen={drawingSelectIsOpen}
                  onSubmit={(values) => {
                    setDrawingSelectIsOpen(false);
                  }}
                  handleClose={() => setDrawingSelectIsOpen(false)}
                  selectedDrawings={values.drawings}
                  projectId={projectId}
                  onChangeSelectedDrawings={onChangeSelectedDrawings}
                />
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const SendSubcontractInsuranceReminderDialogForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  insuranceContact: {
    full_name: string;
    id: number;
  };
  projectDisplay: string;
  contractDisplay: string;
}) => {
  const {onSubmit, handleClose, isOpen, insuranceContact, contractDisplay, projectDisplay, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            values.contactId = insuranceContact.id;
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  <Grid container justifyContent="space-between">
                    <Grid item>Send insurance reminder to {insuranceContact.full_name}</Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      Please update your insurance requirements for NOVO Construction {projectDisplay} on subcontract{" "}
                      {contractDisplay}. All insurance documents specific to this project can be uploaded here.
                      <TinyMCEMui name="message" label="Message" />
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={"Email Request"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const SendSubcontractCOIReminderDialogForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  project: any;
  insuranceContact: {
    full_name: string;
    id: number;
  };
  commitment: any;
  // projectDisplay: string;
  // contractDisplay: string;
}) => {
  const {onSubmit, handleClose, isOpen, project, insuranceContact, commitment, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            values.contactId = insuranceContact.id;
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  <Grid container justifyContent="space-between">
                    <Grid item>Send COI reminder to {insuranceContact.full_name}</Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <p>
                        A project specific Certificate of Insurance (COI) for project {project.display} should be sent
                        to{" "}
                        <Link href="mailto:certificates@novoconstruction.com" underline="always">
                          certificates@novoconstruction.com
                        </Link>
                        .
                      </p>
                      <p>The additional insured should read as follows:</p>
                      <p>
                        {project.additional_insured} NOVO: {project.timberline_job}
                      </p>
                      <TinyMCEMui name="message" label="" />
                      {/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={"Email Request"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const SubContractItemForm = (props: {
  isOpen: boolean;
  isNew?: boolean;
  projectId: number;
  onSubmit: (values) => void;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, handleClose, isOpen, isNew, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <DialogTitle id="form-dialog-title">
                    <Grid container justifyContent="space-between">
                      <Grid item>{isNew ? `Create Item` : `Update Item`}</Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <BeforeUnload block={!pristine} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui name="description" label="Description" required autoFocus />
                        </Grid>
                        <Grid item xs={12}>
                          <CurrencyFieldMui name="value" label="Value" required />
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                    {!isNew && (
                      <ProjectAuditLink
                        projectId={projectId}
                        app="contracts"
                        model="subcontractitem"
                        id={values.id}
                        mt={1}
                      />
                    )}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton disabled={submitting || pristine} label={"Save"} />
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              </>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const SubcontractUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  restrictNovoSigners?: boolean;
  [rest: string]: any;
}) => {
  const {
    projectId,
    onSubmit,
    isOpen,
    handleClose,
    isNew = false,
    isReadOnly = false,
    restrictNovoSigners = false,
    ...rest
  } = props;
  const [disableSignator, setDisableSignator] = React.useState(false);
  // const getCompanySignator = async (companyId) => {
  //   const result = await axiosAPI.get(`/companies/${companyId}/`);
  //   const {signator, name} = result.data;
  //   if (signator) signator.company = {name: name};
  //   return signator;
  // };
  const formPropsRef = React.useRef(null);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
        maxWidth="xl"
        fullWidth
      >
        <FinalForm onSubmit={onSubmit} {...rest}>
          {(props) => {
            const {handleSubmit, form, submitting, pristine, values} = props;
            formPropsRef.current = props;
            const onChangeToCompany = async (selection) => {
              const signator = await getCompanySignator(selection.id);
              form.batch(() => {
                if (signator) {
                  form.change("to_signed_by", signator);
                  setDisableSignator(true);
                } else {
                  form.change("to_signed_by", undefined);
                  setDisableSignator(false);
                }
              });
            };
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {/* {isNew ? "Create Subcontract" : "Update Subcontract"} */}
                  {isNew ? `Create` : `Update`} {values.commitment_type_display}
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  {/* <BeforeUnload block={!pristine} /> */}
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <SubContractCommonFields
                      projectId={projectId}
                      isReadOnly={isReadOnly}
                      values={values}
                      isNew={isNew}
                      onChangeToCompany={onChangeToCompany}
                      disableSignator={disableSignator}
                      restrictNovoSigners={restrictNovoSigners}
                    />
                  </FinalFormKeyboardShortcuts>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {isNew && (
                    <FormActions.SaveAndEditButton
                      disabled={submitting || pristine}
                      onClick={() => form.change("editAfterSubmit", true)}
                    />
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ProjectDrawingAutoSelectMui = (props) => {
  const {projectId, ...rest} = props;

  return (
    <ApiAutoselectMui
      labelProp="display"
      optionsProp="results"
      baseURL={`projects/${projectId}/drawings2/`}
      minCharacters={0}
      qsParams={{}}
      getOptionLabel={(option) => (option ? `${option.display}` : "")}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            label={<>{option.display}</>}
            // {...getTagProps({index})}
            disabled
          />
        ));
      }}
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.display}</div>
            <div>
              <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
                {option?.system_key_display}
              </Typography>
            </div>
          </>
        );
      }}
      {...rest}
    />
  );
};

interface Drawing {
  id: number;
  display: string;
  system_key_display: string;
  system_url: string;
}

export const DrawingSelectDialog = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  onChangeSelectedDrawings: (selected) => void;
  selectedDrawings: Drawing[];
  projectId: number;
}) => {
  const {onSubmit, handleClose, isOpen, selectedDrawings, onChangeSelectedDrawings, projectId, ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const novoClasses = makeNovoClasses();

  const [drawingFilterOptions, setDrawingFilterOptions, clearDrawingFilterOption, clearAllDrawingFilterOptions] =
    useFilterOptions(["Search", "System"]);

  const drawingFilterParams = {
    q: drawingFilterOptions.Search.value,
    system_key: drawingFilterOptions.System.value,
    page_size: pageSize,
  };

  const {query: filteredDrawingsQuery} = useSentinelListAPI(
    `projects/${projectId}/drawings2/?page_size=${pageSize}&page=${page}&${qs.stringify(drawingFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const availableDrawings = filteredDrawingsQuery.data.results;
  const allItemIds = availableDrawings.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  React.useEffect(() => {
    selectedDrawings.map((drawing) => addSelectedItemId(drawing.id));
  }, []);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate={true}
                autoComplete="off"
                style={{
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DialogTitle id="form-dialog-title">Select Drawings</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FilterSearch
                          label="Search"
                          value={drawingFilterOptions.Search.value}
                          name="Search"
                          onChange={(value) => {
                            setDrawingFilterOptions("Search", value, value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FilterSelect
                          label="System"
                          name="System"
                          options={[
                            {value: "plangrid", label: "Plan Grid"},
                            {value: "fieldwire", label: "FieldWire"},
                          ]}
                          value={drawingFilterOptions.System.value}
                          onChange={(value, label) => {
                            setDrawingFilterOptions("System", value, label);
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => availableDrawings.map((drawing) => addSelectedItemId(drawing.id))}
                          startIcon={<FontAwesomeIcon icon={faSquarePlus} />}
                          disabled={availableDrawings.length < 1}
                        >
                          Add Visible
                        </Button>
                        {"  "}
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => availableDrawings.map((drawing) => removeSelectedItemId(drawing.id))}
                          startIcon={<FontAwesomeIcon icon={faSquareMinus} />}
                          disabled={availableDrawings.length < 1}
                        >
                          Remove Visible
                        </Button>
                        {"  "}
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={removeAllSelectedItemIds}
                          startIcon={<FontAwesomeIcon icon={faImageSlash} />}
                        >
                          Remove All
                        </Button>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        {(filteredDrawingsQuery.data.total_pages > 1 || pageSize > 100) && (
                          <Box textAlign={"right"}>
                            <PaginationWithPageSize
                              page={page}
                              setPage={setPage}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              count={filteredDrawingsQuery.data.total_pages}
                            />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Box mb={3} />
                    {!filteredDrawingsQuery.isFetching ? (
                      <ReactTableMui
                        size="small"
                        className={classnames(
                          novoClasses.stripedTable,
                          novoClasses.smallTable,
                          novoClasses.boldHeaderTable
                        )}
                        showFooter={false}
                        rightAlignColumns={[]}
                        columns={[
                          {
                            accessor: "id",
                            disableSortBy: true,
                            Cell: ({value, row}) => (
                              <ReactTableMuiCheckboxSelector
                                key={row.original.id}
                                id={row.original.id}
                                selected={selectedItemIds.has(row.original.id)}
                                onAddSelected={addSelectedItemId}
                                onRemoveSelected={removeSelectedItemId}
                                onClick={(event) => event.preventDefault()}
                              />
                            ),
                          },
                          {
                            Header: "Name",
                            accessor: "display",
                            Cell: ({value, row}) => (
                              <>
                                {value}{" "}
                                <Tooltip title={`View on ${row.original.system_key_display}`}>
                                  <Link
                                    href={row.original.system_url}
                                    target="_blank"
                                    rel="noopener"
                                    underline="always"
                                  >
                                    <FontAwesomeIcon icon={faUpRightFromSquare} size="xs" />
                                  </Link>
                                </Tooltip>
                              </>
                            ),
                          },
                          {
                            Header: "Version Name",
                            accessor: "version_name",
                          },
                          {
                            Header: "Description",
                            accessor: "description",
                          },
                          {
                            Header: "System",
                            accessor: "system_key_display",
                            Cell: ({value, row}) => (
                              <Link href={row.original.system_url} underline="always">
                                {value}
                              </Link>
                            ),
                          },
                        ]}
                        initialState={{
                          sortBy: [
                            {
                              id: "display",
                              asc: true,
                            },
                          ],
                        }}
                        data={availableDrawings}
                        getRowProps={(row) => {
                          return {
                            onClick: () => {
                              selectedItemIds.has(row.original.id)
                                ? removeSelectedItemId(row.original.id)
                                : addSelectedItemId(row.original.id);
                            },
                          };
                        }}
                        {...rest}
                      />
                    ) : (
                      <>
                        {[...new Array(selectedDrawings.length < 50 ? selectedDrawings.length : 50)].map(() => (
                          <Skeleton />
                        ))}
                      </>
                    )}
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.Right>
                    <Button
                      onClick={() => {
                        const drawingIds = Array.from(selectedItemIds);

                        onChangeSelectedDrawings(drawingIds.map((drawingId) => ({id: drawingId})));
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Select ({selectedItemIds.size})
                    </Button>
                  </FormActions.Right>
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
