import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Checkbox, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import {Skeleton} from "@material-ui/lab";
import {omit} from "lodash";
import moment from "moment";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../../components/Filters";
import offices from "../../../components/forms/choices/offices.json";
import bidTypeOptions from "../../../components/forms/choices/rfpBidTypes.json";
import projectTypeChoices from "../../../components/forms/choices/rfpProjectTypeChoices.json";
import requestTypeChoices from "../../../components/forms/choices/rfpRequestTypes.json";
import sectorTypeChoices from "../../../components/forms/choices/rfpSectors.json";
import statusChoices from "../../../components/forms/choices/rfpStatusTypes.json";
import {LabelValue} from "../../../components/LabelValue";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {Pagination} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import ReactGridV2 from "../../../components/ReactGrid/ReactGridV2";
import {StatusCellTemplate} from "../../../components/ReactGrid/StatusCellTemplate";
import {TabCountChip} from "../../../components/Tabs";
import {TagList} from "../../../components/Tags";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {getStatusColor} from "../../../theme/colors";
import {saveExcel} from "../../../utils/excel";
import {RFPOnboardingDialogForm} from "./RFPForms";

const RFPs = (props) => {
  const {...rest} = props;

  const [rfpOnboardingDialogFormIsOpen, setRFPOnboardingDialogFormIsOpen] = React.useState(false);
  const [editRFPDialogFormIsOpen, setEditRFPDialogFormIsOpen] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(0);
  const [activeItem, setActiveItem] = React.useState(null);
  const [initialFetched, setInitialFetched] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10000);
  const [storedFilterOptions, setStoredFilterOptions] = useLocalStorage("rfpFilterOptions", {
    Hidden: {
      value: "",
      label: "",
    },
    Status: {
      value: "",
      label: "",
    },
    Search: {
      value: "",
      label: "",
    },
    Ordering: {
      value: "",
      label: "",
    },
    Office: {
      value: "",
      label: "",
    },
  });

  const storeRFPFilterOptions = () => {
    setTimeout(() => {
      setStoredFilterOptions(filterOptions);
    }, 100);
  };

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    [
      "Hidden",
      "Status",
      "Search",
      "Ordering",
      "Office",
      "Is Tracked",
      "From Date",
      "To Date",
      "Tag",
      "Year",
      "Project Executive",
      "CM",
      "Architect",
      "Bid Type",
    ],
    {
      Status: {value: "pending", label: "Pending"},
      Hidden: {
        value: [
          "bid_type",
          "submission_time",
          "notes",
          "tags",
          "competitors",
          "is_tracked",
          "project_engineer",
          "assistant_project_manager",
          "request_type",
          "due_time",
        ],
        label: "",
      },
    }
  );

  const blockUI = useBlockUI();

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    ordering: filterOptions.Ordering.value,
    office: filterOptions.Office.value,
    is_tracked: filterOptions["Is Tracked"].value,
    date_min: filterOptions["From Date"].value,
    date_max: filterOptions["To Date"].value,
    tag: filterOptions.Tag.value,
    year: filterOptions.Year.value,
    project_executive: filterOptions["Project Executive"].value,
    construction_manager: filterOptions.CM.value,
    architect: filterOptions.Architect.value,
    bid_type: filterOptions["Bid Type"].value,
  };

  const {
    query: rfpQuery,
    create: createRFP,
    update: updateRFP,
    delete: deleteRFP,
  } = useSentinelListAPI(`marketing/rfps/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
      keepPreviousData: true,
    },
  });

  const rfps = rfpQuery.data.results;

  const {query: tagQuery} = useSentinelListAPI(`marketing/rfps/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  // const {query: cmQuery} = useSentinelListAPI(`marketing/rfps/construction-managers/`, {
  //   initialData: [],
  // });

  // const cmOptions = cmQuery.data;

  // const {query: pxQuery} = useSentinelListAPI(`marketing/rfps/project-executives/`, {
  //   initialData: [],
  // });

  // const pxOptions = pxQuery.data;

  const {query: additionalFilterQuery} = useSentinelListAPI(`marketing/rfps/additional-filter-options/`, {
    initialData: {
      cmOptions: [],
      pxOptions: [],
      architectOptions: [],
    },
  });

  const {cmOptions, pxOptions, architectOptions} = additionalFilterQuery.data;

  const pageDataQuery = useFetchCurrentPage({
    initialData: {
      rfpYearChoices: [],
    },
  });

  const {rfpYearChoices} = pageDataQuery.data;

  React.useEffect(() => {
    if (rfpQuery.isFetchedAfterMount && pageDataQuery.isFetchedAfterMount) {
      setInitialFetched(true);
    }
  }, [rfpQuery.isFetchedAfterMount]);

  const DATE_COL_WIDTH = 110;

  const allCells = [
    {
      noHide: true,
      headerLabel: "",
      columnId: "detail",
      width: 40,
      buildCell: (item) => {
        return {
          type: "text",
          text: "",
          renderer: (text) => (
            <Tooltip title="Edit RFP">
              <Box justifyContent="center" display="flex" width="100%">
                <Link href={`/v2/marketing/rfps/${item.id}/`} target="_blank">
                  <LaunchIcon
                    // onClick={() => {
                    //   setActiveItem(item);
                    //   setEditRFPDialogFormIsOpen(true);
                    // }}

                    style={{cursor: "pointer"}}
                    fontSize="small"
                  />
                </Link>
              </Box>
            </Tooltip>
          ),
        };
      },
    },

    {
      headerLabel: "Tracked",
      columnId: "is_tracked",
      resizable: true,
      width: 50,
      buildCell: (item) => ({type: "checkbox", checked: item.is_tracked}),
    },

    {
      headerLabel: "Status",
      columnId: "status",
      resizable: true,
      width: 150,
      // width: 50,
      buildCell: (item) => {
        // return {type: "status", status: item.status, nonEditable: true, cellPropertyType: "item", statusType: "rfp"};
        return {
          type: "dropdown",
          values: statusChoices,
          selectedValue: item.status,

          styles: {singleValue: {color: getStatusColor(item.status_display, "rfp")}},
        };
      },
    },
    {
      noHide: true,
      headerLabel: "Project Name",
      columnId: "project_name",
      resizable: true,
      width: 300,
      buildCell: (item) => {
        return {
          type: "text",
          text: item.project_name,
        };
      },
    },
    {
      headerLabel: "Due Date",
      columnId: "due_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.due_date ? moment(item?.due_date).toDate() : null,
          format: ReactGridV2.formatters.date,
        };
      },
    },
    {
      noHide: true,
      headerLabel: "Office",
      columnId: "office",
      resizable: true,
      width: 200,
      buildCell: (item) => {
        return {
          type: "dropdown",
          values: offices,
          selectedValue: item.office,
        };
      },
    },
    {
      headerLabel: "Bid Type",
      columnId: "bid_type",
      resizable: true,
      // buildCell: (item) => ({type: "text", text: item.bid_type_display}),
      buildCell: (item) => ({
        type: "dropdown",
        values: bidTypeOptions,
        selectedValue: item.bid_type,
      }),
    },
    {
      headerLabel: "Sector",
      columnId: "sector",
      resizable: true,
      // buildCell: (item) => ({type: "text", text: item.sector_display}),
      buildCell: (item) => ({
        type: "dropdown",
        values: sectorTypeChoices,
        selectedValue: item.sector,
      }),
    },
    {
      headerLabel: "Project Types",
      columnId: "project_types",
      resizable: true,
      width: 200,
      buildCell: (item) => ({
        type: "text",
        text: item.project_types.map((type) => type.description).join(", "),
        nonEditable: true,
      }),

      // buildCell: (item) => ({
      //   type: "dropdown",
      //   values: projectTypeChoices,
      //   selectedValue: item.project_type,
      // }),
    },

    {
      headerLabel: "SF",
      columnId: "square_footage",
      resizable: true,
      buildCell: (item) => ({type: "number", value: item?.square_footage ?? null}),
    },
    {
      headerLabel: "Budget",
      columnId: "anticipated_budget",
      resizable: true,
      buildCell: (item) => ({
        type: "number",
        value: item.anticipated_budget,
        format: ReactGridV2.formatters.currencyNoDecimals,
      }),
    },
    {
      headerLabel: "Address",
      columnId: "address1",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item.address1}),
    },
    {headerLabel: "City", columnId: "city", resizable: true, buildCell: (item) => ({type: "text", text: item.city})},
    {
      headerLabel: "CM",
      columnId: "cm",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.construction_manager?.name ?? "", nonEditable: true}),
    },
    {
      headerLabel: "Architect",
      columnId: "architect",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.architect?.name ?? "", nonEditable: true}),
    },
    {
      headerLabel: "Project Executive",
      columnId: "project_executive",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.project_executive?.full_name ?? "", nonEditable: true}),
    },
    {
      headerLabel: "Sr. Project Manager",
      columnId: "senior_project_manager",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.senior_project_manager?.full_name ?? "", nonEditable: true}),
    },
    {
      headerLabel: "Project Manager",
      columnId: "project_manager",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.project_manager?.full_name ?? "", nonEditable: true}),
    },
    {
      headerLabel: "Superintendent",
      columnId: "superintendent",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.superintendent?.full_name ?? "", nonEditable: true}),
    },

    {
      headerLabel: "Client",
      columnId: "client",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.client?.name ?? "", nonEditable: true}),
    },

    {
      headerLabel: "Request Type",
      columnId: "request_type",
      resizable: true,
      buildCell: (item) => ({type: "dropdown", values: requestTypeChoices, selectedValue: item.request_type}),
    },

    {
      headerLabel: "Submission Time",
      columnId: "due_time",
      resizable: true,
      buildCell: (item) => {
        return {
          type: "text",
          text: item?.due_time,
        };
      },
    },

    {
      headerLabel: "Competitors",
      columnId: "competitors",
      resizable: true,
      width: 250,
      buildCell: (item) => ({
        type: "text",
        text: "",
        nonEditable: true,
        renderer: (text) => {
          return (
            // <Box justifyContent="center" display="flex" width="100%">
            <TagList tags={item.competitors.map((competitor) => competitor)} icon={undefined} />
            // </Box>
          );
        },
      }),
    },

    {
      headerLabel: "Asst. Project Manager",
      columnId: "assistant_project_manager",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.assistant_project_manager?.full_name ?? "", nonEditable: true}),
    },
    {
      headerLabel: "Project Engineer",
      columnId: "project_engineer",
      resizable: true,
      buildCell: (item) => ({type: "text", text: item?.project_engineer?.full_name ?? "", nonEditable: true}),
    },

    {
      headerLabel: "Notes",
      columnId: "notes",
      resizable: true,
      width: 300,
      buildCell: (item) => ({type: "text", text: item?.notes ?? ""}),
    },
    {
      headerLabel: "Tags",
      columnId: "tags",
      resizable: true,
      width: 200,
      buildCell: (item) => ({
        type: "text",
        text: "",
        nonEditable: true,
        renderer: (text) => {
          return (
            // <Box justifyContent="center" display="flex" width="100%">
            <TagList tags={item.tags} />
            // </Box>
          );
        },
      }),
    },
    {
      headerLabel: "Weekly Log",
      columnId: "show_on_weekly_log",
      resizable: true,
      width: 75,
      buildCell: (item) => ({type: "checkbox", checked: item.show_on_weekly_log}),
    },
  ];

  const hideableColumns = allCells
    .filter((cell) => !cell?.noHide)
    .map((cell) => ({label: cell.headerLabel, value: cell.columnId}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const cellData = React.useMemo(
    () => allCells.filter((item) => !filterOptions.Hidden.value.includes(item.columnId)),
    [filterOptions.Hidden.value, openDropdown]
  );

  const saveChanges = (newData) => {
    updateRFP.mutateAsync(newData[0]);
  };

  const getFilterValues = (value): [newValue: {}[], newLabel: string] => {
    const selected = value[0];
    const prevValue = filterOptions.Hidden.value || [];
    let newValue = [];

    if (prevValue.includes(selected)) {
      newValue = [...prevValue].filter((hidden) => hidden !== selected);
    } else {
      newValue = [...prevValue, selected];
    }
    const newLabel = newValue
      .map((value) => hideableColumns.find((option) => option.value === value)?.label)
      .join(", ");
    return [newValue, newLabel];
  };

  // if (!initialFetched) {
  //   return <BlockUI show={true} message="Fetching RFPs..." />;
  // }

  return (
    <>
      <Helmet title="RFP Log" />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        <Typography color="textSecondary">RFPs</Typography>
        {/* </Link> */}
        <Typography color="textPrimary">RFP Log</Typography>
      </Breadcrumbs>

      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title></PageHeader.Title>
        </PageHeader.Left>
        <Box>
          <Box>
            <PageHeader.Right>
              <PageHeader.Right.RightSpark
                title="Total RFPs"
                value={rfps.length}
                loading={rfpQuery.isFetching}
                color="#1976d2"
              />
              <PageHeader.Right.RightSpark
                title="Wins"
                value={rfps.filter((rfp) => rfp.status === "won").length}
                loading={rfpQuery.isFetching}
                color="green"
                border
              />
              <PageHeader.Right.RightSpark
                title="Losses"
                value={rfps.filter((rfp) => rfp.status === "lost").length}
                loading={rfpQuery.isFetching}
                color="red"
              />
              <PageHeader.Right.RightSpark
                title="Hit Rate"
                value={`${
                  Math.round(
                    (rfps.filter((rfp) => rfp.status === "won").length /
                      (rfps.filter((rfp) => rfp.status === "won").length +
                        rfps.filter((rfp) => rfp.status === "lost").length)) *
                      100
                  ) || 0
                }%`}
                loading={rfpQuery.isFetching}
                border
              />
              <PageHeader.Right.CurrencySpark
                title="Estimated Revenue"
                number={rfps.reduce(
                  (total, rfp) => (rfp.status === "won" ? total + (rfp.anticipated_budget || 0) : total),
                  0
                )}
                loading={rfpQuery.isFetching}
                border
              />
            </PageHeader.Right>
          </Box>
        </Box>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <Box display="flex" alignItems="center">
              RFP Log <TabCountChip isLoading={rfpQuery.isFetching} count={rfpQuery.data.count} />
            </Box>
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton href={"/v2/marketing/rfps/onboarding/"}>
                RFP Onboarding
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={rfpQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    rfps.map((item) => {
                      return {
                        Status: item.status_display,
                        Project: item.project_name,
                        Client: item.client?.name,
                        "Due Date": item.due_date,
                        Office: item.office_display,
                        "Bid Type": item.bid_type_display,
                        Sector: item.sector_display,
                        "Request Type": item.request_type_display,
                        "Project Type": item.project_types.map((type) => type.description).join(", "),
                        SF: item.square_footage,
                        Budget: item.anticipated_budget,
                        Address: item.address1,
                        City: item.city,
                        "Project Executive": item.project_executive?.full_name,
                        "Sr. Project Manager": item.senior_project_manager?.full_name,
                        "Project Manager": item.project_manager?.full_name,
                        "Asst. Project Manager": item.assistant_project_manager?.full_name,
                        "Project Engineer": item.project_engineer?.full_name,
                        Superintendent: item.superintendent?.full_name,
                        Architect: item.architect?.name,
                        CM: item?.construction_manager?.name,
                        "CM Contact": item.construction_manager_contact?.full_name,
                        Landlord: item.landlord?.name,
                        Broker: item.broker?.name,
                        "Broker Contact": item.broker_contact?.full_name,
                        "Submission Time": item.due_time,
                        Competitors: item.competitors.map((competitor) => competitor).join(" "),
                        Notes: item.notes,
                        Tags: item.tags.join(", "),
                      };
                    }),
                    `RFPs`
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton isFetching={rfpQuery.isFetching} onClick={() => rfpQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                  storeRFPFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusChoices}
                value={filterOptions.Status.value}
                showStatusIcon
                statusType="rfp"
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                  storeRFPFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                options={hideableColumns}
                label="Visible Columns"
                name="hidden"
                onChange={(value, label) => {
                  const [newValue, newLabel] = getFilterValues(value);

                  if (newValue.length < 1) {
                    clearFilterOption("Hidden");
                    storeRFPFilterOptions();
                  } else {
                    setFilterOption("Hidden", newValue, newLabel);

                    storeRFPFilterOptions();
                  }
                }}
                multiple
                renderOption={(option) => (
                  <>
                    <Checkbox checked={!filterOptions.Hidden.value.includes(option.value)} size="small" />{" "}
                    {option.label}
                  </>
                )}
                allowNull={false}
              />
            </Grid>
            {/* <Grid item sm={3} xs={12}>
              <FilterSelect
                options={[
                  {label: "Description", value: "description"},
                  {label: "Number", value: "number"},
                  {label: "Package Number", value: "submittal_package__number"},
                ]}
                label="Ordering"
                name="ordering"
                value={filterOptions.Ordering.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Ordering", value, label);
                }}
              />
            </Grid> */}
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Office"
                name="office"
                options={offices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Office", value, label);
                  storeRFPFilterOptions();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterDate
                label="From Date"
                name="From Date"
                value={filterOptions["From Date"].value}
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("From Date", value, value);
                  clearFilterOption("Year");
                }}
                disableFuture={false}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterDate
                label="To Date"
                name="To Date"
                value={filterOptions["To Date"].value}
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("To Date", value, value);
                  clearFilterOption("Year");
                }}
                disableFuture={false}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                // native
                label="Tag"
                name="Tag"
                options={tagOptions}
                value={filterOptions.Tag.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Tag", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="Year"
                label="Year"
                options={rfpYearChoices}
                value={filterOptions["Year"].value}
                onChange={(value, label) => {
                  setPage(1);
                  clearFilterOption("From Date");
                  clearFilterOption("To Date");
                  setFilterOption("Year", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="Project Executive"
                label="Project Executive"
                options={pxOptions}
                value={filterOptions["Project Executive"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Project Executive", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="CM"
                label="CM"
                options={cmOptions}
                value={filterOptions.CM.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("CM", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="Bid Type"
                label="Bid Type"
                options={bidTypeOptions}
                value={filterOptions["Bid Type"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Bid Type", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="Architect"
                label="Architect"
                options={architectOptions}
                value={filterOptions.Architect.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Architect", value, label);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={omit(filterOptions, ["Hidden"])}
                onDelete={(key) => {
                  setPage(1);
                  clearFilterOption(key);
                  storeRFPFilterOptions();
                }}
                onDeleteAll={() => {
                  setPage(1);
                  clearAllFilterOptions();
                  storeRFPFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box display={"flex"} justifyContent="flex-end">
                <Pagination count={rfpQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
      </PaperPanel>
      <Box style={{overflow: "scroll"}}>
        <ReactGridV2
          customCellTemplates={{
            status: new StatusCellTemplate(),
          }}
          enableRangeSelection
          // enableFillHandle
          onChange={saveChanges}
          stickyLeftColumns={3}
          stickyTopRows={1}
          cellData={cellData}
          data={rfps}
          disableVirtualScrolling
        />
        {/* Empty box here to allow the dropdown to be visible on the last row */}
        <Box
          py={
            Math.max(
              ...[bidTypeOptions.length, statusChoices.length, projectTypeChoices.length, sectorTypeChoices.length]
            ) * 2
          }
        />
      </Box>
      <RFPOnboardingDialogForm
        open={rfpOnboardingDialogFormIsOpen}
        onClose={() => setRFPOnboardingDialogFormIsOpen(false)}
        onSubmit={(values) => {
          createRFP.mutateAsync(values).then(() => {
            setRFPOnboardingDialogFormIsOpen(false);
          });
        }}
        isNew
        tagOptions={tagOptions.map((tag) => tag.label)}
        isFetching={tagQuery.isFetching}
      />

      <RFPOnboardingDialogForm
        open={editRFPDialogFormIsOpen}
        onClose={() => setEditRFPDialogFormIsOpen(false)}
        onSubmit={(values) => {
          updateRFP.mutateAsync(values).then(() => {
            setEditRFPDialogFormIsOpen(false);
          });
        }}
        initialValues={activeItem}
        tagOptions={tagOptions.map((tag) => tag.label)}
        isFetching={tagQuery.isFetching}
      />
    </>
  );
};

export default RFPs;
