import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../../components/Filters";
import {PageHeader} from "../../../components/PageHeader";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {saveExcel} from "../../../utils/excel";
import JobCostTransactionTable from "./JobCostTransactionTable";

const ProjectBudgetCostDetail = (props: any) => {
  const {project, ...rest} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(500);
  const {search} = useLocation();
  const [dateRange, setDateRange] = React.useState("" as number | string);
  const queryString = qs.parse(search);

  const pageSizes = [500, 1000, 1500, 2000];
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Category", "Cost Code", "Start Date", "End Date"],
    {
      Category: {
        label: queryString.category || "",
        value: queryString.category || "",
      },
      "Cost Code": {
        label: queryString.cost_code || "",
        value: queryString.cost_code || "",
      },
    }
  );

  const filterParams = {
    q: filterOptions.Search.value,
    category: filterOptions["Category"].value,
    cost_code: filterOptions["Cost Code"].value,
    date_min: filterOptions["Start Date"].value,
    date_max: filterOptions["End Date"].value,
  };

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      jtdCost: 0,
    },
  });
  const pageData = pageDataQuery.data;
  const {query: transactionsQuery} = useSentinelListAPI(
    `projects/${project.id}/timberline/job-cost/transactions/?ordering=cost_code,transaction_date&page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const transactions = transactionsQuery.data?.results;
  const {query: categoriesQuery} = useSentinelListAPI(
    `projects/${project.id}/timberline/job-cost/transactions/categories/`,
    {
      initialData: [],
    }
  );
  const categories = categoriesQuery.data;
  const {query: costCodeQuery} = useSentinelListAPI(
    `projects/${project.id}/timberline/job-cost/transactions/cost-codes/`,
    {
      initialData: [],
    }
  );
  const costCodes = costCodeQuery.data;
  const isFiltered =
    transactionsQuery.data.total_pages > 1 || Object.values(filterParams).some((value) => value !== "");
  return (
    <>
      <Helmet title={`${project.display} - Cost Detail`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Cost Detail</Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>Job Cost Detail</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="JTD Cost" number={pageData.jtdCost} />
        </PageHeader.Right>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={transactionsQuery.isFetching}>
          <PaperPanel.Header.Title>Cost Detail</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={transactionsQuery.isFetching}
                onClick={(event) => {
                  let excelRows = transactions.map((transaction) => {
                    return {
                      Category: transaction.category,
                      "Cost Code": transaction.cost_code,
                      Vendor: transaction.vendor_name,
                      Invoice: transaction.invoice,
                      Description: transaction.description,
                      "Transaction Date": transaction.transaction_date,
                      Units: {v: transaction.units, t: "n", z: "#,##0.00"},
                      Amount: {v: transaction.amount, t: "n", z: "$#,##0.00"},
                    };
                  });
                  if (isFiltered) {
                    excelRows.push({
                      Category: "Filtered",
                    });
                  }
                  saveExcel(excelRows, `${project.display} - Cost Detail`, [
                    {wch: 10},
                    {wch: 20},
                    {wch: 40},
                    {wch: 40},
                    {wch: 60},
                    {wch: 15},
                    {wch: 15},
                    {wch: 15},
                  ]);
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action px={0} border>
              <PaperPanel.Header.RefreshButton
                isFetching={transactionsQuery.isFetching}
                onClick={() => transactionsQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                label="Cost Code"
                name="Cost Code"
                options={costCodes}
                value={filterOptions["Cost Code"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Cost Code", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                label="Category"
                name="Category"
                options={categories}
                value={filterOptions["Category"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Category", value, label);
                }}
              />
            </Grid>
            <Grid container spacing={1}></Grid>
            <Grid item sm={2} xs={6}>
              <FilterDate
                name="Start Date"
                value={filterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("Start Date", date, date);
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={2} xs={6}>
              <FilterDate
                name="End Date"
                value={filterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("End Date", date, date);
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                name="Date Range"
                label="Date Range"
                value={dateRange}
                options={[
                  {label: "Last Week", value: 7},
                  {
                    label: "Last Month",
                    value: 30,
                  },
                  {
                    label: "Last 2 Months",
                    value: 60,
                  },
                  {
                    label: "Last 4 Months",
                    value: 90,
                  },
                  {
                    label: "Last Year",
                    value: 365,
                  },
                ]}
                onChange={(value, label) => {
                  if (value === "") {
                    clearFilterOption("Start Date");
                    clearFilterOption("End Date");
                    setDateRange("");
                    return;
                  }
                  const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                  const endDate = moment().format("YYYY-MM-DD");
                  setPage(1);
                  setFilterOption("Start Date", startDate, startDate);
                  setFilterOption("End Date", endDate, endDate);
                  setDateRange(value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                  if (key === "Start Date" || key === "End Date") {
                    setDateRange("");
                  }
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={transactionsQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={pageSizes}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <JobCostTransactionTable transactions={transactions} isFiltered={isFiltered} />
        </PaperPanel.Body>
        {transactionsQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={transactionsQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={pageSizes}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(filterParams, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(transactionsQuery, null, 2)}</pre> */}
    </>
  );
};

export default ProjectBudgetCostDetail;
