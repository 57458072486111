import {faPaperPlaneAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Typography} from "@material-ui/core";
import moment from "moment";
import Helmet from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {SubmittalTransmittalDetailForm} from "./SubmittalTransmittalForms";

const SubmittalTransmittalDetail = (props) => {
  const {project, ...rest} = props;
  const {transmittalId, packageId} = useParams();
  const navigate = useNavigate();
  const blockUI = useBlockUI();

  const {
    query: transmittalQuery,
    update: updateTransmittal,
    create: createTransmittal,
    rpc: transmittalRPC,
    delete: deleteTransmittal,
  } = useSentinelDetailAPI(`projects/${project.id}/submittals/packages/${packageId}/transmittals/${transmittalId}/`, {
    initialData: {},
  });

  const transmittal = transmittalQuery.data;

  const {query: packageContactsQuery} = useSentinelListAPI(
    ["projects", project.id, "submittals/packages", parseInt(packageId), "contacts"],
    {
      initialData: {
        results: [],
      },
    }
  );

  const packageContacts = packageContactsQuery.data.results;

  if (!transmittalQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching transmittal info..." />;
  }

  return (
    <>
      <Helmet title={`${props.project.display} - Submittal  Transmittal ${transmittal.number_display} `} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/submittals/packages/`}>
          <Typography color="textSecondary">Submittal Packages</Typography>
        </MuiNavLink>
        <MuiNavLink href={`/v2/projects/${project.id}/submittals/packages/${packageId}/`}>
          <Typography color="textSecondary">{transmittal?.submittal_package?.display}</Typography>
        </MuiNavLink>

        <Typography color="textPrimary">Submittal Transmittal {transmittal.number_display}</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={transmittalQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPaperPlaneAlt} /> Submittal Transmittal {transmittal.number_display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButtons
                // pdfURL={`/reports2/rfis/${rfiId}/`}
                buildReportURL={`/reports2/projects/${project.id}/submittals/transmittals/detail/filter/?pk=${transmittalId}`}
              >
                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/submittals/transmittal/${transmittalId}/`}>
                  Transmittal
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/submittals/transmittal/${transmittalId}/?display_reviewers=on&display_reviewer_notes=on`}
                >
                  Transmittal With Reviewer Comments
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.BoxFilesButton
                href={`/v2/projects/${project.id}/box/files/submittals/submittalpackage/${packageId}/`}
                uploadURL={`/projects/${project.id}/submittals/packages/${packageId}/transmittals/${transmittalId}/upload-to-box/`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={transmittalQuery.isFetching}
                onClick={() => transmittalQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body mt={2} mx={1} mb={1}>
          <SubmittalTransmittalDetailForm
            projectId={project.id}
            onSubmit={(values) => {
              const {submitMode} = values;
              delete values.submitMode;
              blockUI.blockUI("Saving...");
              updateTransmittal
                .mutateAsync(values)
                .then(() => {
                  if (submitMode === "saveAndEdit") {
                    transmittalQuery.refetch().then(() => blockUI.unblockUI());
                  } else {
                    navigate(`/v2/projects/${project.id}/submittals/packages/${packageId}/#tab=transmittals`);
                    blockUI.unblockUI();
                  }
                })
                .catch(() => blockUI.unblockUI());
            }}
            initialValues={{date_sent: moment().format("YYYY-MM-DD"), ...transmittal}}
            approverIds={packageContacts
              .filter((contact) => contact.role === "approver")
              .map((approver) => approver.contact_id)}
            onDelete={() => {
              blockUI.blockUI("Deleting...");
              deleteTransmittal.mutateAsync(transmittal).then(() => {
                navigate(`/v2/projects/${project.id}/submittals/packages/${packageId}/#tab=transmittals`);
                blockUI.unblockUI();
              });
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/submittals/transmittals/${transmittalId}/legacy/`} mt={2} />
    </>
  );
};

export default SubmittalTransmittalDetail;
