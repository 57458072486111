import {Box, Grid, Link, Typography} from "@material-ui/core";
import pluralize from "pluralize";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useSelectIds} from "../../../../js/hooks";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import offices from "../../../components/forms/choices/offices.json";
import statusChoices from "../../../components/forms/choices/rfpStatusTypes.json";
import {MuiNavLink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {TabCountChip} from "../../../components/Tabs";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {RFPBulkEditDialogForm, RFPImportValidationForm} from "./RFPForms";

const RFPImports = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(50);
  const [activeRFP, setActiveRFP] = React.useState(null);
  const [validateRFPDialogOpen, setValidateRFPDialogOpen] = React.useState(false);
  const [bulkValidationDialogOpen, setBulkValidationDialogOpen] = React.useState(false);
  const [verifiedRFPIds, setVerifiedRFPIds] = React.useState(new Set());
  const [bulkEditDialogIsOpen, setBulkEditDialogIsOpen] = React.useState(false);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Search",
    "Ordering",
    "Office",

    "Year",
    "Project Executive",
    "CM",
    "Architect",
    "Bid Type",
  ]);

  const blockUI = useBlockUI();

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    ordering: filterOptions.Ordering.value,
    office: filterOptions.Office.value,

    year: filterOptions.Year.value,
    project_executive: filterOptions["Project Executive"].value,
    construction_manager: filterOptions.CM.value,
    architect: filterOptions.Architect.value,
    bid_type: filterOptions["Bid Type"].value,
  };

  const {
    query: rfpQuery,
    create: createRFP,
    update: updateRFP,
    delete: deleteRFP,
    rpc: rfpRCP,
  } = useSentinelListAPI(`marketing/rfps/imported/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
    },
  });

  const rfps = rfpQuery.data.results.filter((rfp) => !verifiedRFPIds.has(rfp.id));

  const pageDataQuery = useFetchCurrentPage({
    initialData: {
      rfpYearChoices: [],
    },
  });

  const {rfpYearChoices} = pageDataQuery.data;

  const allItemIds = rfps.map((rfp) => rfp.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  return (
    <>
      <Helmet title="Imported RFPs" />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        {/* </Link> */}
        <Link href="/v2/marketing/rfps/">
          <Typography color="textSecondary">RFPs</Typography>
        </Link>
        <Typography color="textPrimary">Imported RFPs</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <Box display="flex" alignItems="center">
              <PaperItem.SelectedCheckbox
                indeterminate={!allItemIdsSelected && selectedItemIds.size !== 0}
                checked={allItemIdsSelected}
                onChange={(event, checked) => {
                  if (checked) {
                    addAllSelectedIds();
                  } else {
                    removeAllSelectedItemIds();
                  }
                }}
              />{" "}
              Imported RFPs <TabCountChip isLoading={rfpQuery.isFetching} count={rfpQuery.data.count} />
            </Box>
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                disabled={selectedItemIds.size < 1}
                onClick={() => setBulkValidationDialogOpen(true)}
              >
                Validate Selected
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => setBulkEditDialogIsOpen(true)}
                disabled={selectedItemIds.size < 1}
              >
                Edit Selected
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusChoices}
                value={filterOptions.Status.value}
                showStatusIcon
                statusType="submittal"
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="Year"
                label="Year"
                options={rfpYearChoices}
                value={filterOptions["Year"].value}
                onChange={(value, label) => {
                  setPage(1);

                  setFilterOption("Year", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Office"
                name="office"
                options={offices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={rfpQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          <VirtualizedList
            itemList={rfps}
            isVirtualized={rfps.length > 50}
            disableDragAndDrop
            onSubmit={(values) => {
              updateRFP.mutateAsync(values);
            }}
          >
            {(props) => {
              const {listItem: rfp, ...rest} = props;
              return (
                <Box mt={1} mb={2} px={0.5}>
                  <RFPImportValidationForm
                    rfp={rfp}
                    initialValues={{...rfp, notes: rfp.dumped_data?.Comments ?? ""}}
                    checked={selectedItemIds.has(rfp.id)}
                    onChangeChecked={(event, value) => {
                      if (value) {
                        addSelectedItemId(rfp.id, event.nativeEvent.shiftKey);
                      } else {
                        removeSelectedItemId(rfp.id);
                      }
                    }}
                    onClickValidate={() => {
                      setActiveRFP(rfp);
                      setValidateRFPDialogOpen(true);
                    }}
                    {...rest}
                  />
                </Box>
              );
            }}
          </VirtualizedList>
          {/* {rfps.map((rfp) => {
            return (
              <RFPImportValidationForm
                rfp={rfp}
                onSubmit={(values) => {
                  updateRFP.mutateAsync(values);
                }}
                initialValues={{...rfp, notes: rfp.dumped_data?.Comments ?? ""}}
                checked={selectedItemIds.has(rfp.id)}
                onChangeChecked={(event, value) => {
                  if (value) {
                    addSelectedItemId(rfp.id, event.nativeEvent.shiftKey);
                  } else {
                    removeSelectedItemId(rfp.id);
                  }
                }}
                onClickValidate={() => {
                  setActiveRFP(rfp);
                  setValidateRFPDialogOpen(true);
                }}
              />
            );
          })} */}

          {rfpQuery.data.total_pages > 1 && (
            <PaperPanel.Footer display="flex" justifyContent="flex-end">
              <PaginationWithPageSize
                count={rfpQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSizes={[50, 100, 250, 500, 1000]}
              />
            </PaperPanel.Footer>
          )}
        </PaperPanel.Body>
      </PaperPanel>

      <ConfirmationDialog
        isOpen={validateRFPDialogOpen}
        onApprove={() => {
          // // blockUI.blockUI();
          // updateRFP.mutateAsync({...activeRFP, dumped_data_has_been_verified: true}).then(() => {
          //   setVerifiedRFPIds((original) => new Set(original).add(activeRFP.id));
          // });
          setValidateRFPDialogOpen(false);
          rfpRCP
            .mutateAsync({
              action: "bulk-validate",
              data: {ids: [activeRFP.id]},
            })
            .then(() => {
              removeAllSelectedItemIds();
              rfpQuery.refetch().then(() => {
                setVerifiedRFPIds(new Set());
                blockUI.unblockUI();
              });
            });
        }}
        onDeny={() => {
          setValidateRFPDialogOpen(false);
        }}
        title="Validate RFP"
        approveLabel="Validate"
      >
        Do you want to verify that this RFP imported correctly?
        <ul>
          <li>The contacts are all correct</li>
          <li>The companies are mapped correctly</li>
        </ul>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={bulkValidationDialogOpen}
        onApprove={() => {
          setBulkValidationDialogOpen(false);
          blockUI.blockUI();
          rfpRCP
            .mutateAsync({
              action: "bulk-validate",
              data: {ids: Array.from(selectedItemIds)},
            })
            .then(() => {
              removeAllSelectedItemIds();
              rfpQuery.refetch().then(() => {
                setVerifiedRFPIds(new Set());
                blockUI.unblockUI();
              });
            });
        }}
        onDeny={() => {
          setBulkValidationDialogOpen(false);
        }}
      >
        You want to verify {selectedItemIds.size > 1 ? `these ${selectedItemIds.size}` : "this"}{" "}
        {pluralize("RFP", selectedItemIds.size)} imported correctly, and {selectedItemIds.size > 1 ? "are all" : "is"}{" "}
        ready to be finalized?
      </ConfirmationDialog>

      <RFPBulkEditDialogForm
        open={bulkEditDialogIsOpen}
        onClose={() => setBulkEditDialogIsOpen(false)}
        onSubmit={(values) => {
          setBulkEditDialogIsOpen(false);
          blockUI.blockUI("Updating RFPs");
          let postValues = {};
          for (let key in values) {
            if (key == "notes") continue;
            postValues[`${key}_id`] = values[key].id;
          }

          rfpRCP
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedItemIds),
                update: postValues,
              },
              method: "PUT",
            })
            .then(() => {
              removeAllSelectedItemIds();
              rfpQuery.refetch().then(() => {
                blockUI.unblockUI();
              });
            });
        }}
        selectedCount={selectedItemIds.size}
      />
    </>
  );
};

export default RFPImports;
