import {faComments} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Button, Grid, Link, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import GroupIcon from "@material-ui/icons/Group";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import LinkIcon from "@material-ui/icons/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {useSnackbar} from "notistack";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router";
import {useQueryState} from "use-location-state";
import ActivityStream from "../../../components/ActivityStream";
import {BallInCourtForm} from "../../../components/BallInCourtForms";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {SendEmailDialogForm} from "../../../components/EmailForms";
import ExternalLinks from "../../../components/ExternalLinks";
import Followers from "../../../components/Followers";
import BlockUI from "../../../components/GlobalLoaders";
import {ProjectGroupSelectDialogForm} from "../../../components/GroupForms";
import {LegacyUILink, MuiNavLink, PortalLink, ProjectAuditLink} from "../../../components/Links";
import MailLog from "../../../components/MailLog";
import PDFAttachmentsPaperPanel from "../../../components/PDFAttachments";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import useWaffle from "../../../hooks/useWaffle";
import {makeNovoClasses} from "../../../theme";
import {RFICustomDataForm, RFIResponseCreateForm, RFIResponseUpdateDialogForm, RFIUpdateForm} from "./RFIForms";
import RFIResponses from "./RFIResponses";

const RFIDetail = (props) => {
  const {project} = props;
  const {rfiId} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const [selectedTab, setSelectedTab] = useQueryState("tab", "detail");
  const [activeRFIResponse, setActiveRFIResponse] = React.useState({} as any);
  const [showUpdateRFIResponseDialog, setShowUpdateRFIResponseDialog] = React.useState(false);
  const [showEmailLinkDialog, setShowEmailLinkDialog] = React.useState(false);
  const [showSelectGroupDialog, setShowSelectGroupDialog] = React.useState(false);
  const [deleteRFIResponseConfirmationIsOpen, setDeleteRFIResponseConfirmationIsOpen] = React.useState(false);
  const [markRFIResponseAsFinalAnswerConfirmationIsOpen, setMarkRFIResponseAsFinalAnswerConfirmationIsOpen] =
    React.useState(false);
  const [initialValuesHack, setInitialValuesHack] = React.useState(Math.random());
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const novoClasses = makeNovoClasses();
  const waffle = useWaffle();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const [rfiInitialValues, setRFIInitialValues] = React.useState({
    response_required_contacts: [],
    cc_contacts: [],
  });
  const [createResponseInitialValues, setCreateResponseInitialValues] = React.useState({
    author: props.userContact,
  });

  const {
    query: rfiQuery,
    update: updateRFI,
    delete: deleteRFI,
    rpc: rfiRPC,
  } = useSentinelDetailAPI(["projects", project.id, "rfis", parseInt(rfiId)], {
    initialData: {
      drawings: [],
      tags: [],
      spec_section: "",
      responsible_party: null,
      authored_by: null,
      answered_by: null,
    },
  });
  const {query: rfiContactsQuery} = useSentinelListAPI(["projects", project.id, "rfis", parseInt(rfiId), "contacts"], {
    initialData: {
      results: [],
    },
  });
  const rfi = rfiQuery.data;
  const rfiContacts = rfiContactsQuery.data;

  const responseRequiredContacts = rfiContacts.results
    .filter((item) => item.role === "response_required")
    .map((item) => item.contact);
  const ccContacts = rfiContacts.results.filter((item) => item.role === "cc").map((item) => item.contact);

  const {
    query: rfiResponsesQuery,
    create: createRFIResponse,
    update: updateRFIResponse,
    delete: deleteRFIResponse,
    rpc: rfiResponseRPC,
  } = useSentinelListAPI(["projects", project.id, "rfis", rfiId, "responses"], {
    initialData: {
      results: [],
    },
  });
  const rfiResponses = rfiResponsesQuery.data;

  React.useEffect(() => {
    setRFIInitialValues({...rfi, response_required_contacts: responseRequiredContacts, cc_contacts: ccContacts});
  }, [rfiQuery.isFetchedAfterMount, rfiContactsQuery.isFetchedAfterMount, initialValuesHack]);

  const onUpdateRFI = React.useCallback((values) => {
    setRFIInitialValues(values);
    updateRFI.mutateAsync(values).then((rfi) => {
      setRFIInitialValues((lastState) => {
        return {...lastState, ...rfi};
      });
    });
  }, []);

  const onRFIResponsesUpdate = React.useCallback((values) => {
    values["author_id"] = values.author.id;
    updateRFIResponse.mutateAsync(values);
  }, []);

  const onCreateRFIResponse = React.useCallback((values, form) => {
    values["author_id"] = values.author.id;
    createRFIResponse.mutateAsync(values).then(() => {
      form.restart({
        author: values.author,
      });
      // form.reset({
      //   author: values.author,
      // });
    });
  }, []);

  const onRFIResponsesDelete = React.useCallback((response) => {
    setActiveRFIResponse(response);
    setDeleteRFIResponseConfirmationIsOpen(true);
  }, []);

  const onRFIResponsesMarkAsFinalAnswer = React.useCallback((response) => {
    setActiveRFIResponse(response);
    setMarkRFIResponseAsFinalAnswerConfirmationIsOpen(true);
  }, []);

  if (!rfiQuery.isFetchedAfterMount || !rfiContactsQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching RFI info..." />;
  }

  return (
    <>
      <Helmet title={rfi.display} />
      {/* <BlockUI show={!rfiQuery.isFetched || !rfiContactsQuery.isFetched} message="Fetching RFI info..." /> */}
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/rfis/`}>
          RFIs
        </MuiNavLink>
        <Typography color="textPrimary">{rfi.display}</Typography>
      </ProjectBreadcrumbs>

      {pageDataQuery.data.currentRevisionURL && (
        <Box mb={2}>
          <Alert severity="info">
            This is a prior revision of this RFI.{" "}
            <MuiNavLink to={`/v2${pageDataQuery.data.currentRevisionURL}`}>Click here</MuiNavLink> to go to the current
            revision.
          </Alert>
        </Box>
      )}

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar
            position="static"
            color="default"
            // elevation={0}
          >
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Detail" value="detail" className={novoClasses.smallTab} />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Responses
                    <TabCountChip
                      isLoading={!rfiResponsesQuery.isFetchedAfterMount}
                      count={rfiResponsesQuery.data.count}
                    />
                    {/*
                    {rfiResponsesQuery.data?.count !== undefined ? (
                      <Box ml={1}>
                        <Chip size="small" label={rfiResponsesQuery.data?.count} />
                      </Box>
                    ) : null} */}
                  </Box>
                }
                value="responses"
                className={novoClasses.smallTab}
              />
              <Tab label="Ball In Court" value="ballInCourt" className={novoClasses.smallTab} />
              <Tab
                label="External Links"
                value="externalLinks"
                className={novoClasses.smallTab}
                disabled={rfi.is_draft}
              />
              <Tab label="Followers" value="followers" className={novoClasses.smallTab} />
              <Tab label="Custom Data" value="customData" className={novoClasses.smallTab} />
              <Tab label="Logs" value="logs" className={novoClasses.smallTab} />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />

          <PaperPanel.Body>
            <TabPanel value="detail">
              <PaperPanel.TabHeader>
                <PaperPanel.Header.Title>
                  <StatusLabel status={rfi.status_display} /> {rfi.display}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.RevisionsButtons
                      url={`projects/${project.id}/rfis/${rfiId}/revisions/`}
                      obj={rfi}
                      onCreateRevision={() => {
                        blockUI.blockUI("Creating new revision...");
                        rfiRPC
                          .mutateAsync({action: "revisions"})
                          .then((response) => {
                            blockUI.unblockUI();
                            navigate(`/v2/projects/${project.id}/rfis/${response.id}/`);
                          })
                          .catch(() => blockUI.unblockUI());
                      }}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButtons
                      // pdfURL={`/reports2/rfis/${rfiId}/`}
                      buildReportURL={`/reports2/projects/${project.id}/rfis/detail/filter/?pk=${rfiId}&display_responses=true`}
                    >
                      <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/rfis/${rfiId}/`}>
                        Without Responses
                      </PaperPanel.Header.Menu.PDFMenuItem>
                      <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/rfis/${rfiId}/?display_responses=true`}>
                        With Responses
                      </PaperPanel.Header.Menu.PDFMenuItem>
                    </PaperPanel.Header.PDFButtons>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/rfis/rfi/${rfiId}/`}
                      uploadURL={`/projects/${project.id}/rfis/${rfiId}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  {project.fieldwire_project_id && (
                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.FieldwireUploadButton
                        uploadURL={`/projects/${project.id}/rfis/${rfiId}/upload-to-fieldwire/`}
                        fieldwireFileId={rfi.fieldwire_file_id}
                        onUploadSuccess={() => {
                          rfiQuery.refetch();
                        }}
                      />
                    </PaperPanel.Header.Action>
                  )}

                  <PaperPanel.Header.Menu border>
                    {(popupState) => (
                      <div>
                        <PaperPanel.Header.Menu.MenuItem
                          href={`/v2/projects/${project.id}/change-order-wizard/pco/?rfi=${rfiId}`}
                          target="_blank"
                          component={Link}
                        >
                          Create PCO
                        </PaperPanel.Header.Menu.MenuItem>
                      </div>
                    )}
                  </PaperPanel.Header.Menu>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <RFIUpdateForm
                initialValues={rfiInitialValues}
                projectId={project.id}
                onSubmit={onUpdateRFI}
                contactChildren={
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button size="small" startIcon={<GroupIcon />} onClick={() => setShowSelectGroupDialog(true)}>
                        Groups
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        href={`mailto:${responseRequiredContacts
                          .map((contact) => contact.email)
                          .join(";")}?cc=${ccContacts.map((contact) => contact.email).join(";")}&subject=${
                          rfi.display
                        }`}
                        size="small"
                        startIcon={<EmailIcon />}
                      >
                        Send Email
                      </Button>
                    </Grid>
                    <Grid item>
                      <Tooltip title={rfi.is_draft ? "Is Draft" : ""}>
                        <span>
                          <Button
                            size="small"
                            startIcon={<LinkIcon />}
                            disabled={rfi.is_draft}
                            onClick={() => setShowEmailLinkDialog(true)}
                          >
                            Email Link
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={rfi.is_draft ? "Is Draft" : ""}>
                        <span>
                          <Button
                            size="small"
                            startIcon={<OpenInNewIcon />}
                            disabled={rfi.is_draft}
                            href={pageDataQuery.data.previewExternalResponseLink}
                            target="_blank"
                          >
                            Preview Link
                          </Button>
                          {/* {pageDataQuery.data.previewExternalResponseLinkV2 && (
                            <Button
                              size="small"
                              startIcon={<OpenInNewIcon />}
                              disabled={rfi.is_draft}
                              href={pageDataQuery.data.previewExternalResponseLinkV2}
                              target="_blank"
                            >
                              Preview Link v2
                            </Button>
                          )} */}
                        </span>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Button size="small" startIcon={<GroupAddIcon />} href={`/projects/${project.id}/groups/`}>
                        Create Group
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              <Box ml={-3} mr={-3} mb={-3}>
                <PDFAttachmentsPaperPanel
                  url={`projects/${project.id}/rfis/${rfiId}/pdf-attachments/`}
                  uploadURL={`/api/v1/projects/${project.id}/rfis/${rfiId}/pdf-attachments/upload/`}
                  contentType="rfis"
                  model="rfipdfattachment"
                />
              </Box>
            </TabPanel>
            <TabPanel value="responses">
              <PaperPanel.TabHeader isLoading={rfiResponsesQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faComments} /> {rfi.display}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      startIcon={<FormatListNumberedIcon />}
                      onClick={() => {
                        blockUI.blockUI("Renumbering responses...");
                        rfiRPC
                          .mutateAsync({action: "renumber-responses"})
                          .then(() => {
                            blockUI.unblockUI();
                            rfiResponsesQuery.refetch();
                          })
                          .catch(() => blockUI.unblockUI());
                      }}
                    >
                      Renumber Responses
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={rfiResponsesQuery.isFetching}
                      onClick={() => rfiResponsesQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <RFIResponses
                rfiResponses={rfiResponses}
                onUpdate={(response) => {
                  setActiveRFIResponse(response);
                  setShowUpdateRFIResponseDialog(true);
                }}
                onDelete={onRFIResponsesDelete}
                onMarkAsFinalAnswer={onRFIResponsesMarkAsFinalAnswer}
              />
              <Box mb={2} />
              <RFIResponseCreateForm
                projectId={project.id}
                onSubmit={onCreateRFIResponse}
                initialValues={createResponseInitialValues}
              />
              {/* <InnerFooter>
                <RFIResponseCreateForm
                  projectId={project.id}
                  onSubmit={onCreateRFIResponse}
                  initialValues={{author: props.userContact}}
                />
              </InnerFooter> */}
            </TabPanel>

            <TabPanel value="ballInCourt">
              <BallInCourtForm projectId={project.id} url={`ball-in-court/rfis/rfi/${rfiId}/`} />
            </TabPanel>

            <TabPanel value="externalLinks">
              <Box mx={-3} mt={-4}>
                <ExternalLinks url={`projects/${project.id}/rfis/${rfiId}/external-links/`} />
              </Box>
            </TabPanel>

            <TabPanel value="followers">
              <Box mx={-3} mt={-4}>
                <Followers url={`followers/rfis/rfi/${rfiId}/`} />
              </Box>
            </TabPanel>

            <TabPanel value="customData">
              <RFICustomDataForm initialValues={rfiInitialValues} onSubmit={onUpdateRFI} />
            </TabPanel>

            <TabPanel value="logs">
              <Box mx={-3} mt={-4}>
                <ActivityStream url={`actstream/rfis/rfi/${rfiId}/`} />
                <Box mb={2} />
                <MailLog url={`mailer/rfis/rfi/${rfiId}/log/`} />
              </Box>
            </TabPanel>
          </PaperPanel.Body>
        </PaperPanel>
      </TabContext>

      <CreatedByModifiedBy obj={rfi} mt={2} />

      <ProjectAuditLink projectId={project.id} app="rfis" model="rfi" id={rfiId} mt={1} />
      <PortalLink href={`/projects/${project.id}/rfis/${rfiId}/`} />
      <LegacyUILink href={`/projects/${project.id}/rfis/${rfiId}/legacy/`} />

      <RFIResponseUpdateDialogForm
        projectId={project.id}
        isOpen={showUpdateRFIResponseDialog}
        handleClose={() => {
          setActiveRFIResponse({});
          setShowUpdateRFIResponseDialog(false);
        }}
        initialValues={activeRFIResponse}
        onSubmit={(values) => {
          setShowUpdateRFIResponseDialog(false);
          onRFIResponsesUpdate(values);
        }}
      />

      <SendEmailDialogForm
        title="Email RFI Link"
        projectId={project.id}
        isOpen={showEmailLinkDialog}
        handleClose={() => {
          setShowEmailLinkDialog(false);
        }}
        initialValues={{
          to_contacts: responseRequiredContacts,
          cc_contacts: ccContacts,
          comments: "",
        }}
        onSubmit={(values) => {
          setShowEmailLinkDialog(false);
          blockUI.blockUI();
          rfiRPC
            .mutateAsync({action: "email-links", data: values})
            .then((response) => {
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {variant: "success"});
            })
            .catch(() => blockUI.unblockUI());
        }}
      />

      <ProjectGroupSelectDialogForm
        projectId={project.id}
        isOpen={showSelectGroupDialog}
        handleClose={() => {
          setShowSelectGroupDialog(false);
        }}
        onSubmit={(values) => {
          setShowSelectGroupDialog(false);
          blockUI.blockUI("Adding group members...");
          rfiRPC
            .mutateAsync({action: "add-group", data: {group_id: values.group?.id}})
            .then(() => {
              rfiContactsQuery
                .refetch()
                .then(() => {
                  blockUI.unblockUI();
                  setInitialValuesHack(Math.random());
                })
                .catch(() => blockUI.unblockUI());
            })
            .catch(() => blockUI.unblockUI());
        }}
      />

      <ConfirmationDialog
        isOpen={deleteRFIResponseConfirmationIsOpen}
        onApprove={() => {
          setDeleteRFIResponseConfirmationIsOpen(false);
          blockUI.blockUI();
          deleteRFIResponse
            .mutateAsync(activeRFIResponse.id)
            .then(() => {
              blockUI.unblockUI();
              setActiveRFIResponse({});
            })
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setDeleteRFIResponseConfirmationIsOpen(false);
        }}
      >
        You want to delete the selected response {activeRFIResponse?.number_display}.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={markRFIResponseAsFinalAnswerConfirmationIsOpen}
        onApprove={() => {
          setMarkRFIResponseAsFinalAnswerConfirmationIsOpen(false);
          blockUI.blockUI();
          rfiResponseRPC
            .mutateAsync({action: `${activeRFIResponse.id}/mark-as-final-answer`})
            .then(() => {
              rfiQuery.refetch().then(() => {
                blockUI.unblockUI();
                setInitialValuesHack(Math.random());
                setSelectedTab("detail");
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setMarkRFIResponseAsFinalAnswerConfirmationIsOpen(false);
        }}
      >
        Are you sure you want to mark the response as the final answer? This will copy the response and author to the
        final response fields. This cannot be undone.
      </ConfirmationDialog>
      {/* <Typography variant="h2">RFI query</Typography>
      <pre>{JSON.stringify(rfiQuery, null, 2)}</pre>
      <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};
export default RFIDetail;
