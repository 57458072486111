import {Box, Grid, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import moment from "moment";
import {FieldArray} from "react-final-form-arrays";
import {DeleteButton} from "../../../components/Buttons";
import {DatePickerMui, MaskedInputMui, SimpleSelect, TextFieldMui} from "../../../components/forms/Fields";
import JobsiteSafetyForm, {AddButton} from "./JobsiteFormComponents";
import {YesNARadios} from "./JobsiteFormFields";
import useBuildField from "./useBuildField";
import {buildQuestionObjects} from "./utils";
import {validateEmailAddress} from "../../../utils/validators";

export const HotWorkPermit = (props) => {
  const {safetyForm, internalDetailView} = props;
  const isSpanish = !internalDetailView && safetyForm?.twilio_contact?.language === "SP";
  const isReadOnly = internalDetailView || safetyForm.is_closed;
  const buildField = useBuildField(isSpanish, isReadOnly);

  const validateDateFields = (value, values, meta) => {
    const requiredMsg = isSpanish ? "Requerido" : "Required";
    const startDateMsg = isSpanish ? "Fecha de inicio requerida" : "Start Date Required";
    const expirationBeforeStartMsg = isSpanish
      ? "La fecha de vencimiento debe ser posterior a la fecha de inicio"
      : "Expiration Date must be after Start Date";
    const maxPermitLengthMsg = isSpanish
      ? "La longitud máxima del permiso es de 7 días"
      : "Max Permit Length Is 7 Days";
    const invalidDateMsg = isSpanish ? "Fecha no válida" : "Invalid Date";

    if (!value) return requiredMsg;

    const expirationDate = moment(value);
    if (!expirationDate.isValid()) return invalidDateMsg;

    const startDate = values?.general?.start_date;
    if (!startDate) return startDateMsg;

    const diffInDays = expirationDate.diff(startDate, "days");
    if (expirationDate.isBefore(startDate)) return expirationBeforeStartMsg;
    if (diffInDays > 6) return maxPermitLengthMsg;
  };

  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return isSpanish ? "Requerida" : "Required";
    }
    if (!validateEmailAddress(value)) {
      return isSpanish ? "La dirección de email no es válida" : "Not a valid email address";
    }
  };

  const hotWorkTypes = [
    {label: isSpanish ? "Soldadura fuerte" : "Brazing", value: "Brazing"},
    {label: isSpanish ? "Corte" : "Cutting", value: "Cutting"},
    {label: isSpanish ? "Rectificado" : "Grinding", value: "Grinding"},
    {label: isSpanish ? "Soldadura blanda" : "Soldering", value: "Soldering"},
    {label: isSpanish ? "Descongelamiento de tuberías" : "Thawing Pipe", value: "Thawing Pipe"},
    {label: isSpanish ? "Techado con soplete" : "Torch Applied Roofing", value: "Torch Applied Roofing"},
    {label: isSpanish ? "Uso de sierra de corte" : "Chop Saw Use", value: "Chop Saw Use"},
    {label: isSpanish ? "Soldadura" : "Welding", value: "Welding"},
    {label: isSpanish ? "Otro" : "Other", value: "Other"},
  ];

  const requiredQuestionsCallback = (field) => {
    if (field?.passRequired) {
      field.passRequiredMessage = isSpanish
        ? "Requerido para trabajos en caliente: el permiso no se emitirá hasta que esté completo."
        : "Required for Hot Work - Permit will not be issued until complete.";
    }
    return field;
  };

  const [fireSystemsFields, areaPreparationFields, enclosedSpacesFields, ppeFields, fireWatchFields] = [
    "fire_systems",
    "area_preparation",
    "enclosed_spaces",
    "ppe",
    "fire_watch",
  ].map((name) => buildQuestionObjects(safetyForm, name, YesNARadios, requiredQuestionsCallback));

  const wallsCeilingsFields = buildQuestionObjects(
    safetyForm,
    "walls_ceilings_upper_decks",
    YesNARadios,
    requiredQuestionsCallback
  );

  const generalFields = buildQuestionObjects(safetyForm, "general", TextFieldMui, (field) => {
    if (field.name === "type") {
      field.Component = SimpleSelect;
      field.options = hotWorkTypes;
      field.validateFields = ["general.expiration_date", "general.start_date", "general.type"];
    }
  });

  return (
    <JobsiteSafetyForm
      isSpanish={isSpanish}
      title={isSpanish ? "Permiso de trabajo caliente" : "Hot Work Permit"}
      initialValues={{email: safetyForm?.twilio_contact?.email}}
      {...props}
    >
      {(form, values) => {
        if (values?.operators === undefined) {
          form.mutators.push("operators", undefined);
        }
        if (values?.fire_watch_contacts === undefined) {
          form.mutators.push("fire_watch_contacts", undefined);
        }

        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Box textAlign="center">
                  <img src="/static/img/stop_sign.png" alt="Stop Sign" width={150} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Alert severity="error">
                  <AlertTitle>
                    {isSpanish
                      ? "¡Evite el trabajo en caliente cuando sea posible! El trabajo en caliente solo se permite como último recurso."
                      : "Avoid Hot Work when possible! Hot Work is only permitted as a last resort."}
                  </AlertTitle>

                  {isSpanish
                    ? "Considere usar un método o ubicación alternativa para realizar dicho trabajo. Se requiere este Permiso de Trabajo en Caliente para todas las operaciones temporales que involucren llamas abiertas o produzcan calor y/o chispas. El Trabajo en Caliente incluye, entre otros, soldadura fuerte, corte, rectificado, soldadura, descongelación de tuberías, colocación de techos con soplete, uso de sierras de corte y soldadura. Este permiso debe completarse con firmas de aprobación para considerarse válido. Donde se realice soldadura en el lugar, se requiere aprobación adicional del Departamento de Seguridad de NOVO."
                    : "Consider using an alternative method or location to perform such work. This Hot Work Permit is required for all temporary operations involving open flames or producing heat and/or sparks. Hot Work includes, but is not limited to brazing, cutting, grinding, soldering, thawing pipe, torch applied roofing, chop saw use and welding. This permit must be completed with approval signatures to be considered valid. Where on-site welding is performed, additional approval from the NOVO Safety Department is required."}
                </Alert>
              </Grid>
            </Grid>

            <Box mb={2} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextFieldMui
                  name="email"
                  label="Email"
                  type="email"
                  required
                  fieldProps={{
                    validate: validateEmail,
                  }}
                  disabled={isReadOnly}
                  helperText={
                    isSpanish
                      ? "Se utiliza para la comunicación sobre permisos de trabajo en caliente: verifique"
                      : "Used For Hot Work Permit Communication - Please Verify"
                  }
                />
              </Grid>
              {generalFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("general", field)}
                </Grid>
              ))}
              <Grid item xs={12} sm={4}>
                <DatePickerMui
                  name="general.start_date"
                  label={isSpanish ? "Fecha de Inicio del Permiso" : "Permit Start Date"}
                  required
                  fieldProps={{validate: validateDateFields}}
                  validateFields={["general.expiration_date", "general.start_date", "general.type"]}
                  // minDate={moment(
                  //   values.general?.type === "Welding" ? moment(safetyForm.date).add(1, "days") : safetyForm.date
                  // )}
                  minDate={moment(safetyForm.date)}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerMui
                  name="general.expiration_date"
                  label={isSpanish ? "Fecha de Vencimiento del Permiso" : "Permit Expiration Date"}
                  required
                  helperText={
                    isSpanish
                      ? "La duración máxima del permiso es de una semana."
                      : "Maximum duration of permit is one week"
                  }
                  fieldProps={{validate: validateDateFields}}
                  validateFields={["general.expiration_date", "general.start_date", "general.type"]}
                  disabled={isReadOnly}
                  minDate={moment(values?.general?.start_date ?? safetyForm.date)}
                  maxDate={moment(values?.general?.start_date ?? safetyForm.date).add(6, "days")}
                />
              </Grid>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Operador(es) de Trabajos Calientes:` : `Hot Work Operator(s):`}
                    </Typography>
                  </Box>
                </Grid>
                <FieldArray name="operators">
                  {({fields}) => {
                    return (
                      <>
                        {fields.map((name, index) => {
                          return (
                            <NameNumberFieldsItem
                              isSpanish={isSpanish}
                              namespace={name}
                              key={index}
                              index={index}
                              onDelete={fields.length > 1 && (() => fields.remove(index))}
                              disabled={isReadOnly}
                            />
                          );
                        })}
                        <Grid item xs={12}>
                          {!isReadOnly && (
                            <AddButton onClick={() => form.mutators.push("operators", undefined)}>
                              Add Contact
                            </AddButton>
                          )}
                        </Grid>
                      </>
                    );
                  }}
                </FieldArray>

                <Grid item xs={12}>
                  {isSpanish
                    ? "Persona que realiza Trabajos en Caliente: Revisar el Permiso de Trabajo en Caliente. Examine el área y asegúrese de que se hayan tomado las siguientes precauciones. Registrar el tiempo iniciado y mostrar el permiso en el área de trabajo caliente. Después de completar el trabajo en caliente, registre el tiempo y deje el permiso a la vista para la vigilancia contra incendios."
                    : "Person performing Hot Work: Review Hot Work Permit. Examine area and ensure the below precautions have been taken. Record time started and display permit at the hot work area. After hot work is completed, record time and leave permit displayed for fire watch."}
                </Grid>
              </Grid>

              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Vigilante del fuego:` : `Fire Watch:`}</Typography>
                  </Box>
                </Grid>
                <FieldArray name="fire_watch_contacts">
                  {({fields}) => {
                    return (
                      <>
                        {fields.map((name, index) => {
                          return (
                            <NameNumberFieldsItem
                              isSpanish={isSpanish}
                              namespace={name}
                              key={index}
                              index={index}
                              onDelete={fields.length > 1 && (() => fields.remove(index))}
                              disabled={isReadOnly}
                            />
                          );
                        })}
                        <Grid item xs={12}>
                          {!isReadOnly && (
                            <AddButton onClick={() => form.mutators.push("fire_watch_contacts", undefined)}>
                              {isSpanish ? `Agregar contacto` : `Add Contact`}
                            </AddButton>
                          )}
                        </Grid>
                      </>
                    );
                  }}
                </FieldArray>

                <Grid item xs={12}>
                  {isSpanish
                    ? "Persona que realiza la vigilancia contra incendios: área de vigilancia durante el trabajo en caliente y después de finalizar el trabajo. Antes de abandonar el área, realice una inspección final, inicialice y notifique al supervisor de NOVO en el sitio."
                    : "Person performing Fire Watch: Watch area during hot work and after work completion. Prior to leaving the area, perform final inspection, initial and notify the NOVO Supervisor onsite."}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h4">
                    {isSpanish
                      ? `Lista de Verificación de Prevención y Protección Contra Incendios`
                      : `Fire Prevention and Protection Checklist`}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">{isSpanish ? `Sistemas contra incendios` : `Fire Systems`}</Typography>
                </Box>
              </Grid>

              {fireSystemsFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("fire_systems", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">{isSpanish ? `Preparación del área` : `Area Preparation`}</Typography>
                </Box>
              </Grid>

              {areaPreparationFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("area_preparation", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">
                    {isSpanish ? `Trabajar en equipos o espacios cerrados.` : `Work on enclosed equipment or spaces`}
                  </Typography>
                </Box>
              </Grid>

              {enclosedSpacesFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("enclosed_spaces", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">
                    {isSpanish ? `Equipo de protección personal` : `Personal Protective Equipment`}
                  </Typography>
                </Box>
              </Grid>

              {ppeFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("ppe", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">
                    {isSpanish
                      ? `Trabajo en caliente en paredes, techos o pisos/cubiertas superiores`
                      : `Hot Work on Walls, Ceilings or Upper Decks/Floors`}
                  </Typography>
                </Box>
              </Grid>

              {wallsCeilingsFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("walls_ceilings_upper_decks", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">
                    {isSpanish
                      ? `Vigilancia de incendios/monitoreo de áreas de trabajo calientes`
                      : `Fire Watch/Hot Work Area Monitoring`}
                  </Typography>
                </Box>
              </Grid>

              {fireWatchFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("fire_watch", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <TextFieldMui
                  name="fire_watch.other_precautions"
                  label="Other Precautions Taken"
                  minRows={3}
                  multiline
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </>
        );
      }}
    </JobsiteSafetyForm>
  );
};

const NameNumberFieldsItem = (props) => {
  const {isSpanish, namespace, onDelete, index, disabled} = props;

  return (
    <>
      <Grid item xs={12}>
        {/* <Box textAlign="center">
          <Typography variant="h6">Operator #{index + 1}:</Typography>
        </Box> */}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name={`${namespace}.name`} label={isSpanish ? "Nombre" : "Name"} required disabled={disabled} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaskedInputMui
          name={`${namespace}.phone`}
          label={isSpanish ? "Número de Contacto" : "Contact Number"}
          mask="(999) 999-9999"
          required
          disabled={disabled}
        />
      </Grid>
      {!disabled && onDelete && (
        <Grid item xs={12}>
          <DeleteButton fullWidth variant="outlined" disableElevation onClick={() => onDelete()}>
            {isSpanish ? `Borrar contacto` : `Delete Contact`}
          </DeleteButton>
        </Grid>
      )}
    </>
  );
};
