import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Tooltip,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import classnames from "classnames";
import {sumBy} from "lodash";
import {Switches as FinalSwitches} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router";
import {useQueryState} from "use-location-state";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {useSelectIds} from "../../../../js/hooks";
import {Currency} from "../../../components/Accounting";
import statusOptions from "../../../components/forms/choices/pccoStatuses.json";
import {
  DatePickerMui,
  NovoRestrictedSignersAutoselectMui,
  NumberFieldMui,
  ProjectContactAutoselectMui,
  SelectStatus,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {StatusIcon} from "../../../components/Icons";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink} from "../../../components/Links";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";

export const PCCOSelectStatus = (props: {oco: any; isDirty?: boolean; [rest: string]: any}) => {
  const {oco, isDirty, ...rest} = props;
  if (isDirty) {
    return <SelectStatus options={statusOptions} name="status" label="Status" {...rest} />;
  }
  if (["submitted"].includes(oco.status)) {
    return (
      <>
        <StatusIcon status={oco.status} type="changeOrder" /> {oco.status_display}
        <FormHelperText>This change order was submitted to accounting on {oco.submitted_date}</FormHelperText>
      </>
    );
  }
  return <SelectStatus options={statusOptions} name="status" label="Status" {...rest} />;
};

export const PCCOGeneralInfoForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  restrictNovoSigners?: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isReadOnly, onDeleteOCO, restrictNovoSigners = false, ...rest} = props;
  const {pathname} = useLocation();

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, dirtyFields, ...rest} = props;

          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <NumberFieldMui
                        name="item_number"
                        label="Item Number"
                        helperText="# per Contract"
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberFieldMui name="number" label="Number" helperText="# In Project" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <SelectStatus name="status" label="Status" disabled={isReadOnly} options={statusOptions} /> */}

                      <PCCOSelectStatus
                        name="status"
                        label="Status"
                        oco={values}
                        isDirty={Boolean(dirtyFields.status)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextFieldMui name="description" label="Description" disabled={isReadOnly} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {restrictNovoSigners ? (
                        <NovoRestrictedSignersAutoselectMui
                          name="from_signed_by"
                          label="From Signed By"
                          projectId={projectId}
                          disabled={isReadOnly}
                        />
                      ) : (
                        <ProjectContactAutoselectMui
                          name="from_signed_by"
                          label="From Signed By"
                          projectId={projectId}
                          disabled={isReadOnly}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectContactAutoselectMui
                        name="to_signed_by"
                        label="To Signed By"
                        helperText={values.to_company_signator ? "Set By Company Signator" : ""}
                        projectId={projectId}
                        disabled={isReadOnly || values.to_company_signator}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui name="date" label="Date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui name="executed_date" label="Executed Date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FinalSwitches name="is_draft" data={{label: "Draft", value: true}} disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="notes" label="Notes" disabled={isReadOnly} />
                    </Grid>
                  </Grid>
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <Tooltip title={values.is_closed ? values.is_closed_reason : ""}>
                        <span>
                          <FormActions.DeleteButton
                            disabled={values.is_closed}
                            component={MuiNavLink}
                            underline="none"
                            to={`/v2/projects/${projectId}/delete/ccos/pcco/${values.id}/?next=/v2/projects/${projectId}/pccos/&cancel=/v2/projects/${projectId}/pccos/${values.id}/`}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

interface OCO {
  id: number;
  display: string;
  commitment: {
    url: string;
    display: string;
    current_value: number;
  };
}

export const PCCOLinkableSelectDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onChangeSelectedItem: (selected) => void;
  projectId: number;
  oco: OCO;
  linkableItems: any[];
}) => {
  const {handleClose, isOpen, onChangeSelectedItem, projectId, oco, linkableItems = [], ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const novoClasses = makeNovoClasses();

  const allItemIds = linkableItems.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  React.useEffect(() => {
    removeAllSelectedItemIds();
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">Link Additional Change Order Items to {oco.display}</DialogTitle>
        <DialogContent>
          <Box mb={3} />
          {linkableItems.length > 0 ? (
            <ReactTableMui
              size="small"
              className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
              showFooter={true}
              rightAlignColumns={["budget_rom", "budget_approved_amount", "budget_proposed_amount"]}
              columns={[
                {
                  accessor: "id",
                  disableSortBy: true,
                  Cell: ({value, row}) => (
                    <ReactTableMuiCheckboxSelector
                      key={row.original.id}
                      id={row.original.id}
                      selected={selectedItemIds.has(row.original.id)}
                      onAddSelected={addSelectedItemId}
                      onRemoveSelected={removeSelectedItemId}
                      onClick={(event) => event.preventDefault()}
                    />
                  ),
                },
                {
                  Header: "Change Order",
                  accessor: "display",
                  Cell: ({value, row}) => (
                    <>
                      <StatusIcon status={row.original.status_display} /> {value}
                    </>
                  ),
                },
                {
                  Header: "Items",
                  accessor: "pcoitem_count",
                },
                // {
                //   Header: "Ref",
                //   accessor: "budget.reference",
                // },
                {
                  Header: "ROM",
                  accessor: "budget_rom",
                  Cell: ({value, row}) => <Currency number={value} precision={2} />,
                },
                {
                  Header: "Proposed",
                  accessor: "budget_proposed_amount",
                  Cell: ({value, row}) => <Currency number={value} precision={2} />,
                },

                {
                  Header: "Budget Approved",
                  accessor: "budget_approved_amount",
                  Footer: () => (
                    <>
                      Total:{" "}
                      <Currency
                        number={sumBy(
                          linkableItems.filter((item) => selectedItemIds.has(item.id)),
                          "budget_approved_amount"
                        )}
                        precision={2}
                      />
                    </>
                  ),
                  Cell: ({value, row}) => <Currency number={value} precision={2} />,
                },
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "display",
                    asc: true,
                  },
                ],
              }}
              data={linkableItems}
              getRowProps={(row) => {
                return {
                  onClick: () => {
                    selectedItemIds.has(row.original.id)
                      ? removeSelectedItemId(row.original.id)
                      : addSelectedItemId(row.original.id);
                  },
                };
              }}
              {...rest}
            />
          ) : (
            <Alert severity="info">No Linkable Items Found</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <FormActions.Right>
            <Button
              onClick={() => {
                const corIds = Array.from(selectedItemIds);
                onChangeSelectedItem(corIds);
              }}
              variant="contained"
              color="primary"
              disabled={selectedItemIds.size < 1}
            >
              Add To OCO
            </Button>
          </FormActions.Right>
          <FormActions.CancelButton onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};
