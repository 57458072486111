import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid} from "@material-ui/core";
import moment from "moment";
import {Checkboxes} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {DateTimePickerMui, TextFieldMui, TinyMCEMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";

const MeetingFormCommonFields = (props) => {
  const {values, isAgenda, isReadOnly, isNew, onChangeStartDate, children, ...rest} = props;
  return (
    <>
      <Grid item sm={6} xs={12}>
        {!isNew ? (
          <TextFieldMui name="location" label="Location" autoFocus disabled={isReadOnly} />
        ) : (
          <TextFieldMui name="meeting_name" label="Meeting Name" autoFocus disabled={isReadOnly} />
        )}
      </Grid>
      <Grid item sm={6} xs={12}>
        <Checkboxes name="is_private" data={{label: "Private", value: false}} disabled={isReadOnly} />
        <Checkboxes name="is_draft" data={{label: "Draft", value: false}} disabled={isReadOnly} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <DateTimePickerMui
          name="start"
          label="Start"
          helperText={`${moment.tz.guess()}`}
          //   onChange={(value: moment.Moment) => {
          //     form.batch(() => {
          //       form.change("finish", value.add(1, "hour"));
          //     });
          //   }}
          disabled={isReadOnly}
          fieldProps={{
            validate: onChangeStartDate,
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <DateTimePickerMui name="finish" label="Finish" disabled={isReadOnly} />
      </Grid>
      {children}

      <Grid item xs={12}>
        <TinyMCEMui name="overview" label="Overview" disabled={isReadOnly} />
      </Grid>
    </>
  );
};

export const CreateMeetingSeriesForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
}) => {
  const {onSubmit, handleClose, isOpen, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            const onChangeStartDate = (value, values, field) => {
              if (!moment(value).isValid()) {
                return "Invalid Date";
              }
              if (value) {
                form.change("finish", moment(value).add(1, "h"));
              }
            };
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Create New Meeting Series</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <MeetingFormCommonFields values={values} isNew onChangeStartDate={onChangeStartDate} />
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const RenameMeetingSeriesForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, handleClose, isOpen, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Rename Meeting Series</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="meeting_name" label="Meeting Name" autoFocus />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const AgendaDetailForm = (props) => {
  const {projectId, isReadOnly, isNew, onSubmit, isAgenda, onDeleteMeeting, ...rest} = props;
  const {pathname} = useLocation();
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, ...rest} = props;

        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <MeetingFormCommonFields values={values} isAgenda />
                  <Grid item xs={12}>
                    <TinyMCEMui name="milestones" label="Milestones" />
                  </Grid>
                </Grid>

                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />

                    <FormActions.DeleteButton onClick={() => onDeleteMeeting()} disabled={isReadOnly} />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const MinutesDetailForm = (props) => {
  const {projectId, isReadOnly, isNew, onSubmit, onDeleteMeeting, ...rest} = props;
  const {pathname} = useLocation();
  const [footerLengthIsValid, setFootertLengthIsValid] = React.useState(true);

  const handleUpdate = (value, editor) => {
    const contents = editor.getContent();
    if (contents.match(/<p>.*<\/p>/gm).length > 5) {
      setFootertLengthIsValid(false);
    } else {
      setFootertLengthIsValid(true);
    }
  };

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, ...rest} = props;

        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <MeetingFormCommonFields values={values}>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerMui name="next_meeting" label="Next Meeting" />
                    </Grid>
                  </MeetingFormCommonFields>

                  <Grid item xs={12}>
                    <TinyMCEMui name="milestones" label="Milestones" disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <TinyMCEMui name="minutes_footer" label="Minutes Footer" onBlur={handleUpdate} />
                    {!footerLengthIsValid && <FormHelperText error>Should be under 5 lines</FormHelperText>}
                  </Grid>
                </Grid>

                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />

                    <Box ml={1} />
                    <FormActions.DeleteButton onClick={() => onDeleteMeeting()} disabled={isReadOnly} />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};
