import {Grid, Link, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {LegacyUILink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {normalizeCostCode} from "../../../utils/string";
import ChangeOrders from "./ChangeOrders";
import JobCostTransactions from "./JobCostTransactions";
import Overview from "./Overview";
import PurchaseOrderAmendments from "./PurchaseOrderAmendments";
import PurchaseOrders from "./PurchaseOrders";
import SubcontractChangeOrders from "./SubcontractChangeOrders";
import Subcontracts from "./Subcontracts";

// costCode.slice(0, 2) + "-" + costCode.slice(2)

const ProjectBudgetCodeDetail = (props) => {
  const {project, ...rest} = props;
  const {projectId, budgetId} = useParams();
  const permissions = usePermissions();

  const {query: budgetQuery} = useSentinelDetailAPI(["projects", parseInt(projectId), "budgets", parseInt(budgetId)], {
    initialData: {},
  });
  const budget = budgetQuery.data;

  const {query: costCodeTransactionsQuery} = useSentinelListAPI(
    `projects/${project.id}/timberline/job-cost/transactions/?ordering=cost_code,transaction_date&page_size=10000&cost_code=${normalizeCostCode(budget?.budget_code)}`,
    {
      initialData: {
        results: [],
      },
      enabled: permissions.can_view_job_cost_reports,
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const costCodeTransactions = costCodeTransactionsQuery.data?.results;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      pcoItems: [],
      // subcontractItems: [],
      subcontracts: [],
      scos: [],
      purchaseOrders: [],
      purchaseOrderAmendments: [],
    },
  });
  const pageData = pageDataQuery.data;
  const pcoItems = pageData.pcoItems;
  // const subcontractItems = pageData.subcontractItems;
  const subcontracts = pageData.subcontracts;
  const scos = pageData.scos;
  const purchaseOrders = pageData.purchaseOrders;
  const purchaseOrderAmendments = pageData.purchaseOrderAmendments;

  return (
    <>
      <Helmet title={`${project.display} - ${budget.display}`} />
      <ProjectBreadcrumbs project={project}>
        <Link color="inherit" href={`/projects/${project.id}/budget/current/`}>
          Current Budget
        </Link>
        <Typography color="textPrimary">{budget.display}</Typography>
      </ProjectBreadcrumbs>
      <PageHeader mb={1}>
        <PageHeader.Left>
          <PageHeader.Title>
            {budget.display} ({budget.allocation_display})
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Overview project={project} budget={budget} isFetching={budgetQuery.isFetching} />
        </Grid>
        <Grid item xs={12}>
          <Subcontracts
            subcontracts={subcontracts}
            project={project}
            budget={budget}
            isFetching={pageDataQuery.isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <SubcontractChangeOrders
            scos={scos}
            project={project}
            budget={budget}
            isFetching={pageDataQuery.isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrders
            purchaseOrders={purchaseOrders}
            project={project}
            budget={budget}
            isFetching={pageDataQuery.isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderAmendments
            purchaseOrderAmendments={purchaseOrderAmendments}
            project={project}
            budget={budget}
            isFetching={pageDataQuery.isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <ChangeOrders pcoItems={pcoItems} project={project} budget={budget} isFetching={pageDataQuery.isFetching} />
        </Grid>
        {permissions.can_view_job_cost_reports && (
          <Grid item xs={12}>
            <JobCostTransactions
              projectId={project.id}
              budgetCode={budget.budget_code}
              transactions={costCodeTransactions}
              isFetching={costCodeTransactionsQuery.isFetching}
            />
          </Grid>
        )}
      </Grid>
      <LegacyUILink href={`/budgets/${budgetId}/legacy/`} mt={2} />
      {/*
      <Typography variant="h2">budgetId: {budgetId}</Typography>
      <Typography variant="h2">budget</Typography>
      <pre>{JSON.stringify(budget, null, 2)}</pre>
      <Typography variant="h2">pageDataQuery</Typography>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
      <Typography variant="h2">props</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre>
      */}
    </>
  );
};

export default ProjectBudgetCodeDetail;
