import {Box, Grid} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {useLocation} from "react-router";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

const TransmittalItemPaperItem = (props: {
  item: any;
  onEdit: () => void;
  onDelete?: () => void;
  onChangeItemSelected: (event, value) => void;
  isSelected: boolean;
  projectId: number;
  [rest: string]: any;
}) => {
  const {item, onEdit, projectId, onChangeItemSelected, isSelected, onDelete, ...rest} = props;
  const {pathname} = useLocation();
  return (
    <PaperItem>
      <PaperItem.Left minWidth={25}>
        <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeItemSelected} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={4}>
            {item.item}
          </Grid>
          <Grid item xs={6} sm={6}>
            {item.description}
          </Grid>
          <Grid item xs={6} sm={2}>
            Qty: {item.quantity}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={70}>
        <Box textAlign="right">
          <Typography2 noWrap type="metadata">
            {item.number_display}
          </Typography2>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} />
        {onDelete && <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDelete} />}
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export default TransmittalItemPaperItem;
