import {
  faFaceZany,
  faFileExcel,
  faFileInvoiceDollar,
  faFilePdf,
  faListAlt,
  faTree,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Helmet} from "react-helmet";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const ProjectReports = (props) => {
  const {project, ...rest} = props;
  const blockUI = useBlockUI();
  const permissions = usePermissions();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });

  const {frozenDate, logoURL, isClosed} = pageDataQuery.data;

  const timberlineJobNumber = project.timberline_job;

  const hideCostByCode = Boolean(frozenDate);
  const hideFrozenCostByCode = !Boolean(frozenDate);
  const hideCostDetail = Boolean(!permissions.can_view_job_cost_reports || frozenDate);
  const hideFrozenCostDetail = Boolean(!permissions.can_view_job_cost_reports || !frozenDate);

  const timberlineReports = [
    {
      reportName: "Cost By Code",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/cost-by-code/`,
        excelURL: `/reports2/projects/${project.id}/timberline/cost-by-code/?export_type=excel`,
      },

      description: `Job costs grouped by cost code.`,
      hidden: hideCostByCode,
    },
    {
      reportName: "Cost By Code",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/cost-by-code-frozen/`,
        excelURL: `/reports2/projects/${project.id}/timberline/cost-by-code-frozen/?export_type=excel`,
      },

      description: `Job costs grouped by cost code.`,
      hidden: hideFrozenCostByCode,
    },
    {
      reportName: "Job Cost Detail",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/job-cost-frozen/`,
        excelURL: `/reports2/projects/${project.id}/timberline/job-cost-frozen/?export_type=excel`,
      },

      description: `Itemized cost by job code.`,
      hidden: hideFrozenCostDetail,
    },
    {
      reportName: "Job Cost Detail",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/job-cost/`,
        excelURL: `/reports2/projects/${project.id}/timberline/job-cost/?export_type=excel`,
      },

      description: `Itemized cost by job code.`,
      hidden: hideCostDetail,
    },
    {
      reportName: "Invoices",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/invoices-by-subcontract/`,
        excelURL: `/reports2/projects/${project.id}/timberline/invoices-by-subcontract/?export_type=excel`,
      },

      description: `Invoices grouped by subcontract.`,
    },
    {
      reportName: "Invoices",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/invoices-by-subcontract/?order_by=budget`,
      },

      description: `Invoices grouped by subcontract. Ordered by budget code.`,
    },
    {
      reportName: "Deposits",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/deposits/`,
        excelURL: `/reports2/projects/${project.id}/timberline/deposits/?export_type=excel`,
      },

      description: (
        <>
          Deposits for this job.{" "}
          <Typography color="error" variant="caption">
            * This report may not be current. *
          </Typography>
        </>
      ),
    },
    {
      reportName: "Aging",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/timberline/aging/`,
        excelURL: `/reports2/projects/${project.id}/timberline/aging/?export_type=excel`,
      },

      description: `Outstanding monies owed for this job.`,
      hidden: isClosed,
    },
  ];

  const accountingReports = [
    {
      reportName: "Budget Revs",
      reportLinks: {
        excelURL: `/reports2/projects/${project.id}/budget-pcco-crosstab/?export_type=excel`,
      },

      description: `This report shows amendments to the budget as a result of prime contract change orders.`,
    },
  ];

  const crazyReports = [
    {
      reportName: "Subcontract Summary With Pending Items",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/subcontracts/with-pending-items/`,
        excelURL: `/reports2/projects/${project.id}/subcontracts/with-pending-items/?export_type=excel`,
      },

      description: `Subcontract summary with pending PCO items with the same budget code.`,
    },
    {
      reportName: "Submittal Ball In Court",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/submittals/packages/ball-in-court/`,
      },

      description: (
        <>
          Ball in court for submittals by contact.{" "}
          <Link
            onClick={() => {
              blockUI.blockUI("Cleaning up Ball In Court...");
              axiosAPI.post(`/projects/${project.id}/submittals/packages/cleanup-ball-in-court/`).then(() => {
                blockUI.unblockUI();
              });
            }}
            underline="always"
            href="#"
          >
            Click here
          </Link>{" "}
          to cleanup BIC for this project.
        </>
      ),
    },
    {
      reportName: "Submittal Item Review Ball In Court",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/submittals/items/ball-in-court/`,
      },

      description: `Ball in court for submittals item reviews by contact.`,
    },
    {
      reportName: "Submittal Ball In Court - By Package (Incomplete Packages)",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/submittals/packages/ball-in-court/by-package/`,
      },

      description: `Ball in court by submittal package (incomplete packages).`,
    },
    {
      reportName: "Submittal Ball In Court - By Package (All Packages)",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/submittals/packages/ball-in-court/by-package/?all=true`,
      },

      description: `Ball in court by submittal package (all packages).`,
    },
    {
      reportName: "RFI Ball In Court",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/rfis/ball-in-court/`,
      },

      description: (
        <>
          Ball in court for submittals by contact.{" "}
          <Link
            onClick={() => {
              blockUI.blockUI("Cleaning up Ball In Court...");
              axiosAPI.post(`/projects/${project.id}/rfis/cleanup-ball-in-court/`).then(() => {
                blockUI.unblockUI();
              });
            }}
            underline="always"
            href="#"
          >
            Click here
          </Link>{" "}
          to cleanup BIC for this project.
        </>
      ),
    },
    {
      reportName: "PCO Crosstab",
      reportLinks: {
        excelURL: `/reports2/projects/${project.id}/pco-crosstab/?export_type=excel`,
      },

      description: `This reports show all Sentinel PCOs by budget code and status`,
    },
    {
      reportName: "PCO Item Export",
      reportLinks: {
        excelURL: `/projects/${project.id}/pcos/items/download/`,
      },

      description: `Export all PCO items`,
    },
    {
      reportName: "COR Crosstab",
      reportLinks: {
        excelURL: `/reports2/projects/${project.id}/cor-crosstab/?export_type=excel`,
      },

      description: `This reports show all Sentinel CORs by budget code and status`,
    },
    {
      reportName: "Detailed Budget Summary Log",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/budget-detailed-summary/`,
        excelURL: `/reports2/projects/${project.id}/budget-detailed-summary/?export_type=excel`,
        buildReportURL: `/reports2/projects/${project.id}/budget-detailed-summary/filter/`,
      },

      description: `This reports shows all budgets and associated PCO items`,
    },
    {
      reportName: "Allowance Log",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/allowances/`,
        excelURL: `/reports2/projects/${project.id}/allowances/?export_type=excel`,
        buildReportURL: `/reports2/projects/${project.id}/budget-detailed-summary/filter/?allocation=allowance`,
      },

      description: `This reports shows all budgets that are allocated to allowance including the associated PCO items`,
    },
    {
      reportName: "Contingency Log",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/contingency-summary/`,
      },

      description: `This reports shows all budgets that have the word "contingency" in the description including the associated PCO items`,
    },
    {
      reportName: "Subcontractor Diversity Summary	",
      reportLinks: {
        pdfURL: `/reports2/projects/${project.id}/directory/subcontractor-diversity/?project_id=${project.id}&logo_url=${logoURL}`,
      },

      description: `This report shows all subcontractors and their diversity attributes`,
    },
  ];

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching report info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Reports`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Project Reports</Typography>
      </ProjectBreadcrumbs>
      {frozenDate && (
        <Box mb={2}>
          <Alert severity="info">
            <>
              <strong>Frozen Project</strong>: This project is closed. Data is as of {frozenDate}.
            </>
          </Alert>
        </Box>
      )}

      {!project.timberline_job && (
        <Box mb={2}>
          <Alert severity="info">This project has not been linked to Timberline.</Alert>
        </Box>
      )}
      <PaperPanel>
        {timberlineJobNumber && (
          <Box mb={2}>
            <ReportTable report={timberlineReports} name="Timberline Reports" icon={faTree} />
          </Box>
        )}
        <Box mb={2}>
          <ReportTable report={accountingReports} name="Accounting Reports" icon={faFileInvoiceDollar} />
        </Box>
        <Box>
          <ReportTable report={crazyReports} name="Crazy Experimental Sentinel Reports" icon={faFaceZany} />
        </Box>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/reports/`} mt={2} />
    </>
  );
};

export default ProjectReports;

const ReportTable = (props) => {
  const {report, name, icon, ...rest} = props;
  return (
    <>
      <PaperPanel.Header>
        <PaperPanel.Header.Title>
          <FontAwesomeIcon icon={icon} /> {name}
        </PaperPanel.Header.Title>
      </PaperPanel.Header>
      <PaperPanel.Body>
        {report
          .filter((row) => !row.hidden)
          .map((row) => {
            return (
              <PaperItem key={row.reportName} {...rest}>
                <PaperItem.Body>
                  <Grid container spacing={1}>
                    <Grid item md={3} xs={12}>
                      <strong>{row.reportName}</strong>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box display="flex">
                        {row.reportLinks.pdfURL && (
                          <Box style={{whiteSpace: "nowrap"}} width="40px">
                            <Link href={row.reportLinks?.pdfURL} target="_blank">
                              <FontAwesomeIcon icon={faFilePdf} fixedWidth />
                              PDF
                            </Link>
                          </Box>
                        )}
                        {row.reportLinks.excelURL && (
                          <Box ml={row.reportLinks.pdfURL ? 1 : 0} style={{whiteSpace: "nowrap"}} width="48px">
                            <Link href={row.reportLinks?.excelURL}>
                              <FontAwesomeIcon icon={faFileExcel} fixedWidth />
                              Excel
                            </Link>
                          </Box>
                        )}
                        {row.reportLinks.buildReportURL && (
                          <Box
                            ml={row.reportLinks.pdfURL || row.reportLinks.excelURL ? 1 : 0}
                            style={{whiteSpace: "nowrap"}}
                            width="52px"
                          >
                            <Link href={row.reportLinks?.buildReportURL}>
                              <FontAwesomeIcon icon={faListAlt} fixedWidth />
                              Build Report
                            </Link>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {row.description}
                    </Grid>
                  </Grid>
                </PaperItem.Body>
              </PaperItem>
            );
          })}
      </PaperPanel.Body>
    </>
  );
};
