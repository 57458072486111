import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import classnames from "classnames";
import {sumBy} from "lodash";
import React from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router";
import {useQueryState} from "use-location-state";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {
  CurrencyFieldMui,
  DatePickerMui,
  NovoRestrictedSignersAutoselectMui,
  NumberFieldMui,
  ProjectContactAutoselectMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {StatusIcon} from "../../../components/Icons";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink} from "../../../components/Links";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../../components/ReactTableMui";
import useWhyDidYouUpdate from "../../../hooks/useWhyDidYouUpdate";
import {makeNovoClasses} from "../../../theme";

export const SCOGeneralInfoForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  restrictNovoSigners?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isReadOnly, onDeleteSCO, isNew, restrictNovoSigners = false, ...rest} = props;
  const {pathname} = useLocation();

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, ...rest} = props;

          const validateNumber = async (value, values, field) => {
            if (!values.item_number) return "Required";
            if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
            const response = await axiosAPI.get(
              `/projects/${projectId}/sccos/validate-number/?number=${values?.number}${
                !isNew ? `&id=${values.id}` : ``
              }`
            );

            if (response.data.error) {
              return response.data.error;
            }
          };

          const validateItemNumber = async (value, values, field) => {
            if (!values.item_number) return "Required";
            if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
            const response = await axiosAPI.get(
              `/projects/${projectId}/sccos/validate-item-number/?subcontract_id=${
                values?.commitment?.id || ""
              }&item_number=${values?.item_number}${!isNew ? `&id=${values.id}` : ``}`
            );

            if (response.data.error) {
              return response.data.error;
            }
          };

          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <NumberFieldMui
                        name="item_number"
                        label="Item Number"
                        helperText="# per Contract"
                        fieldProps={{
                          validate: validateItemNumber,
                        }}
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <NumberFieldMui
                        name="number"
                        label="Number"
                        helperText="# In Project"
                        fieldProps={{
                          validate: validateNumber,
                        }}
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextFieldMui name="description" label="Description" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {restrictNovoSigners ? (
                        <NovoRestrictedSignersAutoselectMui
                          projectId={projectId}
                          name="from_signed_by"
                          label="From Signed By"
                          disabled={isReadOnly}
                        />
                      ) : (
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="from_signed_by"
                          label="From Signed By"
                          disabled={isReadOnly}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectContactAutoselectMui
                        name="to_signed_by"
                        label="To Signed By"
                        helperText={values.to_company_signator ? "Set By Company Signator" : ""}
                        projectId={projectId}
                        disabled={isReadOnly || values.to_company_signator}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePickerMui name="date" label="Date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePickerMui name="executed_date" label="Executed Date" />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="notes" label="Notes" />
                    </Grid>
                  </Grid>
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <Tooltip title={values.is_closed ? values.is_closed_reason : ""}>
                        <span>
                          <FormActions.DeleteButton
                            disabled={values.is_closed}
                            component={MuiNavLink}
                            underline="none"
                            to={`/v2/projects/${projectId}/delete/ccos/scco/${values.id}/?next=/v2/projects/${projectId}/sccos/&cancel=/v2/projects/${projectId}/sccos/${values.id}/`}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const SCOItemDialogForm = (props) => {
  const {onSubmit, isOpen, handleClose, isReadOnly, isReadOnlyReason, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Update Item Cost</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui name="description" label="Description" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12}>
                      <CurrencyFieldMui name="cost" label="Cost" disabled={isReadOnly} helperText={isReadOnlyReason} />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

interface SCO {
  id: number;
  display: string;
  commitment: {
    url: string;
    display: string;
    current_value: number;
  };
}

export const SCOLinkableSelectDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onChangeSelectedItem: (selected) => void;
  projectId: number;
  sco: SCO;
  linkableItems: any[];
}) => {
  const {handleClose, isOpen, onChangeSelectedItem, projectId, sco, linkableItems = [], ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const novoClasses = makeNovoClasses();

  const allItemIds = linkableItems.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  React.useEffect(() => {
    removeAllSelectedItemIds();
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">Link Additional Change Order Items to {sco.display}</DialogTitle>
        <DialogContent>
          {/* <pre>{JSON.stringify(linkableItems, null, 2)}</pre> */}
          {linkableItems.length > 0 ? (
            <ReactTableMui
              size="small"
              className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
              showFooter={true}
              rightAlignColumns={[
                // "budget_cost",
                "budget_approved_amount",
                "cost",
              ]}
              columns={[
                {
                  accessor: "id",
                  disableSortBy: true,
                  Cell: ({value, row}) => (
                    <ReactTableMuiCheckboxSelector
                      key={row.original.id}
                      id={row.original.id}
                      selected={selectedItemIds.has(row.original.id)}
                      onAddSelected={addSelectedItemId}
                      onRemoveSelected={removeSelectedItemId}
                      onClick={(event) => event.preventDefault()}
                    />
                  ),
                },
                {
                  Header: "Change Order",
                  accessor: "pco_display",
                  Cell: ({value, row}) => (
                    <>
                      <StatusIcon status={row.original.status_display} />{" "}
                      <Link href={row.original.url} target="_blank" underline="always">
                        {value} {row.original.pco_is_internal && <i>(Internal)</i>}
                      </Link>
                    </>
                  ),
                },
                {
                  Header: "Item",
                  accessor: "number_display",
                },
                // {
                //   Header: "Ref",
                //   accessor: "budget.reference",
                // },
                {
                  Header: "Budget",
                  accessor: "budget.budget_code",
                },
                {
                  Header: "Description",
                  accessor: "description",
                },
                {
                  Header: "Budget Approved",
                  accessor: "budget_approved_amount",
                  Footer: "Total To Add:",
                  Cell: ({value, row}) => <Currency number={value} precision={2} />,
                },
                // {
                //   Header: "Budget Cost",
                //   accessor: "budget_cost",
                //   Cell: ({value, row}) => <Currency number={value} precision={2} />,
                // },
                {
                  Header: "Cost",
                  accessor: "cost",
                  Cell: ({value, row}) => (
                    <>
                      {value === 0 && (
                        <Tooltip title="Calculated Value">
                          <sup style={{fontSize: "8px"}}>*</sup>
                        </Tooltip>
                      )}
                      <Currency number={value !== 0 ? value : row.original.budget_cost} precision={2} />
                    </>
                  ),
                  Footer: () => (
                    <>
                      <Currency
                        number={sumBy(
                          linkableItems.filter((item) => selectedItemIds.has(item.id)),
                          (item) => (item.cost !== 0 ? item.cost : item.budget_cost)
                        )}
                        precision={2}
                      />
                    </>
                  ),
                },
              ]}
              initialState={
                {
                  // sortBy: [
                  //   {
                  //     id: "display",
                  //     asc: true,
                  //   },
                  // ],
                }
              }
              data={linkableItems}
              getRowProps={(row) => {
                return {
                  onClick: () => {
                    selectedItemIds.has(row.original.id)
                      ? removeSelectedItemId(row.original.id)
                      : addSelectedItemId(row.original.id);
                  },
                };
              }}
              {...rest}
            />
          ) : (
            <Alert severity="info">No Linkable Items Found</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <FormActions.Right>
            <Button
              onClick={() => {
                const corIds = Array.from(selectedItemIds);
                onChangeSelectedItem(corIds);
              }}
              variant="contained"
              color="primary"
              disabled={selectedItemIds.size < 1}
            >
              Add To SCO
            </Button>
          </FormActions.Right>
          <FormActions.CancelButton onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const InternalSCOCreateDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values: any) => void;
  projectId: number;
  [rest: string]: any;
}) => {
  const {handleClose, isOpen, onSubmit, projectId, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">Create SCO</DialogTitle>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirty}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="description" label="Description" required autoFocus />
                  </Grid>
                  <Grid item xs={12}>
                    <CurrencyFieldMui name="amount" label="Amount" required />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <FormActions.Right>
                  <FormActions.CreateButton type="submit" disabled={pristine || submitting} />
                </FormActions.Right>
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ExistingSCOCreateDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values: any) => void;
  projectId: number;
  linkableItems: any[];
  [rest: string]: any;
}) => {
  const {handleClose, isOpen, onSubmit, projectId, linkableItems = [], ...rest} = props;
  const useStyles = makeStyles({
    table: {
      "& .MuiTableCell-sizeSmall": {
        padding: 0, // <-- arbitrary value
      },
    },
  });
  const classes = useStyles();
  const novoClasses = makeNovoClasses();

  // useWhyDidYouUpdate("SCOCreateDialog", props);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">Create SCO</DialogTitle>
        <FinalForm
          onSubmit={onSubmit}
          validate={(values) => {
            const errors = {};
            if (values.items.length === 0) {
              errors["items"] = "Must select one or more items";
            }
            return errors;
          }}
          {...rest}
        >
          {({handleSubmit, form, submitting, pristine, values, errors, dirty}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="description" label="Description" required autoFocus />
                  </Grid>
                  <Grid item xs={12}>
                    {linkableItems.length > 0 ? (
                      <Table
                        size="small"
                        classes={{root: classes.table}}
                        className={classnames(
                          novoClasses.stripedTable,
                          novoClasses.smallTable,
                          novoClasses.boldHeaderTable
                        )}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>COR</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Budget</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Budget Approved</TableCell>
                            <TableCell align="right">Cost</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {linkableItems.map((item) => {
                            return (
                              <TableRow key={item.id}>
                                <TableCell>
                                  <Field type="checkbox" name="items" value={item.id}>
                                    {(props) => (
                                      <Checkbox
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                      />
                                    )}
                                  </Field>
                                </TableCell>
                                <TableCell>
                                  <StatusIcon status={item.status_display} /> {item.pco_display}
                                </TableCell>
                                <TableCell>{item.number_display}</TableCell>
                                <TableCell>{item.budget?.display}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell align="right">
                                  <Currency number={item.budget_approved_amount} precision={2} />
                                </TableCell>
                                <TableCell align="right">
                                  <>
                                    {item.cost === 0 && (
                                      <Tooltip title="Calculated Value">
                                        <sup style={{fontSize: "8px"}}>*</sup>
                                      </Tooltip>
                                    )}
                                    <Currency number={item.cost !== 0 ? item.cost : item.budget_cost} precision={2} />
                                  </>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">
                              <strong>Total To Add:</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>
                                <Currency
                                  number={sumBy(
                                    linkableItems.filter((item) => new Set(values.items).has(item.id)),
                                    (item) => (item.cost !== 0 ? item.cost : item.budget_cost)
                                  )}
                                  precision={2}
                                />
                              </strong>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    ) : (
                      <Alert severity="info">No Linkable Items Found</Alert>
                    )}
                  </Grid>
                </Grid>
                {dirty && errors.items && <FormHelperText error>{errors.items}</FormHelperText>}
                {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(dirty, null, 2)}</pre>
                <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.Right>
                  <FormActions.CreateButton type="submit" disabled={pristine || submitting} />
                </FormActions.Right>
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
